import {
  ContentShare,
} from '@alucio/aws-beacon-amplify/src/models'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { generateClient } from 'aws-amplify/api';
import { commonReducers, initialState } from './common'
import { updateContentShare } from '@alucio/aws-beacon-amplify/src/graphql/mutations';
import store from '../store';
import { UpdateContentShareMutation } from '@alucio/aws-beacon-amplify/src/API';

const sliceName = 'contentShare'
const { reducers, extraReducers } = commonReducers<ContentShare>(sliceName)

const revokeContentShare = createAsyncThunk(
  'contentShare/revokeContentShare',
  async (args: { contentShare: ContentShare }) => {
    const { contentShare } = args
    const appsyncClient = generateClient<UpdateContentShareMutation>();
    const { data } = await appsyncClient.graphql({
      query: updateContentShare,
      variables: {
        input: {
          id: contentShare.id,
          revoked: true,
          updatedAt: new Date().toISOString(),
        },
      },
    });
    if (data?.updateContentShare) {
      // ECFLAG
      store.dispatch(contentShareActions.update(data.updateContentShare as any))
      analytics?.track('HUB_SHARE_REVOKE', {
        action: 'HUB_SHARE_REVOKE',
        category: 'HUB',
      });
    }
  },
)

const contentShareSlice = createSlice({
  name: sliceName,
  initialState: initialState<ContentShare>(),
  reducers: {
    ...reducers,
  },
  extraReducers,
})

export default contentShareSlice
export const contentShareActions = {
  revokeContentShare,
  ...contentShareSlice.actions,
}
