import React, { useEffect, useMemo, useState, PropsWithChildren } from 'react';
import { CognitoUser } from '@alucio/beacon/src/models/User';
import { userActions } from '../../state/redux/slice/user';
import store, { storeReset } from '../../state/redux/store';
// import { useHistory } from 'src/router'

import ActiveUser from 'src/state/global/ActiveUser'
// AMPLIFY
import { Amplify } from 'aws-amplify'
import { amplifyConfig } from '@alucio/aws-beacon-amplify'
import { useAuthenticator } from '@aws-amplify/ui-react-native';
import { DataStore } from 'aws-amplify/datastore';
import { getCurrentUser, fetchAuthSession, signOut } from 'aws-amplify/auth'
import { Hub } from 'aws-amplify/utils'
import { Authenticator as AmplifyAuthenticator, withAuthenticator } from './Authenticator';
import config from 'src/config/app.json';

// Custom Authentication Screens
import SignIn from 'src/screens/Authentication/SignIn/SignIn';
import ForgotPassword from 'src/screens/Authentication/Password/ForgotPassword/ForgotPassword';
import { CognitoIdToken } from 'amazon-cognito-identity-js';
import { useAppSettings } from 'src/state/context/AppSettings';
import Loading from 'src/screens/Loading';
import useFeatureFlags from 'src/hooks/useFeatureFlags/useFeatureFlags';
import { generateZendeskURL } from 'src/utils/zendeskHelpers';
import { ZENDESK_FEATURE } from '@alucio/aws-beacon-amplify/src/API';
import { SignedInProvider } from './services/SignedStateProvider';
import * as logger from 'src/utils/logger'

amplifyConfig.oauth = {
  domain: `${config.authUrl}.auth.us-west-2.amazoncognito.com`,
  scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
  redirectSignIn: `${window.location.origin}/`,
  redirectSignOut: `${window.location.origin}/`,
  responseType: 'code',
}

Amplify.configure(amplifyConfig, {
  API: {
    GraphQL: {
      headers: async () => {
        return new Promise((resolve) => {
          fetchAuthSession().then((session) => {
            // Only override the auth header if we are actually signed in and have tokens
            if (session.tokens) {
              resolve({ Authorization: session.tokens?.idToken?.toString() })
            } else {
              resolve({})
            }
          }).catch(() => {
            resolve({})
          })
        })
      },
    },
  },
});
interface AuthProps {
  authData?: CognitoUser;
}

// Alternatively, don't use the HOC and use the components instead
// https://aws-amplify.github.io/docs/js/authentication#using-the-authenticator-component
const Authenticator: React.FC<PropsWithChildren<AuthProps>> = props => {
  const { children } = props;
  const { isOfflineEnabled, initialUrlSearchParams } = useAppSettings();
  // const history = useHistory()
  const returnTo = initialUrlSearchParams.get('return_to')
  // ECFLAG
  // eslint-disable-next-line
  const state = initialUrlSearchParams.get('state');
  const enableZendeskKnowledgebase = useFeatureFlags('enableZendeskKnowledgebase');
  const { user, route, } = useAuthenticator();

  logger.auth.signIn.authenticator.debug('Authenticator Rendered')
  useEffect(() => {
    // Listen for Auth SignIn event and clear out datastore to handle case if session expires
    // and user signs in again
    // We use a separate AuthListener rather than adding it to the useEffect code below
    // to handle switch from Browser -> PWA (BEAC-2453)
    const authListener = async (data) => {
      switch (data.payload.event) {
        case 'signIn':
          store.dispatch(storeReset())
          await DataStore.clear()
          ActiveUser.clear()
          break
      }
    }
    const removeListener = Hub.listen('auth', authListener);

    return () => removeListener();
  }, [isOfflineEnabled]);

  useEffect(() => {
    logger.auth.signIn.authenticator.debug('useEffect onAuthState/authData Called')
    const init = async () => {
      const cognitoUser = user || await getCurrentUser()
      if (cognitoUser) {
        const userSession = await fetchAuthSession();
        const jwtToken = userSession?.tokens?.idToken?.toString() || '';
        const userAttributtes = new CognitoIdToken({
          IdToken: jwtToken,
        })
          .decodePayload()

        // const attributes = (user. || userAttributtes) as any;
        store.dispatch(
          userActions.setCognitoUser({ username: cognitoUser.username, attributes: userAttributtes }),
        );
        localStorage.setItem('amplify-latest-user-attributes', JSON.stringify({
          username: cognitoUser.username, attributes: userAttributtes,
        }));

        if (returnTo && enableZendeskKnowledgebase) {
          const url = await generateZendeskURL(ZENDESK_FEATURE.SSO, returnTo);
          window.location.href = url;
        }
      }
    }
    init()
  }, [user, enableZendeskKnowledgebase, route]);

  if (returnTo && enableZendeskKnowledgebase) {
    return <Loading context='Redirect Authenticator' />
  }

  return (<>{children}</>)
};

const components = {
  SignIn: SignIn,
  ForgotPassword: ForgotPassword,
};

// ON FIRST LOAD, AMPLIFY CHECKS THE ACCESS TOKEN. IF IT'S EXPIRED, AMPLIFY TRIES TO REFRESH IT.
// IF IT FAILS REFRESHING IT (WHICH WILL HAPPEN IF OFFLINE), IT LOGS OUT THE USER.
// WE WANT TO HANDLE THAT SCENARIO SO IF THE LAST AMPLIFY STATUS IS VALID, WE DON'T LOG THEM OUT.
const PWAAuthenticator: React.FC<PropsWithChildren> = (props) => {
  const { isOnline } = useAppSettings();
  const { route } = useAuthenticator();
  const onLoadAmplifyAuthState = useMemo(
    () => localStorage.getItem('amplify-authenticator-authState') || undefined, [],
  )
  const [cognitoState, setCognitoState] = useState({
    authState: !isOnline ? onLoadAmplifyAuthState : undefined,
    authData: undefined,
  });

  useEffect(() => {
    async function handleOffLineLoad(): Promise<void> {
      let cognitoUser;
      try {
        cognitoUser = await getCurrentUser();
      } catch (e) {
        logger.auth.signIn.authenticator.debug('Authenticated user call failed, ', e);
        logger.auth.signIn.authenticator.debug('Getting CognitoUser from LocalStorage');
        cognitoUser = JSON.parse(localStorage.getItem('amplify-latest-user-attributes') ?? '');
      }

      if (!cognitoUser) {
        logger.auth.signIn.authenticator.debug('CognitoUser from LocalStorage not found, signing Out...');
        logger.auth.signIn.authenticator.debug('Signing Out');
        await signOut();
        setCognitoState({ authState: undefined, authData: undefined });
        return;
      }

      store.dispatch(
        userActions.setCognitoUser({ username: cognitoUser.username, attributes: cognitoUser.attributes }),
      );
    }

    if (!isOnline && onLoadAmplifyAuthState === 'signedIn') {
      handleOffLineLoad();
    }
  }, []);

  const MemoizedAuthenticator = useMemo(() => (
    <Authenticator
      authData={cognitoState.authData}
    >
      {props.children}
    </Authenticator>
  ), [cognitoState]);

  if (route !== 'authenticated') {
    return (
      <AmplifyAuthenticator
        components={components}
      />
    );
  }

  return MemoizedAuthenticator;
};

const AuthenticatorWrapper: React.FC<PropsWithChildren> = (props) => {
  const { isPWAStandalone } = useAppSettings();

  const DesktopAuthenticator = useMemo(() =>
    withAuthenticator(Authenticator, { components }), []);

  const wrapper = isPWAStandalone
    ? (<AmplifyAuthenticator.Provider>
      <PWAAuthenticator {...props} />
    </AmplifyAuthenticator.Provider>) : <DesktopAuthenticator {...props} />;

  return <SignedInProvider>{wrapper}</SignedInProvider>
};

export default AuthenticatorWrapper;
