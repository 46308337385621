import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Image, StyleSheet } from 'react-native'
import { useSelector } from 'react-redux';
import { BroadcastChannel } from 'broadcast-channel'
import throttle from 'lodash/throttle'
import { generateClient } from 'aws-amplify/api';

import { generateTokenForContentAccess } from '@alucio/aws-beacon-amplify/src/graphql/queries';
import AWSConfig from '@alucio/aws-beacon-amplify/src/aws-exports'
import {
  Dimensions,
  PDFChannelMessage,
  PDFMessageTypes,
  PlayerActions,
  PlayerControlData,
  PPZTransform,
  PresentationContentState,
  usePresentationContext,
  SetPresentationMeta,
} from '@alucio/core'
import { v4 as uuid } from 'uuid';
import { RootState } from 'src/state/redux';
import {
  SWIPE_TYPE,
  useContentViewerModalState,
} from 'src/components/ContentPreviewModal/state/ContentViewerModalStateProvider';
import { DeviceMode, useAppSettings } from 'src/state/context/AppSettings'
import { AlucioChannel, DNAAspectRatio, DNABox, Iffy, useSafePromise } from '@alucio/lux-ui'
import { FileType } from '@alucio/aws-beacon-amplify/src/models';
import { RATIOS } from '@alucio/lux-ui/src/components/layout/DNAAspectRatio/DNAAspectRatio';
import { CustomDeckORM, DocumentVersionORM } from 'src/types/types';
import * as logger from 'src/utils/logger'

const styles = StyleSheet.create({
  loaderContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'black',
  },
  loaderImage: {
    height: 200,
    width: 200,
  },
});

export const getDocPath = (documentVersionORM: DocumentVersionORM, isOnline: boolean) => {
  const isContentCached = documentVersionORM.meta.assets.isContentCached
  const converterFolderKeyPath = documentVersionORM.model.convertedFolderKey?.split('/').slice(0, -1).join('/');
  // TODO: REMOVE THIS IN A FUTURE CLEAN UP
  let path;
  if (documentVersionORM.model.type === FileType.PDF) {
    if (isOnline && !isContentCached) path = `${converterFolderKeyPath}/${documentVersionORM.model.id}.pdf`
    else path = `${converterFolderKeyPath}/original.pdf`
  }
  else if (documentVersionORM.model.type === FileType.MP4) path = `${converterFolderKeyPath}`
  else if (documentVersionORM.model.type === FileType.HTML) path = `${converterFolderKeyPath}/html`
  else path = `${converterFolderKeyPath}/html/data/`

  return path;
}

const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

const IFrame: React.FC<{ devQS?: {} }> = () => {
  const [IFrameLoaded, setIFrameLoaded] = useState<'player' | 'ref' | 'both' | undefined>(undefined)
  const [isDocumentLoaded, setIsDocumentLoaded] = useState<boolean>(false);
  const iFrameIdRef = useRef<string>(uuid());
  const [aspectRatio, setAspectRatio] = useState<RATIOS>(RATIOS['16_9'])
  const iframeRef = useRef<HTMLIFrameElement>(null)
  const channel = useRef<BroadcastChannel<PDFChannelMessage>>(
    AlucioChannel.get(AlucioChannel.commonChannels.PDF_CHANNEL),
  )
  const { isOnline } = useAppSettings()

  const {
    activeSlide,
    setActiveSlide,
    activeDocVersionORM,
    setControlsTimeout,
    setCurrentPPZCoords,
    onSwipeAction,
    customDeckORM,
    isFullWindow,
    visiblePages,
    setVisibleSearch,
    setVisibleSidebar,
    getCurrentConsecutiveGroup,
    setContentPanelVisible,
  } = useContentViewerModalState()
  const { deviceMode } = useAppSettings();
  const currentActiveSlideRef = useRef<number>(activeSlide);
  const presentationControl = useSelector((state: RootState) => state.PresentationControl)
  const safePromise = useSafePromise()
  const presentationState = usePresentationContext();
  const onPPTXChange = useRef<() => void | undefined>();
  const customDeckRef = useRef<CustomDeckORM | undefined>(customDeckORM);

  useEffect(() => {
    customDeckRef.current = customDeckORM;
  }, [customDeckORM]);

  const bumpControlsTimeout = useCallback(
    throttle(setControlsTimeout, 100),
    [setControlsTimeout],
  )

  /** BROADCAST IFRAME AUTH SETUP */
  useEffect(() => {
    if (IFrameLoaded === 'both') {
      channel.current.postMessage({
        type: PDFMessageTypes.PLAYER_MODE,
        frameId: iFrameIdRef.current,
        value: {
          mode: 'INTERACTIVE',
        },
      })
    }
  }, [IFrameLoaded])

  useEffect(() => {
    if ((activeDocVersionORM || customDeckRef.current) && IFrameLoaded === 'both') {
      // WE RELOAD THE IFRAME WHEN CHANGING FROM ONE PPTX TO ANOTHER
      // SINCE WE DON'T WANT TO UPDATE THE WHOLE THING BY DOING SETACTIVEDOCVERSION
      // WE STORE IN A REF THE BROADCASTSTEUP CALL FOR WHEN THE IFRAME IS READY
      if (onPPTXChange.current) {
        onPPTXChange.current?.();
        onPPTXChange.current = undefined;
        return;
      }

      safePromise.makeSafe(broadcastSetup(
        activeDocVersionORM,
        (customDeckRef.current ? visiblePages[activeSlide - 1].number : activeSlide),
        true))
        .catch(err => !err.isCanceled
          ? console.error(err)
          : undefined,
        )
    }
  }, [activeDocVersionORM, isOnline, IFrameLoaded, customDeckORM]);

  async function broadcastSetup(documentVersionORM: DocumentVersionORM,
    slide: number,
    showLoader: boolean,
    startLastAnimation?: boolean): Promise<void> {
    logger.iFrameWeb.debug('Starting load of document')
    setIsDocumentLoaded(!showLoader)
    const docPath = getDocPath(documentVersionORM, isOnline)
    const isContentCached = documentVersionORM.meta.assets.isContentCached
    let JWT

    // [TODO-PWA] - Avoid a reload here when possible
    if (isOnline && !isContentCached) {
      if (documentVersionORM.meta.assets?.accessToken) {
        await delay(1000)
        JWT = documentVersionORM.meta.assets?.accessToken
      }
      else {
        const appsyncClient = generateClient();
        const { data } = await appsyncClient.graphql({
          query: generateTokenForContentAccess,
          variables: {
            documentId: documentVersionORM.relations.documentORM.model.id,
            documentVersionId: documentVersionORM.model.id,
            authorizedPath: docPath,
            durationSeconds: 60 * 60,
          },
        });
        if (!data.generateTokenForContentAccess) {
          throw new Error('Could not generate access token for content')
        }
        logger.iFrameWeb.debug('Got JWT Token')

        JWT = data.generateTokenForContentAccess.token
      }
    } else {
      logger.iFrameWeb.debug('Offline or content cached - skip fetching JWT')
    }

    const consecutiveCustomDeckGroup = getCurrentConsecutiveGroup(currentActiveSlideRef.current);
    const bucket = AWSConfig.aws_user_files_s3_bucket;
    const contentInfo = {
      JWT: JWT ?? '',
      bucket,
      docPath: `/content/${docPath}`,
      documentVersionId: documentVersionORM.model.id,
      documentId: documentVersionORM.relations.documentORM.model.id,
      customDeckId: customDeckORM?.model.id,
      groupId: consecutiveCustomDeckGroup?.consecutiveGroupNumber.toString(),
      contentType: documentVersionORM.model.type as FileType,
      state: {
        page: slide,
        step: startLastAnimation ? -1 : 0,
      },
      visiblePages: customDeckORM
        ? consecutiveCustomDeckGroup?.visiblePageNumbers || []
        : visiblePages.map((p) => p.number),
    };
    presentationState.dispatch({
      type: 'presentContent',
      payload: {
        ...contentInfo,
        customDeckId: customDeckRef.current?.model.id,
      },
    });

    channel.current.postMessage({
      type: PDFMessageTypes.LOAD_FILE,
      frameId: iFrameIdRef.current,
      value: contentInfo,
    });

    logger.iFrameWeb.debug('Done transmitting init mesages')
  }

  useEffect(() => {
    if (activeSlide) {
      // IF IT'S A CUSTOM DECK, BEFORE JUST SENDING A "GOTOSLIDE" MSG, WE NEED TO KNOW
      // IF THIS NEW SLIDE BELONGS TO A DIFFERENT DOCUMENT. IF SO, WE'LL CALL BROADCASTSETUP
      // TO SEND A "LOAD A NEW DOCUMENT" MESSAGE INSTEAD
      if (customDeckRef.current) {
        const newConsecutiveCustomDeckGroup = getCurrentConsecutiveGroup(activeSlide);
        const previousConsecutiveCustomDeckGroup = getCurrentConsecutiveGroup(currentActiveSlideRef.current);

        const isDifferentGroup = newConsecutiveCustomDeckGroup?.consecutiveGroupNumber !==
          previousConsecutiveCustomDeckGroup?.consecutiveGroupNumber;
        const newDocumentVersion = visiblePages[activeSlide - 1]?.documentVersionORM;
        const isFromDifferentDocument = newDocumentVersion?.model.id !==
          visiblePages[currentActiveSlideRef.current - 1]?.documentVersionORM?.model.id;
        const isGoingToPrevious = activeSlide === currentActiveSlideRef.current - 1;
        const isPPTX = newDocumentVersion?.model.type === FileType.PPTX;

        if (isFromDifferentDocument && newDocumentVersion) {
          const isPreviousNotPPTX =
            visiblePages[currentActiveSlideRef.current - 1]?.documentVersionORM?.model.type !== FileType.PPTX;
          const changeFileCall = () => {
            safePromise.makeSafe(
              broadcastSetup(newDocumentVersion,
                visiblePages[activeSlide - 1].number,
                false,
                isGoingToPrevious && isPPTX))
              .catch(err => !err.isCanceled
                ? console.error(err)
                : undefined,
              );
            currentActiveSlideRef.current = activeSlide;
          };

          if (!isPPTX || isPreviousNotPPTX) {
            // IF IT'S NOT A PPTX OR WE'RE COMING FROM A NOT PPTX FILE,
            // WE DON'T NEED TO RELOAD THE IFRAME
            changeFileCall();
          } else {
            onPPTXChange.current = changeFileCall;
            iFrameIdRef.current = uuid();
            setIFrameLoaded(undefined);
          }
          return;
        } else if (currentActiveSlideRef.current !== activeSlide) {
          const updatePayload: SetPresentationMeta = {
            slide: visiblePages[activeSlide - 1]?.number,
            step: 0,
          };

          if (isDifferentGroup) {
            updatePayload.visiblePages = newConsecutiveCustomDeckGroup?.visiblePageNumbers;
            updatePayload.groupId = newConsecutiveCustomDeckGroup?.consecutiveGroupNumber.toString();

            if (isPPTX && isGoingToPrevious) {
              updatePayload.step = -1;
            }
          }

          logger.iFrameWeb.debug('Sending a msg update the current state,', updatePayload);
          channel.current.postMessage({
            type: PDFMessageTypes.SET_PRESENTATION_META,
            frameId: iFrameIdRef.current,
            value: updatePayload,
          });
        } else if (isDifferentGroup) {
          channel.current.postMessage({
            type: PDFMessageTypes.SET_PRESENTATION_META,
            frameId: iFrameIdRef.current,
            value: {
              groupId: newConsecutiveCustomDeckGroup?.consecutiveGroupNumber.toString(),
            },
          });
        }
      } else {
        presentationState.dispatch({
          type: 'updateContentState',
          payload: {
            page: customDeckRef.current ? visiblePages[activeSlide - 1].number : activeSlide,
            step: undefined,
          },
        });
      }
      currentActiveSlideRef.current = activeSlide;
    }
  }, [activeSlide]);

  /** BROADCAST IFRAME PRESENTATION CONTROLS */
  useEffect(() => {
    if (!presentationControl.controlMsg || activeDocVersionORM?.meta.allPages.length === 0) return;
    channel.current.postMessage({
      type: PDFMessageTypes.PLAYER_CONTROL,
      frameId: iFrameIdRef.current,
      value: presentationControl.controlMsg,
    })
      .catch(console.warn)
    presentationState.dispatch({
      type: 'recordControlDataMessage',
      payload: presentationControl.controlMsg,
    })
  }, [presentationControl.controlMsg, activeDocVersionORM, customDeckORM]);

  const addIFrameMouseHandler = () => {
    // [TODO-231] This doesn't capture events from hovering the actual PDF
    //  Consider using Broadcast Channel to send events instead
    iframeRef.current
      ?.contentDocument
      ?.addEventListener('mouseover', () => { bumpControlsTimeout() })
  }

  // There seems to be race condition where the IFrame ready event can come before the
  // ref being updated when both are loaded we want to set the mouse event handler
  useEffect(() => {
    if (iframeRef.current && IFrameLoaded === 'both') { addIFrameMouseHandler() }
  }, [IFrameLoaded])

  /** RECEIVE IFRAME MESSAGES */
  useEffect(() => {
    channel.current.onmessage = (msg: PDFChannelMessage) => {
      if (msg.frameId === iFrameIdRef.current) {
        logger.iFrameWeb.debug(`Received message from IFRAME ${msg.type}`, msg)
        if (msg.type === PDFMessageTypes.IFRAME_LOADED) {
          logger.iFrameWeb.debug('Received message from IFRAME: IFRAME_LOADED')
          setIFrameLoaded(p => p === 'ref' || p === 'both' ? 'both' : 'player')
        } else if (msg.type === PDFMessageTypes.DOCUMENT_LOADED) {
          setIsDocumentLoaded(true)
        } else if (msg.type === PDFMessageTypes.PDF_PAGE_LOADED) {
          logger.iFrameWeb.debug('Received message from IFRAME: PDF_PAGE_LOADED')
          const pageBounds = msg.value as Dimensions
          const pageRatio = parseFloat(
            (pageBounds.height / pageBounds.width).toFixed(2),
          )
          setAspectRatio(
            pageRatio === RATIOS['4_3']
              ? RATIOS['4_3']
              : RATIOS['16_9'],
          )
          setIsDocumentLoaded(true)
        } else if (msg.type === PDFMessageTypes.PPZ_TRANSFORM) {
          logger.iFrameWeb.debug('Received message from IFRAME: PPZ_TRANSFORM')
          const coords = msg.value as PPZTransform;
          setCurrentPPZCoords(coords);

          // Todo: There's probably a cleaner way to integrate this into updateContentState
          presentationState.dispatch({
            type: 'recordControlDataMessage',
            payload: new PlayerControlData(
              PlayerActions.animateTransform,
              coords,
            ),
          })

          const updateContentStatePayload: PresentationContentState = {
            viewport: coords,
          }
          presentationState.dispatch({
            type: 'updateContentState',
            payload: updateContentStatePayload,
          })
        } else if (msg.type === PDFMessageTypes.PAGE_CHANGE) {
          const updatedState = msg.value as PresentationContentState;
          let newActiveSlide = updatedState.page;
          let slideFound = false;
          const updatedGroupIdAsNumber = updatedState.groupId ? Number.parseInt(updatedState.groupId, 10) : undefined
          if (customDeckRef.current) {
            const consecutiveCustomDeckGroup = getCurrentConsecutiveGroup(currentActiveSlideRef.current);
            // IF IT'S A CUSTOM DECK, WE NEED TO FIND, IN OUR VISIBLE PAGES ARRAY
            // THE INDEX OF THE NEW PAGE TO SET AS ACTIVESLIDE
            visiblePages.forEach((page, index) => {
              if (!slideFound && page.documentVersionORM?.model.id === updatedState?.documentVersionId &&
                page.number === newActiveSlide &&
                consecutiveCustomDeckGroup?.consecutiveGroupNumber === updatedGroupIdAsNumber &&
                consecutiveCustomDeckGroup?.groupIds.includes(page?.groupId || '')) {
                newActiveSlide = index + 1;
                slideFound = true;
              }
            });

            // IF THIS IS FALSE, IT MEANS THE PLAYER TRIGGERED PAGE_CHANGE AFTER CHANGING THE GROUP/DOCVER
            // AND WE DON'T NEED TO UPDATE THE ACTIVE SLIDE
            if (!slideFound) {
              return;
            }
          }

          presentationState.dispatch({
            type: 'updateContentState',
            payload: updatedState,
          });
          setActiveSlide(newActiveSlide)
        } else if (msg.type === PDFMessageTypes.SWIPE_VERTICAL) {
          onSwipeAction?.(SWIPE_TYPE.VERTICAL);
        } else if (msg.type === PDFMessageTypes.NAVIGATE_PAST_LAST) {
          // IF IT'S TRYING TO GO BEYOND THE VISIBLEPAGES AND IT'S A CUSTOM DECK,
          // WE NEED TO SELECT THE NEXT DOCVERSION/PAGE OF THE DECK
          if (customDeckRef.current) {
            logger.iFrameWeb.debug('handling NAVIGATE_PAST_LAST', currentActiveSlideRef.current)
            const nextPage = visiblePages[currentActiveSlideRef.current];
            if (nextPage) {
              logger.iFrameWeb.debug('Navigating to next slide')
              setActiveSlide(currentActiveSlideRef.current + 1);
            }
          }
        } else if (msg.type === PDFMessageTypes.NAVIGATE_PAST_FIRST) {
          // IF IT'S TRYING TO GO BEFORE THE VISIBLEPAGES AND IT'S A CUSTOM DECK,
          // WE NEED TO SELECT THE PREVIOUS DOCVERSION/PAGE OF THE DECK
          if (customDeckRef.current) {
            const previousPage = visiblePages[currentActiveSlideRef.current - 2];
            if (previousPage) {
              setActiveSlide(currentActiveSlideRef.current - 1);
            }
          }
        } else if (msg.type === PDFMessageTypes.SHOW_SEARCH) {
          setVisibleSearch((prev) => {
            if (!prev) {
              analytics.track('DOCUMENT_SEARCH_START', {
                action: 'SEARCH_START',
                category: 'DOCUMENT',
                documentId: activeDocVersionORM.relations.documentORM.model.id,
                documentVersionId: activeDocVersionORM.model.id,
              });
            }
            return true
          });
          setVisibleSidebar(undefined);
        } else if (msg.type === PDFMessageTypes.SHOW_MY_CONTENT) {
          setContentPanelVisible(true);
        }
        else {
          logger.iFrameWeb.warn(`Received unknown message from IFRAME: ${msg.type}`)
        }
      }
    }

    // Generally we should remove the channel, but there's a race condition
    //  since when the component goes full screen it remounts
    //  and closing is an async operation
    // return () => AlucioChannel.remove(PDF_CHANNEL)
  }, [customDeckORM?.model.id, activeDocVersionORM?.model.id])

  // We can also run the PDF-Presentation-Player locally and connect the iFrame to that instead
  // However, we cannot load auth files since the iFrame is considered on a different domain
  // const optionalDevQS = qs.stringify(devQS, { addQueryPrefix: true })

  const iFrameProps = {
    frameBorder: 0,
    ref: (refVal: HTMLIFrameElement) => {
      // NOTE: This ref callback is executed multiple times
      if (!iframeRef.current) {
        setIFrameLoaded(p => p === 'player' || p === 'both' ? 'both' : 'ref')
      }

      // @ts-ignore - is fine
      iframeRef.current = refVal
    },
    // eslint-disable-next-line max-len
    src: `/pdf/index.html?frameId=${iFrameIdRef.current}&isFullWindow=${isFullWindow}&isTabletMode=${deviceMode === DeviceMode.tablet}&allowSearch=${!customDeckORM}`,
    // src={`http://localhost:3002/index.html${optionalDevQS}`}
    style: { flex: 1 },
    id: `player-iframe-${activeDocVersionORM?.model.id}`,
  }

  return (
    <DNAAspectRatio
      ratio={aspectRatio}
      enabled={activeDocVersionORM.model.type === 'PPTX'}
    >
      <iframe {...iFrameProps} />
      <Iffy is={!isDocumentLoaded}>
        <DNABox
          alignX="center"
          alignY="center"
          style={styles.loaderContainer}
        >
          <Image
            testID="dna-loader"
            style={styles.loaderImage}
            source={require('../../../../../assets/images/LoaderDNA_dark.gif')}
          />

        </DNABox>
      </Iffy>
    </DNAAspectRatio>
  )
}

export default IFrame
