import React, { useMemo, useState } from 'react';
import { GenericToast, ToastOrientations, useToast } from '@alucio/lux-ui';
import { DNAModalVariant } from '../DNAConnectedModal';
import { FolderORM } from 'src/types/orms';
import ActiveUser from 'src/state/global/ActiveUser';
import { useTenant } from 'src/state/redux/selector/tenant';
import {
  LabelValues,
  PermissionType,
  SharePermission,
  ShareTargetType,
  User,
} from '@alucio/aws-beacon-amplify/src/models';
import ShareFolder from './ShareFolder';
import CreateAccessFilter from './CreateAccessFilter';
import { v4 as uuid } from 'uuid';
import { folderActions } from 'src/state/redux/slice/folder';
import { useDispatch } from 'src/state/redux';
import UnshareWarning from './UnshareWarning';
import { DeviceMode, useAppSettings } from 'src/state/context/AppSettings';
import AddPeople from './AddPeople/AddPeople';
import useFeatureFlags from 'src/hooks/useFeatureFlags/useFeatureFlags';
import {
  PERMISSION_TYPE,
  SHARE_TARGET_TYPE,
  SharePermissionInput,
} from '@alucio/aws-beacon-amplify/src/API';
import { generateClient } from 'aws-amplify/api';
import { updateFolderSharePermissions } from '@alucio/aws-beacon-amplify/src/graphql/mutations';

export type AccessFilterType = {
  [x: string]: LabelValues;
}
export interface ShareFilter {
  id: string,
  filter: User | AccessFilterType | 'ALL',
  targetType: ShareTargetType,
  type: PermissionType,
  // TO KNOW WHETHER WE NEED TO UPDATE
  // THE UPDATED/CREATED AT OR NOT
  // (FOR ALREADY EXISTING FILTERS)
  createdAt?: string,
  updatedAt?: string,
  isDeleted: boolean,
  deletedAt?: string,
  deletedBy?: string,
}

const filterToString = (filter: AccessFilterType) => {
  return Object.keys(filter).map((key) => {
    return filter[key].key + '-' + filter[key].values.join(',');
  }).join(',');
}

export const isEveryoneType = (item: User | AccessFilterType | 'ALL') : item is 'ALL' => {
  return item === 'ALL';
}

export const isUserType = (item: User | AccessFilterType | 'ALL'): item is User => {
  return !isEveryoneType(item) && Object.keys(item).includes('id');
}

export const isFilterType = (item: User | AccessFilterType | 'ALL'): item is AccessFilterType => {
  return !isEveryoneType(item) && !Object.keys(item).includes('id');
}

export enum ShareFolderMode {
  CREATE_ACCESS_FILTER,
  SHARE_FOLDER,
  UNSHARE,
  ADD_PEOPLE,
}

const DNAShareFolderModal: DNAModalVariant<{ folderORM: FolderORM }> = (props) => {
  const tenant = useTenant(ActiveUser.user!.tenantId);
  const { deviceMode } = useAppSettings();
  const isDesktop = deviceMode === DeviceMode.desktop;
  const tenantUsers = tenant?.meta.users || [];
  const { folderORM, toggleModal } = props;
  const [currentItems, setCurrentItems] = useState<ShareFilter[]>(initializeCurrentItems())
  const initialValues = useMemo(() => currentItems, []);
  const toast = useToast()
  const dispatch = useDispatch();
  const [mode, setMode] = useState<ShareFolderMode>(ShareFolderMode.SHARE_FOLDER);
  const [sendEmailNotification, setSendEmailNotification] = useState(true);
  const enableCustomDecksCollaborationEdit = useFeatureFlags('enableCustomDecksCollaborationEdit');
  const [isProcessing, setIsProcessing] = useState(false);

  const handleAddItem = (item: User | AccessFilterType | 'ALL') => {
    const itemExists = currentItems.find((shareFilter) => {
      if (isEveryoneType(shareFilter.filter) && isEveryoneType(item)) {
        return shareFilter.targetType === ShareTargetType.ALL && !shareFilter.isDeleted;
      }
      else if (isUserType(shareFilter.filter) && isUserType(item)) {
        return item.id === shareFilter.filter.id && !shareFilter.isDeleted;
      }
      else if (isFilterType(shareFilter.filter) && isFilterType(item)) {
        return filterToString(shareFilter.filter) === filterToString(item) && !shareFilter.isDeleted;
      }
    })

    if (itemExists) {
      const textError = isUserType(item) ? 'User already on the list' : 'Filter already on the list'
      toast.add(
        <GenericToast
          title={textError}
          status="error"
        />,
        ToastOrientations.TOP_RIGHT,
      )
      setMode(ShareFolderMode.SHARE_FOLDER);
      return
    }

    const payload = {
      id: uuid(),
      filter: item,
      isDeleted: false,
      type: !enableCustomDecksCollaborationEdit ? PermissionType.EDIT : PermissionType.VIEW,
    }

    if (isUserType(item)) {
      setCurrentItems((prev) => [...prev, {
        ...payload,
        targetType: ShareTargetType.USER,
      }])
    }

    else if (isFilterType(item)) {
      setCurrentItems((prev) => [{
        ...payload,
        targetType: ShareTargetType.FILTER,
      }, ...prev])
    }

    else if (isEveryoneType(item)) {
      setCurrentItems((prev) => [{
        ...payload,
        targetType: ShareTargetType.ALL,
        filter: 'ALL',
      }, ...prev])
    }
    setMode(ShareFolderMode.SHARE_FOLDER);
  }

  const handleTogglePermission = (id: string) => {
    setCurrentItems((prev) => prev.map((item) => {
      const shouldUpdate = item.id === id;
      return {
        ...item,
        type: shouldUpdate
          ? (item.type === PermissionType.EDIT ? PermissionType.VIEW : PermissionType.EDIT)
          : item.type,
        updatedAt:  shouldUpdate ? new Date().toISOString() : item.updatedAt,
      }
    }))
  }

  const handleRemoveItem = (idToRemove: string) => {
    if (initialValues.find((item) => item.id === idToRemove)) {
      setCurrentItems((prev) => prev.map((item) => {
        const shouldUpdate = item.id === idToRemove;
        return {
          ...item,
          isDeleted: shouldUpdate ? true : item.isDeleted,
          deletedAt: shouldUpdate ? new Date().toISOString() : item.deletedAt,
          deletedBy: shouldUpdate ? ActiveUser.user!.id : item.deletedBy,
        }
      }));
    }
    else {
      setCurrentItems((prev) => prev.filter((item) => item.id !== idToRemove));
    }
  }

  const handleShareWithEveryone = () => {
    handleAddItem('ALL');
  }

  function initializeCurrentItems(): ShareFilter[] {
    const items = folderORM.relations.sharePermissions.reduce<ShareFilter[]>((acc, permissionORM) => {
      const { model: permission, meta: { customFilterValues } } = permissionORM;
      const user = tenantUsers.find(({ id }) => id === permission.targetUsername);
      const filterObj = {}

      Object.keys(customFilterValues).forEach((fieldId) => {
        if (customFilterValues[fieldId].valuesDefinition?.length) {
          const valuesIds: string[] = [];
          customFilterValues[fieldId].valuesDefinition?.forEach((valueDefinition) => {
            if (!valueDefinition.disabled) {
              valuesIds.push(valueDefinition.id);
            }
          });
          if (valuesIds.length) {
            filterObj[fieldId] = {
              key: fieldId,
              values: valuesIds,
            }
          }
        }
      });

      // IGNORE THE PERMISSION IF NO VALUE IS FOUND (VALUES MIGHT BE DISABLED)
      if (permission.targetType === ShareTargetType.FILTER && !Object.keys(filterObj).length) {
        return acc;
      }

      acc.push({
        id: permission.id,
        targetType: permission.targetType as ShareTargetType,
        filter: permission.targetType === ShareTargetType.ALL ? 'ALL' : (user || filterObj),
        isDeleted: !!permission.isDeleted,
        type: permission.type as PermissionType,
      });

      return acc;
    }, []);
    return items;
  }

  const handleUnshare = () => {
    const sharePermissions: SharePermission[] = currentItems?.map((item) => {
      const now = new Date().toISOString();
      const isUser = isUserType(item.filter);
      const sharePermission: SharePermission = {
        id: item.id,
        isDeleted: true,
        createdAt: item.createdAt || now,
        updatedAt: item.updatedAt || now,
        createdBy: ActiveUser.user!.id,
        updatedBy: ActiveUser.user!.id,
        targetUsername: isUserType(item.filter) ? item.filter.id : undefined,
        targetType: isUser ? ShareTargetType.USER : ShareTargetType.FILTER,
        type: item.type,
        deletedAt: now,
        deletedBy: ActiveUser.user!.id,
      };

      return sharePermission;
    }) || [];

    analytics?.track('FOLDER_UNSHARE_ALL', {
      action: 'UNSHARE_ALL',
      category: 'FOLDER',
      folderId: folderORM.model.id,
    });
    dispatch(folderActions.updateSharePermission(sharePermissions, folderORM.model));
    toggleModal();
  }

  const handleConfirm = async () => {
    const now = new Date().toISOString();
    if (!currentItems.length) {
      return;
    }
    setIsProcessing(true);

    const sharePermissions: SharePermissionInput[] = currentItems.map((item) => {
      const sharePermission: SharePermissionInput = {
        id: item.id,
        isDeleted: item.isDeleted,
        createdAt: item.createdAt || now,
        updatedAt: item.updatedAt || now,
        createdBy: ActiveUser.user!.id,
        updatedBy: ActiveUser.user!.id,
        targetUsername: isUserType(item.filter) ? item.filter.id : undefined,
        targetType: SHARE_TARGET_TYPE[item.targetType],
        type: PERMISSION_TYPE[item.type],
        targetCustomValues: [],
        deletedAt: item.deletedAt,
        deletedBy: item.deletedBy,
      };

      if (isFilterType(item.filter)) {
        Object.keys(item.filter).forEach((fieldId) => {
          sharePermission.targetCustomValues?.push({
            fieldId,
            values: item.filter[fieldId].values,
          });
        });
      }
      return sharePermission;
    }) || [];

    analytics?.track('FOLDER_SHARE_SAVE', {
      action: 'SHARE_SAVE',
      category: 'FOLDER',
      folderId: folderORM.model.id,
    });
    await updateSharePermission(sharePermissions);
    setIsProcessing(false);
  }

  const updateSharePermission = async (sharePermissions: SharePermissionInput[]) => {
    try {
      const appsyncClient = generateClient();
      await appsyncClient.graphql({
        query: updateFolderSharePermissions,
        variables: { folderId: folderORM.model.id, sharePermissions, sendEmailNotification },
      });

      toggleModal()
    } catch (error) {
      console.error('An error occurred while updating share permissions:', error);
    }
  }

  return (
    <>
      <ShareFolder
        {...props}
        setMode={setMode}
        currentItems={currentItems}
        onRemoveItem={handleRemoveItem}
        onConfirm={handleConfirm}
        togglePermission={handleTogglePermission}
        onShareWithEveryone={handleShareWithEveryone}
        initialValues={initialValues}
        isDesktop={isDesktop}
        display={mode === ShareFolderMode.SHARE_FOLDER}
        isProcessing={isProcessing}
        sendEmailNotification={sendEmailNotification}
        setSendEmailNotification={setSendEmailNotification}
      />
      <AddPeople
        {...props}
        setMode={setMode}
        display={mode === ShareFolderMode.ADD_PEOPLE}
        currentItems={currentItems}
        onAddItem={handleAddItem}
      />
      <CreateAccessFilter
        {...props}
        folderName={folderORM.model.name}
        setMode={setMode}
        isDesktop={isDesktop}
        onCreateFilter={handleAddItem}
        display={mode === ShareFolderMode.CREATE_ACCESS_FILTER}
      />
      <UnshareWarning
        {...props}
        setMode={setMode}
        display={mode === ShareFolderMode.UNSHARE}
        onUnshare={handleUnshare}
      />
    </>
  )
}

export default DNAShareFolderModal;
