import { UserRole } from '@alucio/aws-beacon-amplify/src/models';
import { useEffect, useState } from 'react';
import { useCurrentUserORM } from 'src/state/redux/selector/user';
import { fetchAuthSession } from 'aws-amplify/auth';

const useIsTenantAdmin = () => {
  const [isTenantAdmin, setIsTenantAdmin] = useState(false);
  const currentUser = useCurrentUserORM();

  const checkTenantAdmin = async () => {
    const authSession = await fetchAuthSession();
    const userAttributtes = authSession.tokens?.accessToken.payload
    if (userAttributtes && userAttributtes['cognito:groups']) {
      // cognito group can get stale / not in sync so it's important we check for both values here in the frontend and the backend;
      const isTenantAdminUserORM =
        Array.prototype.includes.call(currentUser?.model.additionalRoles ?? [], UserRole.TENANT_ADMIN);
      const isTenantAdminCognito =
        Array.prototype.includes.call(userAttributtes['cognito:groups'], UserRole.TENANT_ADMIN);
      setIsTenantAdmin(isTenantAdminUserORM && isTenantAdminCognito);
    }
  }
  useEffect(() => {
    checkTenantAdmin().catch(console.error);
  }, [])

  return isTenantAdmin;
}

export default useIsTenantAdmin;
