import {
  DNABox,
  DNAButton,
  DNAModal,
  DNAText,
  GenericToast,
  ToastOrientations,
  useToast,
} from '@alucio/lux-ui';
import { useForm, Controller } from 'react-hook-form'
import * as z from 'zod'
import React from 'react';
import { DNAModalVariant } from 'src/components/DNA/Modal/DNAConnectedModal';
import InputComponent from 'src/components/Publishers/InputComponent';
import { zodResolver } from '@hookform/resolvers/zod';

import { generateClient } from 'aws-amplify/api';
import { createTenantLambda } from '@alucio/aws-beacon-amplify/src/graphql/mutations';
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors';

export type DNAFileShareModalProps = {}

const formSchema = z.object({
  name: z
    .string()
    .nonempty({ message: 'This field is required' }),
})

const NewTenantModal: DNAModalVariant<DNAFileShareModalProps> = props => {
  const { closeModal } = props;

  const toast = useToast();

  const form = useForm({
    resolver: zodResolver(formSchema),
    mode: 'onChange',
    defaultValues: {
      name: '',
    },
  })

  const {
    control, handleSubmit,
    formState: { errors, isValid, isSubmitting },
  } = form

  const onSubmit = async (data: any) => {
    try {
      const appsyncClient = generateClient();
      await appsyncClient.graphql({
        query: createTenantLambda,
        variables: {
          tenant: {
            name: data.name,
          },
        },
      });

      toast?.add(
        <GenericToast title={'New organization created'} status="success" />, ToastOrientations.TOP_RIGHT,
      );
      form.reset()
      closeModal()
    } catch (e) {
      form.setError('name', { message: 'Issue saving please contact support' });
    }
  }

  return (
    <DNAModal>
      <DNAModal.Header onClose={closeModal}>
        <DNAText h5>Create new organization</DNAText>
      </DNAModal.Header>
      <DNAModal.Body>
        <DNABox appearance="col" style={{ minWidth: 560, minHeight: 120, marginTop: 24 }}>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <InputComponent
                {...field}
                title="ORGANIZATION NAME"
                required
                hideLabel={false}
                numOfLines={1}
                characterLimit={100}
                disabled={false}
                showCharacterCounter
                inputStyle={errors.name && { borderColor: colors['color-danger-500'] }}
                removeMarginPadding={true}
              />
            )}
          />
          {
            errors.name &&
              <DNAText status="danger">
                {errors.name.message}
              </DNAText>
          }
        </DNABox>
      </DNAModal.Body>
      <DNAModal.Footer>
        <DNABox fill alignX="end">
          <DNAButton size="sm" appearance="outline" status="tertiary" onPress={closeModal}>
            Cancel
          </DNAButton>
          <DNAButton
            size="sm"
            style={{ marginLeft: 8 }}
            disabled={!isValid || isSubmitting}
            onPress={handleSubmit(onSubmit)}
          >
            Create
          </DNAButton>
        </DNABox>
      </DNAModal.Footer>
    </DNAModal>
  )
}

export default NewTenantModal;
