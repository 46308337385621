import { fetchAuthSession } from 'aws-amplify/auth'
import CacheDB from 'src/worker/db/cacheDB';
import ActiveUser from 'src/state/global/ActiveUser'
import { DataStore } from 'aws-amplify/datastore';
import workerChannel from 'src/worker/channels/workerChannel'
import store, { storeReset } from '../../../state/redux/store';
import { Hub } from '@aws-amplify/core';
import { deleteDB } from 'idb';
import { AlucioChannel } from '@alucio/lux-ui';
import { SessionStatus } from '../../IdleComponent/IdleComponent';
import { WebLinking as Linking } from '@alucio/core';
import { isIOS } from 'react-device-detect';

// Analitycs DB
import { clear as clearIdb } from 'src/utils/analytics/queueIdb';
import { withStore as withclearAnalyticsStore } from 'src/utils/analytics/index';
import { withStore as withclearLoggerStore } from 'src/state/machines/logger/util';
import { CRMUtil } from 'src/state/machines/CRM/util';
import setupCacheDB from 'src/worker/db/setupCacheDB';
import { PrevUserType } from 'src/state/machines/auth/validateUserMachine';
import * as logger from 'src/utils/logger';

interface IAuthUtil {
  DBS: string[];
  cacheDB: CacheDB;
  crmDB: CRMUtil;
  prevAuthUserKey: string;
  idleChannelName: string;
  cleanLocalStorageKeys: string[];
  disableWorkerOnLogOut: boolean;
  isLoggingOut: boolean;
  purgeCacheDB: () => Promise<void>;
  clearUserDetailLocalStorage: () => void;
  cleanCRMData: () => Promise<void>;
  resetRedux: () => void;
  /**
   * when the user is logged out we show a message to the user
   * and we send a message to the alucio channel to notify the user is logged out
   */
  sendLogOutMessageToChannel: (reason: SessionStatus) => void;
  pauseSync: () => void;
  /**
   *  method in charge to clean the data store indexDB when is required
   */
  clearDataStore: () => Promise<void>;
  hubDispatch(): void;
  setPrevAuthUser(): Promise<void>;
  getPrevAuthUser(): PrevUserType;
  clearOfflineAnalyticsDB(): Promise<void>;
  cleanGlobalState(): void;
}

class AuthUtil implements IAuthUtil {
  DBS = ['amplify-datastore']
  cacheDB: CacheDB
  crmDB: CRMUtil;
  prevAuthUserKey = 'prevAuthUser'
  idleChannelName = 'beacon-idle'
  cleanLocalStorageKeys = [
    'amplify-latest-user-attributes',
    'hasDatastoreHydrated',
  ]

  disableWorkerOnLogOut = false
  isLoggingOut = false
  isStarted = false

  constructor() {
    this.cacheDB = new CacheDB()
    this.crmDB = new CRMUtil()
  }

  /*
    it is in charge to clean the index db used for offline mode to cache all the documents
  */
  // [TODO] - Repeated code of the logout function in `App.tsx`?
  public purgeCacheDB = async () => {
    try {
      logger.auth.signIn.authenticator.info('purgeCacheDB function called.');
      const cacheDB = this.cacheDB

      if (!cacheDB) {
        logger.auth.signIn.authenticator.error('cacheDB is not defined');
        return
      }

      if (!cacheDB) throw new Error('CacheDB is not initialized')

      await cacheDB.open()
      logger.auth.signIn.authenticator.info('cacheDB opened.');
      await cacheDB.purge()
      logger.auth.signIn.authenticator.info('cacheDB purged.');
      await cacheDB.close()
      logger.auth.signIn.authenticator.info('cacheDB closed.');

      // for the restriction of safary of clearing the index db only after the pwa is closed
      // we delete the db and we create the db again, this seems to work at least on the simulator
      if (isIOS) {
        await deleteDB('CacheDB')
        logger.auth.signIn.authenticator.info('CacheDB deleted.')

        await setupCacheDB()
        logger.auth.signIn.authenticator.info('CacheDB setup.')
      }
    } catch (e) {
      logger.auth.signIn.authenticator.warn('Error occurred purging cacheDB', e);
    }
  }

  /*
    Clear user details in local storage (used for offline)
  */
  public clearUserDetailLocalStorage = () => {
    logger.auth.signOut.info('Clearing user details in localStorage: ', this.cleanLocalStorageKeys)
    this.cleanLocalStorageKeys.forEach(key => localStorage.removeItem(key))
  }

  /*
      Clean the database used to store the CRM data
  */
  public cleanCRMData = async () => {
    logger.auth.signOut.info('Clearing CRM DB')
    await this.crmDB.cleanLogOutCRMStorage()
  }

  /*
      clean the redux store
  */
  public resetRedux = () => {
    store.dispatch(storeReset())
  }

  /*
    In charge of distpach the amplify event for logout
  */
  public hubDispatch() {
    logger.auth.signOut.info('Dispatching Logout Event to AWS Auth')
    Hub.dispatch('logout', { event: 'logout' });
  }

  /*
    @param {string} username - identifier that will be used to check if the user was logged previously
    used to store the previous user logged email
    this will check next time that the user is logged in
    and if the user is the same we will not purge clean some index DBS
  */
  public async setPrevAuthUser() {
    try {
      logger.auth.signIn.authenticator.info('setPrevAuthUser function called.');
      const userEmail = await this.getCurrentUserEmail()
      logger.auth.signIn.authenticator.info('Current user email retrieved: ', userEmail);

      const prevUser = {
        email: userEmail,
        date: new Date(),
      }

      logger.auth.signIn.authenticator.info('Previous user object created: ', prevUser);

      userEmail && localStorage.setItem(this.prevAuthUserKey, JSON.stringify(prevUser))
      logger.auth.signIn.authenticator.info('Previous user object stored in local storage.');
    }
    catch (e) {
      logger.auth.signIn.authenticator.warn('Error occurred in setPrevAuthUser function', e);
      throw e
    }
  }

  /*
      @return {PrevUserType} - the previous user logged email
  */
  public getPrevAuthUser(): PrevUserType {
    const prevUserTypeObject = localStorage.getItem(this.prevAuthUserKey)
    if (!prevUserTypeObject || prevUserTypeObject === null) return null

    const prevUserType: PrevUserType = JSON.parse(prevUserTypeObject)
    return prevUserType
  }

  /**
* This function retrieves the email of the current user.
* It uses the `AuthUtil.getCurrentUserEmail` method to fetch the email.
* If the method fails, it logs the error and tries again after a delay of 1 second.
* The function will attempt to retrieve the email up to a maximum number of retries.
* If it still can't get the email after the maximum number of retries, it will return null.
*
* There might be a potential race condition in this function. If the system is under heavy load,
* the `AuthUtil.getCurrentUserEmail` method might not always return the user's email as expected.
* This could be due to the asynchronous nature of the method, which might not have completed its execution
* before the next iteration of the while loop begins.
*
* @param {number} maxRetries - The maximum number of times to retry fetching the email.
* @returns {Promise<string | undefined | null>} The email of the current user, or null if the email could not be retrieved after maximum retries, or undefined if the email could not be retrieved.
*/
  public async getEmail(maxRetries = 10) {
    let email: string | null | undefined = null || undefined;
    let retries = 0;

    try {
      email = await this.getCurrentUserEmail();
    } catch (error) {
      logger.auth.signIn.authenticator.error('Failed to get current user email', error);
    }

    while (email === null && retries < maxRetries) {
      await new Promise(resolve => setTimeout(resolve, 1000));
      try {
        email = await this.getCurrentUserEmail();
      } catch (error) {
        logger.auth.signIn.authenticator.error('Retrying failed to get current user email', error);
      }
      retries++;
    }

    if (retries === maxRetries) {
      logger.auth.signIn.authenticator.warn(`Could not get current user's email after ${maxRetries} tries`)
      return null;
    }

    return email;
  }

  /**
  * when the user is logged out we show a message to the user
  * and we send a message to the alucio channel to notify the user is logged out
  */
  public sendLogOutMessageToChannel = (
    reason: SessionStatus,
  ) => {
    logger.auth.signOut.info('Sending Logout message to other App instances')
    AlucioChannel.get(this.idleChannelName)?.postMessage(reason);
  }

  public pauseSync = () => {
    workerChannel.postMessageExtended({ type: 'PAUSE_SYNC' })
  }

  /**
   *  method in charge to clean the data store indexDB when is required
   */
  public clearDataStore = async () => {
    this.isLoggingOut = true;
    logger.auth.signOut.info('Clearing DataStore')
    await DataStore.clear()
    logger.auth.signOut.info('DataStore cleared')
    logger.auth.signOut.info('Stopping DataStore')
    await DataStore.stop()
    logger.auth.signOut.info('DataStore stopped')
    return Promise.resolve()
  }

  /**
   * start data store
   * after a multiple checks we found that the data store is not starting
   * after the user is logged out
   * this method is in charge to start the data store
   * we have a wait of 1 second to make sure that the data store is started
   * this method is a good candidate to a patch to do a better implementation
   */
  public async dataStoreInit() {
    this.startDataStore()
  }

  /**
   * Sometimes data store failes to start could be multiple reason for instance the db is not ready
   * we want to make sure that the data store is started
   */
  private startDataStore = async () => {
    this.isLoggingOut = false;
    if (this.isStarted) {
      logger.auth.signIn.info('DataStore already started, skipping start request')
      return
    }
    logger.auth.signIn.info('Starting DataStore')
    await DataStore.start()
    this.enforceDataStoreInit()
  }

  /**
   * Data store is not starting after the user is logged in due multiple reasons, some of those are still unknown
   * this method is in charge to make sure that the data store is started to avoid the infinite loading spinner
   */
  private enforceDataStoreInit = async () => {
    const interval = setInterval(() => {
      // eslint-disable-next-line dot-notation
      if (!DataStore['initialized'] && !this.isLoggingOut) {
        const authState = localStorage.getItem('amplify-authenticator-authState');
        if (authState === 'signedIn') {
          logger.auth.signIn.info('Force starting DataStore')
          DataStore.start()
          clearInterval(interval)
        }
      }
    }, 1000)
  }

  /*
    clean analytics DB
    this db is connected with segment is in charge to save the events that occurs during the offline mode
    and sync them with segment when the user is online
  */
  public async clearOfflineAnalyticsDB() {
    try {
      logger.auth.signIn.info('Clearing Offline Analytics DB')
      await clearIdb(withclearAnalyticsStore);
    }
    catch (e) {
      logger.auth.signIn.authenticator.warn('Error clearing analytics queue', e)
    }
  }

  /*
    clean logger DB
    this db is in charge for saving the logger logs
  */
  public async clearLoggerDB() {
    try {
      logger.auth.signIn.info('Clearing Offline Logger DB')
      await clearIdb(withclearLoggerStore);
    }
    catch (e) {
      logger.auth.signIn.authenticator.warn('Error clearing logger indexdb', e)
    }
  }

  /*
    we used a global var to store the active user this should clean all the data related to the user in the global state
  */
  public cleanGlobalState() {
    logger.auth.signIn.info('Clearing active user singleton')
    ActiveUser.clear()
  }

  public clearPrevAuthUser = () => {
    const prevUser = this.getPrevAuthUser()
    if (!prevUser || prevUser === null) {
      logger.auth.signIn.authenticator.info('No previous user found, skipping clearing prevAuthUser')
      return
    }

    // check if the prev user date is older than 1 minute, to avoid having race conditions
    const now = new Date()
    const diff = now.getTime() - new Date(prevUser.date).getTime()
    const minutes = Math.floor(diff / 60000)
    if (minutes < 1) {
      logger.auth.signIn.authenticator.info('Previous user data is less than 1 minute old, not clearing.')
      return
    }
    localStorage.removeItem(this.prevAuthUserKey)
    logger.auth.signIn.authenticator.info('Previous user data cleared from local storage.')
  }

  /*
    get the current user email if the user is logged if not an undefined value will be returned
  */
  public async getCurrentUserEmail(): Promise<string | undefined> {
    const validUser = await this.isAValidUser()
    if (!validUser) {
      logger.auth.signIn.authenticator.info('No valid user found.')
      return
    }

    const userInfo = (await fetchAuthSession())

    if (userInfo.tokens?.idToken?.payload.email) {
      logger.auth.signIn.authenticator.info('User email retrieved.')
    } else {
      logger.auth.signIn.authenticator.info('No user email found.')
    }

    return userInfo.tokens?.idToken?.payload.email as string
  }

  /**
   * remove all indexedDBs declared on the internal var DBS
   */
  public async deleteDBs() {
    const dbsToDelete = this.DBS
    for (const idbName of dbsToDelete) {
      try {
        await deleteDB(idbName)
        logger.auth.signIn.authenticator.info(`Successfully deleted indexDB ${idbName}`)
      } catch (e) {
        logger.auth.signIn.authenticator.error(`Failed to delete indexDB ${idbName}. Error: ${e}`)
      }
    }
  }

  /*
    clear the storage keys after logout
  */
  private clearLocalStorage() {
    this.cleanLocalStorageKeys.forEach(key => localStorage.removeItem(key))
  }

  /*
     used to check if the user session is still valid
  */
  private async isAValidUser(): Promise<boolean> {
    try {
      // ECFLAG
      await fetchAuthSession()
      return true
    }
    catch (e) {
      return false
    }
  }

  /*
      method in charge to remove the webworker
    */
  public static disableServiceWorker = async () => {
    logger.auth.signIn.authenticator.info('disableServiceWorker function called.');
    // We subscribe to watch for a purge success message from the SW before terminating
    // the service worker
    workerChannel
      .observable
      .filter(msg => msg.type === 'CACHE_DB' && msg.value === 'PURGE_COMPLETE')
      .subscribe(async () => {
        logger.auth.signIn.authenticator
          .info('Purge complete, message received. Unregistering service worker and reloading app');
        const registration = await navigator.serviceWorker.getRegistration('/')
        registration?.unregister().then(() => {
          Linking.openURL('/profile/offline', '_self');
        })
      })

    logger.auth.signIn.authenticator.info('Posting PURGE message to workerChannel.');
    workerChannel.postMessageExtended({
      type: 'CACHE_DB',
      value: 'PURGE',
    })
  }
}

export default new AuthUtil();
