import { Dispatch } from 'redux'
import {
  BookmarkedDoc,
  Document,
  PurposeType,
  User,
} from '@alucio/aws-beacon-amplify/src/models';
import { userActions } from 'src/state/redux/slice/user';
import { CurrentUser } from 'src/state/redux/selector/user';
import { DataStore } from 'aws-amplify/datastore'

/** [TODO] This can be added in meta on documentORM */
function toPurposeText(purpose: PurposeType): string {
  switch (purpose) {
    case PurposeType.INTERNAL_USE_ONLY: {
      return 'INT';
    }
    case PurposeType.EXTERNAL_PROACTIVE: {
      return 'EP';
    }
    case PurposeType.EXTERNAL_REACTIVE: {
      return 'ER';
    }
    default: {
      return '';
    }
  }
}

function handleBookmark(doc: Document, user: CurrentUser, dispatch: Dispatch): void {
  const now = new Date().toISOString();

  const { userProfile } = user;
  let isBookmarked = true;

  let bookmarkedDocsModified: any[] | null = [];
  const bookmarked = {
    docID: doc.id,
    createdAt: now,
  }

  function getBookmarkDocIndex(document: Document, bookmarkedList: BookmarkedDoc[]) {
    return bookmarkedList.findIndex(bookmark => bookmark.docID === document.id);
  }

  async function updateUser(modifiedBookmarks: BookmarkedDoc[]) {
    if (userProfile) {
      // We need to re-fetch the user from DataStore since we always want to be updating the user in datastore
      // sometimes the object could be one that we directly queried from AppSync (due to field level restrictions)
      const userObj = await DataStore.query(User, userProfile.id)
      dispatch(
        userActions.bookmark({
          model: User,
          entity: userObj!,
          updates: {
            // @ts-ignore [BEAC-399] - The Amplify models do not accept null,
            //  however it's a valid value in DynamoDB?
            bookmarkedDocs: modifiedBookmarks,
          },
        }),
      );
    }
  }

  if (userProfile) {
    if (userProfile.bookmarkedDocs) {
      const docIndex = getBookmarkDocIndex(doc, userProfile.bookmarkedDocs);
      bookmarkedDocsModified = [...userProfile.bookmarkedDocs];

      if (docIndex < 0) {
        bookmarkedDocsModified.push(bookmarked);
      } else {
        isBookmarked = false;
        bookmarkedDocsModified.splice(docIndex, 1);
      }
    } else {
      bookmarkedDocsModified = [bookmarked];
    }

    if (isBookmarked) {
      analytics?.track('DOCUMENT_BOOKMARK', {
        action: 'BOOKMARK',
        category: 'DOCUMENT',
        documentId: doc.id,
      });
    } else {
      analytics?.track('DOCUMENT_REMOVE_BOOKMARK', {
        action: 'REMOVE_BOOKMARK',
        category: 'DOCUMENT',
        documentId: doc.id,
      });
    }

    updateUser(bookmarkedDocsModified);
  }
}

function customLog (message?, color?, size?) {
  const multiParam = (typeof message === 'object' && Array.isArray(message))
  if (typeof message === 'string') {
    console.warn(
      '%c%s',
      `color:${color || 'red'}; font-size:${size || '30px'}; text-shadow:2px 2px #000; font-family:arial`,
      message,
    )
  } else if (multiParam) {
    console.warn(
      '%c%s',
      `color:${color || 'red'}; font-size:${size || '30px'}; text-shadow:2px 2px #000; font-family:arial`,
      ...message,
    )
  }
}

export {
  toPurposeText,
  handleBookmark,
  customLog,
}
