import workerChannel from '../channels/workerChannel';
import { ConsoleLogger as Logger } from 'aws-amplify/utils';
const logger = new Logger('Online Checker', 'INFO');

const setupOnlineChecker = (url: string, reduceCheckingPeriod?: boolean) => {
  const timeoutGap = reduceCheckingPeriod ? 10000 : 30000;
  let isOnline = navigator.onLine;
  setTimeout(async () => {
    logger.debug('Checking client\'s connectivity');

    if (!navigator.onLine) {
      sendStatusMessage(false);
    } else {
      isOnline = await canPingURL(url);
      sendStatusMessage(isOnline);
    }
    setupOnlineChecker(url, !isOnline);
  }, timeoutGap);
};

export const canPingURL = async(url: string): Promise<boolean> => {
  try {
    const controller = new AbortController();
    // IF THE REQUEST TAKES LONGER THAN 5 SECONDS,
    // IT'LL BE ABORTED AND CONSIDERED AS IF THERE'S NO CONNECTION
    const timeout = setTimeout(() => controller.abort(), 5000);
    const response = await fetch(url, { method: 'HEAD', signal: controller.signal });
    clearTimeout(timeout);
    return !!response.ok;
  } catch (e) {
    logger.debug('An error occurred:', e);
    return false;
  }
};

export const sendStatusMessage = (isOnline: boolean): void => {
  const connectivity = isOnline ? 'ONLINE' : 'OFFLINE';
  logger.debug(`Sending [${connectivity}] message`);
  workerChannel.postMessageExtended({
    type: 'NETWORK_STATUS',
    value: connectivity,
  })
};

export default setupOnlineChecker;
