/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createUser = /* GraphQL */ `mutation CreateUser(
  $input: CreateUserInput!
  $condition: ModelUserConditionInput
) {
  createUser(input: $input, condition: $condition) {
    id
    cognitoSubId
    tenantId
    email
    givenName
    phoneNumber
    familyName
    meetingId
    defaultFiltersCustomValues {
      fieldId
      objectRecords {
        id
        externalId
        syncStatus
        status
        values {
          fieldId
          values
          __typename
        }
        __typename
      }
      values
      __typename
    }
    lockedFiltersCustomValues {
      fieldId
      objectRecords {
        id
        externalId
        syncStatus
        status
        values {
          fieldId
          values
          __typename
        }
        __typename
      }
      values
      __typename
    }
    defaultFilterValues {
      key
      values
      __typename
    }
    lockedFilters {
      key
      values
      __typename
    }
    shareBCC
    shareCC
    createdAt
    createdBy
    updatedAt
    status
    role
    bookmarkedDocs {
      docID
      createdAt
      __typename
    }
    isExcludedFromReporting
    additionalRoles
    altUsername
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserMutationVariables,
  APITypes.CreateUserMutation
>;
export const updateUser = /* GraphQL */ `mutation UpdateUser(
  $input: UpdateUserInput!
  $condition: ModelUserConditionInput
) {
  updateUser(input: $input, condition: $condition) {
    id
    cognitoSubId
    tenantId
    email
    givenName
    phoneNumber
    familyName
    meetingId
    defaultFiltersCustomValues {
      fieldId
      objectRecords {
        id
        externalId
        syncStatus
        status
        values {
          fieldId
          values
          __typename
        }
        __typename
      }
      values
      __typename
    }
    lockedFiltersCustomValues {
      fieldId
      objectRecords {
        id
        externalId
        syncStatus
        status
        values {
          fieldId
          values
          __typename
        }
        __typename
      }
      values
      __typename
    }
    defaultFilterValues {
      key
      values
      __typename
    }
    lockedFilters {
      key
      values
      __typename
    }
    shareBCC
    shareCC
    createdAt
    createdBy
    updatedAt
    status
    role
    bookmarkedDocs {
      docID
      createdAt
      __typename
    }
    isExcludedFromReporting
    additionalRoles
    altUsername
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserMutationVariables,
  APITypes.UpdateUserMutation
>;
export const deleteUser = /* GraphQL */ `mutation DeleteUser(
  $input: DeleteUserInput!
  $condition: ModelUserConditionInput
) {
  deleteUser(input: $input, condition: $condition) {
    id
    cognitoSubId
    tenantId
    email
    givenName
    phoneNumber
    familyName
    meetingId
    defaultFiltersCustomValues {
      fieldId
      objectRecords {
        id
        externalId
        syncStatus
        status
        values {
          fieldId
          values
          __typename
        }
        __typename
      }
      values
      __typename
    }
    lockedFiltersCustomValues {
      fieldId
      objectRecords {
        id
        externalId
        syncStatus
        status
        values {
          fieldId
          values
          __typename
        }
        __typename
      }
      values
      __typename
    }
    defaultFilterValues {
      key
      values
      __typename
    }
    lockedFilters {
      key
      values
      __typename
    }
    shareBCC
    shareCC
    createdAt
    createdBy
    updatedAt
    status
    role
    bookmarkedDocs {
      docID
      createdAt
      __typename
    }
    isExcludedFromReporting
    additionalRoles
    altUsername
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserMutationVariables,
  APITypes.DeleteUserMutation
>;
export const createTenant = /* GraphQL */ `mutation CreateTenant(
  $input: CreateTenantInput!
  $condition: ModelTenantConditionInput
) {
  createTenant(input: $input, condition: $condition) {
    id
    name
    licensedUsers
    status
    fields {
      fieldName
      description
      required
      isEmailTemplateFilter
      dataType
      userFilter
      defaultSearchFilter
      values
      __typename
    }
    folderUpdateGracePeriodDays
    medInfoURL
    publisherListConfig {
      fields {
        fieldName
        header
        width
        __typename
      }
      sort {
        field
        isAsc
        __typename
      }
      __typename
    }
    mslListConfig {
      fields {
        fieldName
        header
        width
        __typename
      }
      sort {
        field
        isAsc
        __typename
      }
      __typename
    }
    featureFlags {
      flagId
      value
      __typename
    }
    createdAt
    createdBy
    updatedAt
    statusChangedAt
    statusChangedBy
    ssoDomains
    integrations
    config {
      requiredSlidesHiddenMessage
      forms {
        id
        name
        label
        fields {
          id
          fieldName
          fieldLabel
          reportingName
          status
          description
          required
          maxLength
          formatValidation
          fieldType
          showByDefault
          dependentFieldId
          controlType
          order
          settings {
            isBadge
            fieldPosition
            __typename
          }
          usage
          documentSettings {
            fieldPosition
            __typename
          }
          fieldValueDefinitions {
            id
            value
            label
            disabled
            isDefault
            documentSettings {
              presentationWatermarkText
              permission {
                present
                modify
                share
                download
                externalNotation
                __typename
              }
              __typename
            }
            badgeColor
            badgeLabel
            createdAt
            dependentValueIds
            __typename
          }
          displayOnParentSelection
          displayOnValueSelection
          objectSetting {
            childrenFieldIds
            restriction {
              syncUpdate
              syncDelete
              update
              delete
              __typename
            }
            __typename
          }
          isChildField
          dateRestriction
          createdAt
          scaleNumber
          precisionNumber
          __typename
        }
        targetRequestTemplate
        targetType
        targetAddress
        targetParameters {
          key
          value
          __typename
        }
        status
        __typename
      }
      meetingFields {
        id
        fieldName
        status
        description
        required
        maxLength
        type
        controlType
        fieldValueDefinitions {
          id
          value
          disabled
          createdAt
          __typename
        }
        createdAt
        __typename
      }
      customFields {
        id
        fieldName
        fieldLabel
        reportingName
        status
        description
        required
        maxLength
        formatValidation
        fieldType
        showByDefault
        dependentFieldId
        controlType
        order
        settings {
          isBadge
          fieldPosition
          __typename
        }
        usage
        documentSettings {
          fieldPosition
          __typename
        }
        fieldValueDefinitions {
          id
          value
          label
          disabled
          isDefault
          documentSettings {
            presentationWatermarkText
            permission {
              present
              modify
              share
              download
              externalNotation
              __typename
            }
            __typename
          }
          badgeColor
          badgeLabel
          createdAt
          dependentValueIds
          __typename
        }
        displayOnParentSelection
        displayOnValueSelection
        objectSetting {
          childrenFieldIds
          restriction {
            syncUpdate
            syncDelete
            update
            delete
            __typename
          }
          __typename
        }
        isChildField
        dateRestriction
        createdAt
        scaleNumber
        precisionNumber
        __typename
      }
      defaultDocumentPermissions {
        present
        modify
        share
        download
        externalNotation
        __typename
      }
      mslListConfig {
        sort {
          field
          isAsc
          __typename
        }
        fields {
          field
          width
          __typename
        }
        __typename
      }
      publisherListConfig {
        sort {
          field
          isAsc
          __typename
        }
        fields {
          field
          width
          __typename
        }
        __typename
      }
      crmIntegration {
        id
        crmIntegrationType
        instanceUrl
        clientId
        redirectUri
        name
        accountsSettings {
          query
          displaySettings {
            shortened
            extended
            __typename
          }
          addressSettings {
            alias
            displaySettings {
              shortened
              extended
              __typename
            }
            __typename
          }
          __typename
        }
        meetingSetting {
          apiName
          presentationsFieldName
          children {
            name
            apiName
            relationshipName
            type
            veevaSetting {
              markerFieldName
              __typename
            }
            __typename
          }
          type
          __typename
        }
        additionalSettings {
          allowCommentsOnTasks
          detailContentTableSettings {
            name
            apiName
            relationshipName
            prefix
            __typename
          }
          enableSurveys
          crmAccountToObjectSettings {
            accountFieldNames {
              accountFieldName
              objectFieldName
              __typename
            }
            apiName
            __typename
          }
          __typename
        }
        loginUrl
        crmStandaloneForms {
          id
          apiName
          children {
            name
            apiName
            relationshipName
            type
            veevaSetting {
              markerFieldName
              __typename
            }
            __typename
          }
          type
          parents {
            apiName
            relationshipName
            __typename
          }
          usage
          __typename
        }
        __typename
      }
      sessionTimeout
      quickFilters {
        id
        label
        path
        __typename
      }
      hubsConfig {
        shareExpirationDays
        disabledSections
        disableNotationDescription
        __typename
      }
      reports {
        key
        value
        __typename
      }
      emailDocumentUpdateFrequency
      sharePPTXasPDF
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTenantMutationVariables,
  APITypes.CreateTenantMutation
>;
export const updateTenant = /* GraphQL */ `mutation UpdateTenant(
  $input: UpdateTenantInput!
  $condition: ModelTenantConditionInput
) {
  updateTenant(input: $input, condition: $condition) {
    id
    name
    licensedUsers
    status
    fields {
      fieldName
      description
      required
      isEmailTemplateFilter
      dataType
      userFilter
      defaultSearchFilter
      values
      __typename
    }
    folderUpdateGracePeriodDays
    medInfoURL
    publisherListConfig {
      fields {
        fieldName
        header
        width
        __typename
      }
      sort {
        field
        isAsc
        __typename
      }
      __typename
    }
    mslListConfig {
      fields {
        fieldName
        header
        width
        __typename
      }
      sort {
        field
        isAsc
        __typename
      }
      __typename
    }
    featureFlags {
      flagId
      value
      __typename
    }
    createdAt
    createdBy
    updatedAt
    statusChangedAt
    statusChangedBy
    ssoDomains
    integrations
    config {
      requiredSlidesHiddenMessage
      forms {
        id
        name
        label
        fields {
          id
          fieldName
          fieldLabel
          reportingName
          status
          description
          required
          maxLength
          formatValidation
          fieldType
          showByDefault
          dependentFieldId
          controlType
          order
          settings {
            isBadge
            fieldPosition
            __typename
          }
          usage
          documentSettings {
            fieldPosition
            __typename
          }
          fieldValueDefinitions {
            id
            value
            label
            disabled
            isDefault
            documentSettings {
              presentationWatermarkText
              permission {
                present
                modify
                share
                download
                externalNotation
                __typename
              }
              __typename
            }
            badgeColor
            badgeLabel
            createdAt
            dependentValueIds
            __typename
          }
          displayOnParentSelection
          displayOnValueSelection
          objectSetting {
            childrenFieldIds
            restriction {
              syncUpdate
              syncDelete
              update
              delete
              __typename
            }
            __typename
          }
          isChildField
          dateRestriction
          createdAt
          scaleNumber
          precisionNumber
          __typename
        }
        targetRequestTemplate
        targetType
        targetAddress
        targetParameters {
          key
          value
          __typename
        }
        status
        __typename
      }
      meetingFields {
        id
        fieldName
        status
        description
        required
        maxLength
        type
        controlType
        fieldValueDefinitions {
          id
          value
          disabled
          createdAt
          __typename
        }
        createdAt
        __typename
      }
      customFields {
        id
        fieldName
        fieldLabel
        reportingName
        status
        description
        required
        maxLength
        formatValidation
        fieldType
        showByDefault
        dependentFieldId
        controlType
        order
        settings {
          isBadge
          fieldPosition
          __typename
        }
        usage
        documentSettings {
          fieldPosition
          __typename
        }
        fieldValueDefinitions {
          id
          value
          label
          disabled
          isDefault
          documentSettings {
            presentationWatermarkText
            permission {
              present
              modify
              share
              download
              externalNotation
              __typename
            }
            __typename
          }
          badgeColor
          badgeLabel
          createdAt
          dependentValueIds
          __typename
        }
        displayOnParentSelection
        displayOnValueSelection
        objectSetting {
          childrenFieldIds
          restriction {
            syncUpdate
            syncDelete
            update
            delete
            __typename
          }
          __typename
        }
        isChildField
        dateRestriction
        createdAt
        scaleNumber
        precisionNumber
        __typename
      }
      defaultDocumentPermissions {
        present
        modify
        share
        download
        externalNotation
        __typename
      }
      mslListConfig {
        sort {
          field
          isAsc
          __typename
        }
        fields {
          field
          width
          __typename
        }
        __typename
      }
      publisherListConfig {
        sort {
          field
          isAsc
          __typename
        }
        fields {
          field
          width
          __typename
        }
        __typename
      }
      crmIntegration {
        id
        crmIntegrationType
        instanceUrl
        clientId
        redirectUri
        name
        accountsSettings {
          query
          displaySettings {
            shortened
            extended
            __typename
          }
          addressSettings {
            alias
            displaySettings {
              shortened
              extended
              __typename
            }
            __typename
          }
          __typename
        }
        meetingSetting {
          apiName
          presentationsFieldName
          children {
            name
            apiName
            relationshipName
            type
            veevaSetting {
              markerFieldName
              __typename
            }
            __typename
          }
          type
          __typename
        }
        additionalSettings {
          allowCommentsOnTasks
          detailContentTableSettings {
            name
            apiName
            relationshipName
            prefix
            __typename
          }
          enableSurveys
          crmAccountToObjectSettings {
            accountFieldNames {
              accountFieldName
              objectFieldName
              __typename
            }
            apiName
            __typename
          }
          __typename
        }
        loginUrl
        crmStandaloneForms {
          id
          apiName
          children {
            name
            apiName
            relationshipName
            type
            veevaSetting {
              markerFieldName
              __typename
            }
            __typename
          }
          type
          parents {
            apiName
            relationshipName
            __typename
          }
          usage
          __typename
        }
        __typename
      }
      sessionTimeout
      quickFilters {
        id
        label
        path
        __typename
      }
      hubsConfig {
        shareExpirationDays
        disabledSections
        disableNotationDescription
        __typename
      }
      reports {
        key
        value
        __typename
      }
      emailDocumentUpdateFrequency
      sharePPTXasPDF
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateTenantMutationVariables,
  APITypes.UpdateTenantMutation
>;
export const deleteTenant = /* GraphQL */ `mutation DeleteTenant(
  $input: DeleteTenantInput!
  $condition: ModelTenantConditionInput
) {
  deleteTenant(input: $input, condition: $condition) {
    id
    name
    licensedUsers
    status
    fields {
      fieldName
      description
      required
      isEmailTemplateFilter
      dataType
      userFilter
      defaultSearchFilter
      values
      __typename
    }
    folderUpdateGracePeriodDays
    medInfoURL
    publisherListConfig {
      fields {
        fieldName
        header
        width
        __typename
      }
      sort {
        field
        isAsc
        __typename
      }
      __typename
    }
    mslListConfig {
      fields {
        fieldName
        header
        width
        __typename
      }
      sort {
        field
        isAsc
        __typename
      }
      __typename
    }
    featureFlags {
      flagId
      value
      __typename
    }
    createdAt
    createdBy
    updatedAt
    statusChangedAt
    statusChangedBy
    ssoDomains
    integrations
    config {
      requiredSlidesHiddenMessage
      forms {
        id
        name
        label
        fields {
          id
          fieldName
          fieldLabel
          reportingName
          status
          description
          required
          maxLength
          formatValidation
          fieldType
          showByDefault
          dependentFieldId
          controlType
          order
          settings {
            isBadge
            fieldPosition
            __typename
          }
          usage
          documentSettings {
            fieldPosition
            __typename
          }
          fieldValueDefinitions {
            id
            value
            label
            disabled
            isDefault
            documentSettings {
              presentationWatermarkText
              permission {
                present
                modify
                share
                download
                externalNotation
                __typename
              }
              __typename
            }
            badgeColor
            badgeLabel
            createdAt
            dependentValueIds
            __typename
          }
          displayOnParentSelection
          displayOnValueSelection
          objectSetting {
            childrenFieldIds
            restriction {
              syncUpdate
              syncDelete
              update
              delete
              __typename
            }
            __typename
          }
          isChildField
          dateRestriction
          createdAt
          scaleNumber
          precisionNumber
          __typename
        }
        targetRequestTemplate
        targetType
        targetAddress
        targetParameters {
          key
          value
          __typename
        }
        status
        __typename
      }
      meetingFields {
        id
        fieldName
        status
        description
        required
        maxLength
        type
        controlType
        fieldValueDefinitions {
          id
          value
          disabled
          createdAt
          __typename
        }
        createdAt
        __typename
      }
      customFields {
        id
        fieldName
        fieldLabel
        reportingName
        status
        description
        required
        maxLength
        formatValidation
        fieldType
        showByDefault
        dependentFieldId
        controlType
        order
        settings {
          isBadge
          fieldPosition
          __typename
        }
        usage
        documentSettings {
          fieldPosition
          __typename
        }
        fieldValueDefinitions {
          id
          value
          label
          disabled
          isDefault
          documentSettings {
            presentationWatermarkText
            permission {
              present
              modify
              share
              download
              externalNotation
              __typename
            }
            __typename
          }
          badgeColor
          badgeLabel
          createdAt
          dependentValueIds
          __typename
        }
        displayOnParentSelection
        displayOnValueSelection
        objectSetting {
          childrenFieldIds
          restriction {
            syncUpdate
            syncDelete
            update
            delete
            __typename
          }
          __typename
        }
        isChildField
        dateRestriction
        createdAt
        scaleNumber
        precisionNumber
        __typename
      }
      defaultDocumentPermissions {
        present
        modify
        share
        download
        externalNotation
        __typename
      }
      mslListConfig {
        sort {
          field
          isAsc
          __typename
        }
        fields {
          field
          width
          __typename
        }
        __typename
      }
      publisherListConfig {
        sort {
          field
          isAsc
          __typename
        }
        fields {
          field
          width
          __typename
        }
        __typename
      }
      crmIntegration {
        id
        crmIntegrationType
        instanceUrl
        clientId
        redirectUri
        name
        accountsSettings {
          query
          displaySettings {
            shortened
            extended
            __typename
          }
          addressSettings {
            alias
            displaySettings {
              shortened
              extended
              __typename
            }
            __typename
          }
          __typename
        }
        meetingSetting {
          apiName
          presentationsFieldName
          children {
            name
            apiName
            relationshipName
            type
            veevaSetting {
              markerFieldName
              __typename
            }
            __typename
          }
          type
          __typename
        }
        additionalSettings {
          allowCommentsOnTasks
          detailContentTableSettings {
            name
            apiName
            relationshipName
            prefix
            __typename
          }
          enableSurveys
          crmAccountToObjectSettings {
            accountFieldNames {
              accountFieldName
              objectFieldName
              __typename
            }
            apiName
            __typename
          }
          __typename
        }
        loginUrl
        crmStandaloneForms {
          id
          apiName
          children {
            name
            apiName
            relationshipName
            type
            veevaSetting {
              markerFieldName
              __typename
            }
            __typename
          }
          type
          parents {
            apiName
            relationshipName
            __typename
          }
          usage
          __typename
        }
        __typename
      }
      sessionTimeout
      quickFilters {
        id
        label
        path
        __typename
      }
      hubsConfig {
        shareExpirationDays
        disabledSections
        disableNotationDescription
        __typename
      }
      reports {
        key
        value
        __typename
      }
      emailDocumentUpdateFrequency
      sharePPTXasPDF
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteTenantMutationVariables,
  APITypes.DeleteTenantMutation
>;
export const createDocumentVersion = /* GraphQL */ `mutation CreateDocumentVersion(
  $input: CreateDocumentVersionInput!
  $condition: ModelDocumentVersionConditionInput
) {
  createDocumentVersion(input: $input, condition: $condition) {
    id
    tenantId
    documentId
    versionNumber
    srcFilename
    contentURL
    conversionStatus
    status
    srcFile {
      bucket
      region
      key
      url
      __typename
    }
    srcHash
    srcSize
    numPages
    pageSettings {
      pageId
      number
      isRequired
      linkedSlides
      __typename
    }
    pageGroups {
      id
      pageIds
      name
      locked
      sourceID
      source
      __typename
    }
    type
    releaseNotes
    changeType
    labelValues {
      key
      value
      __typename
    }
    customValues {
      fieldId
      objectRecords {
        id
        externalId
        syncStatus
        status
        values {
          fieldId
          values
          __typename
        }
        __typename
      }
      values
      __typename
    }
    title
    shortDescription
    longDescription
    owner
    expiresAt
    hasCopyright
    hasCustomThumbnail
    purpose
    canHideSlides
    distributable
    downloadable
    isInternalGenerated
    semVer {
      minor
      major
      __typename
    }
    notificationScope
    selectedThumbnail
    publishedAt
    uploadedAt
    uploadedBy
    convertedArchiveKey
    convertedArchiveSize
    convertedFolderKey
    associatedFiles {
      id
      isDistributable
      isDefault
      type
      attachmentId
      status
      createdAt
      createdBy
      __typename
    }
    editPermissions
    converterVersion
    createdAt
    createdBy
    updatedAt
    updatedBy
    integration {
      externalVersionId
      version
      timestamp
      srcFileHash
      srcDocumentHash
      __typename
    }
    integrationType
    conversionWarningCode
    conversionErrorCode
    scheduledPublish
    mappingStatus
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDocumentVersionMutationVariables,
  APITypes.CreateDocumentVersionMutation
>;
export const updateDocumentVersion = /* GraphQL */ `mutation UpdateDocumentVersion(
  $input: UpdateDocumentVersionInput!
  $condition: ModelDocumentVersionConditionInput
) {
  updateDocumentVersion(input: $input, condition: $condition) {
    id
    tenantId
    documentId
    versionNumber
    srcFilename
    contentURL
    conversionStatus
    status
    srcFile {
      bucket
      region
      key
      url
      __typename
    }
    srcHash
    srcSize
    numPages
    pageSettings {
      pageId
      number
      isRequired
      linkedSlides
      __typename
    }
    pageGroups {
      id
      pageIds
      name
      locked
      sourceID
      source
      __typename
    }
    type
    releaseNotes
    changeType
    labelValues {
      key
      value
      __typename
    }
    customValues {
      fieldId
      objectRecords {
        id
        externalId
        syncStatus
        status
        values {
          fieldId
          values
          __typename
        }
        __typename
      }
      values
      __typename
    }
    title
    shortDescription
    longDescription
    owner
    expiresAt
    hasCopyright
    hasCustomThumbnail
    purpose
    canHideSlides
    distributable
    downloadable
    isInternalGenerated
    semVer {
      minor
      major
      __typename
    }
    notificationScope
    selectedThumbnail
    publishedAt
    uploadedAt
    uploadedBy
    convertedArchiveKey
    convertedArchiveSize
    convertedFolderKey
    associatedFiles {
      id
      isDistributable
      isDefault
      type
      attachmentId
      status
      createdAt
      createdBy
      __typename
    }
    editPermissions
    converterVersion
    createdAt
    createdBy
    updatedAt
    updatedBy
    integration {
      externalVersionId
      version
      timestamp
      srcFileHash
      srcDocumentHash
      __typename
    }
    integrationType
    conversionWarningCode
    conversionErrorCode
    scheduledPublish
    mappingStatus
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDocumentVersionMutationVariables,
  APITypes.UpdateDocumentVersionMutation
>;
export const deleteDocumentVersion = /* GraphQL */ `mutation DeleteDocumentVersion(
  $input: DeleteDocumentVersionInput!
  $condition: ModelDocumentVersionConditionInput
) {
  deleteDocumentVersion(input: $input, condition: $condition) {
    id
    tenantId
    documentId
    versionNumber
    srcFilename
    contentURL
    conversionStatus
    status
    srcFile {
      bucket
      region
      key
      url
      __typename
    }
    srcHash
    srcSize
    numPages
    pageSettings {
      pageId
      number
      isRequired
      linkedSlides
      __typename
    }
    pageGroups {
      id
      pageIds
      name
      locked
      sourceID
      source
      __typename
    }
    type
    releaseNotes
    changeType
    labelValues {
      key
      value
      __typename
    }
    customValues {
      fieldId
      objectRecords {
        id
        externalId
        syncStatus
        status
        values {
          fieldId
          values
          __typename
        }
        __typename
      }
      values
      __typename
    }
    title
    shortDescription
    longDescription
    owner
    expiresAt
    hasCopyright
    hasCustomThumbnail
    purpose
    canHideSlides
    distributable
    downloadable
    isInternalGenerated
    semVer {
      minor
      major
      __typename
    }
    notificationScope
    selectedThumbnail
    publishedAt
    uploadedAt
    uploadedBy
    convertedArchiveKey
    convertedArchiveSize
    convertedFolderKey
    associatedFiles {
      id
      isDistributable
      isDefault
      type
      attachmentId
      status
      createdAt
      createdBy
      __typename
    }
    editPermissions
    converterVersion
    createdAt
    createdBy
    updatedAt
    updatedBy
    integration {
      externalVersionId
      version
      timestamp
      srcFileHash
      srcDocumentHash
      __typename
    }
    integrationType
    conversionWarningCode
    conversionErrorCode
    scheduledPublish
    mappingStatus
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDocumentVersionMutationVariables,
  APITypes.DeleteDocumentVersionMutation
>;
export const createAttachedFile = /* GraphQL */ `mutation CreateAttachedFile(
  $input: CreateAttachedFileInput!
  $condition: ModelAttachedFileConditionInput
) {
  createAttachedFile(input: $input, condition: $condition) {
    id
    title
    tenantId
    documentId
    srcFile {
      bucket
      region
      key
      url
      __typename
    }
    srcFileName
    srcHash
    srcSize
    type
    editPermissions
    createdAt
    createdBy
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAttachedFileMutationVariables,
  APITypes.CreateAttachedFileMutation
>;
export const updateAttachedFile = /* GraphQL */ `mutation UpdateAttachedFile(
  $input: UpdateAttachedFileInput!
  $condition: ModelAttachedFileConditionInput
) {
  updateAttachedFile(input: $input, condition: $condition) {
    id
    title
    tenantId
    documentId
    srcFile {
      bucket
      region
      key
      url
      __typename
    }
    srcFileName
    srcHash
    srcSize
    type
    editPermissions
    createdAt
    createdBy
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAttachedFileMutationVariables,
  APITypes.UpdateAttachedFileMutation
>;
export const deleteAttachedFile = /* GraphQL */ `mutation DeleteAttachedFile(
  $input: DeleteAttachedFileInput!
  $condition: ModelAttachedFileConditionInput
) {
  deleteAttachedFile(input: $input, condition: $condition) {
    id
    title
    tenantId
    documentId
    srcFile {
      bucket
      region
      key
      url
      __typename
    }
    srcFileName
    srcHash
    srcSize
    type
    editPermissions
    createdAt
    createdBy
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAttachedFileMutationVariables,
  APITypes.DeleteAttachedFileMutation
>;
export const createDocument = /* GraphQL */ `mutation CreateDocument(
  $input: CreateDocumentInput!
  $condition: ModelDocumentConditionInput
) {
  createDocument(input: $input, condition: $condition) {
    id
    tenantId
    accessLevel
    status
    owner
    expiresAt
    integration {
      integrationId
      externalId
      firstSync
      lastSync
      __typename
    }
    integrationType
    type
    editPermissions
    createdAt
    createdBy
    updatedAt
    updatedBy
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDocumentMutationVariables,
  APITypes.CreateDocumentMutation
>;
export const updateDocument = /* GraphQL */ `mutation UpdateDocument(
  $input: UpdateDocumentInput!
  $condition: ModelDocumentConditionInput
) {
  updateDocument(input: $input, condition: $condition) {
    id
    tenantId
    accessLevel
    status
    owner
    expiresAt
    integration {
      integrationId
      externalId
      firstSync
      lastSync
      __typename
    }
    integrationType
    type
    editPermissions
    createdAt
    createdBy
    updatedAt
    updatedBy
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDocumentMutationVariables,
  APITypes.UpdateDocumentMutation
>;
export const deleteDocument = /* GraphQL */ `mutation DeleteDocument(
  $input: DeleteDocumentInput!
  $condition: ModelDocumentConditionInput
) {
  deleteDocument(input: $input, condition: $condition) {
    id
    tenantId
    accessLevel
    status
    owner
    expiresAt
    integration {
      integrationId
      externalId
      firstSync
      lastSync
      __typename
    }
    integrationType
    type
    editPermissions
    createdAt
    createdBy
    updatedAt
    updatedBy
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDocumentMutationVariables,
  APITypes.DeleteDocumentMutation
>;
export const createEmailTemplate = /* GraphQL */ `mutation CreateEmailTemplate(
  $input: CreateEmailTemplateInput!
  $condition: ModelEmailTemplateConditionInput
) {
  createEmailTemplate(input: $input, condition: $condition) {
    id
    tenantId
    labelValues {
      key
      value
      __typename
    }
    customFilterValues {
      fieldId
      objectRecords {
        id
        externalId
        syncStatus
        status
        values {
          fieldId
          values
          __typename
        }
        __typename
      }
      values
      __typename
    }
    subject
    title
    cc
    bcc
    body
    associatedFiles {
      id
      isDistributable
      isDefault
      type
      attachmentId
      status
      createdAt
      createdBy
      __typename
    }
    status
    editPermissions
    createdBy
    createdAt
    updatedBy
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateEmailTemplateMutationVariables,
  APITypes.CreateEmailTemplateMutation
>;
export const updateEmailTemplate = /* GraphQL */ `mutation UpdateEmailTemplate(
  $input: UpdateEmailTemplateInput!
  $condition: ModelEmailTemplateConditionInput
) {
  updateEmailTemplate(input: $input, condition: $condition) {
    id
    tenantId
    labelValues {
      key
      value
      __typename
    }
    customFilterValues {
      fieldId
      objectRecords {
        id
        externalId
        syncStatus
        status
        values {
          fieldId
          values
          __typename
        }
        __typename
      }
      values
      __typename
    }
    subject
    title
    cc
    bcc
    body
    associatedFiles {
      id
      isDistributable
      isDefault
      type
      attachmentId
      status
      createdAt
      createdBy
      __typename
    }
    status
    editPermissions
    createdBy
    createdAt
    updatedBy
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateEmailTemplateMutationVariables,
  APITypes.UpdateEmailTemplateMutation
>;
export const deleteEmailTemplate = /* GraphQL */ `mutation DeleteEmailTemplate(
  $input: DeleteEmailTemplateInput!
  $condition: ModelEmailTemplateConditionInput
) {
  deleteEmailTemplate(input: $input, condition: $condition) {
    id
    tenantId
    labelValues {
      key
      value
      __typename
    }
    customFilterValues {
      fieldId
      objectRecords {
        id
        externalId
        syncStatus
        status
        values {
          fieldId
          values
          __typename
        }
        __typename
      }
      values
      __typename
    }
    subject
    title
    cc
    bcc
    body
    associatedFiles {
      id
      isDistributable
      isDefault
      type
      attachmentId
      status
      createdAt
      createdBy
      __typename
    }
    status
    editPermissions
    createdBy
    createdAt
    updatedBy
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteEmailTemplateMutationVariables,
  APITypes.DeleteEmailTemplateMutation
>;
export const createDocumentSetting = /* GraphQL */ `mutation CreateDocumentSetting(
  $input: CreateDocumentSettingInput!
  $condition: ModelDocumentSettingConditionInput
) {
  createDocumentSetting(input: $input, condition: $condition) {
    id
    tenantId
    createdAt
    createdBy
    updatedAt
    updatedBy
    documentId
    documentVersionId
    notation {
      id
      type
      description
      status
      pageId
      coordinate {
        x
        y
        __typename
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      __typename
    }
    status
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDocumentSettingMutationVariables,
  APITypes.CreateDocumentSettingMutation
>;
export const updateDocumentSetting = /* GraphQL */ `mutation UpdateDocumentSetting(
  $input: UpdateDocumentSettingInput!
  $condition: ModelDocumentSettingConditionInput
) {
  updateDocumentSetting(input: $input, condition: $condition) {
    id
    tenantId
    createdAt
    createdBy
    updatedAt
    updatedBy
    documentId
    documentVersionId
    notation {
      id
      type
      description
      status
      pageId
      coordinate {
        x
        y
        __typename
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      __typename
    }
    status
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDocumentSettingMutationVariables,
  APITypes.UpdateDocumentSettingMutation
>;
export const deleteDocumentSetting = /* GraphQL */ `mutation DeleteDocumentSetting(
  $input: DeleteDocumentSettingInput!
  $condition: ModelDocumentSettingConditionInput
) {
  deleteDocumentSetting(input: $input, condition: $condition) {
    id
    tenantId
    createdAt
    createdBy
    updatedAt
    updatedBy
    documentId
    documentVersionId
    notation {
      id
      type
      description
      status
      pageId
      coordinate {
        x
        y
        __typename
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      __typename
    }
    status
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDocumentSettingMutationVariables,
  APITypes.DeleteDocumentSettingMutation
>;
export const createUserNotations = /* GraphQL */ `mutation CreateUserNotations(
  $input: CreateUserNotationsInput!
  $condition: ModelUserNotationsConditionInput
) {
  createUserNotations(input: $input, condition: $condition) {
    id
    tenantId
    createdAt
    createdBy
    updatedAt
    updatedBy
    documentId
    documentVersionId
    customDeckId
    notation {
      id
      type
      description
      status
      pageId
      coordinate {
        x
        y
        __typename
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      __typename
    }
    status
    type
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserNotationsMutationVariables,
  APITypes.CreateUserNotationsMutation
>;
export const updateUserNotations = /* GraphQL */ `mutation UpdateUserNotations(
  $input: UpdateUserNotationsInput!
  $condition: ModelUserNotationsConditionInput
) {
  updateUserNotations(input: $input, condition: $condition) {
    id
    tenantId
    createdAt
    createdBy
    updatedAt
    updatedBy
    documentId
    documentVersionId
    customDeckId
    notation {
      id
      type
      description
      status
      pageId
      coordinate {
        x
        y
        __typename
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      __typename
    }
    status
    type
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserNotationsMutationVariables,
  APITypes.UpdateUserNotationsMutation
>;
export const deleteUserNotations = /* GraphQL */ `mutation DeleteUserNotations(
  $input: DeleteUserNotationsInput!
  $condition: ModelUserNotationsConditionInput
) {
  deleteUserNotations(input: $input, condition: $condition) {
    id
    tenantId
    createdAt
    createdBy
    updatedAt
    updatedBy
    documentId
    documentVersionId
    customDeckId
    notation {
      id
      type
      description
      status
      pageId
      coordinate {
        x
        y
        __typename
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      __typename
    }
    status
    type
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserNotationsMutationVariables,
  APITypes.DeleteUserNotationsMutation
>;
export const createCustomDeck = /* GraphQL */ `mutation CreateCustomDeck(
  $input: CreateCustomDeckInput!
  $condition: ModelCustomDeckConditionInput
) {
  createCustomDeck(input: $input, condition: $condition) {
    id
    createdAt
    createdBy
    autoUpdateAcknowledgedAt
    updatedAt
    updatedBy
    tenantId
    groups {
      id
      pages {
        pageId
        pageNumber
        documentVersionId
        __typename
      }
      visible
      srcId
      docAccessLevel
      name
      locked
      __typename
    }
    title
    editMutex {
      userId
      timeStarted
      lastSeenAt
      __typename
    }
    sourceCustomDeckId
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCustomDeckMutationVariables,
  APITypes.CreateCustomDeckMutation
>;
export const updateCustomDeck = /* GraphQL */ `mutation UpdateCustomDeck(
  $input: UpdateCustomDeckInput!
  $condition: ModelCustomDeckConditionInput
) {
  updateCustomDeck(input: $input, condition: $condition) {
    id
    createdAt
    createdBy
    autoUpdateAcknowledgedAt
    updatedAt
    updatedBy
    tenantId
    groups {
      id
      pages {
        pageId
        pageNumber
        documentVersionId
        __typename
      }
      visible
      srcId
      docAccessLevel
      name
      locked
      __typename
    }
    title
    editMutex {
      userId
      timeStarted
      lastSeenAt
      __typename
    }
    sourceCustomDeckId
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCustomDeckMutationVariables,
  APITypes.UpdateCustomDeckMutation
>;
export const deleteCustomDeck = /* GraphQL */ `mutation DeleteCustomDeck(
  $input: DeleteCustomDeckInput!
  $condition: ModelCustomDeckConditionInput
) {
  deleteCustomDeck(input: $input, condition: $condition) {
    id
    createdAt
    createdBy
    autoUpdateAcknowledgedAt
    updatedAt
    updatedBy
    tenantId
    groups {
      id
      pages {
        pageId
        pageNumber
        documentVersionId
        __typename
      }
      visible
      srcId
      docAccessLevel
      name
      locked
      __typename
    }
    title
    editMutex {
      userId
      timeStarted
      lastSeenAt
      __typename
    }
    sourceCustomDeckId
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCustomDeckMutationVariables,
  APITypes.DeleteCustomDeckMutation
>;
export const createFolder = /* GraphQL */ `mutation CreateFolder(
  $input: CreateFolderInput!
  $condition: ModelFolderConditionInput
) {
  createFolder(input: $input, condition: $condition) {
    id
    tenantId
    name
    isPinned
    status
    items {
      id
      type
      itemId
      status
      addedAt
      updateAcknowledgedAt
      itemLastUpdatedAt
      visiblePages
      customTitle
      __typename
    }
    createdAt
    createdBy
    updatedAt
    updatedBy
    sharePermissions {
      id
      isDeleted
      type
      targetType
      targetUsername
      targetCustomValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
            __typename
          }
          __typename
        }
        values
        __typename
      }
      targetLabels {
        key
        values
        __typename
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      deletedBy
      deletedAt
      __typename
    }
    shareStatus
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateFolderMutationVariables,
  APITypes.CreateFolderMutation
>;
export const updateFolder = /* GraphQL */ `mutation UpdateFolder(
  $input: UpdateFolderInput!
  $condition: ModelFolderConditionInput
) {
  updateFolder(input: $input, condition: $condition) {
    id
    tenantId
    name
    isPinned
    status
    items {
      id
      type
      itemId
      status
      addedAt
      updateAcknowledgedAt
      itemLastUpdatedAt
      visiblePages
      customTitle
      __typename
    }
    createdAt
    createdBy
    updatedAt
    updatedBy
    sharePermissions {
      id
      isDeleted
      type
      targetType
      targetUsername
      targetCustomValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
            __typename
          }
          __typename
        }
        values
        __typename
      }
      targetLabels {
        key
        values
        __typename
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      deletedBy
      deletedAt
      __typename
    }
    shareStatus
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFolderMutationVariables,
  APITypes.UpdateFolderMutation
>;
export const deleteFolder = /* GraphQL */ `mutation DeleteFolder(
  $input: DeleteFolderInput!
  $condition: ModelFolderConditionInput
) {
  deleteFolder(input: $input, condition: $condition) {
    id
    tenantId
    name
    isPinned
    status
    items {
      id
      type
      itemId
      status
      addedAt
      updateAcknowledgedAt
      itemLastUpdatedAt
      visiblePages
      customTitle
      __typename
    }
    createdAt
    createdBy
    updatedAt
    updatedBy
    sharePermissions {
      id
      isDeleted
      type
      targetType
      targetUsername
      targetCustomValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
            __typename
          }
          __typename
        }
        values
        __typename
      }
      targetLabels {
        key
        values
        __typename
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      deletedBy
      deletedAt
      __typename
    }
    shareStatus
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteFolderMutationVariables,
  APITypes.DeleteFolderMutation
>;
export const createContentShare = /* GraphQL */ `mutation CreateContentShare(
  $input: CreateContentShareInput!
  $condition: ModelContentShareConditionInput
) {
  createContentShare(input: $input, condition: $condition) {
    id
    token
    tenantId
    type
    contentId
    meetingId
    expiresAt
    createdAt
    updatedAt
    createdBy
    customValues {
      fieldId
      objectRecords {
        id
        externalId
        syncStatus
        status
        values {
          fieldId
          values
          __typename
        }
        __typename
      }
      values
      __typename
    }
    revoked
    customDeckDependencies {
      versions
      folderId
      __typename
    }
    notificationScope
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateContentShareMutationVariables,
  APITypes.CreateContentShareMutation
>;
export const updateContentShare = /* GraphQL */ `mutation UpdateContentShare(
  $input: UpdateContentShareInput!
  $condition: ModelContentShareConditionInput
) {
  updateContentShare(input: $input, condition: $condition) {
    id
    token
    tenantId
    type
    contentId
    meetingId
    expiresAt
    createdAt
    updatedAt
    createdBy
    customValues {
      fieldId
      objectRecords {
        id
        externalId
        syncStatus
        status
        values {
          fieldId
          values
          __typename
        }
        __typename
      }
      values
      __typename
    }
    revoked
    customDeckDependencies {
      versions
      folderId
      __typename
    }
    notificationScope
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateContentShareMutationVariables,
  APITypes.UpdateContentShareMutation
>;
export const deleteContentShare = /* GraphQL */ `mutation DeleteContentShare(
  $input: DeleteContentShareInput!
  $condition: ModelContentShareConditionInput
) {
  deleteContentShare(input: $input, condition: $condition) {
    id
    token
    tenantId
    type
    contentId
    meetingId
    expiresAt
    createdAt
    updatedAt
    createdBy
    customValues {
      fieldId
      objectRecords {
        id
        externalId
        syncStatus
        status
        values {
          fieldId
          values
          __typename
        }
        __typename
      }
      values
      __typename
    }
    revoked
    customDeckDependencies {
      versions
      folderId
      __typename
    }
    notificationScope
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteContentShareMutationVariables,
  APITypes.DeleteContentShareMutation
>;
export const createIntegrationLog = /* GraphQL */ `mutation CreateIntegrationLog(
  $input: CreateIntegrationLogInput!
  $condition: ModelIntegrationLogConditionInput
) {
  createIntegrationLog(input: $input, condition: $condition) {
    integrationId
    id
    log {
      level
      timestamp
      message
      srcDocId
      srcDocVersion
      __typename
    }
    status
    tenantId
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateIntegrationLogMutationVariables,
  APITypes.CreateIntegrationLogMutation
>;
export const updateIntegrationLog = /* GraphQL */ `mutation UpdateIntegrationLog(
  $input: UpdateIntegrationLogInput!
  $condition: ModelIntegrationLogConditionInput
) {
  updateIntegrationLog(input: $input, condition: $condition) {
    integrationId
    id
    log {
      level
      timestamp
      message
      srcDocId
      srcDocVersion
      __typename
    }
    status
    tenantId
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateIntegrationLogMutationVariables,
  APITypes.UpdateIntegrationLogMutation
>;
export const deleteIntegrationLog = /* GraphQL */ `mutation DeleteIntegrationLog(
  $input: DeleteIntegrationLogInput!
  $condition: ModelIntegrationLogConditionInput
) {
  deleteIntegrationLog(input: $input, condition: $condition) {
    integrationId
    id
    log {
      level
      timestamp
      message
      srcDocId
      srcDocVersion
      __typename
    }
    status
    tenantId
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteIntegrationLogMutationVariables,
  APITypes.DeleteIntegrationLogMutation
>;
export const createIntegrationSettings = /* GraphQL */ `mutation CreateIntegrationSettings(
  $input: CreateIntegrationSettingsInput!
  $condition: ModelIntegrationSettingsConditionInput
) {
  createIntegrationSettings(input: $input, condition: $condition) {
    id
    tenantId
    syncContentEvery
    name
    mapping {
      key
      fieldType
      dataType
      targetFieldName
      srcFieldname
      valueMappings {
        srcValue
        targetValue
        __typename
      }
      __typename
    }
    clientConfigurationFields {
      key
      value
      __typename
    }
    integrationType
    enabled
    notificationEmail
    errorSyncEmail
    createdAt
    createdBy
    updatedAt
    updatedBy
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateIntegrationSettingsMutationVariables,
  APITypes.CreateIntegrationSettingsMutation
>;
export const updateIntegrationSettings = /* GraphQL */ `mutation UpdateIntegrationSettings(
  $input: UpdateIntegrationSettingsInput!
  $condition: ModelIntegrationSettingsConditionInput
) {
  updateIntegrationSettings(input: $input, condition: $condition) {
    id
    tenantId
    syncContentEvery
    name
    mapping {
      key
      fieldType
      dataType
      targetFieldName
      srcFieldname
      valueMappings {
        srcValue
        targetValue
        __typename
      }
      __typename
    }
    clientConfigurationFields {
      key
      value
      __typename
    }
    integrationType
    enabled
    notificationEmail
    errorSyncEmail
    createdAt
    createdBy
    updatedAt
    updatedBy
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateIntegrationSettingsMutationVariables,
  APITypes.UpdateIntegrationSettingsMutation
>;
export const deleteIntegrationSettings = /* GraphQL */ `mutation DeleteIntegrationSettings(
  $input: DeleteIntegrationSettingsInput!
  $condition: ModelIntegrationSettingsConditionInput
) {
  deleteIntegrationSettings(input: $input, condition: $condition) {
    id
    tenantId
    syncContentEvery
    name
    mapping {
      key
      fieldType
      dataType
      targetFieldName
      srcFieldname
      valueMappings {
        srcValue
        targetValue
        __typename
      }
      __typename
    }
    clientConfigurationFields {
      key
      value
      __typename
    }
    integrationType
    enabled
    notificationEmail
    errorSyncEmail
    createdAt
    createdBy
    updatedAt
    updatedBy
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteIntegrationSettingsMutationVariables,
  APITypes.DeleteIntegrationSettingsMutation
>;
export const createMeeting = /* GraphQL */ `mutation CreateMeeting(
  $input: CreateMeetingInput!
  $condition: ModelMeetingConditionInput
) {
  createMeeting(input: $input, condition: $condition) {
    id
    tenantId
    title
    startTime
    endTime
    contentPresented {
      contentId
      groupId
      folderItemId
      title
      groupTitle
      status
      contentType
      events {
        pageNumber
        pageId
        timestamp
        end
        __typename
      }
      openedAt
      closedAt
      presentedMeta {
        pageId
        presented
        followUp
        sentiment
        note
        title
        __typename
      }
      __typename
    }
    type
    fieldValues {
      fieldId
      valueOrId
      __typename
    }
    customValues {
      fieldId
      objectRecords {
        id
        externalId
        syncStatus
        status
        values {
          fieldId
          values
          __typename
        }
        __typename
      }
      values
      __typename
    }
    notes
    status
    createdAt
    createdBy
    updatedAt
    updatedBy
    attendees {
      id
      name
      status
      updatedAt
      email
      crmAccountId
      crmAddressId
      attendeeType
      crmRecord {
        crmCallId
        crmCustomValues {
          fieldId
          objectRecords {
            id
            externalId
            syncStatus
            status
            values {
              fieldId
              values
              __typename
            }
            __typename
          }
          values
          __typename
        }
        crmSyncStatus
        lastSyncedAt
        __typename
      }
      lastSyncedAt
      __typename
    }
    crmRecord {
      crmCallId
      crmCustomValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
            __typename
          }
          __typename
        }
        values
        __typename
      }
      crmSyncStatus
      lastSyncedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMeetingMutationVariables,
  APITypes.CreateMeetingMutation
>;
export const updateMeeting = /* GraphQL */ `mutation UpdateMeeting(
  $input: UpdateMeetingInput!
  $condition: ModelMeetingConditionInput
) {
  updateMeeting(input: $input, condition: $condition) {
    id
    tenantId
    title
    startTime
    endTime
    contentPresented {
      contentId
      groupId
      folderItemId
      title
      groupTitle
      status
      contentType
      events {
        pageNumber
        pageId
        timestamp
        end
        __typename
      }
      openedAt
      closedAt
      presentedMeta {
        pageId
        presented
        followUp
        sentiment
        note
        title
        __typename
      }
      __typename
    }
    type
    fieldValues {
      fieldId
      valueOrId
      __typename
    }
    customValues {
      fieldId
      objectRecords {
        id
        externalId
        syncStatus
        status
        values {
          fieldId
          values
          __typename
        }
        __typename
      }
      values
      __typename
    }
    notes
    status
    createdAt
    createdBy
    updatedAt
    updatedBy
    attendees {
      id
      name
      status
      updatedAt
      email
      crmAccountId
      crmAddressId
      attendeeType
      crmRecord {
        crmCallId
        crmCustomValues {
          fieldId
          objectRecords {
            id
            externalId
            syncStatus
            status
            values {
              fieldId
              values
              __typename
            }
            __typename
          }
          values
          __typename
        }
        crmSyncStatus
        lastSyncedAt
        __typename
      }
      lastSyncedAt
      __typename
    }
    crmRecord {
      crmCallId
      crmCustomValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
            __typename
          }
          __typename
        }
        values
        __typename
      }
      crmSyncStatus
      lastSyncedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMeetingMutationVariables,
  APITypes.UpdateMeetingMutation
>;
export const deleteMeeting = /* GraphQL */ `mutation DeleteMeeting(
  $input: DeleteMeetingInput!
  $condition: ModelMeetingConditionInput
) {
  deleteMeeting(input: $input, condition: $condition) {
    id
    tenantId
    title
    startTime
    endTime
    contentPresented {
      contentId
      groupId
      folderItemId
      title
      groupTitle
      status
      contentType
      events {
        pageNumber
        pageId
        timestamp
        end
        __typename
      }
      openedAt
      closedAt
      presentedMeta {
        pageId
        presented
        followUp
        sentiment
        note
        title
        __typename
      }
      __typename
    }
    type
    fieldValues {
      fieldId
      valueOrId
      __typename
    }
    customValues {
      fieldId
      objectRecords {
        id
        externalId
        syncStatus
        status
        values {
          fieldId
          values
          __typename
        }
        __typename
      }
      values
      __typename
    }
    notes
    status
    createdAt
    createdBy
    updatedAt
    updatedBy
    attendees {
      id
      name
      status
      updatedAt
      email
      crmAccountId
      crmAddressId
      attendeeType
      crmRecord {
        crmCallId
        crmCustomValues {
          fieldId
          objectRecords {
            id
            externalId
            syncStatus
            status
            values {
              fieldId
              values
              __typename
            }
            __typename
          }
          values
          __typename
        }
        crmSyncStatus
        lastSyncedAt
        __typename
      }
      lastSyncedAt
      __typename
    }
    crmRecord {
      crmCallId
      crmCustomValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
            __typename
          }
          __typename
        }
        values
        __typename
      }
      crmSyncStatus
      lastSyncedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMeetingMutationVariables,
  APITypes.DeleteMeetingMutation
>;
export const createHub = /* GraphQL */ `mutation CreateHub(
  $input: CreateHubInput!
  $condition: ModelHubConditionInput
) {
  createHub(input: $input, condition: $condition) {
    id
    tenantId
    name
    status
    createdAt
    createdBy
    updatedAt
    updatedBy
    hubSections {
      id
      sectionHeader
      status
      type
      visible
      order
      textarea {
        content
        __typename
      }
      sharedFiles {
        id
        contentId
        title
        contentType
        documentVersionSettings {
          associatedFiles {
            associatedFileId
            versionId
            notation {
              id
              type
              description
              status
              pageId
              coordinate {
                x
                y
                __typename
              }
              createdAt
              createdBy
              updatedAt
              updatedBy
              __typename
            }
            __typename
          }
          __typename
        }
        status
        createdAt
        updatedAt
        notation {
          id
          type
          description
          status
          pageId
          coordinate {
            x
            y
            __typename
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
          __typename
        }
        __typename
      }
      toDos {
        id
        title
        status
        completedAt
        resolution
        order
        createdAt
        updatedAt
        __typename
      }
      links {
        id
        url
        title
        status
        order
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    customValues {
      fieldId
      objectRecords {
        id
        externalId
        syncStatus
        status
        values {
          fieldId
          values
          __typename
        }
        __typename
      }
      values
      __typename
    }
    meetingId
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateHubMutationVariables,
  APITypes.CreateHubMutation
>;
export const updateHub = /* GraphQL */ `mutation UpdateHub(
  $input: UpdateHubInput!
  $condition: ModelHubConditionInput
) {
  updateHub(input: $input, condition: $condition) {
    id
    tenantId
    name
    status
    createdAt
    createdBy
    updatedAt
    updatedBy
    hubSections {
      id
      sectionHeader
      status
      type
      visible
      order
      textarea {
        content
        __typename
      }
      sharedFiles {
        id
        contentId
        title
        contentType
        documentVersionSettings {
          associatedFiles {
            associatedFileId
            versionId
            notation {
              id
              type
              description
              status
              pageId
              coordinate {
                x
                y
                __typename
              }
              createdAt
              createdBy
              updatedAt
              updatedBy
              __typename
            }
            __typename
          }
          __typename
        }
        status
        createdAt
        updatedAt
        notation {
          id
          type
          description
          status
          pageId
          coordinate {
            x
            y
            __typename
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
          __typename
        }
        __typename
      }
      toDos {
        id
        title
        status
        completedAt
        resolution
        order
        createdAt
        updatedAt
        __typename
      }
      links {
        id
        url
        title
        status
        order
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    customValues {
      fieldId
      objectRecords {
        id
        externalId
        syncStatus
        status
        values {
          fieldId
          values
          __typename
        }
        __typename
      }
      values
      __typename
    }
    meetingId
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateHubMutationVariables,
  APITypes.UpdateHubMutation
>;
export const deleteHub = /* GraphQL */ `mutation DeleteHub(
  $input: DeleteHubInput!
  $condition: ModelHubConditionInput
) {
  deleteHub(input: $input, condition: $condition) {
    id
    tenantId
    name
    status
    createdAt
    createdBy
    updatedAt
    updatedBy
    hubSections {
      id
      sectionHeader
      status
      type
      visible
      order
      textarea {
        content
        __typename
      }
      sharedFiles {
        id
        contentId
        title
        contentType
        documentVersionSettings {
          associatedFiles {
            associatedFileId
            versionId
            notation {
              id
              type
              description
              status
              pageId
              coordinate {
                x
                y
                __typename
              }
              createdAt
              createdBy
              updatedAt
              updatedBy
              __typename
            }
            __typename
          }
          __typename
        }
        status
        createdAt
        updatedAt
        notation {
          id
          type
          description
          status
          pageId
          coordinate {
            x
            y
            __typename
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
          __typename
        }
        __typename
      }
      toDos {
        id
        title
        status
        completedAt
        resolution
        order
        createdAt
        updatedAt
        __typename
      }
      links {
        id
        url
        title
        status
        order
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    customValues {
      fieldId
      objectRecords {
        id
        externalId
        syncStatus
        status
        values {
          fieldId
          values
          __typename
        }
        __typename
      }
      values
      __typename
    }
    meetingId
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteHubMutationVariables,
  APITypes.DeleteHubMutation
>;
export const createCustomFormRecord = /* GraphQL */ `mutation CreateCustomFormRecord(
  $input: CreateCustomFormRecordInput!
  $condition: ModelCustomFormRecordConditionInput
) {
  createCustomFormRecord(input: $input, condition: $condition) {
    id
    tenantId
    customFormId
    entity
    status
    crmFields {
      accountId
      externalId
      apiName
      recordTypeId
      syncStatus
      lastSyncedAt
      __typename
    }
    name
    parentId
    parentModel
    values {
      fieldId
      objectRecords {
        id
        externalId
        syncStatus
        status
        values {
          fieldId
          values
          __typename
        }
        __typename
      }
      values
      __typename
    }
    createdAt
    createdBy
    updatedAt
    updatedBy
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateCustomFormRecordMutationVariables,
  APITypes.CreateCustomFormRecordMutation
>;
export const updateCustomFormRecord = /* GraphQL */ `mutation UpdateCustomFormRecord(
  $input: UpdateCustomFormRecordInput!
  $condition: ModelCustomFormRecordConditionInput
) {
  updateCustomFormRecord(input: $input, condition: $condition) {
    id
    tenantId
    customFormId
    entity
    status
    crmFields {
      accountId
      externalId
      apiName
      recordTypeId
      syncStatus
      lastSyncedAt
      __typename
    }
    name
    parentId
    parentModel
    values {
      fieldId
      objectRecords {
        id
        externalId
        syncStatus
        status
        values {
          fieldId
          values
          __typename
        }
        __typename
      }
      values
      __typename
    }
    createdAt
    createdBy
    updatedAt
    updatedBy
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCustomFormRecordMutationVariables,
  APITypes.UpdateCustomFormRecordMutation
>;
export const deleteCustomFormRecord = /* GraphQL */ `mutation DeleteCustomFormRecord(
  $input: DeleteCustomFormRecordInput!
  $condition: ModelCustomFormRecordConditionInput
) {
  deleteCustomFormRecord(input: $input, condition: $condition) {
    id
    tenantId
    customFormId
    entity
    status
    crmFields {
      accountId
      externalId
      apiName
      recordTypeId
      syncStatus
      lastSyncedAt
      __typename
    }
    name
    parentId
    parentModel
    values {
      fieldId
      objectRecords {
        id
        externalId
        syncStatus
        status
        values {
          fieldId
          values
          __typename
        }
        __typename
      }
      values
      __typename
    }
    createdAt
    createdBy
    updatedAt
    updatedBy
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteCustomFormRecordMutationVariables,
  APITypes.DeleteCustomFormRecordMutation
>;
export const createTenantLambda = /* GraphQL */ `mutation CreateTenantLambda($tenant: CreateTenantInputLambda) {
  createTenantLambda(tenant: $tenant) {
    id
    name
    licensedUsers
    status
    fields {
      fieldName
      description
      required
      isEmailTemplateFilter
      dataType
      userFilter
      defaultSearchFilter
      values
      __typename
    }
    folderUpdateGracePeriodDays
    medInfoURL
    publisherListConfig {
      fields {
        fieldName
        header
        width
        __typename
      }
      sort {
        field
        isAsc
        __typename
      }
      __typename
    }
    mslListConfig {
      fields {
        fieldName
        header
        width
        __typename
      }
      sort {
        field
        isAsc
        __typename
      }
      __typename
    }
    featureFlags {
      flagId
      value
      __typename
    }
    createdAt
    createdBy
    updatedAt
    statusChangedAt
    statusChangedBy
    ssoDomains
    integrations
    config {
      requiredSlidesHiddenMessage
      forms {
        id
        name
        label
        fields {
          id
          fieldName
          fieldLabel
          reportingName
          status
          description
          required
          maxLength
          formatValidation
          fieldType
          showByDefault
          dependentFieldId
          controlType
          order
          settings {
            isBadge
            fieldPosition
            __typename
          }
          usage
          documentSettings {
            fieldPosition
            __typename
          }
          fieldValueDefinitions {
            id
            value
            label
            disabled
            isDefault
            documentSettings {
              presentationWatermarkText
              permission {
                present
                modify
                share
                download
                externalNotation
                __typename
              }
              __typename
            }
            badgeColor
            badgeLabel
            createdAt
            dependentValueIds
            __typename
          }
          displayOnParentSelection
          displayOnValueSelection
          objectSetting {
            childrenFieldIds
            restriction {
              syncUpdate
              syncDelete
              update
              delete
              __typename
            }
            __typename
          }
          isChildField
          dateRestriction
          createdAt
          scaleNumber
          precisionNumber
          __typename
        }
        targetRequestTemplate
        targetType
        targetAddress
        targetParameters {
          key
          value
          __typename
        }
        status
        __typename
      }
      meetingFields {
        id
        fieldName
        status
        description
        required
        maxLength
        type
        controlType
        fieldValueDefinitions {
          id
          value
          disabled
          createdAt
          __typename
        }
        createdAt
        __typename
      }
      customFields {
        id
        fieldName
        fieldLabel
        reportingName
        status
        description
        required
        maxLength
        formatValidation
        fieldType
        showByDefault
        dependentFieldId
        controlType
        order
        settings {
          isBadge
          fieldPosition
          __typename
        }
        usage
        documentSettings {
          fieldPosition
          __typename
        }
        fieldValueDefinitions {
          id
          value
          label
          disabled
          isDefault
          documentSettings {
            presentationWatermarkText
            permission {
              present
              modify
              share
              download
              externalNotation
              __typename
            }
            __typename
          }
          badgeColor
          badgeLabel
          createdAt
          dependentValueIds
          __typename
        }
        displayOnParentSelection
        displayOnValueSelection
        objectSetting {
          childrenFieldIds
          restriction {
            syncUpdate
            syncDelete
            update
            delete
            __typename
          }
          __typename
        }
        isChildField
        dateRestriction
        createdAt
        scaleNumber
        precisionNumber
        __typename
      }
      defaultDocumentPermissions {
        present
        modify
        share
        download
        externalNotation
        __typename
      }
      mslListConfig {
        sort {
          field
          isAsc
          __typename
        }
        fields {
          field
          width
          __typename
        }
        __typename
      }
      publisherListConfig {
        sort {
          field
          isAsc
          __typename
        }
        fields {
          field
          width
          __typename
        }
        __typename
      }
      crmIntegration {
        id
        crmIntegrationType
        instanceUrl
        clientId
        redirectUri
        name
        accountsSettings {
          query
          displaySettings {
            shortened
            extended
            __typename
          }
          addressSettings {
            alias
            displaySettings {
              shortened
              extended
              __typename
            }
            __typename
          }
          __typename
        }
        meetingSetting {
          apiName
          presentationsFieldName
          children {
            name
            apiName
            relationshipName
            type
            veevaSetting {
              markerFieldName
              __typename
            }
            __typename
          }
          type
          __typename
        }
        additionalSettings {
          allowCommentsOnTasks
          detailContentTableSettings {
            name
            apiName
            relationshipName
            prefix
            __typename
          }
          enableSurveys
          crmAccountToObjectSettings {
            accountFieldNames {
              accountFieldName
              objectFieldName
              __typename
            }
            apiName
            __typename
          }
          __typename
        }
        loginUrl
        crmStandaloneForms {
          id
          apiName
          children {
            name
            apiName
            relationshipName
            type
            veevaSetting {
              markerFieldName
              __typename
            }
            __typename
          }
          type
          parents {
            apiName
            relationshipName
            __typename
          }
          usage
          __typename
        }
        __typename
      }
      sessionTimeout
      quickFilters {
        id
        label
        path
        __typename
      }
      hubsConfig {
        shareExpirationDays
        disabledSections
        disableNotationDescription
        __typename
      }
      reports {
        key
        value
        __typename
      }
      emailDocumentUpdateFrequency
      sharePPTXasPDF
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTenantLambdaMutationVariables,
  APITypes.CreateTenantLambdaMutation
>;
export const createDocumentFromS3Upload = /* GraphQL */ `mutation CreateDocumentFromS3Upload(
  $inputDoc: CreateDocumentFromS3UploadInput!
) {
  createDocumentFromS3Upload(inputDoc: $inputDoc) {
    id
    tenantId
    documentId
    versionNumber
    srcFilename
    contentURL
    conversionStatus
    status
    srcFile {
      bucket
      region
      key
      url
      __typename
    }
    srcHash
    srcSize
    numPages
    pageSettings {
      pageId
      number
      isRequired
      linkedSlides
      __typename
    }
    pageGroups {
      id
      pageIds
      name
      locked
      sourceID
      source
      __typename
    }
    type
    releaseNotes
    changeType
    labelValues {
      key
      value
      __typename
    }
    customValues {
      fieldId
      objectRecords {
        id
        externalId
        syncStatus
        status
        values {
          fieldId
          values
          __typename
        }
        __typename
      }
      values
      __typename
    }
    title
    shortDescription
    longDescription
    owner
    expiresAt
    hasCopyright
    hasCustomThumbnail
    purpose
    canHideSlides
    distributable
    downloadable
    isInternalGenerated
    semVer {
      minor
      major
      __typename
    }
    notificationScope
    selectedThumbnail
    publishedAt
    uploadedAt
    uploadedBy
    convertedArchiveKey
    convertedArchiveSize
    convertedFolderKey
    associatedFiles {
      id
      isDistributable
      isDefault
      type
      attachmentId
      status
      createdAt
      createdBy
      __typename
    }
    editPermissions
    converterVersion
    createdAt
    createdBy
    updatedAt
    updatedBy
    integration {
      externalVersionId
      version
      timestamp
      srcFileHash
      srcDocumentHash
      __typename
    }
    integrationType
    conversionWarningCode
    conversionErrorCode
    scheduledPublish
    mappingStatus
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDocumentFromS3UploadMutationVariables,
  APITypes.CreateDocumentFromS3UploadMutation
>;
export const createUserDocumentFromS3Upload = /* GraphQL */ `mutation CreateUserDocumentFromS3Upload(
  $inputDoc: CreateDocumentFromS3UploadInput!
) {
  createUserDocumentFromS3Upload(inputDoc: $inputDoc) {
    id
    tenantId
    documentId
    versionNumber
    srcFilename
    contentURL
    conversionStatus
    status
    srcFile {
      bucket
      region
      key
      url
      __typename
    }
    srcHash
    srcSize
    numPages
    pageSettings {
      pageId
      number
      isRequired
      linkedSlides
      __typename
    }
    pageGroups {
      id
      pageIds
      name
      locked
      sourceID
      source
      __typename
    }
    type
    releaseNotes
    changeType
    labelValues {
      key
      value
      __typename
    }
    customValues {
      fieldId
      objectRecords {
        id
        externalId
        syncStatus
        status
        values {
          fieldId
          values
          __typename
        }
        __typename
      }
      values
      __typename
    }
    title
    shortDescription
    longDescription
    owner
    expiresAt
    hasCopyright
    hasCustomThumbnail
    purpose
    canHideSlides
    distributable
    downloadable
    isInternalGenerated
    semVer {
      minor
      major
      __typename
    }
    notificationScope
    selectedThumbnail
    publishedAt
    uploadedAt
    uploadedBy
    convertedArchiveKey
    convertedArchiveSize
    convertedFolderKey
    associatedFiles {
      id
      isDistributable
      isDefault
      type
      attachmentId
      status
      createdAt
      createdBy
      __typename
    }
    editPermissions
    converterVersion
    createdAt
    createdBy
    updatedAt
    updatedBy
    integration {
      externalVersionId
      version
      timestamp
      srcFileHash
      srcDocumentHash
      __typename
    }
    integrationType
    conversionWarningCode
    conversionErrorCode
    scheduledPublish
    mappingStatus
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserDocumentFromS3UploadMutationVariables,
  APITypes.CreateUserDocumentFromS3UploadMutation
>;
export const createWebDocument = /* GraphQL */ `mutation CreateWebDocument {
  createWebDocument {
    id
    tenantId
    documentId
    versionNumber
    srcFilename
    contentURL
    conversionStatus
    status
    srcFile {
      bucket
      region
      key
      url
      __typename
    }
    srcHash
    srcSize
    numPages
    pageSettings {
      pageId
      number
      isRequired
      linkedSlides
      __typename
    }
    pageGroups {
      id
      pageIds
      name
      locked
      sourceID
      source
      __typename
    }
    type
    releaseNotes
    changeType
    labelValues {
      key
      value
      __typename
    }
    customValues {
      fieldId
      objectRecords {
        id
        externalId
        syncStatus
        status
        values {
          fieldId
          values
          __typename
        }
        __typename
      }
      values
      __typename
    }
    title
    shortDescription
    longDescription
    owner
    expiresAt
    hasCopyright
    hasCustomThumbnail
    purpose
    canHideSlides
    distributable
    downloadable
    isInternalGenerated
    semVer {
      minor
      major
      __typename
    }
    notificationScope
    selectedThumbnail
    publishedAt
    uploadedAt
    uploadedBy
    convertedArchiveKey
    convertedArchiveSize
    convertedFolderKey
    associatedFiles {
      id
      isDistributable
      isDefault
      type
      attachmentId
      status
      createdAt
      createdBy
      __typename
    }
    editPermissions
    converterVersion
    createdAt
    createdBy
    updatedAt
    updatedBy
    integration {
      externalVersionId
      version
      timestamp
      srcFileHash
      srcDocumentHash
      __typename
    }
    integrationType
    conversionWarningCode
    conversionErrorCode
    scheduledPublish
    mappingStatus
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateWebDocumentMutationVariables,
  APITypes.CreateWebDocumentMutation
>;
export const updateDocumentThumbnail = /* GraphQL */ `mutation UpdateDocumentThumbnail(
  $inputDocumentThumbnail: UpdateDocumentThumbnailInput!
) {
  updateDocumentThumbnail(inputDocumentThumbnail: $inputDocumentThumbnail) {
    id
    tenantId
    documentId
    versionNumber
    srcFilename
    contentURL
    conversionStatus
    status
    srcFile {
      bucket
      region
      key
      url
      __typename
    }
    srcHash
    srcSize
    numPages
    pageSettings {
      pageId
      number
      isRequired
      linkedSlides
      __typename
    }
    pageGroups {
      id
      pageIds
      name
      locked
      sourceID
      source
      __typename
    }
    type
    releaseNotes
    changeType
    labelValues {
      key
      value
      __typename
    }
    customValues {
      fieldId
      objectRecords {
        id
        externalId
        syncStatus
        status
        values {
          fieldId
          values
          __typename
        }
        __typename
      }
      values
      __typename
    }
    title
    shortDescription
    longDescription
    owner
    expiresAt
    hasCopyright
    hasCustomThumbnail
    purpose
    canHideSlides
    distributable
    downloadable
    isInternalGenerated
    semVer {
      minor
      major
      __typename
    }
    notificationScope
    selectedThumbnail
    publishedAt
    uploadedAt
    uploadedBy
    convertedArchiveKey
    convertedArchiveSize
    convertedFolderKey
    associatedFiles {
      id
      isDistributable
      isDefault
      type
      attachmentId
      status
      createdAt
      createdBy
      __typename
    }
    editPermissions
    converterVersion
    createdAt
    createdBy
    updatedAt
    updatedBy
    integration {
      externalVersionId
      version
      timestamp
      srcFileHash
      srcDocumentHash
      __typename
    }
    integrationType
    conversionWarningCode
    conversionErrorCode
    scheduledPublish
    mappingStatus
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDocumentThumbnailMutationVariables,
  APITypes.UpdateDocumentThumbnailMutation
>;
export const createDocumentFromS3UploadIntegrationEngine = /* GraphQL */ `mutation CreateDocumentFromS3UploadIntegrationEngine(
  $inputDoc: CreateDocumentFromS3UploadEngineInput!
) {
  createDocumentFromS3UploadIntegrationEngine(inputDoc: $inputDoc) {
    id
    tenantId
    documentId
    versionNumber
    srcFilename
    contentURL
    conversionStatus
    status
    srcFile {
      bucket
      region
      key
      url
      __typename
    }
    srcHash
    srcSize
    numPages
    pageSettings {
      pageId
      number
      isRequired
      linkedSlides
      __typename
    }
    pageGroups {
      id
      pageIds
      name
      locked
      sourceID
      source
      __typename
    }
    type
    releaseNotes
    changeType
    labelValues {
      key
      value
      __typename
    }
    customValues {
      fieldId
      objectRecords {
        id
        externalId
        syncStatus
        status
        values {
          fieldId
          values
          __typename
        }
        __typename
      }
      values
      __typename
    }
    title
    shortDescription
    longDescription
    owner
    expiresAt
    hasCopyright
    hasCustomThumbnail
    purpose
    canHideSlides
    distributable
    downloadable
    isInternalGenerated
    semVer {
      minor
      major
      __typename
    }
    notificationScope
    selectedThumbnail
    publishedAt
    uploadedAt
    uploadedBy
    convertedArchiveKey
    convertedArchiveSize
    convertedFolderKey
    associatedFiles {
      id
      isDistributable
      isDefault
      type
      attachmentId
      status
      createdAt
      createdBy
      __typename
    }
    editPermissions
    converterVersion
    createdAt
    createdBy
    updatedAt
    updatedBy
    integration {
      externalVersionId
      version
      timestamp
      srcFileHash
      srcDocumentHash
      __typename
    }
    integrationType
    conversionWarningCode
    conversionErrorCode
    scheduledPublish
    mappingStatus
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDocumentFromS3UploadIntegrationEngineMutationVariables,
  APITypes.CreateDocumentFromS3UploadIntegrationEngineMutation
>;
export const createDocumentVersionFromS3Upload = /* GraphQL */ `mutation CreateDocumentVersionFromS3Upload(
  $inputVersion: CreateDocumentVersionFromS3UploadInput!
) {
  createDocumentVersionFromS3Upload(inputVersion: $inputVersion) {
    id
    tenantId
    documentId
    versionNumber
    srcFilename
    contentURL
    conversionStatus
    status
    srcFile {
      bucket
      region
      key
      url
      __typename
    }
    srcHash
    srcSize
    numPages
    pageSettings {
      pageId
      number
      isRequired
      linkedSlides
      __typename
    }
    pageGroups {
      id
      pageIds
      name
      locked
      sourceID
      source
      __typename
    }
    type
    releaseNotes
    changeType
    labelValues {
      key
      value
      __typename
    }
    customValues {
      fieldId
      objectRecords {
        id
        externalId
        syncStatus
        status
        values {
          fieldId
          values
          __typename
        }
        __typename
      }
      values
      __typename
    }
    title
    shortDescription
    longDescription
    owner
    expiresAt
    hasCopyright
    hasCustomThumbnail
    purpose
    canHideSlides
    distributable
    downloadable
    isInternalGenerated
    semVer {
      minor
      major
      __typename
    }
    notificationScope
    selectedThumbnail
    publishedAt
    uploadedAt
    uploadedBy
    convertedArchiveKey
    convertedArchiveSize
    convertedFolderKey
    associatedFiles {
      id
      isDistributable
      isDefault
      type
      attachmentId
      status
      createdAt
      createdBy
      __typename
    }
    editPermissions
    converterVersion
    createdAt
    createdBy
    updatedAt
    updatedBy
    integration {
      externalVersionId
      version
      timestamp
      srcFileHash
      srcDocumentHash
      __typename
    }
    integrationType
    conversionWarningCode
    conversionErrorCode
    scheduledPublish
    mappingStatus
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDocumentVersionFromS3UploadMutationVariables,
  APITypes.CreateDocumentVersionFromS3UploadMutation
>;
export const createDocumentVersionFromS3UploadIntegrationEngine = /* GraphQL */ `mutation CreateDocumentVersionFromS3UploadIntegrationEngine(
  $inputVersion: CreateDocumentVersionFromS3UploadEngineInput!
) {
  createDocumentVersionFromS3UploadIntegrationEngine(
    inputVersion: $inputVersion
  ) {
    id
    tenantId
    documentId
    versionNumber
    srcFilename
    contentURL
    conversionStatus
    status
    srcFile {
      bucket
      region
      key
      url
      __typename
    }
    srcHash
    srcSize
    numPages
    pageSettings {
      pageId
      number
      isRequired
      linkedSlides
      __typename
    }
    pageGroups {
      id
      pageIds
      name
      locked
      sourceID
      source
      __typename
    }
    type
    releaseNotes
    changeType
    labelValues {
      key
      value
      __typename
    }
    customValues {
      fieldId
      objectRecords {
        id
        externalId
        syncStatus
        status
        values {
          fieldId
          values
          __typename
        }
        __typename
      }
      values
      __typename
    }
    title
    shortDescription
    longDescription
    owner
    expiresAt
    hasCopyright
    hasCustomThumbnail
    purpose
    canHideSlides
    distributable
    downloadable
    isInternalGenerated
    semVer {
      minor
      major
      __typename
    }
    notificationScope
    selectedThumbnail
    publishedAt
    uploadedAt
    uploadedBy
    convertedArchiveKey
    convertedArchiveSize
    convertedFolderKey
    associatedFiles {
      id
      isDistributable
      isDefault
      type
      attachmentId
      status
      createdAt
      createdBy
      __typename
    }
    editPermissions
    converterVersion
    createdAt
    createdBy
    updatedAt
    updatedBy
    integration {
      externalVersionId
      version
      timestamp
      srcFileHash
      srcDocumentHash
      __typename
    }
    integrationType
    conversionWarningCode
    conversionErrorCode
    scheduledPublish
    mappingStatus
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDocumentVersionFromS3UploadIntegrationEngineMutationVariables,
  APITypes.CreateDocumentVersionFromS3UploadIntegrationEngineMutation
>;
export const createDocumentVersionFromExisting = /* GraphQL */ `mutation CreateDocumentVersionFromExisting(
  $documentId: ID!
  $newVersionNumber: Int!
  $existingVersionId: ID!
) {
  createDocumentVersionFromExisting(
    documentId: $documentId
    newVersionNumber: $newVersionNumber
    existingVersionId: $existingVersionId
  ) {
    id
    tenantId
    documentId
    versionNumber
    srcFilename
    contentURL
    conversionStatus
    status
    srcFile {
      bucket
      region
      key
      url
      __typename
    }
    srcHash
    srcSize
    numPages
    pageSettings {
      pageId
      number
      isRequired
      linkedSlides
      __typename
    }
    pageGroups {
      id
      pageIds
      name
      locked
      sourceID
      source
      __typename
    }
    type
    releaseNotes
    changeType
    labelValues {
      key
      value
      __typename
    }
    customValues {
      fieldId
      objectRecords {
        id
        externalId
        syncStatus
        status
        values {
          fieldId
          values
          __typename
        }
        __typename
      }
      values
      __typename
    }
    title
    shortDescription
    longDescription
    owner
    expiresAt
    hasCopyright
    hasCustomThumbnail
    purpose
    canHideSlides
    distributable
    downloadable
    isInternalGenerated
    semVer {
      minor
      major
      __typename
    }
    notificationScope
    selectedThumbnail
    publishedAt
    uploadedAt
    uploadedBy
    convertedArchiveKey
    convertedArchiveSize
    convertedFolderKey
    associatedFiles {
      id
      isDistributable
      isDefault
      type
      attachmentId
      status
      createdAt
      createdBy
      __typename
    }
    editPermissions
    converterVersion
    createdAt
    createdBy
    updatedAt
    updatedBy
    integration {
      externalVersionId
      version
      timestamp
      srcFileHash
      srcDocumentHash
      __typename
    }
    integrationType
    conversionWarningCode
    conversionErrorCode
    scheduledPublish
    mappingStatus
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDocumentVersionFromExistingMutationVariables,
  APITypes.CreateDocumentVersionFromExistingMutation
>;
export const createDocumentVersionFromExistingIntegrationEngine = /* GraphQL */ `mutation CreateDocumentVersionFromExistingIntegrationEngine(
  $input: CreateDocumentVersionFromExistingEngineInput
) {
  createDocumentVersionFromExistingIntegrationEngine(input: $input) {
    id
    tenantId
    documentId
    versionNumber
    srcFilename
    contentURL
    conversionStatus
    status
    srcFile {
      bucket
      region
      key
      url
      __typename
    }
    srcHash
    srcSize
    numPages
    pageSettings {
      pageId
      number
      isRequired
      linkedSlides
      __typename
    }
    pageGroups {
      id
      pageIds
      name
      locked
      sourceID
      source
      __typename
    }
    type
    releaseNotes
    changeType
    labelValues {
      key
      value
      __typename
    }
    customValues {
      fieldId
      objectRecords {
        id
        externalId
        syncStatus
        status
        values {
          fieldId
          values
          __typename
        }
        __typename
      }
      values
      __typename
    }
    title
    shortDescription
    longDescription
    owner
    expiresAt
    hasCopyright
    hasCustomThumbnail
    purpose
    canHideSlides
    distributable
    downloadable
    isInternalGenerated
    semVer {
      minor
      major
      __typename
    }
    notificationScope
    selectedThumbnail
    publishedAt
    uploadedAt
    uploadedBy
    convertedArchiveKey
    convertedArchiveSize
    convertedFolderKey
    associatedFiles {
      id
      isDistributable
      isDefault
      type
      attachmentId
      status
      createdAt
      createdBy
      __typename
    }
    editPermissions
    converterVersion
    createdAt
    createdBy
    updatedAt
    updatedBy
    integration {
      externalVersionId
      version
      timestamp
      srcFileHash
      srcDocumentHash
      __typename
    }
    integrationType
    conversionWarningCode
    conversionErrorCode
    scheduledPublish
    mappingStatus
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDocumentVersionFromExistingIntegrationEngineMutationVariables,
  APITypes.CreateDocumentVersionFromExistingIntegrationEngineMutation
>;
export const createAttachedFileFromS3Upload = /* GraphQL */ `mutation CreateAttachedFileFromS3Upload(
  $inputAttached: CreateAttachedFileFromS3UploadInput!
) {
  createAttachedFileFromS3Upload(inputAttached: $inputAttached) {
    id
    title
    tenantId
    documentId
    srcFile {
      bucket
      region
      key
      url
      __typename
    }
    srcFileName
    srcHash
    srcSize
    type
    editPermissions
    createdAt
    createdBy
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAttachedFileFromS3UploadMutationVariables,
  APITypes.CreateAttachedFileFromS3UploadMutation
>;
export const downloadDocumentVersionAsPdfFile = /* GraphQL */ `mutation DownloadDocumentVersionAsPdfFile($documentVersionId: ID!) {
  downloadDocumentVersionAsPdfFile(documentVersionId: $documentVersionId)
}
` as GeneratedMutation<
  APITypes.DownloadDocumentVersionAsPdfFileMutationVariables,
  APITypes.DownloadDocumentVersionAsPdfFileMutation
>;
export const downloadCustomDeckAsPdfFile = /* GraphQL */ `mutation DownloadCustomDeckAsPdfFile($customDeckId: ID!) {
  downloadCustomDeckAsPdfFile(customDeckId: $customDeckId)
}
` as GeneratedMutation<
  APITypes.DownloadCustomDeckAsPdfFileMutationVariables,
  APITypes.DownloadCustomDeckAsPdfFileMutation
>;
export const generateContentShare = /* GraphQL */ `mutation GenerateContentShare(
  $type: SHARE_TYPE!
  $contentId: ID!
  $expiresInMins: Int!
  $customValues: [CustomValuesInput!]!
  $notificationScope: SHARE_NOTIFICATION_SCOPE
  $meetingId: String
  $customDeckDependencies: CustomDeckDependenciesInput
) {
  generateContentShare(
    type: $type
    contentId: $contentId
    expiresInMins: $expiresInMins
    customValues: $customValues
    notificationScope: $notificationScope
    meetingId: $meetingId
    customDeckDependencies: $customDeckDependencies
  ) {
    id
    token
    tenantId
    type
    contentId
    meetingId
    expiresAt
    createdAt
    updatedAt
    createdBy
    customValues {
      fieldId
      objectRecords {
        id
        externalId
        syncStatus
        status
        values {
          fieldId
          values
          __typename
        }
        __typename
      }
      values
      __typename
    }
    revoked
    customDeckDependencies {
      versions
      folderId
      __typename
    }
    notificationScope
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.GenerateContentShareMutationVariables,
  APITypes.GenerateContentShareMutation
>;
export const updateUserLambda = /* GraphQL */ `mutation UpdateUserLambda($user: UpdateUserLambdaInput) {
  updateUserLambda(user: $user) {
    id
    cognitoSubId
    tenantId
    email
    givenName
    phoneNumber
    familyName
    meetingId
    defaultFiltersCustomValues {
      fieldId
      objectRecords {
        id
        externalId
        syncStatus
        status
        values {
          fieldId
          values
          __typename
        }
        __typename
      }
      values
      __typename
    }
    lockedFiltersCustomValues {
      fieldId
      objectRecords {
        id
        externalId
        syncStatus
        status
        values {
          fieldId
          values
          __typename
        }
        __typename
      }
      values
      __typename
    }
    defaultFilterValues {
      key
      values
      __typename
    }
    lockedFilters {
      key
      values
      __typename
    }
    shareBCC
    shareCC
    createdAt
    createdBy
    updatedAt
    status
    role
    bookmarkedDocs {
      docID
      createdAt
      __typename
    }
    isExcludedFromReporting
    additionalRoles
    altUsername
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserLambdaMutationVariables,
  APITypes.UpdateUserLambdaMutation
>;
export const updateCustomDeckLambda = /* GraphQL */ `mutation UpdateCustomDeckLambda(
  $customDeck: UpdateCustomDeckLambdaInput!
  $folderId: ID!
  $rootFolderId: ID!
) {
  updateCustomDeckLambda(
    customDeck: $customDeck
    folderId: $folderId
    rootFolderId: $rootFolderId
  ) {
    id
    createdAt
    createdBy
    autoUpdateAcknowledgedAt
    updatedAt
    updatedBy
    tenantId
    groups {
      id
      pages {
        pageId
        pageNumber
        documentVersionId
        __typename
      }
      visible
      srcId
      docAccessLevel
      name
      locked
      __typename
    }
    title
    editMutex {
      userId
      timeStarted
      lastSeenAt
      __typename
    }
    sourceCustomDeckId
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCustomDeckLambdaMutationVariables,
  APITypes.UpdateCustomDeckLambdaMutation
>;
export const updateUserStatusLambda = /* GraphQL */ `mutation UpdateUserStatusLambda($user: DisableUserLambdaInput) {
  updateUserStatusLambda(user: $user) {
    id
    cognitoSubId
    tenantId
    email
    givenName
    phoneNumber
    familyName
    meetingId
    defaultFiltersCustomValues {
      fieldId
      objectRecords {
        id
        externalId
        syncStatus
        status
        values {
          fieldId
          values
          __typename
        }
        __typename
      }
      values
      __typename
    }
    lockedFiltersCustomValues {
      fieldId
      objectRecords {
        id
        externalId
        syncStatus
        status
        values {
          fieldId
          values
          __typename
        }
        __typename
      }
      values
      __typename
    }
    defaultFilterValues {
      key
      values
      __typename
    }
    lockedFilters {
      key
      values
      __typename
    }
    shareBCC
    shareCC
    createdAt
    createdBy
    updatedAt
    status
    role
    bookmarkedDocs {
      docID
      createdAt
      __typename
    }
    isExcludedFromReporting
    additionalRoles
    altUsername
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserStatusLambdaMutationVariables,
  APITypes.UpdateUserStatusLambdaMutation
>;
export const createTenantUser = /* GraphQL */ `mutation CreateTenantUser($user: CreateUserLambdaInput, $sendInvite: Boolean) {
  createTenantUser(user: $user, sendInvite: $sendInvite) {
    id
    cognitoSubId
    tenantId
    email
    givenName
    phoneNumber
    familyName
    meetingId
    defaultFiltersCustomValues {
      fieldId
      objectRecords {
        id
        externalId
        syncStatus
        status
        values {
          fieldId
          values
          __typename
        }
        __typename
      }
      values
      __typename
    }
    lockedFiltersCustomValues {
      fieldId
      objectRecords {
        id
        externalId
        syncStatus
        status
        values {
          fieldId
          values
          __typename
        }
        __typename
      }
      values
      __typename
    }
    defaultFilterValues {
      key
      values
      __typename
    }
    lockedFilters {
      key
      values
      __typename
    }
    shareBCC
    shareCC
    createdAt
    createdBy
    updatedAt
    status
    role
    bookmarkedDocs {
      docID
      createdAt
      __typename
    }
    isExcludedFromReporting
    additionalRoles
    altUsername
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateTenantUserMutationVariables,
  APITypes.CreateTenantUserMutation
>;
export const submitIntegrationRun = /* GraphQL */ `mutation SubmitIntegrationRun($input: RunIntegrationEngineInput) {
  submitIntegrationRun(input: $input) {
    success
    message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SubmitIntegrationRunMutationVariables,
  APITypes.SubmitIntegrationRunMutation
>;
export const submitUserIntegrationRun = /* GraphQL */ `mutation SubmitUserIntegrationRun($input: RunIntegrationEngineInput) {
  submitUserIntegrationRun(input: $input) {
    success
    message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.SubmitUserIntegrationRunMutationVariables,
  APITypes.SubmitUserIntegrationRunMutation
>;
export const updateTenantLogo = /* GraphQL */ `mutation UpdateTenantLogo($input: UpdateTenantLogoInput) {
  updateTenantLogo(input: $input)
}
` as GeneratedMutation<
  APITypes.UpdateTenantLogoMutationVariables,
  APITypes.UpdateTenantLogoMutation
>;
export const lockCustomDeck = /* GraphQL */ `mutation LockCustomDeck(
  $customDeckId: String!
  $timestarted: String!
  $lock: Boolean!
) {
  lockCustomDeck(
    customDeckId: $customDeckId
    timestarted: $timestarted
    lock: $lock
  ) {
    id
    createdAt
    createdBy
    autoUpdateAcknowledgedAt
    updatedAt
    updatedBy
    tenantId
    groups {
      id
      pages {
        pageId
        pageNumber
        documentVersionId
        __typename
      }
      visible
      srcId
      docAccessLevel
      name
      locked
      __typename
    }
    title
    editMutex {
      userId
      timeStarted
      lastSeenAt
      __typename
    }
    sourceCustomDeckId
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.LockCustomDeckMutationVariables,
  APITypes.LockCustomDeckMutation
>;
export const updateZendeskOrg = /* GraphQL */ `mutation UpdateZendeskOrg($input: UpdateZendeskOrgInput!) {
  updateZendeskOrg(input: $input)
}
` as GeneratedMutation<
  APITypes.UpdateZendeskOrgMutationVariables,
  APITypes.UpdateZendeskOrgMutation
>;
export const publishDocument = /* GraphQL */ `mutation PublishDocument($documentId: String!) {
  publishDocument(documentId: $documentId) {
    id
    tenantId
    documentId
    versionNumber
    srcFilename
    contentURL
    conversionStatus
    status
    srcFile {
      bucket
      region
      key
      url
      __typename
    }
    srcHash
    srcSize
    numPages
    pageSettings {
      pageId
      number
      isRequired
      linkedSlides
      __typename
    }
    pageGroups {
      id
      pageIds
      name
      locked
      sourceID
      source
      __typename
    }
    type
    releaseNotes
    changeType
    labelValues {
      key
      value
      __typename
    }
    customValues {
      fieldId
      objectRecords {
        id
        externalId
        syncStatus
        status
        values {
          fieldId
          values
          __typename
        }
        __typename
      }
      values
      __typename
    }
    title
    shortDescription
    longDescription
    owner
    expiresAt
    hasCopyright
    hasCustomThumbnail
    purpose
    canHideSlides
    distributable
    downloadable
    isInternalGenerated
    semVer {
      minor
      major
      __typename
    }
    notificationScope
    selectedThumbnail
    publishedAt
    uploadedAt
    uploadedBy
    convertedArchiveKey
    convertedArchiveSize
    convertedFolderKey
    associatedFiles {
      id
      isDistributable
      isDefault
      type
      attachmentId
      status
      createdAt
      createdBy
      __typename
    }
    editPermissions
    converterVersion
    createdAt
    createdBy
    updatedAt
    updatedBy
    integration {
      externalVersionId
      version
      timestamp
      srcFileHash
      srcDocumentHash
      __typename
    }
    integrationType
    conversionWarningCode
    conversionErrorCode
    scheduledPublish
    mappingStatus
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.PublishDocumentMutationVariables,
  APITypes.PublishDocumentMutation
>;
export const updateDocumentPageData = /* GraphQL */ `mutation UpdateDocumentPageData($input: UpdateJsonPagesLambdaInput!) {
  updateDocumentPageData(input: $input)
}
` as GeneratedMutation<
  APITypes.UpdateDocumentPageDataMutationVariables,
  APITypes.UpdateDocumentPageDataMutation
>;
export const generateDownloadKey = /* GraphQL */ `mutation GenerateDownloadKey(
  $token: ID!
  $contentId: ID
  $contentType: ASSOCIATED_FILE_TYPE
) {
  generateDownloadKey(
    token: $token
    contentId: $contentId
    contentType: $contentType
  )
}
` as GeneratedMutation<
  APITypes.GenerateDownloadKeyMutationVariables,
  APITypes.GenerateDownloadKeyMutation
>;
export const generateDownloadKeyByTenant = /* GraphQL */ `mutation GenerateDownloadKeyByTenant(
  $hubId: ID!
  $contentId: ID
  $contentType: ASSOCIATED_FILE_TYPE
) {
  generateDownloadKeyByTenant(
    hubId: $hubId
    contentId: $contentId
    contentType: $contentType
  )
}
` as GeneratedMutation<
  APITypes.GenerateDownloadKeyByTenantMutationVariables,
  APITypes.GenerateDownloadKeyByTenantMutation
>;
export const pushAnalytics = /* GraphQL */ `mutation PushAnalytics($input: AWSJSON!, $userToken: String) {
  pushAnalytics(input: $input, userToken: $userToken)
}
` as GeneratedMutation<
  APITypes.PushAnalyticsMutationVariables,
  APITypes.PushAnalyticsMutation
>;
export const pushLogs = /* GraphQL */ `mutation PushLogs($input: [String!]!, $shouldSendEmail: Boolean) {
  pushLogs(input: $input, shouldSendEmail: $shouldSendEmail)
}
` as GeneratedMutation<
  APITypes.PushLogsMutationVariables,
  APITypes.PushLogsMutation
>;
export const getLatestDocumentVersionId = /* GraphQL */ `mutation GetLatestDocumentVersionId($input: AWSJSON!) {
  getLatestDocumentVersionId(input: $input)
}
` as GeneratedMutation<
  APITypes.GetLatestDocumentVersionIdMutationVariables,
  APITypes.GetLatestDocumentVersionIdMutation
>;
export const submitTenantForm = /* GraphQL */ `mutation SubmitTenantForm($formId: ID!, $customValues: [CustomValuesInput!]) {
  submitTenantForm(formId: $formId, customValues: $customValues)
}
` as GeneratedMutation<
  APITypes.SubmitTenantFormMutationVariables,
  APITypes.SubmitTenantFormMutation
>;
export const updateFolderSharePermissions = /* GraphQL */ `mutation UpdateFolderSharePermissions(
  $folderId: ID!
  $sharePermissions: [SharePermissionInput!]
  $sendEmailNotification: Boolean
) {
  updateFolderSharePermissions(
    folderId: $folderId
    sharePermissions: $sharePermissions
    sendEmailNotification: $sendEmailNotification
  ) {
    id
    tenantId
    name
    isPinned
    status
    items {
      id
      type
      itemId
      status
      addedAt
      updateAcknowledgedAt
      itemLastUpdatedAt
      visiblePages
      customTitle
      __typename
    }
    createdAt
    createdBy
    updatedAt
    updatedBy
    sharePermissions {
      id
      isDeleted
      type
      targetType
      targetUsername
      targetCustomValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
            __typename
          }
          __typename
        }
        values
        __typename
      }
      targetLabels {
        key
        values
        __typename
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      deletedBy
      deletedAt
      __typename
    }
    shareStatus
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFolderSharePermissionsMutationVariables,
  APITypes.UpdateFolderSharePermissionsMutation
>;
export const updateFolderItems = /* GraphQL */ `mutation UpdateFolderItems(
  $foldersUpdates: [UpdateFolderInput!]!
  $rootFolderId: String
) {
  updateFolderItems(
    foldersUpdates: $foldersUpdates
    rootFolderId: $rootFolderId
  ) {
    id
    tenantId
    name
    isPinned
    status
    items {
      id
      type
      itemId
      status
      addedAt
      updateAcknowledgedAt
      itemLastUpdatedAt
      visiblePages
      customTitle
      __typename
    }
    createdAt
    createdBy
    updatedAt
    updatedBy
    sharePermissions {
      id
      isDeleted
      type
      targetType
      targetUsername
      targetCustomValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
            __typename
          }
          __typename
        }
        values
        __typename
      }
      targetLabels {
        key
        values
        __typename
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      deletedBy
      deletedAt
      __typename
    }
    shareStatus
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateFolderItemsMutationVariables,
  APITypes.UpdateFolderItemsMutation
>;
export const createSubFolder = /* GraphQL */ `mutation CreateSubFolder($newSubFolder: CreateSubFolderInput!) {
  createSubFolder(newSubFolder: $newSubFolder) {
    id
    tenantId
    name
    isPinned
    status
    items {
      id
      type
      itemId
      status
      addedAt
      updateAcknowledgedAt
      itemLastUpdatedAt
      visiblePages
      customTitle
      __typename
    }
    createdAt
    createdBy
    updatedAt
    updatedBy
    sharePermissions {
      id
      isDeleted
      type
      targetType
      targetUsername
      targetCustomValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
            __typename
          }
          __typename
        }
        values
        __typename
      }
      targetLabels {
        key
        values
        __typename
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      deletedBy
      deletedAt
      __typename
    }
    shareStatus
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateSubFolderMutationVariables,
  APITypes.CreateSubFolderMutation
>;
export const upsertVersionContents = /* GraphQL */ `mutation UpsertVersionContents(
  $documentVersionId: ID!
  $tenantId: ID!
  $configName: String
) {
  upsertVersionContents(
    documentVersionId: $documentVersionId
    tenantId: $tenantId
    configName: $configName
  ) {
    documentVersionId
    tenantId
    result
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpsertVersionContentsMutationVariables,
  APITypes.UpsertVersionContentsMutation
>;
export const updateDocVerPageMatches = /* GraphQL */ `mutation UpdateDocVerPageMatches($docVerId: ID!) {
  updateDocVerPageMatches(docVerId: $docVerId) {
    tenantId
    documentVersionId
    status
    message
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDocVerPageMatchesMutationVariables,
  APITypes.UpdateDocVerPageMatchesMutation
>;
