import React, { ReactElement, useEffect, useState, useRef } from 'react';
import { ListRenderItemInfo, Text, TouchableOpacity, StyleSheet, View } from 'react-native';
import { Button, Divider, Icon, List, Modal, Spinner } from '@ui-kitten/components';
import ExtensionBadge from '../ExtensionBadge';
import { ProgressBar, DNAText, DNAIcon } from '@alucio/lux-ui';
import DNAPopover from 'src/components/DNA/Popover/DNAPopover';
import { VIDEO_EXTENSIONS, bytesToSize, formatDocumentFileNameHeader, getExtension } from 'src/utils/documentHelpers';
import useFeatureFlags from 'src/hooks/useFeatureFlags/useFeatureFlags';
import { v4 as uuid } from 'uuid';

// AMPLIFY
import { generateClient } from 'aws-amplify/api';
import { uploadData, UploadDataWithPathOutput, isCancelError } from 'aws-amplify/storage'
import {
  CreateDocumentFromS3UploadInput
} from '@alucio/aws-beacon-amplify/src/API';
import {
  createDocumentFromS3Upload,
  createUserDocumentFromS3Upload,
} from '@alucio/aws-beacon-amplify/src/graphql/mutations';

const styles = StyleSheet.create({
  actionButton: {
    marginRight: 5,
    maxHeight: 37,
    maxWidth: 80,
  },
  actionButtonBackground: {
    backgroundColor: '#0095FF',
  },
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  badge: {
    zIndex: 1,
  },
  defaultIconColor: {
    color: 'gray',
  },
  errorIconColor: {
    color: '#CB3831',
  },
  flexEnd: {
    justifyContent: 'flex-end',
  },
  itemField: {
    flexDirection: 'row',
    paddingLeft: 5,
    paddingRight: 5,
  },
  itemRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  grayColor: {
    color: 'gray',
  },
  largeFlex: {
    flex: 8,
  },
  listItemWrapper: {
    backgroundColor: 'white',
    height: 45,
    justifyContent: 'space-between',
    paddingLeft: 15,
    paddingRight: 15,
  },
  marginLeft: {
    marginLeft: 30,
  },
  mediumFlex: {
    flex: 6,
  },
  modal: {
    backgroundColor: 'white',
    borderRadius: 5,
    maxHeight: 365,
    minWidth: 650,
  },
  modalHeader: {
    justifyContent: 'space-between',
    padding: 15,
  },
  smallFlex: {
    flex: 2,
  },
  statusTextWrapper: {
    flexDirection: 'column',
    marginLeft: 15,
  },
  statusIcon: {
    height: 22,
    width: 22,
  },
  statusText: {
    fontWeight: 'bold',
    fontSize: 17,
  },
  successIconColor: {
    color: '#35C895',
  },
  warningIconColor: {
    color: '#FFAA00',
  },
  xSmallFlex: {
    flex: 1,
  },
});

const MAX_USER_FILE_SIZE = 35 * 1024 * 1024; // 35 MB

const MAX_DOCUMENT_FILE_SIZE = {
  'MP4': 524288000, // 500 MB
  'ZIP': 104857600, // 100 MB
}

interface Props {
  files: File[];
  closeModal: () => void;
  isUserDocument?: boolean;
}

interface FilesDetails {
  index: number;
  filename: string;
  extension: string;
  formattedSize: string;
  size: number;
  uploadingPercentage: number;
  isValidExtension: boolean;
  isValidSize: boolean;
  uploadTask: UploadDataWithPathOutput | null;
  status: 'CANCELED' | 'PENDING' | 'UPLOADING' | 'CREATING' | 'ERROR' | 'UPLOADED';
}

function UploadDocumentsModal(props: Props) {
  const { closeModal, files, isUserDocument } = props;
  const supportedExtensions = [...VIDEO_EXTENSIONS, 'PPTX', 'PDF', 'ZIP'];
  const enableZipDocuments = useFeatureFlags('enableZipDocuments');
  if (!enableZipDocuments) {
    supportedExtensions.splice(supportedExtensions.indexOf('ZIP'), 1);
  }
  const [isUploading, setIsUploading] = useState<boolean>(true);
  const [filesDetails, setFilesDetails] = useState<FilesDetails[]>(formatFiles(files, supportedExtensions));
  const validExtensionFilesLength = filesDetails.filter(({ isValidExtension, isValidSize }) =>
    isValidExtension && isValidSize).length;
  const isCanceled = useRef(false);
  const uploadedSize = useRef<number>(0);
  const uploadedFiles = useRef<number>(0);
  const lastProgress = useRef<number>(0);
  const uploadErrors = useRef<any>([]);
  const uploadStatusText =
    getUploadStatusText(uploadedFiles.current, uploadErrors.current.length, validExtensionFilesLength, isUploading);
  const totalSize = filesDetails.reduce((acc, { isValidExtension, isValidSize, size, status }) => {
    return status === 'CANCELED' || !isValidExtension || !isValidSize ? acc : acc + size;
  }, 0);

  useEffect(() => {
    uploadFiles();
  }, []);

  async function uploadFile(file: File, index: number) {
    try {
      if (filesDetails[index].status === 'CANCELED' || isCanceled.current || !filesDetails[index].isValidExtension) {
        return;
      }

      // Update the status of the uploading file
      setFilesDetails(getUpdatedFileDetails(filesDetails, index, 'UPLOADING'));

      const { size } = file;
      const fileExtension = getExtension(file);
      const key = `publish_tmp/${uuid()}.${fileExtension}`;

      // Check if user file exceeds the limit
      if (size > MAX_USER_FILE_SIZE && isUserDocument) {
        uploadErrors.current[index] = 'This file is too large for upload. The maximum supported file size is ' +
          `${MAX_USER_FILE_SIZE / (1024 * 1024)} MB.`
        setFilesDetails(getUpdatedFileDetails(filesDetails, index, 'ERROR'));
        return;
      }

      // Check if video file exceeds the limit
      if (!filesDetails[index].isValidSize) {
        const maxFileSize = MAX_DOCUMENT_FILE_SIZE[filesDetails[index].extension.toUpperCase()];
        const formattedSize = maxFileSize ? (maxFileSize / 1024 / 1024) : 0
        uploadErrors.current[index] =
          `This file is too large for upload. The maximum supported file size is ${formattedSize}MB.`;
        setFilesDetails(getUpdatedFileDetails(filesDetails, index, 'ERROR'));
        filesDetails.length === 1 && setIsUploading(false);
        return;
      }
      const name = formatDocumentFileNameHeader(file.name)

      // Store the file on S3
      lastProgress.current = 0;
      const uploadTask = uploadData({
        path: ({ identityId }) => `private/${identityId}/${key}`,
        data: file,
        options: {
          contentType: file!.type,
          contentDisposition: `attachment; filename="${name}"`,
          onProgress: (progress) => {
            if (isCanceled.current) {
              return;
            }
            // The idea of calculating on 95% instead of 100% is because after the file is updloaded, the api needs to be called.
            // Therefore, the user won't see the bar completed since there's still an operation being performed.
            const percentageUploaded = (progress.transferredBytes * 95) / size;
            const rounded = percentageUploaded.toFixed(2);

            // Update the uploaded percentage of the uploading file
            setFilesDetails(updateFileDetailsFunction(filesDetails, index, (file) => {
              file.uploadingPercentage = Number.parseFloat(rounded);
              return file
            }));
            // Update the total content uploaded
            uploadedSize.current = (uploadedSize.current - lastProgress.current) + progress.transferredBytes;
            lastProgress.current = progress.transferredBytes;
          },
        },
      })
      setFilesDetails(updateFileDetailsFunction(filesDetails, index, (file) => {
        file.uploadTask = uploadTask;
        return file
      }));
      await uploadTask.result
      setFilesDetails(getUpdatedFileDetails(filesDetails, index, 'CREATING'));
      const apiIinputObj: CreateDocumentFromS3UploadInput = {
        srcFilename: file.name,
        fileS3Key: `${key}`,
      };
      // Update the number of already uploaded files
      uploadedFiles.current += 1;
      if (uploadedFiles.current === validExtensionFilesLength) {
        setIsUploading(false);
        uploadedSize.current = totalSize
      }

      if (isUserDocument) {
        const appsyncClient = generateClient();
        const result = await appsyncClient.graphql({
          query: createUserDocumentFromS3Upload,
          variables: {
            inputDoc: apiIinputObj,
          },
        });
        analytics?.track('VIEWER_DOCUMENT_CREATE', {
          action: 'CREATE',
          category: 'VIEWER_DOCUMENT',
          documentId: result.data?.createUserDocumentFromS3Upload?.documentId,
          documentVersionId: result.data?.createUserDocumentFromS3Upload?.id,
        });
      }
      else {
        const appsyncClient = generateClient();
        const result = await appsyncClient.graphql({
          query: createDocumentFromS3Upload,
          variables: {
            inputDoc: apiIinputObj,
          },
        });
        analytics?.track('DOCUMENT_CREATE', {
          action: 'CREATE',
          category: 'DOCUMENT',
          documentId: result.data?.createDocumentFromS3Upload?.documentId,
          documentVersionId: result.data?.createDocumentFromS3Upload?.id,
        });
      }
      // Change the file's status to uploaded
      setFilesDetails(updateFileDetailsFunction(filesDetails, index, (file) => {
        file.uploadingPercentage = 100;
        file.status = 'UPLOADED';
        return file
      }));
    } catch (e) {
      // The await for the upload will throw an exception if the task is cancelled
      if (isCancelError(e)) {
        console.info(`Upload of ${file.name} was cancelled`)
      } else {
        console.error(e);
        if ((e instanceof Error) && e?.message.includes('Network Error')) {
          uploadErrors.current[index] = e.message;
        } else {
          uploadErrors.current[index] = 'An unexpected error occurred';
        }

        // Update the status of the file to Error
        setFilesDetails(getUpdatedFileDetails(filesDetails, index, 'ERROR'));
      }
    }
  }

  async function uploadFiles(): Promise<void> {
    for (let i = 0; i < files.length; i++) {
      await uploadFile(files[i], i)
    }

    setIsUploading(false);
  }

  function cancelUpload(): void {
    isCanceled.current = true;
    for (let i = 0; i < files.length; i++) {
      if (filesDetails[i].status === 'UPLOADING') {
        setFilesDetails(updateFileDetailsFunction(filesDetails, i, (file) => {
          file.uploadTask?.cancel();
          file.status = 'CANCELED'
          return file
        }));
      }
    }
    closeModal();
  }

  // Update the status of a file to Canceled
  function cancelFile(index: number): void {
    setFilesDetails(updateFileDetailsFunction(filesDetails, index, (file) => {
      file.uploadTask?.cancel();
      file.status = 'CANCELED'
      return file
    }));
  }

  function renderListItem({ item: file }: ListRenderItemInfo<FilesDetails>): ReactElement<View> {
    const showProgressBar = ['PENDING', 'UPLOADING'].includes(file.status) && file.isValidExtension;
    const fileNameSpace = showProgressBar ? styles.mediumFlex : styles.largeFlex;
    return (
      <View style={[styles.listItemWrapper, styles.itemRow]}>
        <View style={[styles.itemField, styles.xSmallFlex, styles.badge]}>
          <ExtensionBadge extension={file.extension} />
        </View>
        <View style={[styles.itemField, fileNameSpace]}>
          <Text numberOfLines={1}>{file.filename}</Text>
        </View>
        {showProgressBar && (
          <View style={styles.smallFlex}>
            <ProgressBar finalValue={100} progress={file.uploadingPercentage} />
          </View>
        )}
        <View style={[styles.itemField, styles.smallFlex, styles.flexEnd]}>
          <Text>{file.formattedSize}</Text>
        </View>
        {getStatusAction(file, () => cancelFile(file.index), uploadErrors)}
      </View>
    );
  }

  function renderModalHeader(): ReactElement<View> {
    const uploadedPercentage = ((uploadedSize.current * 100) / totalSize).toFixed(2);
    return (
      <View style={[styles.itemRow, styles.modalHeader]}>
        <View style={styles.itemRow}>
          {isUploading && <Spinner status="success" />}
          <View style={styles.statusTextWrapper}>
            <Text style={styles.statusText}>{uploadStatusText}</Text>
            {isUploading && <Text style={styles.grayColor}>Progress: {uploadedPercentage}%</Text>}
          </View>
        </View>
        <View style={[styles.itemRow, styles.marginLeft]}>
          {
            isUploading && <Button
              // @ts-ignore
              style={[styles.actionButton, styles.actionButtonBackground]}
              onPress={cancelUpload}
              testID="cancel-button"

            >Cancel
            </Button>
          }
          <Button
            // @ts-ignore
            style={[styles.actionButton, !isUploading && styles.actionButtonBackground]}
            disabled={isUploading}
            onPress={closeModal}
            testID="close-button"
          >
            Close
          </Button>
        </View>
      </View>
    );
  }

  return (
    <Modal visible={true} backdropStyle={styles.backdrop}>
      <View style={styles.modal}>
        {renderModalHeader()}
        <ProgressBar finalValue={totalSize} progress={uploadedSize.current} />
        <Divider />
        <List
          style={{ overflow: 'visible' }}
          data={filesDetails}
          ItemSeparatorComponent={Divider}
          renderItem={renderListItem}
        />
      </View>
    </Modal>
  );
}

// Returns a list of files formatted to be rendered
function formatFiles(files: File[], supportedExtensions: string[]): FilesDetails[] {
  return files.map((file: File, idx) => {
    const extension = (file.name.split('.').pop() || '').toUpperCase();
    return {
      filename: file.name.substring(0, file.name.lastIndexOf('.')),
      extension,
      formattedSize: bytesToSize(file.size),
      size: file.size,
      uploadingPercentage: 0,
      status: idx === 0 ? 'UPLOADING' : 'PENDING',
      index: idx,
      isValidExtension: supportedExtensions.includes(extension),
      isValidSize: (extension !== 'MP4' && extension !== 'ZIP') ||
        (extension === 'MP4' && file.size <= MAX_DOCUMENT_FILE_SIZE.MP4) ||
        (extension === 'ZIP' && file.size <= MAX_DOCUMENT_FILE_SIZE.ZIP),
      uploadTask: null,
    };
  });
}

// According to the file's status will return an icon
function getStatusAction(file: FilesDetails, callback: () => void, uploadErrors): ReactElement<View> {
  if (!file.isValidExtension) {
    return <View style={[styles.itemField, styles.xSmallFlex]} />;
  }

  switch (file.status) {
    case 'CANCELED':
      return (
        <View style={[styles.itemField, styles.xSmallFlex, styles.flexEnd]}>
          <Icon
            style={[styles.statusIcon, styles.warningIconColor]}
            name="alert"
          />
        </View>
      );

    case 'CREATING':
      return (
        <View style={[styles.itemField, styles.xSmallFlex, styles.flexEnd]}>
          <Spinner
            // @ts-ignore
            style={[styles.statusIcon]}
            status="success"
          />
        </View>
      );

    case 'UPLOADED':
      return (
        <View style={[styles.itemField, styles.xSmallFlex, styles.flexEnd]}>
          <DNAIcon
            style={[styles.statusIcon, styles.successIconColor]}
            name="check-circle"
            testID="successful-check-circle"
          />
        </View>
      );

    case 'ERROR': {
      console.error(uploadErrors.current);
      const networkError = uploadErrors.current[file.index].includes('Network');
      return (
        <DNAPopover >
          <DNAPopover.Anchor>
            <DNAIcon
              style={[styles.statusIcon, networkError ? styles.defaultIconColor : styles.errorIconColor]}
              name={networkError ? 'cloud-off-outline' : 'information-outline'}
            />
          </DNAPopover.Anchor>
          <DNAPopover.Content>
            <DNAText status="basic">{uploadErrors.current[file.index]}</DNAText>
          </DNAPopover.Content>
        </DNAPopover>
      );
    }
    default:
      return (
        <View style={[styles.itemField, styles.xSmallFlex, styles.flexEnd]}>
          <TouchableOpacity onPress={() => callback()}>
            <DNAIcon
              style={[styles.statusIcon, styles.defaultIconColor]}
              name="close"
            />
          </TouchableOpacity>
        </View>
      );
  }
}

// Returns the header text according to progress
function getUploadStatusText(uploadedFiles: number, errors: number, totalFiles: number, isUploading: boolean): string {
  let statusText = '';

  if (uploadedFiles === totalFiles || !isUploading) {
    statusText = `Uploaded ${uploadedFiles} item(s)`;
  } else {
    statusText = `Uploading ${uploadedFiles + 1} of ${totalFiles} item(s)`;
  }

  if (errors > 0) {
    statusText += ` - ${errors} error(s)`;
  }

  return statusText;
}

// Returns the file details list with the status updated of a specific file
function getUpdatedFileDetails(files: FilesDetails[], index: number, status: FilesDetails['status']): FilesDetails[] {
  return files.map((file, idx) => {
    if (idx === index) {
      file.status = status;
    }
    return file;
  });
}

function updateFileDetailsFunction(
  files: FilesDetails[],
  index: number,
  updater: (a: FilesDetails) => FilesDetails): FilesDetails[] {
  return files.map((file, idx) => {
    if (idx === index) {
      return updater(file);
    }
    return file;
  });
}

export default UploadDocumentsModal;
