import transform from 'lodash/transform'
import isObject from 'lodash/isObject'

export const OmitDeep = (obj: any, keysToRemove: string[]) => {
  return transform(obj, function (result: any, value: any, key: string) {
    if (!keysToRemove.includes(key)) {
      result[key] = isObject(value) ? OmitDeep(value, keysToRemove) : value
    }
  });
}
