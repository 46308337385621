
import type {
  StateFrom,
  InterpreterFrom,
  Prop,
  EventObject,
  ActorRef,
} from 'xstate';
import { Platform } from '@alucio/core';
import { DeviceMode } from 'src/state/context/AppSettings';
import type loggerSM from './logger.machine';
import type { Typegen0 } from './logger.machine.typegen';

export type LoggerState = StateFrom<typeof loggerSM>
export type LoggerService = InterpreterFrom<typeof loggerSM>
export type LoggerSend = Prop<LoggerService, 'send'>
export type LoggerMachine = typeof loggerSM

export interface User {
  sub: string;
  'custom:user_id': string;
  'custom:org_id': string;
  'cognito:username': string;
  email: string;
}

enum LogCategories {
  'custom-note',
}
export type LogCategory = keyof typeof LogCategories

export enum LogLevel {
  LOG = 'LOG',
  INFO = 'INFO',
  WARN = 'WARN',
  ERROR = 'ERROR',
  DEBUG = 'DEBUG',
  VERBOSE = 'VERBOSE',
}

interface LogInfo {
  loggerLevel: LogLevel,
  logLevel: LogLevel,
  logCategory: string,
  logMessage: any[],
}

export type Log = {
  key: string,
  payload: logPayload,
}

export interface logPayload extends LogInfo {
  tenantId: string,
  userId: string,
  email: string,
  connectionStatus: 'online' | 'offline',
  timeStamp: string,
  timeZone?: string,
  deviceMode: DeviceMode,
  platform: Platform,
}

export enum StateTags {
  MACHINE_READY = 'MACHINE_READY',
  SUCCESSFULLY_SENT_LOGS = 'SUCCESSFULLY_SENT_LOGS',
  FAILED_TO_SEND_LOGS = 'FAILED_TO_SEND_LOGS',
}

export type SMContext = {
  user?: User,
  timeZone?: string,
  deviceMode: DeviceMode,
  platform: Platform,
  err?: any,
  logs: Log[],
  logsToBatchStore?: Log[],
  logsToBatchSend?: string[],
  authActor?: ActorRef<any, EVT_USER_SIGNED_IN | EVT_USER_SIGNED_IN>,
  logActor?: ActorRef<EVT_LOG, EVT_LOG>,
}

export type SMServices = {
  getUserData: {
    data: User
  }
}

export type SMContextKeys = keyof SMContext
export type StringStates = Extract<Typegen0['matchesStates'], string>

export type EvtValues<
  K extends T['type'],
  T extends EventObject = SMEvents
> = T extends { type: K }
  ? Omit<T, 'type'>
  : never

export type EVT_LOG = {
  type: 'LOG'
  payload: LogInfo
}

export type EVT_SEND_LOGS = {
  type: 'SEND_LOGS'
}

export type EVT_USER_SIGNED_IN = {
  type: 'USER_SIGNED_IN',
}

export type EVT_USER_SIGNED_OUT = {
  type: 'USER_SIGNED_OUT',
}

export type EVT_USER_SIGNING_OUT = {
  type: 'USER_SIGNING_OUT',
}

export type SMEvents =
  | EVT_LOG
  | EVT_SEND_LOGS
  | EVT_USER_SIGNED_IN
  | EVT_USER_SIGNED_OUT
  | EVT_USER_SIGNING_OUT
