import React, { createContext, PropsWithChildren, useContext, useState } from 'react';
import { ScrollView, StyleSheet, Text, useWindowDimensions, View } from 'react-native';
import { updatePassword } from 'aws-amplify/auth';
import {
  Iffy,
  DNABox,
  DNAButton,
  luxColors,
  DNAText,
  useToast,
  ToastOrientations,
  GenericToast,
  InformationMessage,
} from '@alucio/lux-ui'

import withPasswordChecker, { ValidationType } from 'src/screens/Authentication/Password/PasswordChecker';
import PasswordCriterias from 'src/screens/Authentication/Password/PasswordCriterias';
import InputComponent from 'src/components/Publishers/InputComponent';
import { useAppSettings } from 'src/state/context/AppSettings';
import { LuxSizeEnum } from '@alucio/lux-ui/src/typings';

const styles = StyleSheet.create({
  buttonsWrapper: {
    marginTop: 8,
  },
  ssoMessage: {
    marginTop: 40,
  },
  errorWrapper: {
    height: 20,
  },
  invalidMessage: {
    color: luxColors.error.primary,
    fontSize: 12,
  },
  mainWrapper: {
    maxWidth: 500,
    paddingTop: 40,
  },
  mainTabletWrapper: {
    maxWidth: '95vw',
    marginHorizontal: '2vw',
    marginTop: '2vw',
  },
  scrollableContainer: {
    paddingBottom: 85,
  },
});

const INVALID_PARAMETER = 'InvalidParameterException';
const NOT_AUTHORIZED = 'NotAuthorizedException';

interface Props {
  isPasswordValid: boolean;
  onPasswordChange: (pswd: string) => void,
  onRepeatedPasswordChange: (pswd: string) => void,
  password: string;
  passwordValidations: ValidationType;
  repeatedPassword: string;
}

export const usePasswordForm = () => {
  const context = useContext(PasswordContext);

  if (!context) {
    throw new Error('useScrollWidth must be used within the SideBarLayoutContextProvider')
  }

  return context;
}

const PasswordProviderInternal: React.FC<PropsWithChildren<Props>> = (props) => {
  const {
    isPasswordValid,
    passwordValidations,
    onPasswordChange,
    onRepeatedPasswordChange,
    password,
    repeatedPassword,
  } = props;

  const [currentPassword, setCurrentPassword] = useState<string>('');
  const [changingInProgress, setChangingInProgress] = useState<boolean>(false);
  const [isCurrentPasswordInvalid, setIsCurrentPasswordInvalid] = useState<boolean>(false);
  const [changedSuccessfully, setChangedSuccessfully] = useState<boolean>(false);
  const [unexpectedError, setUnexpectedError] = useState<boolean>(false);
  const toast = useToast()
  const { isSSO } = useAppSettings()

  function showToast() {
    toast.add(
      <GenericToast
        title="Password successfully changed."
        status="success"
      />,
      ToastOrientations.TOP_RIGHT_MEETINGS,
    )
  }

  // [TODO] Consider using a reducer to avoid regenerating functions
  function onCurrentPasswordChange(pswd: string): void {
    setCurrentPassword(pswd);
  }

  function clearFields(): void {
    setCurrentPassword('');
    onPasswordChange('');
    onRepeatedPasswordChange('');
  }

  async function onChangePassword(): Promise<boolean> {
    let error = false

    setIsCurrentPasswordInvalid(false);
    setChangingInProgress(true);
    setUnexpectedError(error);
    try {
      if (currentPassword && isPasswordValid) {
        await updatePassword({
          oldPassword: currentPassword,
          newPassword: password,
        });
        setChangedSuccessfully(true);

        // Cleans the inputs
        clearFields()

        showToast();
      }
    } catch (e: any) {
      error = true
      setChangedSuccessfully(false);
      if (e.code === INVALID_PARAMETER || e.code === NOT_AUTHORIZED) {
        setIsCurrentPasswordInvalid(true);
      } else {
        setUnexpectedError(error);
      }
    } finally {
      setChangingInProgress(false);
    }

    return error
  }

  const contextValue = {
    currentPassword,
    changingInProgress,
    isCurrentPasswordInvalid,
    changedSuccessfully,
    unexpectedError,
    onCurrentPasswordChange,
    onChangePassword,
    passwordValidations,
    repeatedPassword,
    isPasswordValid,
    onPasswordChange,
    onRepeatedPasswordChange,
    password,
    clearFields,
    isSSO,
  }

  return (
    <PasswordContext.Provider value={contextValue}>
      {props.children}
    </PasswordContext.Provider>
  )
}

export const PasswordProvider = withPasswordChecker(PasswordProviderInternal)

export interface PasswordType {
  currentPassword: string,
  changingInProgress: boolean,
  isCurrentPasswordInvalid: boolean,
  changedSuccessfully: boolean,
  unexpectedError: boolean,
  onCurrentPasswordChange: (pswd: string) => void,
  onChangePassword: () => Promise<boolean>,
  passwordValidations: ValidationType,
  repeatedPassword: string,
  isPasswordValid: boolean,
  onPasswordChange: (pswd: string) => void,
  onRepeatedPasswordChange: (pswd: string) => void,
  password: string,
  clearFields: () => void,
  isSSO: boolean,
}

export const PasswordContext = createContext<PasswordType>(null!);

const PasswordDesktopComponent = () => {
  const {
    currentPassword,
    changingInProgress,
    isCurrentPasswordInvalid,
    changedSuccessfully,
    unexpectedError,
    onCurrentPasswordChange,
    onChangePassword,
    passwordValidations,
    repeatedPassword,
    isPasswordValid,
    onPasswordChange,
    onRepeatedPasswordChange,
    password,
    isSSO,
  } = usePasswordForm();

  // [TODO - Comeback to this]
  if (isSSO)
  { return (
    <InformationMessage
      text="Your password is managed by your organization."
      variance="warning"
      customWrapperStyle={styles.ssoMessage}
    />
  ) }

  return (
    <DNABox style={styles.mainWrapper} appearance="col" spacing="sm">
      <DNABox appearance="col">
        <InputComponent
          testID="current-password-input"
          hidePlaceholder={true}
          isPassword={true}
          removeMarginPadding={true}
          required={false}
          title="CURRENT PASSWORD"
          titleColor={luxColors.contentText.tertiary}
          value={currentPassword}
          titleSpacing={LuxSizeEnum.md}
          inputStyle={{ backgroundColor: luxColors.info.primary }}
          onChangeText={onCurrentPasswordChange}
          disabled={changingInProgress}
          autoCompleteType="new-password"
        />
        <View style={styles.errorWrapper}>
          <Iffy is={isCurrentPasswordInvalid}>
            <Text testID="current-invalid-password" style={styles.invalidMessage}>Invalid password</Text>
          </Iffy>
        </View>
      </DNABox>
      <PasswordCriterias
        passwordValidations={passwordValidations}
        headerTextColor={luxColors.contentText.tertiary}
        headerText="New password must contain:"
      />
      <InputComponent
        testID="new-password-input"
        hidePlaceholder={true}
        isPassword={true}
        removeMarginPadding={true}
        required={false}
        title="NEW PASSWORD"
        titleSpacing={LuxSizeEnum.md}
        titleColor={luxColors.contentText.tertiary}
        value={password}
        inputStyle={{ backgroundColor: luxColors.info.primary }}
        onChangeText={onPasswordChange}
        disabled={changingInProgress}
      />
      <InputComponent
        testID="confirm-password-input"
        hidePlaceholder={true}
        isPassword={true}
        removeMarginPadding={true}
        required={false}
        title="CONFIRM NEW PASSWORD"
        titleSpacing={LuxSizeEnum.md}
        titleColor={luxColors.contentText.tertiary}
        value={repeatedPassword}
        inputStyle={{ backgroundColor: luxColors.info.primary }}
        onChangeText={onRepeatedPasswordChange}
        onSubmitEditing={onChangePassword}
        disabled={changingInProgress}
      />
      <Iffy is={changedSuccessfully}>
        <DNAText testID="password-change-message" status="success">Your password was successfully changed.</DNAText>
      </Iffy>
      <Iffy is={unexpectedError}>
        <Text style={styles.invalidMessage}>Something unexpected happened.</Text>
        <Text style={styles.invalidMessage}>Please try again or contact support@alucio.io</Text>
      </Iffy>
      <DNABox spacing="sm" style={styles.buttonsWrapper}>
        <DNAButton
          testID="change-password-button"
          size="sm"
          onPress={onChangePassword}
          disabled={!isPasswordValid || !currentPassword || changingInProgress}
        >
          Change password
        </DNAButton>
      </DNABox>
    </DNABox>
  );
};

const PasswordTabletComponent = () => {
  const {
    currentPassword,
    changingInProgress,
    isCurrentPasswordInvalid,
    unexpectedError,
    onCurrentPasswordChange,
    onChangePassword,
    passwordValidations,
    repeatedPassword,
    onPasswordChange,
    onRepeatedPasswordChange,
    password,
    isSSO,
  } = usePasswordForm();
  const { height } = useWindowDimensions()

  if (isSSO) {
    return (
      <InformationMessage text="Your password is managed by your organization." variance="warning" />
    )
  }

  return (
    <DNABox fill appearance="col">
      <ScrollView contentContainerStyle={styles.scrollableContainer} style={{ height: height - 72 }}>
        <DNABox style={styles.mainTabletWrapper} appearance="col" spacing="sm">
          <DNABox appearance="col">
            <InputComponent
              hidePlaceholder={true}
              isPassword={true}
              removeMarginPadding={true}
              required={false}
              title="CURRENT PASSWORD"
              titleColor={luxColors.contentText.tertiary}
              value={currentPassword}
              titleSpacing={LuxSizeEnum.md}
              inputStyle={{ backgroundColor: luxColors.info.primary }}
              onChangeText={onCurrentPasswordChange}
              disabled={changingInProgress}
            />
            <View style={styles.errorWrapper}>
              <Iffy is={isCurrentPasswordInvalid}>
                <Text style={styles.invalidMessage}>Invalid password</Text>
              </Iffy>
            </View>
          </DNABox>
          <PasswordCriterias
            passwordValidations={passwordValidations}
            headerTextColor={luxColors.contentText.tertiary}
            headerText="New password must contain:"
          />
          <InputComponent
            hidePlaceholder={true}
            isPassword={true}
            removeMarginPadding={true}
            required={false}
            title="NEW PASSWORD"
            titleSpacing={LuxSizeEnum.md}
            titleColor={luxColors.contentText.tertiary}
            value={password}
            inputStyle={{ backgroundColor: luxColors.info.primary }}
            onChangeText={onPasswordChange}
            disabled={changingInProgress}
            autoCompleteType="new-password"
          />
          <InputComponent
            hidePlaceholder={true}
            isPassword={true}
            removeMarginPadding={true}
            required={false}
            title="CONFIRM NEW PASSWORD"
            titleSpacing={LuxSizeEnum.md}
            titleColor={luxColors.contentText.tertiary}
            value={repeatedPassword}
            inputStyle={{ backgroundColor: luxColors.info.primary }}
            onChangeText={onRepeatedPasswordChange}
            onSubmitEditing={onChangePassword}
            disabled={changingInProgress}
            autoCompleteType="new-password"
          />
          <Iffy is={unexpectedError}>
            <Text style={styles.invalidMessage}>Something unexpected happened.</Text>
            <Text style={styles.invalidMessage}>Please try again or contact support@alucio.io</Text>
          </Iffy>
        </DNABox>
      </ScrollView>
    </DNABox>
  );
};

const PasswordSwitcher = (props) => {
  const { deviceMode } = useAppSettings()

  return (
    deviceMode === 'desktop'
      ? (
        <PasswordProvider {...props}>
          <PasswordDesktopComponent {...props} />
        </PasswordProvider>
      )
      : <PasswordTabletComponent {...props} />
  )
}

export default PasswordSwitcher;
