import { useEffect, useRef } from 'react';
import { HubCapsule, HubPayload } from '@aws-amplify/core';
import * as logger from 'src/utils/logger';
import { useIsExternalPlayer } from 'src/screens/Loading';
import { useAppSettings } from 'src/state/context/AppSettings';
import useFeatureFlags from '../useFeatureFlags/useFeatureFlags';
import { EventDataMap } from '@aws-amplify/core/dist/esm/Hub/types';

enum DATASTORE_EVENTS {
  READY = 'ready',
  STORAGE_SUBSCRIBED = 'storageSubscribed',
  SYNC_QUERIES_READY = 'syncQueriesReady',
  SYNC_QUERIES_STARTED = 'syncQueriesStarted',
  NETWORK_STATUS = 'networkStatus'
}
export type DataStoreEventKeys = keyof typeof DATASTORE_EVENTS

/**
 * Hook that keeps track of DataStore events during the syncing process.
 * @hook
 * @returns {Function} A callback function to be used as the event handler for DataStore events which will determine
 * if the initial syncing process of DataStore is completed.
 * The function accepts a single parameter:
 * @param capsule: HubCapsule: The event object from DataStore, which includes the event type and payload data.
 * @see {@link https://docs.amplify.aws/gen1/react/build-a-backend/more-features/datastore/datastore-events/ | DataStore Events Documentation}
 */
const useHandleDSInitialSyncingStatus = (): ((capsule: HubCapsule<string, EventDataMap>) => boolean) => {
  const featureFlags = useFeatureFlags('enableSkipDatastoreOnReady', 'enableSkipDatastoreOnReady');
  const isExternalPlayer = useIsExternalPlayer();
  const dataStoreInitializing = useRef<boolean>(false);
  const { isOnline } = useAppSettings();
  const isOnlineRef = useRef<boolean>(isOnline);
  const observedEvents = useRef<Record<DataStoreEventKeys, HubPayload | undefined>>({
    NETWORK_STATUS: undefined,
    READY: undefined,
    STORAGE_SUBSCRIBED: undefined,
    SYNC_QUERIES_READY: undefined,
    SYNC_QUERIES_STARTED: undefined,
  })

  useEffect(() => {
    isOnlineRef.current = isOnline;
  }, [isOnline]);

  const isInitialSyncingProcessCompleted = (capsule: HubCapsule<string, EventDataMap>): boolean => {
    const { payload: { event } } = capsule;

    if (event === DATASTORE_EVENTS.STORAGE_SUBSCRIBED) {
      observedEvents.current[DATASTORE_EVENTS.STORAGE_SUBSCRIBED] = capsule.payload
      logger.userInit.debug(`Got ${DATASTORE_EVENTS.STORAGE_SUBSCRIBED} event`, capsule)
    }

    if (event === DATASTORE_EVENTS.SYNC_QUERIES_STARTED) {
      observedEvents.current[DATASTORE_EVENTS.SYNC_QUERIES_STARTED] = capsule.payload
      logger.userInit.debug(`Got ${DATASTORE_EVENTS.SYNC_QUERIES_STARTED} event`, capsule)
      logger.userInit.debug(`Waiting for ${DATASTORE_EVENTS.SYNC_QUERIES_READY} event`)
    }

    if (event === DATASTORE_EVENTS.SYNC_QUERIES_READY) {
      observedEvents.current[DATASTORE_EVENTS.SYNC_QUERIES_READY] = capsule.payload
      logger.userInit.debug(`Got ${DATASTORE_EVENTS.SYNC_QUERIES_READY} event`, capsule)
    }

    if (event === DATASTORE_EVENTS.READY) {
      observedEvents.current[DATASTORE_EVENTS.READY] = capsule.payload
      logger.userInit.debug(`Got ${DATASTORE_EVENTS.READY} event`, capsule)
    }

    if (event === DATASTORE_EVENTS.NETWORK_STATUS) {
      observedEvents.current[DATASTORE_EVENTS.NETWORK_STATUS] = capsule.payload;
      logger.userInit.debug(`Got ${DATASTORE_EVENTS.NETWORK_STATUS} event`, capsule)
    }

    // [NOTE] - There seems to be a bug within DataStore that MAY fire the `ready` event pre-maturely
    //          - The consistency of this issue may be affected by the amount of records a Tenant may have
    //            where larger records counts are more likely to reproduce
    //        - Normally, we're under the assumption that the `ready` should be dispatched when all models have synced
    //          - This seems to be the normal case for both full syncs and even delta/partial sync
    //            - (Though we've seen one case where during a partial sync where it's fire before any delta syncs happen)
    //        - Since the `ready` event MAY fires pre-maturely, it allows the user to access the app sooner than it should
    //          which causes the internal DataStore processor to get backed up with too many requests
    //        - We instead rely on the `syncQueriesReady` event as it seems to always dispatch in the correct order when necessary
    //          - When the app HAS an internet connection, it will always attempt a sync which first dispatches the `syncQueriesStarted`
    //            - This happens when loading initially, refreshing and coming back online
    //            - Therefore, iff we see this event, we need to wait for `syncQueriesReady`
    //          - When the app does NOT have an internet connection, it will NOT dispatch `syncQueriesStarted`
    //            and subsequently not dispatch `syncQueriesReady`
    //            - So if we do NOT see this event `syncQueriesStarted`, fallback to just waiting for the normal `ready`
    const hasSyncQueriesStartedEvent = !!observedEvents.current[DATASTORE_EVENTS.SYNC_QUERIES_STARTED]
    const hasSyncQueriesReadyEvent = !!observedEvents.current[DATASTORE_EVENTS.SYNC_QUERIES_READY]
    const hasReadyEvent = !!observedEvents.current[DATASTORE_EVENTS.READY]
    const hasStorageSubscribedEvent = !!observedEvents.current[DATASTORE_EVENTS.STORAGE_SUBSCRIBED]

    const isDataStoreReadyOnline = (hasSyncQueriesStartedEvent && hasSyncQueriesReadyEvent)
    const isDataStoreReadyOffline = hasReadyEvent && (!hasSyncQueriesStartedEvent ||
      (!isOnlineRef.current && observedEvents.current[DATASTORE_EVENTS.NETWORK_STATUS]?.data?.active === false));
    const isDataStoreReadyPremature = (
      hasSyncQueriesStartedEvent &&
      !hasSyncQueriesReadyEvent &&
      hasReadyEvent
    )

    if (isDataStoreReadyPremature) {
      // [TODO] - Keep monitoring to see if that can happen during certain states and lock the user out due to `syncQueriesReady` never finishing
      //        - i.e., Offline-like scenarios, we may want to set a timeout and allow them to eventually enter the app
      //          - Or just wait for the DNALoader to suggest refresh/logout/etc
      logger.userInit.warn('RECEIVED PREMATURE READY EVENT', observedEvents.current)
    }

    // WE'LL SKIP THE READY EVENT FROM DATASTORE IF IT HAS PREVIOUSLY SYNCED ITS MODELS //
    // WE'LL SKIP BY DEFAULT DURING 3PC FOR THE EXTERNAL WINDOW
    const skipReadyEvent = (
      (featureFlags.enableSkipDatastoreOnReady || isExternalPlayer) &&
      localStorage.getItem('hasDatastoreHydrated') === 'true' &&
      hasStorageSubscribedEvent
    )

    const isDataStoreReady = (
      isDataStoreReadyOnline ||
      isDataStoreReadyOffline ||
      skipReadyEvent
    ) && !dataStoreInitializing.current

    if (isDataStoreReady) {
      // [NOTE] - Make sure we don't double initialize
      dataStoreInitializing.current = true
      logger.userInit.debug(
        'Starting datastore',
        {
          isDataStoreReadyOnline,
          isDataStoreReadyOffline,
          skipReadyEvent,
          observedEvents,
          dataStoreInitializing: dataStoreInitializing.current,
        },
      )
      if (skipReadyEvent) {
        // DataStore has already hydrated
        logger.userInit.debug('Skipping DataStore ready event');
      }
    }

    return isDataStoreReady;
  }

  return isInitialSyncingProcessCompleted;
};

export default useHandleDSInitialSyncingStatus;
