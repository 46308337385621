import React, { useEffect, useState } from 'react';
import { DNABox, DNAButton, DNAText, DNAContextMenu, Iffy, Rotate, Icon } from '@alucio/lux-ui';
import { StyleProp, ViewStyle, StyleSheet } from 'react-native';
import VersioningPanel from 'src/screens/Publishers/Versioning/VersioningPanel/VersioningPanel';
import { generateClient } from 'aws-amplify/api';
import { createWebDocument } from '@alucio/aws-beacon-amplify/src/graphql/mutations'
import { useDocumentVersionORM } from 'src/state/redux/selector/document';
import { colors } from '@alucio/lux-ui/src/theming/themes/alucio/colors';
import useFeatureFlags from 'src/hooks/useFeatureFlags/useFeatureFlags';
interface Props {
  testID?: string;
  disabled?: boolean;
  multiple?: boolean;
  style?: StyleProp<ViewStyle>;
  context?: 'round';
  size?: 'xs' | 'sm' | 'md';
  iconLeft?: string;
  iconStyle?: StyleProp<ViewStyle>;
  onSelect: (files: File[]) => void;
  text: string;
  status?: 'primary' | 'success' | 'info';
  acceptedTypes?: string;
  appearance?: 'filled' | 'outline' | 'ghost' | undefined;
  rounded?: 'none' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'full';
  showContextMenu?: boolean;
}

const styles = StyleSheet.create({
  uploadBtn: {
    marginRight: 16,
  },
  uploadBtnWithContextMenu: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  contextMenu: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    marginLeft: 1,
  },
  processingIcon: {
    height: 16,
    width: 16,
    color: colors['text-white'],
  },
})

function SelectFileButton(props: Props) {
  const enableZipDocuments = useFeatureFlags('enableZipDocuments');
  const acceptedFileTypes = enableZipDocuments
    ? '.pptx,.pdf,.mp4,.mov,.avi,.mpeg,.webm,.mkv,.zip'
    : '.pptx,.pdf,.mp4,.mov,.avi,.mpeg,.webm,.mkv';

  const {
    acceptedTypes = acceptedFileTypes,
    multiple,
    onSelect,
    text,
    disabled,
    showContextMenu,
    ...restButtonProps
  } = props;
  function onFilesSelected(event): void {
    event.stopPropagation();
    event.preventDefault();
    onSelect([...event.target.files]);
    // Set value to empty string so onChange always fire
    setValue('');
  }
  const [documentVersionId, setDocumentVersionId] = useState<string>('')
  const [documentId, setDocumentId] = useState<string>()
  const [isDisabled, setIsDisabled] = useState<boolean>(false)
  const [value, setValue] = useState('');
  const uploadBtnStyle = showContextMenu ? styles.uploadBtnWithContextMenu : styles.uploadBtn
  const documentVersionORM = useDocumentVersionORM(documentVersionId);
  useEffect(() => {
    if (documentVersionORM && documentVersionORM.model.documentId !== documentId) {
      setDocumentId(documentVersionORM.model.documentId)
      setIsDisabled(false)
      setDocumentVersionId('')
    }
  }, [documentVersionORM])

  const uploadBtnLoadingChildren: React.FC = () => {
    return (
      <>
        <Rotate>
          <Icon style={styles.processingIcon} name="refresh" />
        </Rotate>
        <DNAText status="basic" style={{ marginLeft: 8 }}>Creating...</DNAText>
      </>
    )
  }

  return (
    <DNABox testID="upload-button">
      <input
        accept={acceptedTypes}
        id="uploadButton"
        type="file"
        style={{ display: 'none' }}
        multiple={!!multiple}
        value={value}
        onChange={onFilesSelected}
        disabled={disabled}
      />
      <label htmlFor="uploadButton">
        <DNAButton
          {...restButtonProps}
          style={uploadBtnStyle}
          disabled={isDisabled}
          children={isDisabled ? uploadBtnLoadingChildren : text}
        />
      </label>
      <Iffy is={showContextMenu}>
        <DNAContextMenu>
          <DNAContextMenu.Anchor>
            <DNAButton
              disabled={isDisabled}
              onPress={() => { }}
              iconLeft="chevron-down"
              style={styles.contextMenu}
              padding="sm"
            />
          </DNAContextMenu.Anchor>
          <DNAContextMenu.Items>
            <DNAContextMenu.Item
              collapseOnPress
              delay={100} // Workaround because sometimes the item deletes too fast before the ContextMenu can hide itself
              title="Create website document"
              onPress={async () => {
                try {
                  setIsDisabled(true)
                  const appsyncClient = generateClient();
                  const { data } = await appsyncClient.graphql({
                    query: createWebDocument
                  });
                  setDocumentVersionId(data.createWebDocument.id)
                } catch (e) {
                  console.warn('Error creating website document.', e)
                }
              }}
            />
          </DNAContextMenu.Items>
        </DNAContextMenu>
      </Iffy>
      <VersioningPanel
        documentId={documentId}
        setDocumentId={setDocumentId}
      />
    </DNABox>
  );
}

export default SelectFileButton;
