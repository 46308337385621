import {
  AttendeeType,
  ControlType,
  CRMAccountToObjectSetting,
  CustomFieldDefinition,
  CustomFieldUsage,
  FieldDataType,
  FieldStatus,
  Tenant,
} from '@alucio/aws-beacon-amplify/src/models';
import {
  getFirstSubmitRecordPayload,
  getRecordIdsToDelete,
  getUpsertRecords,
  getValidObjectsIds,
  SalesforceFormTranslator,
} from './SalesforceFormTranslator';
import {
  getCustomFormRecordsToDelete,
  getCustomFormRecordsToUpsert,
  getFormattedCRMCustomFormRecordsFirstSubmitPayload,
} from './CRMCustomFormRecordTranslator';
import {
  CRMAccount,
  CRMAddress,
  CRMSubmitMeetingPayload,
  CRMSubmitStandaloneFormPayload,
  FormSettings,
  LayoutItem,
  LayoutSection,
  RecordToDelete,
  SalesforceFirstSubmitPayload,
  SalesforceFirstSubmitRecord,
  SalesforceFormSettings,
  SalesforceRecordToUpsert,
  SalesforceUpdateSubmit,
  SFPicklistRecord,
  VeevaBasicPayloadFields,
} from '../CRMIndexedDBTypes';
import { FormTranslatorResponse } from '../CRMHandler';
import {
  ADDITIONAL_TREATMENT_FIELDS,
  ATTENDEE_LIST_FIELDS,
  handleZvod,
  Marker,
  SUPPORTED_ZVODS,
} from './VeevaMarkerHandler';
import get from 'lodash/get';
import omit from 'lodash/omit';
import cloneDeep from 'lodash/cloneDeep';
import format from 'date-fns/format';
import { isIOS, isMobile, isTablet } from 'react-device-detect'
import { Singleton as IndexDbCrm } from '../CRMIndexedDB';
import { isArrayOfObjectsWithIds, isSalesforceFirstSubmitPayload } from 'src/types/typeguards';
import { AttendeeForm } from 'src/state/context/Meetings/saveMeetingHelper';
import { checkProcessItem, getIdsOfNonUpdateableObjects, TABLES } from './VeevaTranslatorUtils';
import { isFieldRequiredInLayout } from 'src/hooks/useCRMMeetingFormHandler/useVeevaWatchFormHandler';
import { VEEVA_SECTION_TO_IGNORE } from '../Syncers/VeevaSyncerUtils';
import { MEETING_SUBMIT_TYPE } from 'src/components/Meeting/AddMeetingProvider';
import { FormValuesType } from 'src/components/CustomFields/ComposableForm';
import { indexArray } from 'src/utils/arrayHelpers';
import * as logger from 'src/utils/logger'

// THERE MIGHT BE FIELDS THAT SHOULDN'T BE COPIED DOWN TO THE ATTENDEE'S OBJECTS
const NON_ATTENDEES_FIELDS = [SUPPORTED_ZVODS.ATTENDEES_LIST];

export class VeevaFormTranslator extends SalesforceFormTranslator {
  protected formSettings: SalesforceFormSettings[];

  // THESE CRM FIELDS ARE BEING SKIPPED AS THEY WILL BE HANDLED UPON
  // SUBMITTING THE CALL AND WILL BE FILLED USING BEACON FIELDS
  private FIELDS_TO_SKIP: { [tableName: string]: string[] } = {
    Call2_vod__c: [
      'Parent_Address_vod__c',
      'Allowed_Products_vod__c',
      'Call_Datetime_vod__c',
      'Address_vod__c',
      'Account_vod__c',
      // [TODO]: WILL BE HANDLED ON BEAC-5259
      'Account_Plan_vod__c',
    ],
    Call2_Discussion_vod__c: [
      'Account_vod__c',
      'Detail_Group_vod__c',
    ],
  };

  constructor(formSettings: SalesforceFormSettings[], mainTable: string) {
    super(formSettings, mainTable);
    this.formSettings = formSettings;
  }

  //* * SALESFORCE CONFIG TO BEACON **//
  getFormCustomFieldConfig(): FormTranslatorResponse {
    return super.getFormCustomFieldConfig();
  }

  protected processSection(
    section: LayoutSection,
    formSettings: SalesforceFormSettings,
    markers: Marker[],
  ): CustomFieldDefinition[] {
    // THERE MIGHT BE SECTIONS WE HANDLE OURSELVES
    if (VEEVA_SECTION_TO_IGNORE.includes(formSettings.apiName)) {
      return [];
    }

    const customFields = super.processSection(section, formSettings, markers);
    const isZodSection = section.layoutRows.every((row) => row.layoutItems
      .every((item) => item.label.startsWith('zvod_')));
    if (customFields.length > 0 && !isZodSection && !markers) {
      const labelCustomField = getSectionLabelCustomField(section);
      customFields.unshift(labelCustomField);
    }
    return customFields;
  }

  protected processItem(
    item: LayoutItem,
    formSetting: SalesforceFormSettings,
    markers?: Marker[],
    rowNumber?: number,
  ): CustomFieldDefinition[] {
    const isZvod = item.label.startsWith('zvod_') || (item.layoutComponents[0]?.apiName || '').startsWith('zvod_');
    const fieldId = get(item, 'layoutComponents[0].apiName', '')
    const processItemSuper = super.processItem.bind(this);
    const formSettings = this.formSettings;

    function processItem(): CustomFieldDefinition[] {
      return checkProcessItem(processItemSuper(item, formSetting, markers, rowNumber), formSetting, formSettings);
    }

    if (this.FIELDS_TO_SKIP[formSetting.objectInfos.apiName]?.includes(fieldId)) {
      return []
    }

    if (isZvod || ADDITIONAL_TREATMENT_FIELDS.includes(fieldId)) {
      return handleZvod(item, this.formSettings, super.processChild.bind(this), processItem);
    }

    return checkProcessItem(super.processItem(item, formSetting, markers, rowNumber), formSetting, this.formSettings);
  }
}

interface AttendeesAccounts {
  additionalAttendees: AttendeeMap[];
  primaryCRMAccount?: CRMAccount;
}

interface AttendeeMap {
  attendeeForm: AttendeeForm;
  account: CRMAccount;
}

// ** FIRST SUBMIT FUNCTIONS ** //
export async function getVeevaFirstSubmitPayload(
  crmSubmitPayload: CRMSubmitMeetingPayload): Promise<SalesforceFirstSubmitPayload> {
  logger.veevaTranslator.debug('Getting payload for first Veeva submit.', crmSubmitPayload);
  const { formSettings, crmCustomFormRecords, tenant } = crmSubmitPayload;
  let attendeesPersonsCount = 0;
  const mainRecordSetting = formSettings.find((record) => record.isMainTable);
  // MEETING ATTENDEES WITH THEIR CRM ACCOUNTS
  const attendeesMapped = await getAttendeesMapped(crmSubmitPayload, crmSubmitPayload.formValuesAttendees);

  attendeesMapped.additionalAttendees.forEach((attendee) => {
    if (attendee.account.IsPersonAccount) {
      attendeesPersonsCount += 1;
    }
  })

  if (!mainRecordSetting) {
    throw Error('Settings for main table not found.');
  }

  // REPLACES RAW FORM FIELDS IF REQUIRED //
  crmSubmitPayload.mainCrmValues = getTreatedFormValues(crmSubmitPayload.mainCrmValues);

  // PAYLOAD FOR THE MAIN CALL RECORD
  const mainAccountCallRecord: SalesforceFirstSubmitRecord =
    await getFirstSubmitMainRecordPayload(mainRecordSetting, crmSubmitPayload,
      attendeesPersonsCount, attendeesMapped.primaryCRMAccount);

  const attendeeRecords = getAttendeesCallRecords(
    attendeesMapped.additionalAttendees,
    cloneDeep(mainAccountCallRecord),
    mainRecordSetting,
    attendeesPersonsCount,
    crmSubmitPayload);

  logger.veevaTranslator.debug('Attendees records payload:', attendeeRecords);

  // ADDS, TO THE MAIN CALL, A RELATIONSHIP WITH CHILD CALLS
  if (attendeeRecords.length) {
    mainAccountCallRecord.Call2_vod__r = {
      records: attendeeRecords,
    };
  }

  // GETS THE CRMCUSTOMFORMRECORDS FIRST SUBMIT PAYLOAD
  const { standAloneCustomRecords, mainRecordChildrenCustomRecords } =
    await getFormattedCRMCustomFormRecordsFirstSubmitPayload(mainRecordSetting, {
      tenant,
      formSettings,
      crmCustomFormRecords,
      isLockedStatus: crmSubmitPayload.submitType === MEETING_SUBMIT_TYPE.SUBMIT_LOCK_TO_CRM,
    });
  logger.veevaTranslator.debug('Standalone Custom Records: ', standAloneCustomRecords);
  logger.veevaTranslator.debug('Children Custom Records: ', mainRecordChildrenCustomRecords);

  // ADDS CHILDREN CUSTOM FORM RECORDS
  Object.entries(mainRecordChildrenCustomRecords).forEach(([key, value]) => {
    mainAccountCallRecord[key] = value;
  });

  let records = [mainAccountCallRecord];

  // ADDS STANDALONE CUSTOM FORMS RECORDS
  if (standAloneCustomRecords?.length) {
    records = [...records, ...standAloneCustomRecords];
  }

  return { records };
}

export async function getStandaloneFormFirstPayload(
  crmSubmitPayload: CRMSubmitStandaloneFormPayload): Promise<SalesforceFirstSubmitPayload> {
  logger.veevaTranslator.debug('Getting payload for first StandaloneForm Veeva submit.', crmSubmitPayload);
  const { isSubmit, tenant, standaloneForm, recordFormORM, formSettings } = crmSubmitPayload;

  if (!standaloneForm.crmFormSetting) {
    logger.veevaTranslator.error('CRM Form Setting not present in the form\'s object', crmSubmitPayload);
    throw Error('CRM Form Setting not present');
  }

  // GETS THE CRMCUSTOMFORMRECORDS FIRST SUBMIT PAYLOAD
  const { standAloneCustomRecords } =
    await getFormattedCRMCustomFormRecordsFirstSubmitPayload(standaloneForm.crmFormSetting, {
      crmCustomFormRecords: [recordFormORM],
      tenant,
      isLockedStatus: isSubmit,
      formSettings,
    });
  logger.veevaTranslator.debug('Standalone Custom Records: ', standAloneCustomRecords);

  return {
    records: standAloneCustomRecords,
  };
}

// THIS FUNCTION RETURNS THE "ON THE GO" GENERATED VEEVA SPECIFIC VALUES FOR MAIN RECORD
async function getVeevaMainRecordFieldValues(
  crmSubmitPayload: CRMSubmitMeetingPayload, mainRecordSetting: FormSettings,
  personAccountsCount: number): Promise<VeevaBasicPayloadFields> {
  const mainAttendee = crmSubmitPayload.formValuesAttendees.find((attendee) =>
    attendee.attendeeType === AttendeeType.PRIMARY);
  const accountId = mainAttendee?.crmAccountId as string | undefined;
  const addressId = mainAttendee?.crmAddressId as string | undefined;
  const isAddressRequired = isFieldRequiredInLayout(mainRecordSetting, 'Address_vod__c');

  if (!mainAttendee) {
    throw new Error('Main CRM Attendee not found on FormValueRecord');
  }

  // GETS THE MAIN ACCOUNT RECORD ON INDEXDB
  const account = await IndexDbCrm.getById<CRMAccount>('ACCOUNT', accountId || '');
  const address = account?.addresses?.find((address) => address.id === addressId);

  if (!account || !accountId) {
    throw new Error('Main CRM Attendee not found on IndexDB');
  } else if (isAddressRequired && (!address || !addressId)) {
    throw new Error('Main Address not found on CRM Attendee record');
  }

  let isPersonAccount = false;
  const accountFields = Object.entries(account).reduce<{[fieldKey: string]: string}>((acc, [fieldKey, value]) => {
    if (typeof value === 'string') {
      acc[fieldKey] = value;
    } else if (typeof value === 'boolean' && fieldKey === 'IsPersonAccount') {
      isPersonAccount = value;
    }
    return acc;
  }, {});

  const Call_Date_vod__c = format(new Date(crmSubmitPayload.startTime), 'Y-MM-dd');
  const Call_Datetime_vod__c = crmSubmitPayload.startTime;
  const veevaDevice = getVeevaDevice();

  return {
    Account_vod__c: accountId,
    Address_vod__c: address ? getFormattedAddressName(address) : null,
    Address_Line_1_vod__c: address?.Name || null,
    Address_Line_2_vod__c: address?.Address_Line_1_vod__c || null,
    Attendee_Type_vod__c: isPersonAccount ? 'Person_Account_vod' : 'Group_Account_vod',
    Attendees_vod__c: personAccountsCount + (isPersonAccount ? 1 : 0),
    Call_Date_vod__c,
    Call_Datetime_vod__c,
    Call_Channel_vod__c: 'Other_vod',
    City_vod__c: address?.City_vod__c || null,
    Credentials_vod__c: accountFields.Credentials_vod__c,
    Duration_vod__c: 30,
    Last_Device_vod__c: veevaDevice,
    Mobile_Created_Datetime_vod__c: veevaDevice === 'Online_vod' ? '' : Call_Datetime_vod__c,
    Parent_Address_vod__c: addressId || null,
    Status_vod__c: crmSubmitPayload.status === 'PLANNED' ? 'Planned_vod' : 'Saved_vod',
    State_vod__c: address?.State_vod__c || null,
    Zip_4_vod__c: address?.Zip_4_vod__c || null,
    Zip_vod__c: address?.Zip_vod__c || null,
  }
}

function getFormattedAddressName(address: CRMAddress): string {
  const fields = ['Address_Line_2_vod__c', 'City_vod__c', 'State_vod__c', 'Zip_vod__c', 'Country_vod__c'];
  return fields.reduce<string>((acc, fieldName) => {
    if (!address[fieldName]) {
      return acc;
    } else if (['Zip_vod__c', 'Country_vod__c'].includes(fieldName)) {
      return `${acc} ${address[fieldName]}`;
    }

    return `${acc}, ${address[fieldName]}`;
  }, address.Name || '');
}

// RETURNS THE BEACON ATTENDEE RECORDS WITH THEIR CRM ACCOUNTS
async function getAttendeesMapped(
  crmSubmitPayload: CRMSubmitMeetingPayload,
  attendees: AttendeeForm[]): Promise<AttendeesAccounts> {
  // FILTER TO ONLY CREATE CHILD CALLS OF THE ADDITIONAL ATTENDEES
  const attendeesCRMIds = attendees.reduce<string[]>((acc, attendee) => {
    const accountId = attendee.crmAccountId;
    if (typeof accountId === 'string') {
      acc.push(accountId);
    }
    return acc;
  }, []);
  const attendeesCRMAccounts = await IndexDbCrm.filterById('ACCOUNT', attendeesCRMIds);
  let primaryCRMAccount: CRMAccount | undefined;
  const additionalAttendees = attendeesCRMAccounts.reduce<AttendeeMap[]>((acc, account) => {
    const attendee = crmSubmitPayload.formValuesAttendees.find(({ crmAccountId }) => crmAccountId === account.id );

    if (typeof attendee?.id === 'string') {
      if (attendee.attendeeType === AttendeeType.SECONDARY) {
        acc.push({
          attendeeForm: attendee,
          account,
        });
      } else {
        primaryCRMAccount = account;
      }
    }
    return acc;
  }, []);

  return { additionalAttendees, primaryCRMAccount };
}

function getSectionLabelCustomField(section: LayoutSection): CustomFieldDefinition {
  // since the heading of a section could have SectionSignals https://crmhelp.veeva.com/doc/Content/CRM_topics/General/SettingUp/Section_Signals.htm
  // we need to remove them from the heading
  const fieldLabel = section.heading.split('--')[0].trim();
  return {
    id: section.id,
    fieldLabel,
    createdAt: new Date().toISOString(),
    fieldName: section.id,
    fieldValueDefinitions: [],
    status: FieldStatus.ENABLED,
    usage: [CustomFieldUsage.MEETING],
    controlType: ControlType.LABEL,
    fieldType: FieldDataType.STRING,
  };
}

// RETURNS AN OBJECT WITH A VALUE(S) FROM THE GIVEN ACCOUNT (IF APPLIES) //
function handleAddCRMAccountFieldToObject(
  tenant: Tenant, mainApiName: string, account?: CRMAccount): {[fieldName: string]: string} {
  const crmAccountToObjectSettings = tenant.config.crmIntegration?.additionalSettings?.crmAccountToObjectSettings;
  const values: { [fieldName: string]: string } = {};

  if (!crmAccountToObjectSettings?.length || !account) {
    return values;
  }

  const indexedSettings = indexArray<CRMAccountToObjectSetting>(crmAccountToObjectSettings, 'apiName');

  if (indexedSettings[mainApiName]) {
    indexedSettings[mainApiName].accountFieldNames.forEach((field) => {
      const valueInAccount = get(account, field.accountFieldName);
      if (!Array.isArray(valueInAccount)) {
        values[field.objectFieldName] = valueInAccount;
      }
    });
  }

  logger.veevaTranslator.debug('New object with AccountFields', { values, account });
  return values;
}

// RECEIVES A LIST OF ATTENDEES AND THE MAIN ACCOUNT CALL RECORD
// TO CREATE THE ATTENDEES' CALL RECORDS BASED ON THE MAIN RECORD
// (SAME OBJECT BUT REPLACING SOME VALUES LIKE ACCOUNT_ID)
function getAttendeesCallRecords(
  attendees: AttendeeMap[],
  mainAccountRecord: SalesforceFirstSubmitRecord,
  mainRecordSetting: FormSettings,
  attendeesPersonsCounts: number,
  crmSubmitPayload: CRMSubmitMeetingPayload,
  parentCallId?: string):
  SalesforceFirstSubmitRecord[] {
  return attendees.map((attendee) => {
    const attendeeBeaconId = typeof attendee.attendeeForm.id === 'string' ? attendee.attendeeForm.id : '';
    // RETURNS AN OBJECT WITH FIELDS FROM THE ACCOUNT THAT NEED TO BE SET TO THE OBJECT (IF REQUIRED)
    const objectWithAccountFieldAdditions = handleAddCRMAccountFieldToObject(
      crmSubmitPayload.tenant, mainRecordSetting.apiName, attendee.account);

    // TRANSFORMS THE ATTENDEE'S CRM VALUES INTO SUBMIT STRUCTURE
    const attendeeSubmitPayload = getFirstSubmitRecordPayload(
      mainRecordSetting.id,
      attendeeBeaconId,
      { ...attendee.attendeeForm.crmValues, ...objectWithAccountFieldAdditions },
      mainRecordSetting,
      crmSubmitPayload.formSettings,
    );

    const attendeeCallRecord: SalesforceFirstSubmitRecord = {
      ...omit(mainAccountRecord, NON_ATTENDEES_FIELDS),
      ...attendeeSubmitPayload,
      Account_vod__c: attendee.account.id,
      Attendee_Type_vod__c: attendee.account.IsPersonAccount ? 'Person_Account_vod' : 'Group_Account_vod',
      Attendees_vod__c: attendeesPersonsCounts,
      Credentials_vod__c: typeof attendee.account.Credentials_vod__c === 'string'
        ? attendee.account.Credentials_vod__c : '',
    };

    if (parentCallId) {
      attendeeCallRecord.Parent_Call_vod__c = parentCallId;
    }

    // ONCE THE ATTENDEE RECORD IS CREATED, IT'S REQUIRED TO ADD TO ITS CHILD RECORDS
    // THE SPECIFIC VEEVA FIELDS FOR THEM. ALSO, ITS CHILD RECORDS MUST HAVE THEIR OWN REFERENCE ID
    return addAdditionalVeevaFieldsToChildObjectsFirstSubmit(attendeeCallRecord, {
      Account_vod__c: attendee.account.id,
      Attendee_Type_vod__c: attendeeCallRecord.Attendee_Type_vod__c,
    }, crmSubmitPayload);
  });
}

function getVeevaDevice(): VeevaBasicPayloadFields['Last_Device_vod__c'] {
  if (isTablet) {
    if (isIOS) {
      return 'iPad_vod';
    }
    return 'Tablet_vod';
  } else if (isMobile) {
    if (isIOS) {
      return 'iPhone_vod';
    }
    return 'Mobile_vod';
  }
  return 'Online_vod';
}

// THIS FUNCTION WILL CHECK CHILD RECORDS AND ADD TO THEM SPECIFIC FIELD/VALUES
// THAT ARE NOT SHOWN ON THE FORM FOR THE USER TO ENTER BUT MIGHT BE REQUIRED
function addAdditionalVeevaFieldsToChildObjectsFirstSubmit(
  firstSubmitRecord: SalesforceFirstSubmitRecord,
  veevaFieldValues: {[fieldKey: string]: any },
  crmSubmitPayload: CRMSubmitMeetingPayload): SalesforceFirstSubmitRecord {
  // ITERATE OVER THE OBJECT'S FIELDS TO CHECK THE OBJECT VALUES
  Object.entries(firstSubmitRecord).forEach(([crmFieldKey, value]) => {
    // IF TRUE, IT'S AN OBJECTS ARRAY FIELD
    if (isSalesforceFirstSubmitPayload(value)) {
      // ADDS SPECIFIC VEEVA FIELDS
      switch (crmFieldKey) {
        case 'Medical_Discussion_vod__r': {
          (<SalesforceFirstSubmitPayload>firstSubmitRecord[crmFieldKey]).records =
            value.records.map((medicalDiscussion) => ({
              ...medicalDiscussion,
              Account_vod__c: veevaFieldValues.Account_vod__c,
              Attendee_Type_vod__c: veevaFieldValues.Attendee_Type_vod__c,
            }))
          break;
        }
        case 'Call2_Discussion_vod__r': {
          (<SalesforceFirstSubmitPayload>firstSubmitRecord[crmFieldKey]).records =
            value.records.map((callDiscussion) => {
              let productId = '';
              let groupId = '';
              if (typeof callDiscussion.Product_vod__c === 'string') {
                [productId, groupId] = callDiscussion.Product_vod__c?.split('.');
              }

              return {
                ...callDiscussion,
                Product_vod__c: productId,
                Detail_Group_vod__c: groupId,
                Account_vod__c: veevaFieldValues.Account_vod__c,
              };
            })
          break;
        }
        case 'Tasks': {
          // PER TASK, GETS/ASSIGNS THE SUBJECT'S LABEL AND FORMAT THE ACTIVITY DATE
          const subjects = getFollowUpSubjects(crmSubmitPayload);
          const records = value.records.map((task) => {
            const subject = subjects?.values.find((subjectValue) =>
              subjectValue.value === task.Followup_Activity_Type_vod__c)
            const activityDate = typeof task.ActivityDate === 'string'
              ? format(new Date(task.ActivityDate), 'Y-MM-dd') : task.ActivityDate;

            return {
              ...task,
              ActivityDate: activityDate,
              Subject: subject?.label || '',
            }
          });

          (<SalesforceFirstSubmitPayload>firstSubmitRecord[crmFieldKey]).records = records;
          break;
        }
      }
    }
  });

  return firstSubmitRecord;
}

// ** ADDS ADDITIONAL FIELDS TO CUSTOM FORM RECORDS (BEFORE PARSE INTO FIRST/EDIT SF STRUCTURE) ** //
export async function addAdditionalFieldsToCustomFormRecord(
  values: FormValuesType, apiName: string, isLockedStatus?: boolean): Promise<FormValuesType> {
  let newValues = { ...values };
  switch (apiName) {
    case TABLES.MEDICAL_INSIGHT: {
      if (isLockedStatus) {
        newValues.Status_vod__c = 'Submitted_vod';
      } else {
        newValues.Status_vod__c = 'Saved_vod';
      }
      break;
    }
    case TABLES.MEDICAL_INQUIRY: {
      newValues = await addConditionalAddressFields(values);
      if (isLockedStatus) {
        newValues.Status_vod__c = 'Submitted_vod';
      } else {
        newValues.Status_vod__c = 'Saved_vod';
      }
      break;
    }
  }

  return newValues;
}

// MEANT TO REPLACE FIELDS OF THE FORM //
function getTreatedFormValues(formValues: FormValuesType): FormValuesType {
  // ATTENDEE_LIST_VOD__C //
  if (isArrayOfObjectsWithIds(formValues[SUPPORTED_ZVODS.ATTENDEES_LIST])) {
    // STRINGIFIES THE ARRAY OF OBJECTS //
    formValues[SUPPORTED_ZVODS.ATTENDEES_LIST] =
      JSON.stringify(formValues[SUPPORTED_ZVODS.ATTENDEES_LIST].map((object) => ({
        [ATTENDEE_LIST_FIELDS.FIRST_NAME]:
        object[`${SUPPORTED_ZVODS.ATTENDEES_LIST}_${ATTENDEE_LIST_FIELDS.FIRST_NAME}`],
        [ATTENDEE_LIST_FIELDS.LAST_NAME]:
        object[`${SUPPORTED_ZVODS.ATTENDEES_LIST}_${ATTENDEE_LIST_FIELDS.LAST_NAME}`],
        [ATTENDEE_LIST_FIELDS.DESIGNATION]:
        object[`${SUPPORTED_ZVODS.ATTENDEES_LIST}_${ATTENDEE_LIST_FIELDS.DESIGNATION}`],
      })));
  }

  return formValues;
}

// GETS THE MAIN RECORD FIRST SUBMIT PAYLOAD
async function getFirstSubmitMainRecordPayload(
  mainRecordSetting: FormSettings,
  crmSubmitPayload: CRMSubmitMeetingPayload,
  personsCount: number, mainCRMAccount?: CRMAccount): Promise<SalesforceFirstSubmitRecord> {
  // RETURNS AN OBJECT WITH FIELDS FROM THE ACCOUNT THAT NEED TO BE SET TO THE OBJECT (IF REQUIRED)
  const objectWithAccountFieldAdditions = handleAddCRMAccountFieldToObject(
    crmSubmitPayload.tenant, mainRecordSetting.apiName, mainCRMAccount);

  // GETS THE FORM'S FIELD VALUES
  const mainRecordPayload = getFirstSubmitRecordPayload(
    mainRecordSetting.id,
    'main-record',
    { ...crmSubmitPayload.mainCrmValues, ...objectWithAccountFieldAdditions },
    mainRecordSetting,
    crmSubmitPayload.formSettings,
    crmSubmitPayload,
  );
  logger.veevaTranslator.debug('Generated main record form fields:', mainRecordPayload);

  // GETS AN OBJECT WITH THE NON-FORM VEEVA FIELD VALUES
  const veevaFieldValues = await getVeevaMainRecordFieldValues(crmSubmitPayload, mainRecordSetting, personsCount);
  logger.veevaTranslator.debug('Generated main record Veeva fields:', veevaFieldValues);

  // ADDS, TO THE CHILD RECORDS, ADDITIONAL SPECIFIC FIELD/VALUES
  const withAdditionalFieldValues =
    addAdditionalVeevaFieldsToChildObjectsFirstSubmit(mainRecordPayload, veevaFieldValues, crmSubmitPayload);

  const mainAccountCallRecord: SalesforceFirstSubmitRecord = {
    ...veevaFieldValues,
    ...withAdditionalFieldValues,
  };
  logger.veevaTranslator.debug('Main account call record payload:', mainAccountCallRecord);

  return mainAccountCallRecord;
}

// ** UPDATE SUBMIT FUNCTIONS ** //
// BASED ON THE CRM RECORDS, PREPARES THEM TO BE UPDATED/CREATED
export async function getVeevaPayloadToUpdateRecords(crmSubmitPayload: CRMSubmitMeetingPayload):
  Promise<SalesforceUpdateSubmit> {
  logger.veevaTranslator.debug('Getting payload for update Veeva submit.', crmSubmitPayload);
  const { formValuesAttendees, formSettings, meetingORM } = crmSubmitPayload;
  const mainRecordSetting = formSettings.find((record) => record.isMainTable);

  if (!mainRecordSetting) {
    logger.veevaTranslator.error('Settings for main table not found.')
    throw Error('Settings for main table not found.');
  }

  let attendeesPersonsCount = 0;
  const attendeesMapped = await getAttendeesMapped(crmSubmitPayload, formValuesAttendees);
  attendeesMapped.additionalAttendees.forEach((attendee) => {
    if (attendee.account.IsPersonAccount) {
      attendeesPersonsCount += 1;
    }
  })

  // REPLACES RAW FORM FIELDS IF REQUIRED //
  crmSubmitPayload.mainCrmValues = getTreatedFormValues(crmSubmitPayload.mainCrmValues);

  // OBJECTS TO BE SKIPPED FOR UPDATE/DELETE
  const nonUpdateableObjectIds = getIdsOfNonUpdateableObjects(crmSubmitPayload.mainCrmValues, meetingORM);

  // SEPARATES NEW/EXISTING ATTENDEES
  const { newAttendees, existingAttendees } =
    formValuesAttendees.reduce<{ newAttendees: AttendeeForm[], existingAttendees: AttendeeForm[] }>((acc, attendee) => {
      if (attendee.attendeeType === AttendeeType.PRIMARY) {
        return acc;
      }

      const isExistingAttendee = meetingORM.model.attendees.some((existingAttendee) =>
        existingAttendee.id === attendee.id && existingAttendee.crmRecord?.crmCallId);
      if (isExistingAttendee) {
        acc.existingAttendees.push(attendee);
      } else {
        acc.newAttendees.push(attendee);
      }

      return acc;
    }, { newAttendees: [], existingAttendees: [] });

  const veevaMainRecordBasicFields =
    await getVeevaMainRecordFieldValues(crmSubmitPayload, mainRecordSetting, attendeesPersonsCount);
  logger.veevaTranslator.debug('Veeva main record', veevaMainRecordBasicFields);

  attendeesPersonsCount = veevaMainRecordBasicFields.Attendees_vod__c;
  const newAttendeesInserts = await getNewAttendeesInserts(
    newAttendees,
    crmSubmitPayload,
    crmSubmitPayload.mainCrmRecordId!,
    attendeesPersonsCount);
  logger.veevaTranslator.debug('New attendees inserts', newAttendeesInserts);

  // RETURNS AN OBJECT WITH FIELDS FROM THE ACCOUNT THAT NEED TO BE SET TO THE OBJECT (IF REQUIRED)
  const objectWithAccountFieldAdditions = handleAddCRMAccountFieldToObject(
    crmSubmitPayload.tenant, mainRecordSetting.apiName, attendeesMapped.primaryCRMAccount);

  // BASED ON THE CRM VALUES (MAIN ACCOUNT),
  // RETURNS OBJECTS TO BE UPSERTED
  const upserts = addVeevaFieldsToUpsertRecords(getUpsertRecords(
    // @ts-ignore (NEED TO INCLUDE THE VEEVA FIELDS WHICH CAN BE BOOLEAN/NUMBERS AS WELL)
    {
      ...veevaMainRecordBasicFields,
      ...crmSubmitPayload.mainCrmValues,
      ...objectWithAccountFieldAdditions,
    },
    crmSubmitPayload.formSettings, crmSubmitPayload.mainCrmRecordId!,
    mainRecordSetting, crmSubmitPayload, nonUpdateableObjectIds), veevaMainRecordBasicFields, crmSubmitPayload);
  logger.veevaTranslator.debug('Main call upserts', upserts);

  // TO EACH ATTENDEE, GETS THEIR UPDATED/NEW OBJECTS
  const attendeesUpserts = await getAttendeesUpserts(
    upserts,
    existingAttendees,
    crmSubmitPayload,
    formSettings,
    mainRecordSetting,
  );
  logger.veevaTranslator.debug('Existing attendees upserts', attendeesUpserts);

  // GETS CUSTOM FORM RECORDS TO BE UPSERTED
  const customFormRecordsUpsert: SalesforceRecordToUpsert[] =
    await getCustomFormRecordsToUpsert(crmSubmitPayload, mainRecordSetting);

  // GETS THE IDS OF THE CUSTOM FORM RECORDS TO BE DELETED
  const customFormRecordsToDelete: RecordToDelete[] =
    getCustomFormRecordsToDelete(crmSubmitPayload.crmCustomFormRecords);
  logger.veevaTranslator.debug('Custom Form Records To Delete: ', customFormRecordsToDelete);

  const recordsToUpsert = [...upserts, ...attendeesUpserts, ...customFormRecordsUpsert];

  // GETS THE IDS OF THE REGULAR MEETING RECORDS TO BE DELETED
  const meetingRecordsToDelete: RecordToDelete[] = getRecordIdsToDelete(
    crmSubmitPayload.meetingORM,
    getValidObjectsIds(recordsToUpsert),
    existingAttendees,
    formSettings,
    nonUpdateableObjectIds,
  );

  const payloads: SalesforceUpdateSubmit = {
    recordsToUpsert,
    recordsToDelete: [...meetingRecordsToDelete, ...customFormRecordsToDelete],
    recordsToInsert: {
      records: newAttendeesInserts.records,
    },
  };

  return payloads;
}

// ADDS VEEVA FIELDS TO RECORDS TO BE UPDATED
function addVeevaFieldsToUpsertRecords(
  records: SalesforceRecordToUpsert[],
  veevaFieldValues: {[fieldKey: string]: any },
  crmSubmitPayload: CRMSubmitMeetingPayload): SalesforceRecordToUpsert[] {
  return records.map((record) => {
    switch (record.apiName) {
      case TABLES.MEDICAL_DISCUSSION: {
        record.fields.Account_vod__c = veevaFieldValues.Account_vod__c;
        record.fields.Attendee_Type_vod__c = veevaFieldValues.Attendee_Type_vod__c;
        break;
      }
      case TABLES.CALL_DISCUSSION: {
        let productId = '';
        let groupId = '';
        if (typeof record.fields.Product_vod__c === 'string') {
          [productId, groupId] = record.fields.Product_vod__c?.split('.');
        }
        record.fields.Detail_Group_vod__c = groupId;
        record.fields.Product_vod__c = productId;
        record.fields.Account_vod__c = veevaFieldValues.Account_vod__c;
        break;
      }
      case TABLES.TASK: {
        // PER TASK, GETS/ASSIGNS THE SUBJECT'S LABEL AND FORMAT THE ACTIVITY DATE
        const subjects = getFollowUpSubjects(crmSubmitPayload);
        const subject =
          subjects?.values.find((subjectValue) => subjectValue.value === record.fields.Followup_Activity_Type_vod__c)
        const ActivityDate = typeof record.fields.ActivityDate === 'string'
          ? format(new Date(record.fields.ActivityDate), 'Y-MM-dd') : record.fields.ActivityDate;

        record.fields.Subject = subject?.label || '';
        record.fields.ActivityDate = ActivityDate;
        break;
      }
    }

    return record;
  });
}

function getFollowUpSubjects(crmSubmitPayload: CRMSubmitMeetingPayload): SFPicklistRecord | undefined {
  const taskFormSetting = crmSubmitPayload.formSettings.find(({ apiName }) =>
    apiName === 'Task');
  return taskFormSetting?.picklists.Followup_Activity_Type_vod__c;
}

// GIVEN THE MAIN RECORD NEW/UPDATED OBJECTS, ITERATE OVER THE EXISTING ATTENDEES TO UPDATE THEIRS
async function getAttendeesUpserts(
  mainUpdates: SalesforceRecordToUpsert[],
  existingAttendees: AttendeeForm[],
  crmSubmitPayload: CRMSubmitMeetingPayload,
  formSettings: FormSettings[],
  mainRecordSetting: FormSettings,
): Promise<SalesforceRecordToUpsert[]> {
  const upsertsPayloads: SalesforceRecordToUpsert[] = [];
  const { meetingORM } = crmSubmitPayload;
  const attendeesMapped = await getAttendeesMapped(crmSubmitPayload, existingAttendees);
  const mainRecordUpdate = mainUpdates.find(({ beaconId }) => beaconId === 'main-record');

  if (!mainRecordUpdate) {
    throw new Error('Main record update payload not found');
  }

  attendeesMapped.additionalAttendees.forEach(({ attendeeForm, account }) => {
    const attendee = meetingORM.model.attendees.find(({ id }) => id === attendeeForm.id);
    // RETURNS AN OBJECT WITH FIELDS FROM THE ACCOUNT THAT NEED TO BE SET TO THE OBJECT (IF REQUIRED)
    const objectWithAccountFieldAdditions = handleAddCRMAccountFieldToObject(
      crmSubmitPayload.tenant, mainRecordSetting.apiName, account);
    // GETS THE ATTENDEE'S MAIN RECORD TO BE UPDATED
    upsertsPayloads.push({
      ...mainRecordUpdate,
      fields: {
        ...omit(mainRecordUpdate.fields, NON_ATTENDEES_FIELDS),
        ...objectWithAccountFieldAdditions,
        Account_vod__c: account.id,
      },
      beaconId: attendeeForm.id,
      salesforceId: attendee?.crmRecord?.crmCallId,
    });

    const upserts = getUpsertRecords(
      attendeeForm.crmValues || {},
      formSettings,
      attendee?.crmRecord?.crmCallId || '',
      mainRecordSetting,
    );

    const upsertsWithVeevaFields = addVeevaFieldsToUpsertRecords(upserts, {
      Account_vod__c: account.id,
      Attendee_Type_vod__c: 'Person_Account_vod',
    }, crmSubmitPayload);

    upsertsPayloads.push(...upsertsWithVeevaFields.filter((update) =>
      update.beaconId !== 'main-record',
    ));
  });

  return upsertsPayloads;
}

// RETURNS THE NEW ATTENDEES INSERTS AND THE MAIN ACCOUNT RECORD
async function getNewAttendeesInserts(
  attendees: AttendeeForm[],
  crmSubmitPayload: CRMSubmitMeetingPayload,
  parentCallId: string,
  attendeesPersonsCount: number): Promise<SalesforceFirstSubmitPayload> {
  const payload: SalesforceFirstSubmitPayload = {
    records: [],
  };

  const mainRecordSetting = crmSubmitPayload.formSettings.find((record) => record.isMainTable);

  if (!mainRecordSetting) {
    throw Error('Settings for main table not found.');
  }

  const [mainAccountCallRecord, attendeesMapped] = await Promise.all([
    getFirstSubmitMainRecordPayload(mainRecordSetting, crmSubmitPayload, attendeesPersonsCount),
    getAttendeesMapped(crmSubmitPayload, attendees),
  ]);

  // ATTENDEES INSERTS
  payload.records = getAttendeesCallRecords(
    attendeesMapped.additionalAttendees,
    cloneDeep(mainAccountCallRecord),
    mainRecordSetting,
    attendeesPersonsCount,
    crmSubmitPayload,
    parentCallId);

  return payload;
}

export async function addConditionalAddressFields(values: FormValuesType) : Promise<FormValuesType> {
  const newValues = { ...values };
  const addressId = newValues.Delivery_Method_Address_Id?.[0];

  if (addressId !== '' && addressId !== null && addressId !== undefined) {
    const accountId = newValues.Account_vod__c?.[0] as string;

    if (!accountId) {
      throw new Error('Account Id not found');
    }

    const account = await IndexDbCrm.getById<CRMAccount>('ACCOUNT', accountId);
    const address = account?.addresses?.find((address) => address.id === addressId);

    if (!address) {
      throw new Error('Address not found');
    }

    newValues.Address_Line_1_vod__c = [address?.Name || ''];
    newValues.City_vod__c = [address?.City_vod__c || ''];
    newValues.State_vod__c = [address?.State__vod_c || ''];
    newValues.Zip_vod__c = [address?.Zip_vod__c || ''];

    delete newValues.Delivery_Method_Address_Id;
    return newValues;
  }

  return newValues;
}
