// ECFLAG - Is this still needed? 
// This context is only used for the require new password flow where we need to store the username
import React, { createContext, PropsWithChildren, useContext, useState } from 'react'

interface InitialValues { }
interface SignInType {
  username: string;
  setSignInUsername: (userName: string) => void;
}
export const SignedIn = createContext<SignInType | null>(null!)

export const SignedInProvider: React.FC<PropsWithChildren<InitialValues>> = ({
  children,
}) => {
  const [username, setSignInUsername] = useState<string>('');

  return (
    <SignedIn.Provider value={{ username, setSignInUsername }}>{children}</SignedIn.Provider>
  )
}

const useSignedIn = () => {
  const context = useContext(SignedIn);
  if (!context) {
    throw new Error('useSignedIn must be used within a SignInProvider');
  }
  return context;
}

export default useSignedIn;