import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'src/state/redux';
import { StyleSheet } from 'react-native';
import { generateClient } from 'aws-amplify/api';
import { createTenantUser, updateUserLambda } from '@alucio/aws-beacon-amplify/src/graphql/mutations';
import {
  DNABox,
  DNAButton,
  DNACheckbox,
  DNASelect,
  DNAText,
  GenericToast,
  Iffy,
  luxColors,
  ToastOrientations,
  useToast,
} from '@alucio/lux-ui';
import {
  CustomFieldDefinition,
  CustomFieldUsage,
  FieldDataType,
  User,
  UserRole,
} from '@alucio/aws-beacon-amplify/src/models';
import {
  USER_ROLE,
  CustomValuesInput
} from '@alucio/aws-beacon-amplify/src/API';
import colors from '@alucio/lux-ui/lib/theming/themes/alucio/colors';
import InputComponent from '../Publishers/InputComponent';
import UserFilter, { CONFIG_FIELDS, LOCKED_FILTERS } from './UserFilter';

import { useFormik } from 'formik';
import DNACloseUserDrawerConfirmation from '../DNA/Modal/DNACloseUserDrawerConfirmation';
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal';
import { useTenant, useTenantCustomFields } from 'src/state/redux/selector/tenant';
import { Spinner } from '@ui-kitten/components';
import { useCurrentUserORM, useUserById } from 'src/state/redux/selector/user';
import { validEmail } from 'src/utils/emailValidation';
import uniq from 'lodash/uniq'

const styles = StyleSheet.create({
  commonPadding: {
    paddingVertical: 16,
    paddingHorizontal: 32,
  },
  content: {
    backgroundColor: colors['color-gray-10'],
    flex: 1,
    paddingHorizontal: 32,
    paddingVertical: 34,
    overflow: 'scroll',
  },
  footer: {
    borderTopColor: colors['color-gray-100'],
    borderTopWidth: 1,
  },
  header: {
    borderBottomColor: colors['color-gray-100'],
    borderBottomWidth: 1,
  },
  inputColor: {
    backgroundColor: luxColors.info.primary,
  },
  inputStyle: {
    backgroundColor: luxColors.info.primary,
    borderColor: colors['color-gray-100'],
  },
  inputStyleError: {
    backgroundColor: luxColors.info.primary,
    borderColor: colors['color-danger-500'],
  },
  roleInputError: {
    backgroundColor: luxColors.info.primary,
    borderColor: colors['color-danger-500'],
    borderRadius: 5,
    borderWidth: 1,
  },
  titleColor: {
    color: luxColors.contentText.quaternary,
  },
});

const MAPPED_ROLES = {
  [UserRole.TENANT_VIEWER]: 'Viewer',
  [UserRole.TENANT_PUBLISHER]: 'Publisher',
};

const EXISTING_EMAIL_ERROR = 'This email already exists.';
const INVALID_PHONE_NUMBER_ERROR = 'Invalid phone number format.';
const EMAIL_ERROR_MESSAGES = {
  ALTERNATIVE_EMAIL: { message: 'This username is already in use as a primary email.', field: 'altUsername' },
  ALTERNATIVE_EMAIL_SAME_AS_EMAIL: { message: 'The email and altUsername cannot be the same.', field: 'altUsername' },
  USERNAME_EXISTS: { message: 'This username is already in use by another user.', field: 'altUsername' },
  EMAIL_IN_USE: { message: 'This email is already in use.', field: 'email' },
  EMAIL_IN_DATABASE: { message: 'This email is already in use in our database.', field: 'email' },
  EMAIL_AS_ALT_USERNAME: { message: 'This email is already in use as an alternative email.', field: 'email' },
} as const;

interface Props {
  tenantId?: string,
  userId?: string,
  toggleDrawer: () => void,
}

interface FooterProps {
  isCreate: boolean,
  closeDrawer: () => void,
  isSubmitting: boolean,
  formikStatus: string,
  sendInvite: boolean,
  setSendInvite: (sendInvite: boolean) => void,
  onSave: () => void,
  userId?: string,
}

interface HeaderProps {
  closeDrawer: () => void,
  userEmail?: string,
}

type RoleValidation = Omit<{ [key in UserRole]: any }, 'TenantAdmin'>;

const ROLE_VALIDATION: RoleValidation = {
  [UserRole.TENANT_VIEWER]: (formik) => {
    const additionalRoles = formik.values.additionalRoles;
    formik.setFieldValue(
      'additionalRoles',
      additionalRoles.filter((role) => role !== USER_ROLE.TenantAdmin),
    );
  },
  [UserRole.TENANT_PUBLISHER]: (formik) => {
    const additionalRoles = formik.values.additionalRoles;
    formik.setFieldValue(
      'additionalRoles',
      additionalRoles.filter((role) => role !== USER_ROLE.TenantViewer),
    );
  },
  [UserRole.ALUCIO_ADMIN]: () => { },
  [UserRole.TEAM_REPORT_VIEWER]: () => { },
}

function initFormikValues(customFields: CustomFieldDefinition[], user?: User) {
  const filterValues = {};

  // WE FORMAT THE FIELDVALUES TO BE HANLDED INDIVIDUALLY, USING THE FOLLOWING STRUCTURE:
  // { [key as string]: { defaultFilterValues: string[], lockedFilters: string[] } }
  customFields.forEach((field) => {
    filterValues[field.id] = {
      defaultFiltersCustomValues: [],
      lockedFiltersCustomValues: [],
    };

    // IF WE'RE EDITING, WE GET THE VALUES THE USER HAS
    filterValues[field.id].defaultFiltersCustomValues = user?.defaultFiltersCustomValues
      .find(({ fieldId }) => fieldId === field.id)?.values || [];
    filterValues[field.id].lockedFiltersCustomValues = user?.lockedFiltersCustomValues
      .find(({ fieldId }) => fieldId === field.id)?.values || [];
  });

  return {
    email: user?.email || '',
    altUsername: user?.altUsername || '',
    familyName: user?.familyName,
    givenName: user?.givenName,
    phoneNumber: user?.phoneNumber,
    role: user?.role || '',
    shareBCC: user?.shareBCC?.join(', ') || '',
    shareCC: user?.shareCC?.join(', ') || '',
    configValues: {
      ...filterValues,
    },
    isExcludedFromReporting: !!user?.isExcludedFromReporting,
    additionalRoles: (Array.isArray(user?.additionalRoles)
      ? user?.additionalRoles : user?.additionalRoles ? [user.additionalRoles] : []) as unknown as USER_ROLE[], // note: fix this typing once the USER_ROLE type is consolidated
  }
}

const DNAUserEdit = (props: Props) => {
  const userORM = useUserById(props.userId || '');
  const toast = useToast();
  const user = userORM?.model;
  const editorUser = useCurrentUserORM();
  const { toggleDrawer } = props;
  const tenant = useTenant(user?.tenantId || props.tenantId!);
  const userFilterFields = useTenantCustomFields({
    tenantId: tenant?.tenant.id,
    usages: { internalUsages: [CustomFieldUsage.USER_FILTER] },
    fieldDataType: [FieldDataType.CATEGORICAL, FieldDataType.MULTICATEGORICAL],
  });
  const dispatch = useDispatch();
  const [sendInvite, setSendInvite] = useState<boolean>(true);
  const [errors, setErrors] = useState<{ [key: string]: string | undefined }>({});
  const [existingEmails, setExistingEmails] = useState(new Set());
  const formik = useFormik({
    initialValues: initFormikValues(userFilterFields, user),
    enableReinitialize: false,
    onSubmit: async (values) => {
      const { defaultFiltersCustomValues, lockedFiltersCustomValues } =
        getFormattedFilterValuesFromFormik(values.configValues);
      const ccEmails = values.shareCC.trim() ? values.shareCC.split(',').map((email) => email.trim()) : [];
      const bccEmails = values.shareBCC.trim() ? values.shareBCC.split(',').map((email) => email.trim()) : [];
      const aditionalRoles = uniq(values.additionalRoles?.filter((role) => role !== null) || []);

      // For removing the tenant admin in additional roles if checkbox is not visible
      const isTenantPublisher = values.role === USER_ROLE.TenantPublisher;
      const processedAdditionalRoles = isTenantPublisher
        ? aditionalRoles
        : aditionalRoles.filter((role) => role !== USER_ROLE.TenantAdmin);

      const upsertPayload = {
        id: user?.id || '',
        email: values.email || '',
        givenName: values.givenName || '',
        familyName: values.familyName || '',
        role: USER_ROLE[values.role],
        tenantId: user?.tenantId || tenant?.tenant.id!,
        phoneNumber: values.phoneNumber,
        shareBCC: bccEmails,
        defaultFiltersCustomValues,
        lockedFiltersCustomValues: isTenantPublisher ? [] : lockedFiltersCustomValues,
        shareCC: ccEmails,
        defaultFilterValues: user?.defaultFilterValues || [],
        lockedFilters: user?.lockedFilters || [],
        isExcludedFromReporting: values.isExcludedFromReporting,
        additionalRoles: processedAdditionalRoles,
        altUsername: values.altUsername,
      };

      try {
        if (user) {
          const appsyncClient = generateClient();
          await appsyncClient.graphql({
            query: updateUserLambda,
            variables: { user: upsertPayload },
          });
          // Tracking for editing a user
          analytics.track('ADMIN_UPDATE_USER', {
            category: 'ADMIN',
            action: 'EDIT',
          });          
        } else {
          const { id, ...restUpsertPayload } = upsertPayload
          const appsyncClient = generateClient();
          await appsyncClient.graphql({
            query: createTenantUser,
            variables: { user: restUpsertPayload, sendInvite },
          });
          // Tracking for creating a user
          analytics.track('ADMIN_CREATE_USER', {
            category: 'ADMIN',
            action: 'CREATE',
          });
          toast?.add(
            <GenericToast title={`New user created. ${sendInvite ? 'Email sent.' : ''}`} status="success" />,
            ToastOrientations.TOP_RIGHT,
          );
          toggleDrawer();
        }
      } catch (e: any) {
        const errorMessage = e.errors?.[0]?.message;

        if (!errorMessage) {
          toast?.add(
            <GenericToast title="Something went wrong" status="error" />,
            ToastOrientations.TOP_RIGHT,
          );
          return;
        }

        // Check for exact matches against known error messages
        const knownError = Object.values(EMAIL_ERROR_MESSAGES)
          .find(msg => errorMessage === msg.message);

        if (knownError) {
          setErrors({ [knownError.field]: knownError.message });
        } else if (errorMessage === INVALID_PHONE_NUMBER_ERROR) {
          setErrors({ phoneNumber: INVALID_PHONE_NUMBER_ERROR });
        } else if (errorMessage === e) {
          setExistingEmails(existing => existing.add(values.email));
          setErrors({ email: EXISTING_EMAIL_ERROR });
        } else {
          toast?.add(
            <GenericToast title="Something went wrong" status="error" />,
            ToastOrientations.TOP_RIGHT,
          );
        }

        formik.setStatus('error');
        formik.setSubmitting(false);
        return;
      }

      formik.setStatus('success');
      formik.setSubmitting(false);
      setTimeout(() => {
        formik.resetForm({ values });
      }, 2000);
    },
  });
  const formikValuesRef = useRef(formik.values);
  const errorsRef = useRef(errors);

  const isEditingSelf = editorUser?.model.id === user?.id;

  // MEMOIZED COMPONENTS/FUNCTIONS
  const HeaderMemoized = useMemo(() => <Header closeDrawer={onCancel} userEmail={user?.email} />, [formik.dirty])
  const FooterMemoized = useMemo(() =>
    (<Footer
      setSendInvite={setSendInvite}
      sendInvite={sendInvite}
      closeDrawer={onCancel}
      onSave={areFieldsValid}
      isCreate={!user}
      userId={user?.id}
      isSubmitting={formik.isSubmitting}
      formikStatus={formik.status}
    />), [formik.dirty, formik.isSubmitting, formik.status, sendInvite]);
  const onChangeFilterOption = useCallback(
    (
      fieldId,
      fieldValueId,
      column,
    ) => onFilterOptionSelected(fieldId, fieldValueId, column),
    [],
  );
  const onAllValuesSelectedMemoized = useCallback(
    (
      fieldId,
      column,
      values,
      checked) => onAllValuesSelected(fieldId, column, values, checked),
    [],
  );
  const onUpdateFamilyName = useCallback((value) => onUpdateField('familyName', value), []);
  const onUpdateGivenName = useCallback((value) => onUpdateField('givenName', value), []);
  // const onUpdatePhoneNumber = useCallback((value) => onChangePhoneNumber(value), []);
  const onUpdateEmail = useCallback((value) => onChangeEmail(value), []);
  const onUpdateAltUsername = useCallback((value) => onUpdateField('altUsername', value), []);
  const onUpdateExcludedFromReporting = useCallback(
    (value) => formik.setFieldValue('isExcludedFromReporting', value),
    [],
  );
  const onUpdateRoleCheckbox = useCallback((checked, role: USER_ROLE.TenantAdmin | USER_ROLE.TeamReportViewer) => {
    const roles = (formik.values.additionalRoles || [])
      .filter((r) => r !== role);
    if (checked) {
      formik.setFieldValue('additionalRoles', [...roles, role])
    }
    else {
      formik.setFieldValue('additionalRoles', roles.filter((r) => r !== role));
    }
  }, [formik])

  // SINCE WE MEMOIZE THE CONFIG VALUES COMPONENTS TO AVOID THEM TO RE RENDER,
  // WE STILL NEED THEM TO BE AWARE OF FORMIK CURRENT STATE, HENCE WE USE A REF OF IT
  useEffect(() => {
    formikValuesRef.current = { ...formik.values };
  }, [formik.values]);

  useEffect(() => {
    errorsRef.current = errors;
  }, [errors]);

  // SELECTS OR DESELECTS EVERY VALUE OF A SPECIFIC FIELD
  function onAllValuesSelected(fieldName: string, column: CONFIG_FIELDS, values: string[], checked: boolean): void {
    const current = { ...formikValuesRef.current.configValues[fieldName] };
    let newSelectedValues: string[] = [];

    if (column === LOCKED_FILTERS) {
      newSelectedValues = checked ? values : [];
    } else {
      if (!checked) {
        newSelectedValues = [];
      } else {
        // IF THE CHECKED IS TRUE, WE CAN ONLY SELECT (FOR THE DEFAULTS) THE ONES ALLOWED BY THE LOCKED ONES
        newSelectedValues = current.lockedFiltersCustomValues.length
          ? [...current.lockedFiltersCustomValues] : [...values];
      }
    }

    formik.setFieldValue(
      'configValues',
      {
        ...formikValuesRef.current.configValues,
        [fieldName]: {
          ...formikValuesRef.current.configValues[fieldName],
          [column]: newSelectedValues,
        },
      });
  }

  function onFilterOptionSelected(fieldId: string, fieldValueId: string, column: CONFIG_FIELDS): void {
    const current = { ...formikValuesRef.current.configValues[fieldId] };

    // IF THE VALUE IS ALREADY IN THE ARRAY, WE REMOVE IT. OTHERWISE WE ADD IT
    if (current[column].includes(fieldValueId)) {
      // const index = current[column].indexOf(fieldValueName);
      current[column] = current[column].filter((name) => name !== fieldValueId);
    } else {
      current[column] = [...current[column], fieldValueId];
    }

    // IF THE SELECTED ELEMENT IS IN THE LOCKED FILTERS COLUMN, AND WE'RE UNCHECKING,
    // WE NEED TO REMOVE IT FROM THE DEFAULT FILTER VALUES
    if (column === LOCKED_FILTERS && current.lockedFiltersCustomValues.length) {
      current.defaultFiltersCustomValues =
        current.defaultFiltersCustomValues.filter((name) => current.lockedFiltersCustomValues.includes(name));
    }

    formik.setFieldValue('configValues', { ...formikValuesRef.current.configValues, [fieldId]: current });
  }

  function onCancel(): void {
    // ONLY SHOW THE CONFIRMATION MODAL IF THERE IS AN UNSAVED MODIFICATION
    if (formik.dirty) {
      const payload = {
        isVisible: true,
        allowBackdropCancel: false,
        component: () => <DNACloseUserDrawerConfirmation closeUserDrawer={toggleDrawer} />,
      };

      dispatch(DNAModalActions.setModal(payload));
    } else {
      toggleDrawer();
    }
  }

  // WE NEED TO PREFIX A + SO COGNITO WON'T COMPLAIN
  // function onChangePhoneNumber(phoneNumber: string): void {
  //   const isValid = !phoneNumber || (phoneNumber.length > 4 && phoneNumber.length < 20);

  //   if (isValid) {
  //     setErrors((errors) => {
  //       delete errors.phoneNumber;
  //       return errors;
  //     });
  //   }

  //   formik.setFieldValue('phoneNumber', phoneNumber ? `+${phoneNumber}` : '');
  // }

  // HERE WE CHECK THE ERRORS PRIOR TO SUBMIT
  function areFieldsValid(): void {
    const values = formikValuesRef.current;
    const formikErrors: { [key: string]: string } = {};

    // CHECK IF THE PHONE NUMBER FORMAT IS VALID
    const isValidPhone = !values.phoneNumber || (values.phoneNumber.length > 4 && values.phoneNumber.length < 20);
    if (!isValidPhone) {
      formikErrors.phoneNumber = INVALID_PHONE_NUMBER_ERROR;
    }
    // CHECK IF THE EMAIL IS VALID
    const isValidEmail = validEmail.test(values.email || '')

    if (!isValidEmail) {
      formikErrors.email = 'Invalid email format.';
    } else if (existingEmails.has(values.email || '')) {
      formikErrors.email = 'This email already exists.';
    }

    // CHECK IF EMAIL AND ALT USERNAME ARE THE SAME
    if (values.email && values.altUsername && values.email.toLowerCase() === values.altUsername.toLowerCase()) {
      formikErrors.altUsername = EMAIL_ERROR_MESSAGES.ALTERNATIVE_EMAIL_SAME_AS_EMAIL.message;
    }

    // CHECK IF THERE'S AN EMPTY REQUIRED VALUE
    ['email', 'givenName', 'familyName', 'role'].forEach((field) => {
      if (!values[field]) {
        formikErrors[field] = 'This field is required.';
      }
    });

    if (Object.keys(formikErrors).length) {
      setErrors(formikErrors);
    } else {
      setErrors({});
      formik.submitForm();
    }
  }

  // ACCORDING TO THE REQUIREMENTS, UPON CHANGING THE EMAIL, WE ONLY WANT TO CHECK IF IT HAS NO ERRORS (CLEAN THE ERROR MSG, NOT ADDING)
  function onChangeEmail(email: string): void {
    const trimmed = email.trim();
    let isValidEmail = true;

    if (isValidEmail && trimmed !== '') {
      // eslint-disable-next-line max-len,no-useless-escape
      isValidEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g.test(trimmed);
    }

    if (isValidEmail) {
      setErrors((errors) => {
        delete errors.email;
        return errors;
      });
    } else if (errorsRef.current.email) {
      setErrors({ ...errorsRef.current, email: 'Invalid email format.' });
    }

    formik.setFieldValue('email', trimmed?.toLocaleLowerCase());
  }

  function onUpdateField(field: string, value: string): void {
    if (value) {
      const updatedErrors = errorsRef.current;
      delete updatedErrors[field];
      setErrors(updatedErrors);
    }

    formik.setFieldValue(field, value);
  }

  return (
    <DNABox appearance="col" style={{ flex: 1 }}>
      {/* HEADER */}
      {HeaderMemoized}
      {/* CONTENT */}
      <DNABox style={styles.content} appearance="col" spacing="xl">
        <DNABox appearance="col">
          <DNAText style={[{ marginBottom: 26 }, styles.titleColor]}>USER INFORMATION</DNAText>
          <DNABox spacing="md" childStyle={{ flex: 1 }}>
            <DNABox appearance="col" spacing="md">
              <DNABox appearance="col" spacing="sm">
                <InputComponent
                  hidePlaceholder
                  inputStyle={errors.givenName ? styles.inputStyleError : styles.inputStyle}
                  titleColor={luxColors.contentText.tertiary}
                  removeMarginPadding
                  value={formik.values.givenName}
                  onChangeText={onUpdateGivenName}
                  title="FIRST NAME"
                  required={true}
                  multiline={true}
                  numOfLines={1}
                  disabled={formik.isSubmitting}
                />
                <Iffy is={errors.givenName}>
                  <DNAText c2 status="danger">{errors.givenName}</DNAText>
                </Iffy>
              </DNABox>
              <DNABox appearance="col" spacing="sm">
                <InputComponent
                  hidePlaceholder
                  inputStyle={errors.familyName ? styles.inputStyleError : styles.inputStyle}
                  titleColor={luxColors.contentText.tertiary}
                  removeMarginPadding
                  value={formik.values.familyName}
                  onChangeText={onUpdateFamilyName}
                  title="LAST NAME"
                  required={true}
                  multiline={true}
                  numOfLines={1}
                  disabled={formik.isSubmitting}
                />
                <Iffy is={errors.familyName}>
                  <DNAText c2 status="danger">{errors.familyName}</DNAText>
                </Iffy>
              </DNABox>
              <DNABox appearance="col" spacing="sm">
                <DNABox appearance="col" spacing="xs">
                  <DNABox spacing="sm">
                    <DNAText status="danger">*</DNAText>
                    <DNAText bold style={{ color: luxColors.contentText.tertiary }}>ROLE</DNAText>
                  </DNABox>
                  <DNASelect
                    onSelect={(value) => {
                      if (Array.isArray(value)) return;
                      formik.setFieldValue('role', Object.keys(MAPPED_ROLES)[value.row])

                      const selectedOption = Object.keys(MAPPED_ROLES)[value.row];
                      ROLE_VALIDATION[selectedOption]?.(formik)
                    }}
                    size="lg"
                    style={errors.role && !formik.values?.role ? styles.roleInputError : undefined}
                    status="flat"
                    disabled={formik.isSubmitting || isEditingSelf}
                    value={MAPPED_ROLES[formik.values?.role || '']}
                  >
                    {
                      Object.values(MAPPED_ROLES).map((value) => (
                        <DNASelect.Item
                          key={value}
                          title={value}
                        />
                      ))
                    }
                  </DNASelect>
                  <Iffy is={formik.values?.role === UserRole.TENANT_PUBLISHER}>
                    <DNACheckbox
                      style={{ margin: 11 }}
                      checked={formik.values.additionalRoles.includes(USER_ROLE.TenantAdmin)}
                      onChange={(e) => onUpdateRoleCheckbox(e, USER_ROLE.TenantAdmin)}
                      children={() => {
                        return (
                          <DNAText style={{ marginLeft: 11 }}>Grant administrative privileges</DNAText>
                        )
                      }}
                      disabled={isEditingSelf}
                    />
                  </Iffy>
                  <Iffy
                    is={
                      [
                        UserRole.TENANT_VIEWER,
                        UserRole.TENANT_PUBLISHER,
                      ].includes(formik.values?.role as UserRole)
                    }
                  >
                    <DNACheckbox
                      style={{ margin: 11 }}
                      checked={formik.values.additionalRoles.includes(USER_ROLE.TeamReportViewer)}
                      onChange={(e) => onUpdateRoleCheckbox(e, USER_ROLE.TeamReportViewer)}
                      children={() => {
                        return (
                          <DNAText style={{ marginLeft: 11 }}>Grant permissions to view Publisher reports</DNAText>
                        )
                      }}
                      disabled={isEditingSelf}
                    />
                  </Iffy>
                </DNABox>
                <Iffy is={errors.role && !formik.values?.role}>
                  <DNAText c2 status="danger">{errors.role || ''}</DNAText>
                </Iffy>
              </DNABox>
              <DNABox appearance="col" spacing="sm">
                <InputComponent
                  titleColor={luxColors.contentText.tertiary}
                  removeMarginPadding
                  inputStyle={errors.email ? styles.inputStyleError : styles.inputStyle}
                  hidePlaceholder
                  value={formik.values.email}
                  title="EMAIL"
                  required={true}
                  multiline={true}
                  numOfLines={1}
                  onChangeText={onUpdateEmail}
                  disabled={formik.isSubmitting}
                />
                <Iffy is={errors.email}>
                  <DNAText c2 status="danger">{errors.email || ''}</DNAText>
                </Iffy>
              </DNABox>
              {/* Add alternate username field */}
              <DNABox appearance="col" spacing="sm">
                <InputComponent
                  titleColor={luxColors.contentText.tertiary}
                  removeMarginPadding
                  inputStyle={errors.altUsername ? styles.inputStyleError : styles.inputStyle}
                  hidePlaceholder
                  value={formik.values.altUsername}
                  title="ALTERNATE USERNAME"
                  required={false}
                  multiline={true}
                  numOfLines={1}
                  onChangeText={onUpdateAltUsername}
                  disabled={formik.isSubmitting}
                />
                <Iffy is={errors.altUsername}>
                  <DNAText c2 status="danger">{errors.altUsername || ''}</DNAText>
                </Iffy>
              </DNABox>
              {/* https://alucioinc.atlassian.net/browse/BEAC-4590 */}
              {/* Hiding phone number field until we need it in the future */}
              {/* <DNABox appearance="col">
                <InputComponent
                  titleColor={luxColors.contentText.tertiary}
                  hidePlaceholder
                  inputStyle={errors.phoneNumber ? styles.inputStyleError : styles.inputStyle}
                  removeMarginPadding
                  isNumeric={true}
                  value={formik.values.phoneNumber}
                  onChangeText={onUpdatePhoneNumber}
                  title="PHONE"
                  multiline={true}
                  required={false}
                  numOfLines={1}
                  disabled={formik.isSubmitting}
                />
                <Iffy is={errors.phoneNumber}>
                  <DNAText c2 status="danger">{errors.phoneNumber || ''}</DNAText>
                </Iffy>
              </DNABox> */}
            </DNABox>
          </DNABox>
        </DNABox>
        { /* CUSTOM FIELDS */}
        <DNABox appearance="col">
          <DNAText style={[{ marginBottom: 26 }, styles.titleColor]}>USER FILTERS</DNAText>
          <DNABox appearance="col">
            {
              userFilterFields.map((field) => (
                <UserFilter
                  key={field.id}
                  fieldDefinition={field}
                  disabled={formik.isSubmitting}
                  values={formik.values.configValues[field.id]}
                  onValueSelected={onChangeFilterOption}
                  onAllValuesSelected={onAllValuesSelectedMemoized}
                  disableLockedFilters={formik.values.role === 'TenantPublisher'}
                />
              ),
              )}
          </DNABox>
        </DNABox>
        <DNABox>
          <DNACheckbox
            checked={formik.values.isExcludedFromReporting}
            onChange={onUpdateExcludedFromReporting}
          />
          <DNAText style={{ marginLeft: 7 }}>Exclude from reporting</DNAText>
        </DNABox>
      </DNABox>
      {/* FOOTER */}
      {FooterMemoized}
    </DNABox>
  );
};

function Footer(props: FooterProps): ReactElement {
  return (
    <DNABox style={[styles.commonPadding, styles.footer]} spacing="between" alignX="end" alignY="center">
      <DNABox spacing="sm">
        <Iffy is={props.isCreate}>
          <DNABox spacing="sm">
            <DNACheckbox
              disabled={props.isSubmitting}
              onChange={() => props.setSendInvite(!props.sendInvite)}
              checked={props.sendInvite}
            />
            <DNAText>Send email invite</DNAText>
          </DNABox>
        </Iffy>

        {props.userId && <DNAText status="subtle">User ID: {props.userId}</DNAText>}

      </DNABox>
      <DNABox spacing="sm">
        <DNAButton
          disabled={props.isSubmitting}
          appearance="outline"
          onPress={props.closeDrawer}
          size="sm"
        >
          Cancel
        </DNAButton>
        <DNAButton
          size="sm"
          status={props.formikStatus === 'success' ? 'success' : undefined}
          iconLeft={props.formikStatus === 'success' ? 'check' : undefined}
          onPress={props.onSave}
        >
          {props.isSubmitting
            ? <Spinner size="tiny" status="info" />
            : props.formikStatus === 'success'
              ? 'Saved'
              : props.isCreate ? 'Create' : 'Save'}
        </DNAButton>
      </DNABox>
    </DNABox>
  );
}

function Header(props: HeaderProps): ReactElement {
  return (
    <DNABox style={[styles.commonPadding, styles.header]} spacing="between" alignY="center">
      <DNAText bold>{props.userEmail ? `Edit ${props.userEmail}` : 'Add user'}</DNAText>
      <DNABox alignY="center" spacing="sm">
        <DNAText status="danger">* Required Fields</DNAText>
        <DNAButton
          status="tertiary"
          appearance="ghost"
          iconLeft="close"
          onPress={props.closeDrawer}
        />
      </DNABox>
    </DNABox>
  );
}

// FORMAT THE FILTER VAlUES STRUCTURE USED FOR FORMIK INTO USER'S LABELS/VALUES SO THEY CAN BE SAVED
function getFormattedFilterValuesFromFormik(configValues) {
  return Object.keys(configValues).reduce((acc, fieldId) => {
    if (configValues[fieldId].lockedFiltersCustomValues.length) {
      acc.lockedFiltersCustomValues.push({
        fieldId,
        values: [...configValues[fieldId].lockedFiltersCustomValues],
      });
    }

    if (configValues[fieldId].defaultFiltersCustomValues.length) {
      acc.defaultFiltersCustomValues.push({
        fieldId,
        values: [...configValues[fieldId].defaultFiltersCustomValues],
      });
    }

    return acc;
  }, { lockedFiltersCustomValues: [] as CustomValuesInput[], defaultFiltersCustomValues: [] as CustomValuesInput[] });
}

DNAUserEdit.displayName = 'UserEdit';

export default DNAUserEdit;
