import { ToastActions } from '@alucio/lux-ui/lib/components/Toast/useToast'
import { generateClient } from 'aws-amplify/api';

import {
  AttachedFile,
  DocumentStatus,
  Document,
  ShareType,
  Tenant,
  CustomValues,
  User,
  EmailTemplate,
  CustomDeckDependencies,
  ShareNotificationScope,
} from '@alucio/aws-beacon-amplify/src/models'
import {
  AssociatedFileORM,
  DocumentVersionORM,
  EmailTemplateORM,
  FolderItemORM,
  HubORM,
  getAssociatedFileTitle,
} from 'src/types/orms'
import { DOCUMENT_ACTIONS_ENUM, isCustomDeckORM, isFolderItemORM } from 'src/types/types'
import {
  shareEmailTemplateGenerateLinks,
  generateAndCopyLinkToClipboard,
  copyExistingLinkToClipboard,
  ContentShareProps,
  ShareFileOption,
  ExistingShareLink,
  shareEmailExistingLinks,
  shareEmailGenerateLink,
  shareEmailTemplateCopyExistingLinks,
  copyExistingEmailTemplateToClipboard,
  generateLinksAndcopyEmailTemplateToClipboard,
} from 'src/utils/shareLink/shareLink.web'
import { ShareableLinkResult } from 'src/utils/shareLink/common'
import {
  allDocumentsSortedAndFilteredFactory,
  canPerformAction,
  documentVersionORMById,
} from 'src/state/redux/selector/document';
import { getContentShare as getContentShareByID } from '@alucio/aws-beacon-amplify/src/graphql/queries';
import { contentShareActions } from 'src/state/redux/slice/contentShare';
import { store } from 'src/state/redux';
import { tenant as getTenant } from 'src/state/redux/selector/tenant';
import { hub as getHubById } from 'src/state/redux/selector/hub';
import { currentUserORM } from 'src/state/redux/selector/user';
import { folderItemORMByIdNoFolder } from 'src/state/redux/selector/folder';

export const getPublishedAndDistributableAssociatedFiles = (associatedFiles: AssociatedFileORM[]) => {
  return associatedFiles.filter(({ file, meta: { canBeSharedByMSL } }) => {
    if (file instanceof AttachedFile) {
      return canBeSharedByMSL
    } else if (file instanceof Document) {
      /** Otherwise, check if document can be shared and is published */
      return (file as Document).status === DocumentStatus.PUBLISHED && canBeSharedByMSL
    } else {
      return false
    }
  })
}

export type ShareFileOptionMapping = {
  [documentVersionId: string]: ShareFileOption[]
}

const getAllShareFileOption = (shareFileOptionMapping: ShareFileOptionMapping) => {
  return Object.values(shareFileOptionMapping).flat(1).filter(sfo => sfo.beingShared)
}

const removeDuplicateShareFileOption = (allShareFileOption: ShareFileOption[]): ShareFileOption[] => {
  const uniqueShareFileOption: ShareFileOption[] = []
  const uniqueSet = new Set()
  allShareFileOption.forEach(shareFileOption => {
    if (!uniqueSet.has(shareFileOption.contentProps.contentId)) {
      uniqueSet.add(shareFileOption.contentProps.contentId)
      uniqueShareFileOption.push(shareFileOption)
    } else if (shareFileOption.isMainDoc) {
      // Swap the non-main doc to the main doc
      const itemToRemove = uniqueShareFileOption
        .find(sfo => sfo.contentProps.contentId === shareFileOption.contentProps.contentId)
      if (itemToRemove) {
        const removeIndex = uniqueShareFileOption.indexOf(itemToRemove)
        uniqueShareFileOption.splice(removeIndex, 1)
        uniqueShareFileOption.push(shareFileOption)
      }
    }
  })
  return uniqueShareFileOption
}

/* Hubs share */
export const shareHubsLink = async (
  hubORM: HubORM,
  user: User,
  customValues: CustomValues[],
  toast: ToastActions,
  notificationScope: ShareNotificationScope,
  existingLinks?: ExistingShareLink[],
) => {
  if (!existingLinks) {
    analytics?.track('HUB_SHARE', {
      action: 'SHARE',
      category: 'HUB',
      hubId: hubORM.model.id,
    });
  } else {
    analytics?.track('HUB_SHARE_RESHARE', {
      action: 'HUB_SHARE_RESHARE',
      category: 'HUB',
      hubId: hubORM.model.id,
    });
  }

  let linkResults
  if (existingLinks?.length) {
    linkResults = shareEmailExistingLinks(existingLinks, user, toast)
  } else {
    const hubShareProps = {
      contentId: hubORM.model.id,
      title: hubORM.model.name,
      type: ShareType.HUB,
    }
    const shareOptions = {
      isMainDoc: false,
      isDefault: false,
      beingShared: false,
      isDistributable: false,
      contentProps: hubShareProps,
    }
    linkResults = shareEmailGenerateLink([shareOptions], user, customValues, toast, notificationScope)
  }

  return linkResults
}

export const shareHubsEmailTemplate = async (
  hubORM: HubORM,
  attachmentsInfo: ContentShareProps[],
  emailTemplate: EmailTemplate,
  customValues: CustomValues[],
  toast: ToastActions,
  notificationScope: ShareNotificationScope,
  existingShareLinks?: ExistingShareLink[],
) => {
  if (!existingShareLinks) {
    analytics?.track('HUB_SHARE', {
      action: 'SHARE',
      category: 'HUB',
      hubId: hubORM.model.id,
    });
  } else {
    analytics?.track('HUB_SHARE_RESHARE', {
      action: 'HUB_SHARE_RESHARE',
      category: 'HUB',
      hubId: hubORM.model.id,
    });
  }

  let linkResults
  if (existingShareLinks?.length) {
    linkResults = shareEmailTemplateCopyExistingLinks(
      toast,
      attachmentsInfo,
      customValues,
      existingShareLinks,
      emailTemplate,
      notificationScope,
    )
  } else {
    const hubShareProps = {
      contentId: hubORM.model.id,
      title: hubORM.model.name,
      type: ShareType.HUB,
    }
    const shareOptions = {
      isMainDoc: false,
      isDefault: false,
      beingShared: false,
      isDistributable: false,
      contentProps: hubShareProps,
    }
    linkResults = await shareEmailTemplateGenerateLinks(
      [shareOptions],
      attachmentsInfo,
      emailTemplate,
      customValues,
      toast,
      notificationScope,
    )
  }

  return linkResults
}

export const copyHubsEmailTemplate = async (
  hubORM: HubORM,
  toast: ToastActions,
  attachmentsInfo: ContentShareProps[],
  emailTemplate: EmailTemplate,
  customValues: CustomValues[],
  notificationScope: ShareNotificationScope,
  existingShareLinks?: ExistingShareLink[],
) => {
  if (!existingShareLinks) {
    analytics?.track('HUB_SHARE', {
      action: 'SHARE',
      category: 'HUB',
      hubId: hubORM.model.id,
    });
  } else {
    analytics?.track('HUB_SHARE_RESHARE', {
      action: 'HUB_SHARE_RESHARE',
      category: 'HUB',
      hubId: hubORM.model.id,
    });
  }

  let linkResults
  if (existingShareLinks?.length) {
    linkResults = await copyExistingEmailTemplateToClipboard(
      toast,
      attachmentsInfo,
      customValues,
      existingShareLinks,
      emailTemplate,
      notificationScope,
    )
  } else {
    const hubShareProps = {
      contentId: hubORM.model.id,
      title: hubORM.model.name,
      type: ShareType.HUB,
    }
    const shareOptions = {
      isMainDoc: false,
      isDefault: false,
      beingShared: false,
      isDistributable: false,
      contentProps: hubShareProps,
    }
    linkResults = (await generateLinksAndcopyEmailTemplateToClipboard(
      toast,
      [shareOptions],
      attachmentsInfo,
      emailTemplate,
      customValues,
      notificationScope,
    )).filesResults
  }
  return linkResults
}

export const copyHubsLink = async (
  hubORM: HubORM,
  toast: ToastActions,
  customValues: CustomValues[],
  notificationScope: ShareNotificationScope,
  existingLinks?: ExistingShareLink[],
) : Promise<{
  result: ShareableLinkResult[] | ExistingShareLink[];
  clipboardText: string;
}> => {
  if (!existingLinks) {
    analytics?.track('HUB_SHARE', {
      action: 'SHARE',
      category: 'HUB',
      hubId: hubORM.model.id,
    });
  } else {
    analytics?.track('HUB_SHARE_RESHARE', {
      action: 'HUB_SHARE_RESHARE',
      category: 'HUB',
      hubId: hubORM.model.id,
    });
  }

  let linkResults: {
    result: ShareableLinkResult[] | ExistingShareLink[];
    clipboardText: string;
  } = { result: [], clipboardText: '' };

  if (existingLinks?.length) {
    // we want to remove the title from the existing links when copying
    const copyResult = await copyExistingLinkToClipboard(toast, existingLinks.map(link => ({
      ...link,
      title: undefined,
    })))

    linkResults.result = copyResult
  } else {
    const hubShareProps = {
      contentId: hubORM.model.id,
      type: ShareType.HUB,
    }
    const shareOptions = {
      isMainDoc: false,
      isDefault: false,
      beingShared: false,
      isDistributable: false,
      contentProps: hubShareProps,
    }
    linkResults = await generateAndCopyLinkToClipboard([shareOptions], toast, customValues, notificationScope )
  }
  return linkResults
}

/* Document share */
export const shareEmailTemplate = async (
  emailTemplateORM: EmailTemplateORM,
  itemORM: DocumentVersionORM[] | FolderItemORM,
  shareFileOptionMapping: ShareFileOptionMapping,
  attachmentsInfo: ContentShareProps[],
  emailTemplate: EmailTemplate,
  customValues: CustomValues[],
  toast: ToastActions,
  notificationScope: ShareNotificationScope,
  meetingId?: string,
) => {
  const allShareFileOption = getAllShareFileOption(shareFileOptionMapping)
  const uniqueShareFileOption = removeDuplicateShareFileOption(allShareFileOption)
  const customDeckDependencies = isFolderItemORM(itemORM) ? getCustomDeckDependencies(itemORM) : undefined

  await shareEmailTemplateGenerateLinks(
    uniqueShareFileOption,
    attachmentsInfo,
    emailTemplate,
    customValues,
    toast,
    notificationScope,
    meetingId,
    customDeckDependencies,
  )

  // Below code is for analytics tracking documents shared
  if (!isFolderItemORM(itemORM)) {
    const contentIdMapping: {[contentId: string]: string} = {}
    for (const documentVersionId in shareFileOptionMapping) {
      shareFileOptionMapping[documentVersionId].forEach(shareFileOption => {
        if (!contentIdMapping[shareFileOption.contentProps.contentId]) {
          contentIdMapping[shareFileOption.contentProps.contentId] = documentVersionId

          const isMainDocBeingShared = shareFileOption.isMainDoc && shareFileOption.beingShared
          const documentVersionORM = itemORM.find(docVer => docVer.model.id === documentVersionId)
          if (isMainDocBeingShared && documentVersionORM) {
            analytics?.track('DOCUMENT_SHARE', {
              action: 'SHARE',
              channel: 'EMAIL',
              category: 'DOCUMENT',
              documentId: documentVersionORM.model.documentId,
              documentVersionId: documentVersionORM.model.id,
              emailTemplateId: emailTemplateORM.model.id,
              context: itemORM.length > 1 ? 'BULK_SHARE' : 'SHARE',
            })
          } else if (documentVersionORM) {
            analytics?.track('DOCUMENT_AF_SHARE', {
              action: 'AF_SHARE',
              attachedContentId: shareFileOption.contentProps.contentId,
              category: 'DOCUMENT',
              channel: 'EMAIL',
              documentId: documentVersionORM.model.documentId,
              documentVersionId: documentVersionORM.model.id,
              sharedContentId: shareFileOption.contentProps.sharedContentId,
              type: shareFileOption.contentProps.type === 'ATTACHED_FILE' ? 'UPLOAD' : 'LINK',
            })
          }
        }
      })
    }
  }
  else {
    analytics?.track('CUSTOM_SHARE', {
      action: 'SHARE',
      category: 'CUSTOM',
      channel: 'EMAIL',
      customDeckId: itemORM.relations.itemORM.model.id,
      context: 'SHARE',
    });
  }
}
export const shareEmailLinks = async (
  shareFileOptionMapping: ShareFileOptionMapping,
  user: User,
  customValues: CustomValues[],
  toast: ToastActions,
  notificationScope: ShareNotificationScope,
  meetingId?: string,
) => {
  const allShareFileOption = getAllShareFileOption(shareFileOptionMapping)
  const uniqueShareFileOption = removeDuplicateShareFileOption(allShareFileOption)
  shareEmailGenerateLink(uniqueShareFileOption, user, customValues, toast, notificationScope, meetingId)
}

export const copyShareEmailTemplate = async (
  emailTemplateORM: EmailTemplateORM,
  itemORM: DocumentVersionORM[] | FolderItemORM,
  toast: ToastActions,
  shareFileOptionMapping: ShareFileOptionMapping,
  attachmentsInfo: ContentShareProps[],
  emailTemplate: EmailTemplate,
  customValues: CustomValues[],
  notificationScope: ShareNotificationScope,
  meetingId?: string,
  skipClipboardWrite?: boolean,
) : Promise<{bodyText: string, filesResults: ShareableLinkResult[]}> => {
  const allShareFileOption = getAllShareFileOption(shareFileOptionMapping)
  const uniqueShareFileOption = removeDuplicateShareFileOption(allShareFileOption)
  const customDeckDependencies = isFolderItemORM(itemORM) ? getCustomDeckDependencies(itemORM) : undefined

  const resultTemplate = await generateLinksAndcopyEmailTemplateToClipboard(
    toast,
    uniqueShareFileOption,
    attachmentsInfo,
    emailTemplate,
    customValues,
    notificationScope,
    meetingId,
    customDeckDependencies,
    skipClipboardWrite,
  )

  // Below code is for analytics tracking
  if (!isFolderItemORM(itemORM)) {
    const contentIdMapping: {[contentId: string]: string} = {}
    for (const documentVersionId in shareFileOptionMapping) {
      shareFileOptionMapping[documentVersionId].forEach(shareFileOption => {
        if (!contentIdMapping[shareFileOption.contentProps.contentId]) {
          contentIdMapping[shareFileOption.contentProps.contentId] = documentVersionId

          const isMainDocBeingShared = shareFileOption.isMainDoc && shareFileOption.beingShared
          const documentVersionORM = itemORM.find(docVer => docVer.model.id === documentVersionId)
          if (isMainDocBeingShared && documentVersionORM) {
            analytics?.track('DOCUMENT_SHARE', {
              action: 'SHARE',
              channel: 'EMAIL',
              category: 'DOCUMENT',
              documentId: documentVersionORM.model.documentId,
              documentVersionId: documentVersionORM.model.id,
              emailTemplateId: emailTemplateORM.model.id,
              context: itemORM.length > 1 ? 'BULK_SHARE' : 'SHARE',
            })
          } else if (documentVersionORM) {
            analytics?.track('DOCUMENT_AF_SHARE', {
              action: 'AF_SHARE',
              attachedContentId: shareFileOption.contentProps.contentId,
              category: 'DOCUMENT',
              channel: 'EMAIL',
              documentId: documentVersionORM.model.documentId,
              documentVersionId: documentVersionORM.model.id,
              sharedContentId: shareFileOption.contentProps.sharedContentId ?? null,
              type: shareFileOption.contentProps.type === 'ATTACHED_FILE' ? 'UPLOAD' : 'LINK',
            })
          }
        }
      })
    }
  }
  else {
    analytics?.track('CUSTOM_SHARE', {
      action: 'SHARE',
      category: 'CUSTOM',
      channel: 'EMAIL',
      customDeckId: itemORM.relations.itemORM.model.id,
      context: 'SHARE',
    });
  }

  return resultTemplate
}

export const copyLink = async (
  itemORM: DocumentVersionORM[] | FolderItemORM,
  shareFileOptionMapping: ShareFileOptionMapping,
  toast: ToastActions,
  customValues: CustomValues[],
  notificationScope: ShareNotificationScope,
  meetingId?: string,
  skipClipboardWrite?: boolean,
) : Promise<string> => {
  const allShareFileOption = getAllShareFileOption(shareFileOptionMapping)
  const uniqueShareFileOption = removeDuplicateShareFileOption(allShareFileOption)
  const customDeckDependencies = isFolderItemORM(itemORM) ? getCustomDeckDependencies(itemORM) : undefined
  const { result:linkResults, clipboardText } = await generateAndCopyLinkToClipboard(uniqueShareFileOption, toast,
    customValues, notificationScope, meetingId, customDeckDependencies, skipClipboardWrite)

  if (Array.isArray(itemORM)) {
  // Below code is for analytics tracking
    const contentIdMapping: {[contentId: string]: string} = {}
    for (const documentVersionId in shareFileOptionMapping) {
      shareFileOptionMapping[documentVersionId].forEach(shareFileOption => {
        if (!contentIdMapping[shareFileOption.contentProps.contentId]) {
          contentIdMapping[shareFileOption.contentProps.contentId] = documentVersionId
        }
      })
    }

    linkResults.forEach((shareableLinkResult: ShareableLinkResult) => {
      const documentVersionORM = itemORM.find(docVer => docVer.model.id === shareableLinkResult.contentId)
      if (documentVersionORM) {
        analytics?.track('DOCUMENT_SHARE', {
          action: 'SHARE',
          channel: 'LINK',
          category: 'DOCUMENT',
          documentId: documentVersionORM.model.documentId,
          documentVersionId: documentVersionORM.model.id,
          shareId: shareableLinkResult.id,
          context: itemORM.length > 1 ? 'BULK_SHARE' : 'SHARE',
        });
        return
      }

      const documentVersionId = contentIdMapping[shareableLinkResult.contentId]
      const documentVersionORM_AF = itemORM.find(docVer => docVer.model.id === documentVersionId)
      if (documentVersionORM_AF) {
        analytics?.track('DOCUMENT_AF_SHARE', {
          action: 'AF_SHARE',
          attachedContentId: shareableLinkResult.contentId,
          category: 'DOCUMENT',
          channel: 'LINK',
          documentId: documentVersionORM_AF.model.documentId,
          documentVersionId: documentVersionORM_AF.model.id,
          shareId: shareableLinkResult.id,
          type: shareableLinkResult.type === 'ATTACHED_FILE' ? 'UPLOAD' : 'LINK',
        });
      }
    });
  }
  else {
    analytics?.track('CUSTOM_SHARE', {
      action: 'SHARE',
      category: 'CUSTOM',
      channel: 'LINK',
      customDeckId: itemORM.relations.itemORM.model.id,
      context: 'SHARE',
    });
  }
  return clipboardText
}

export const getMainFileShareOptions = (
  documentVersionORM: DocumentVersionORM,
  tenant: Tenant,
  beingShared: boolean = true,
): ShareFileOption => ({
  isMainDoc: true,
  isDefault: false,
  beingShared,
  isDistributable: canPerformAction(
    DOCUMENT_ACTIONS_ENUM.share,
    documentVersionORM.meta.customValues.configsMap,
    tenant,
  ),
  contentProps: {
    contentId: documentVersionORM.model.id,
    title: documentVersionORM?.model.title,
    type: ShareType.DOCUMENT_VERSION,
  },
})

export const getCustomDeckDependencies = (
  itemORM: FolderItemORM,
): CustomDeckDependencies | undefined => {
  const folderId = itemORM.relations.parentORM?.model.id
  if ( isCustomDeckORM(itemORM.relations.itemORM) && folderId) {
    const versions = new Set(itemORM.relations.itemORM.model.groups
      .flatMap(group => group.pages.map(page => page.documentVersionId)));
    return {
      folderId,
      versions: Array.from(versions),
    }
  }
}

export const getAndUpdateReduxContentShare = async (
  contentShareId: string,
): Promise<void> => {
  const appsyncClient = generateClient();
  const { data } = await appsyncClient.graphql({
    query: getContentShareByID,
    variables: {
      id: contentShareId,
    },
  });
  if (data?.getContentShare) {
    // ECFLAG
    store.dispatch(contentShareActions.upsert(data?.getContentShare as any))
  }
  // TODO error handling?
}

export const getCurrentDocVer = (docVerId: string) => {
  const reduxState = store.getState()
  const docVerORM = documentVersionORMById(reduxState, docVerId)
  return docVerORM
}

export const getTenantFromDocVerORM = (docVerId: string) => {
  const reduxState = store.getState()
  const docVerORM = documentVersionORMById(reduxState, docVerId)
  const currentTenant =  getTenant(reduxState, undefined, docVerORM?.model.tenantId)
  return currentTenant
}

export const getShareFileOption = (af: AssociatedFileORM) => {
  return {
    isMainDoc: false,
    beingShared: !!(af.meta.canBeSharedByMSL && af.model.isDefault),
    isDefault: !!af.model.isDefault,
    isDistributable: !!af.meta.canBeSharedByMSL,
    contentProps: {
      contentId: af.model.type === 'ATTACHED_FILE'
        ? af.file?.id || ''
        : af.relations.latestUsableDocumentVersion?.id || '',
      // sharedContentId: af.model.id, // not sure what this is, how is this different than contentId above?
      title: getAssociatedFileTitle(af),
      type: af.model.type === 'ATTACHED_FILE'
        ? ShareType.ATTACHED_FILE
        : ShareType.DOCUMENT_VERSION,
    },
  }
}

export const getFileShareOptionsMapping = (associatedFileORM: AssociatedFileORM[]) => {
  return associatedFileORM.map(af => {
    const shareFileOption = getShareFileOption(af)
    return { [af.model.id]: shareFileOption }
  })
}

// formats main, required and options files
export const formatShareableFiles = (documentVersionORM: DocumentVersionORM) => {
  const sortedAssocatedFiles: {
    required: AssociatedFileORM[],
    optional: AssociatedFileORM[]
  } = { required: [], optional: [] }
  const formatAllAssociatedFiles = documentVersionORM.relations.associatedFiles?.reduce((acc, orm) => {
    if (orm.meta.canBeSharedByMSL && orm.model.isDefault) {
      acc.required.push(orm)
    } else if (orm.meta.canBeSharedByMSL && !orm.model.isDefault) {
      acc.optional.push(orm)
    }
    return acc
  }, sortedAssocatedFiles)

  return { ...formatAllAssociatedFiles, ...{ main: documentVersionORM } }
}

export const isEntitySelectedToShare = (
  mainDocVerId: string,
  targetedEntityId: string,
  availableContentToShare?: ShareFileOptionMapping,
) => {
  if (!availableContentToShare) return false
  if (!availableContentToShare[mainDocVerId]) return false
  for (const sfo of availableContentToShare[mainDocVerId]) {
    if (sfo.contentProps.contentId === targetedEntityId) return sfo.beingShared
  }
}

export const getDocVerORMFromEntityShareIds = (entityShareIds: string[]) => {
  const reduxState = store.getState()
  const documentVersionORMs: DocumentVersionORM[] = []
  entityShareIds.forEach(shareId => {
    const docVerORM = documentVersionORMById(reduxState, shareId)
    if (docVerORM) documentVersionORMs.push(docVerORM)
  })
  return documentVersionORMs
}

export const getHubORMFromEntityShareIds = (entityShareIds: string[]) => {
  const reduxState = store.getState()
  const hubORMs: HubORM[] = []
  entityShareIds.forEach(shareId => {
    const hubORM = getHubById(reduxState, undefined, shareId)
    if (hubORM) hubORMs.push(hubORM)
  })
  return hubORMs
}

export const getItemORMFromEntityShareIds = (entityShareIds: string[]) => {
  const reduxState = store.getState()
  const itemORMs: FolderItemORM[] = []
  entityShareIds.forEach(shareId => {
    const itemORM = folderItemORMByIdNoFolder(reduxState, { id: shareId })
    if (itemORM) itemORMs.push(itemORM)
  })
  return itemORMs
}

export const getAttachmentsToShare = (
  associatedFiles: AssociatedFileORM[],
) => {
  const reduxState = store.getState()
  const allDocumentORM = allDocumentsSortedAndFilteredFactory()(reduxState)
  return associatedFiles
    .filter(af => af.meta.canBeSharedByMSL)
    .map(af => {
      let contentId = af.file?.id!
      if (af.model.type === 'DOCUMENT') {
        const [currentAssociatedDoc] = allDocumentORM
          .filter(currentDoc => af.file?.id! === currentDoc.model.id)
        contentId = currentAssociatedDoc != null
          ? currentAssociatedDoc.relations.version.latestPublishedDocumentVersionORM?.model.id!
          : contentId
      }
      return {
        title: getAssociatedFileTitle(af),
        contentId,
        sharedContentId: af.model.id,
        type: af.model.type === 'ATTACHED_FILE' ? ShareType.ATTACHED_FILE : ShareType.DOCUMENT_VERSION,
      }
    })
}

export const getUser = () => {
  const reduxState = store.getState()
  return currentUserORM(reduxState)
}

export const getSelectedNumOfContent = (allContentInArrayFormat: ShareFileOption[]): number => {
  let selectedNumContent = 0
  allContentInArrayFormat.forEach(sfo => {
    if (sfo.beingShared) selectedNumContent++
  })
  return selectedNumContent
}

export const CopyLinkButtonProps = (skipClipboardWrite: boolean) => ({
  idle: {
    DOC_VER_SHARE: { label: skipClipboardWrite ? 'Create file names and links' : 'Copy file names and links' },
    CUSTOM_DECK_SHARE: { label: skipClipboardWrite ? 'Create file names and links' : 'Copy file names and links' },
    HUB_SHARE: { label: 'Copy hub link' },
    status: 'tertiary',
    icon: 'content-copy',
    appearance: 'outline',
  },
  copying: {
    DOC_VER_SHARE: { label: skipClipboardWrite ? 'Creating...' : 'Copying...' },
    CUSTOM_DECK_SHARE: { label: skipClipboardWrite ? 'Creating...' : 'Copying...' },
    HUB_SHARE: { label: skipClipboardWrite ? 'Creating...' : 'Copying...' },
    status: 'tertiary',
    icon: 'sync',
    appearance: 'outline',
  },
  success: {
    DOC_VER_SHARE: { label: skipClipboardWrite ? 'Created' : 'Copied' },
    CUSTOM_DECK_SHARE: { label: skipClipboardWrite ? 'Created' : 'Copied' },
    HUB_SHARE: { label: skipClipboardWrite ? 'Created' : 'Copied' },
    status: 'success',
    icon: 'check-bold',
    appearance: 'filled',
  },
  error: {
    DOC_VER_SHARE: { label: 'Error' },
    CUSTOM_DECK_SHARE: { label: 'Error' },
    HUB_SHARE: { label: 'Error' },
    status: 'danger',
    icon: 'alert',
    appearance: 'filled',
  },
  ready: {
    DOC_VER_SHARE: { label: 'Copy file names and links' },
    CUSTOM_DECK_SHARE: { label: 'Copy file names and links' },
    HUB_SHARE: { label: 'Copy hub link' },
    status: 'primary',
    icon: 'content-copy',
    appearance: 'filled',
  },
})

export const CopyEmailButtonProps = (skipClipboardWrite: boolean) => ({
  idle: {
    label: skipClipboardWrite ? 'Create email' : 'Copy email',
    status: 'tertiary',
    icon: 'content-copy',
    appearance: 'outline',
  },
  copying: {
    label: skipClipboardWrite ? 'Creating...' : 'Copying...',
    status: 'tertiary',
    icon: 'sync',
    appearance: 'outline',
  },
  success: {
    label: 'Copied',
    status: 'success',
    icon: 'check-bold',
    appearance: 'filled',
  },
  error: {
    label: 'Error',
    status: 'danger',
    icon: 'alert',
    appearance: 'filled',
  },
  ready: {
    label: 'Copy email',
    status: 'primary',
    icon: 'content-copy',
    appearance: 'filled',
  },
})

export const OpenEmailButtonProps = {
  idle: {
    label: 'Open email',
    status: 'tertiary',
    icon: 'email-send-outline',
    appearance: 'outline',
  },
  opening: {
    label: 'Opening...',
    status: 'tertiary',
    icon: 'sync',
    appearance: 'outline',
  },
  success: {
    label: 'Opened',
    status: 'success',
    icon: 'check-bold',
    appearance: 'filled',
  },
  error: {
    label: 'Error',
    status: 'danger',
    icon: 'alert',
    appearance: 'filled',
  },
}
