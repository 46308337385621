import React from 'react'
import {
  EmailTemplate, User, ShareType, CustomValues,
  CustomDeckDependencies,
  ShareNotificationScope,
} from '@alucio/aws-beacon-amplify/src/models'
import { GenericToast, ToastOrientations } from '@alucio/lux-ui';
import { ToastActions } from '@alucio/lux-ui/lib/components/Toast/useToast';
import { getShareableLink, ShareableLinkResult } from './common'
import { isSafari, isWindows } from 'react-device-detect'
import format from 'date-fns/format'
import addDays from 'date-fns/addDays'
import ROUTES from 'src/router/routeDef';
import logger from 'src/utils/logger'

const JUMP_LINE = '%0D%0A'
const WINDOWS_MAX_ENCODED_URI_LENGTH = 2046
const WINDOWS_MAX_ENCODED_URI_LENGTH_PADDING = 46
const MAILTO_PREFIX = 'mailto:?'
const MAX_ENCODED_URI_LENGTH_PLACEHOLDER = 'Paste the message from your clipboard here. (Ctrl-V)'

// [TODO] - This probably isn't needed ... We should just encode everything instead of selectively
//        - But handle that whenever we refactor everything here
// https://docs.mapp.com/docs/url-encoding-and-what-characters-are-valid-in-a-uri
// https://datatracker.ietf.org/doc/html/rfc3986
const RESERVED_URI_CHARS = [
  ':',
  '@',
  '/',
  '?',
  '&',
  '=',
  '#',
  '%',
  '+',
  // [NOTE] - These are only reserved for IPV6, but we don't want to mess with our [Files] interpolation at the moment so disable replacing these
  // '[',
  // ']',
  '!',
  '$',
  '(',
  ')',
  '*',
  ',',
  ';',
]

const RESERVED_URI_CHARS_REGEX = new RegExp(RESERVED_URI_CHARS.map(char => `(\\${char})`).join('|'), 'g')

const encodeReservedURICharacters = (target: string) => {
  return target.replaceAll(
    RESERVED_URI_CHARS_REGEX,
    (v) => encodeURIComponent(v),
  )
}

const generateMailtoURI = (
  cc: string,
  bcc: string,
  subject: string,
  body: string,
) => {
  const query = Object
    .entries({
      cc,
      bcc,
      subject,
      body,
    })
    .filter((keyVal): keyVal is [string, string] => !!keyVal.at(1))
    .map(([key, val]) => `${key}=${val}`)
    .join('&')

  return `${MAILTO_PREFIX}${query}`
}

export interface ShareFileOption {
  isMainDoc: boolean,
  isDefault: boolean,
  beingShared: boolean,
  isDistributable: boolean,
  contentProps: ContentShareProps
}

export interface ContentShareProps {
  contentId: string,
  sharedContentId?: string,
  title?: string,
  type: ShareType,
  customDeckDependencies?: CustomDeckDependencies,
}

export interface ExistingShareLink {
  title?: string,
  expiresAt: string,
  link: string,
  id: string,
}

/**
  * This function generates a shareable link and copies it to clipboard
  * @param shareFileOptions array of ShareFileOption
  * @param toast: toast actions
  * @param customValues: tenant custom values
  * @param notificationScope enum for determining if the user should be notified
  * @param optional meetingId, if a meeting id is attached to this link
  * @returns list of shareLinks
 */
async function generateAndCopyLinkToClipboard(
  shareFileOptions: ShareFileOption[],
  toast: ToastActions,
  customValues: CustomValues[],
  notificationScope: ShareNotificationScope,
  meetingId?: string,
  customDeckDependencies?: CustomDeckDependencies,
  skipClipboardWrite?: boolean,
): Promise<{ result: ShareableLinkResult[], clipboardText: string }> {
  const shareableLinksList: Promise<ShareableLinkResult>[] = shareFileOptions.map((share: ShareFileOption) => {
    const { contentId, type, title } = share.contentProps
    return getShareableLink(contentId, type, title, customValues, notificationScope, meetingId, customDeckDependencies)
  })
  const result: ShareableLinkResult[] = await Promise.all(shareableLinksList)
  const clipboardText = result.reduce((acc, val) => {
    return `${acc}${val.title}${val.title ? '\n' : ''}${val.link}?expiresAt=${val.expiresAt} \n\n`
  }, '')
  if (!skipClipboardWrite) {
    const remainingDays = calculateRemainingDaysFromDate(result[0].expiresAt);
    const successTitle = `Link copied, expires in ${remainingDays} day${remainingDays > 1 ? 's' : ''}.`;
    copyToClipboard(toast, clipboardText, successTitle);
  }
  return { result, clipboardText }
}

/**
  * This function copies existing sharebale link to clipboard
  * @param toast: toast actions
  * @param existingShareLinks: list of existing links to copy to clipboard
  * @returns existing share links
 */
async function copyExistingLinkToClipboard(
  toast: ToastActions,
  existingShareLinks: ExistingShareLink[],
): Promise<ExistingShareLink[]> {
  const clipboardText = existingShareLinks.reduce((acc, val) => {
    return `${acc}${val.title ? val.title : ''}${val.title ? '\n' : ''}${val.link}?expiresAt=${val.expiresAt} \n\n`
  }, '')
  const remainingDays = calculateRemainingDaysFromDate(existingShareLinks[0].expiresAt);
  const successTitle = `Link copied, expires in ${remainingDays} day${remainingDays > 1 ? 's' : ''}.`;
  copyToClipboard(toast, clipboardText, successTitle);
  return existingShareLinks
}

/**
 * this fuctions generates shareable links and opens the email client
 * @param shareFileOptions array of ShareFileOption
 * @param user current user
 * @param customValues tenant custom values
 * @param toast toast actions
 * @param notificationScope enum for determining if the user should be notified
 * @param meetingId optional if the links are attached to a meeting id
 * @returns list of shareable links
 */
async function shareEmailGenerateLink(
  shareFileOptions: ShareFileOption[],
  user: User,
  customValues: CustomValues[],
  toast: ToastActions,
  notificationScope: ShareNotificationScope,
  meetingId?: string,
): Promise<ShareableLinkResult[]> {
  const shares: ContentShareProps[] =
    shareFileOptions.map((shareFileOption: ShareFileOption): ContentShareProps => shareFileOption.contentProps);
  const shareableLinksList = shares.map(share => {
    const { title, contentId, type, customDeckDependencies } = share
    return getShareableLink(contentId, type, title, customValues, notificationScope, meetingId, customDeckDependencies)
  })
  const result = await Promise.all(shareableLinksList)

  const bodyText = result.reduce((acc, val) => {
    /* eslint-disable max-len */
    return `${acc}${encodeURIComponent(val.title)}${val.title ? JUMP_LINE : ''}${encodeURIComponent(val.link)}%20(link expires ${val.expiresAt})${JUMP_LINE + JUMP_LINE}`
  }, '')

  const cc = user.shareCC && user.shareCC.length > 0 ? encodeURIComponent(user.shareCC.join(',')) : '';
  const bcc = user.shareBCC && user.shareBCC.length > 0 ? encodeURIComponent(user.shareBCC.join(',')) : '';

  await sendEmail(cc, bcc, JUMP_LINE + bodyText, toast)
  return result
}

/**
 * this function opens the email client with existing links
 * @param existingShareLinks: list of existing links to copy to clipboard
 * @param user: current user
 * @param toast: toast actions
 * @returns existing share links
 */
async function shareEmailExistingLinks(
  existingShareLinks: ExistingShareLink[],
  user: User,
  toast: ToastActions,
): Promise<ExistingShareLink[]> {
  const bodyText = existingShareLinks.reduce((acc, val) => {
    /* eslint-disable max-len */
    return `${acc}${val.title ? encodeURIComponent(val.title) : ''}${val.title ? JUMP_LINE : ''}${encodeURIComponent(val.link)}%20(link expires ${val.expiresAt})${JUMP_LINE + JUMP_LINE}`
  }, '')
  const linkResult = existingShareLinks
  const cc = user.shareCC && user.shareCC.length > 0 ? encodeURIComponent(user.shareCC.join(',')) : '';
  const bcc = user.shareBCC && user.shareBCC.length > 0 ? encodeURIComponent(user.shareBCC.join(',')) : '';

  await sendEmail(cc, bcc, JUMP_LINE + bodyText, toast)
  return linkResult
}

/**
 * this function generates and copies email template to clipboard
 * @param toast toast actions
 * @param shareFileOptions array of ShareFileOption
 * @param attachmentsInfo attached files to email template (and attached files to the document??)
 * @param emailTemplate the email template to copy
 * @param customValues tenant custom values
 * @param notificationScope enum for determining if the user should be notified
 * @param meetingId optional if the links are attached to a meeting id
 * @returns list of shareable links
 */
async function generateLinksAndcopyEmailTemplateToClipboard(
  toast: ToastActions,
  shareFileOptions: ShareFileOption[],
  attachmentsInfo: ContentShareProps[],
  emailTemplate: EmailTemplate,
  customValues: CustomValues[],
  notificationScope: ShareNotificationScope,
  meetingId?: string,
  customDeckDependencies?: CustomDeckDependencies,
  skipWriteToClipboard?: boolean,
): Promise<{bodyText: string, filesResults: ShareableLinkResult[]}> {
  const shares: ContentShareProps[] = shareFileOptions.map((shareFileOption: ShareFileOption): ContentShareProps => shareFileOption.contentProps);
  const toastWidth = 275

  const processor = (links: ShareableLinkResult[]) => links.reduce(
    (acc, val, idx) => {
      return (
        `${acc}${val.title}${val.title ? '\n' : ''}${val.link}?expiresAt=${val.expiresAt}` +
        `${idx !== links.length - 1 ? '\n\n' : ''}`
      )
    },
    '',
  )

  const bodyTextAndFileResults = await getEmailBodyAndFilesResultsWithTemplate(
    shares,
    attachmentsInfo,
    emailTemplate,
    processor,
    customValues,
    notificationScope,
    false,
    meetingId,
    customDeckDependencies,
  )

  const handleCopyLink = () => {
    navigator.clipboard.writeText(bodyTextAndFileResults.bodyText).then(() => {
      toast.add(<GenericToast
        title="Email copied."
        status="success"
        width={toastWidth}
      />, ToastOrientations.TOP_RIGHT, true, 3000)
    }, () => {
      toast.add(
        <GenericToast title="Unable to access clipboard." width={toastWidth} status="error" />,
        ToastOrientations.TOP_RIGHT,
      )
    })
  }

  if (!skipWriteToClipboard) {
    navigator.clipboard.writeText(bodyTextAndFileResults.bodyText).then(
      () => {
        toast.add(
          <GenericToast
            title="Email copied."
            status="success"
            width={150}
          />, ToastOrientations.TOP_RIGHT, true, 3000)
        return undefined
      },
      () => {
        toast.add(
          <GenericToast
            title="Email generated"
            width={toastWidth}
            status="information"
            actionConfig={{ actionTitle: 'Copy email', callback: handleCopyLink }}
          />,
          ToastOrientations.TOP_RIGHT, false, 0);
        return undefined
      },
    )
  }

  return bodyTextAndFileResults
}

/**
 * this function copies an email template to clipboard with existing links
 * @param toast toast actions
 * @param attachmentsInfo attached files to email template (and attached files to the document??)
 * @param customValues tenant custom values
 * @param existingShareLinks list of existing shareable links
 * @param emailTemplate the email template to copy
 * @param notificationScope enum for determining if the user should be notified
 * @param meetingId optional if the links are attached to a meeting id
 * @returns list of existing sharable links
 */
async function copyExistingEmailTemplateToClipboard(
  toast: ToastActions,
  attachmentsInfo: ContentShareProps[],
  customValues: CustomValues[],
  existingShareLinks: ExistingShareLink[],
  emailTemplate: EmailTemplate,
  notificationScope: ShareNotificationScope,
  meetingId?: string,
): Promise<ExistingShareLink[]> {
  const processor = (links: ExistingShareLink[]) => links.reduce(
    (acc, val, idx) => {
      return (
        `${acc}${val.title ? val.title : ''}${val.title ? '\n' : ''}${val.link}?expiresAt=${val.expiresAt}` +
        `${idx !== links.length - 1 ? '\n\n' : ''}`
      )
    },
    '',
  )

  const bodyTextAndFileResults = await copyExistingEmailBodyAndFileWithTemplate(
    attachmentsInfo,
    customValues,
    processor,
    existingShareLinks,
    emailTemplate,
    notificationScope,
    false,
    meetingId,
  )

  copyToClipboard(toast, bodyTextAndFileResults.bodyText)
  return bodyTextAndFileResults.filesResults
}

/**
 * this function generates links and opens email client
 * @param shareFileOptions array of ShareFileOption
 * @param attachmentsInfo attached files to email template (and attached files to the document??)
 * @param emailTemplate the email template to copy
 * @param customValues tenant custom values
 * @param toast toast actions
 * @param notificationScope enum for determining if the user should be notified
 * @param meetingId optional if the links are attached to a meeting id
 * @returns list of shareable link
 */
async function shareEmailTemplateGenerateLinks(
  shareFileOptions: ShareFileOption[],
  attachmentsInfo: ContentShareProps[],
  emailTemplate: EmailTemplate,
  customValues: CustomValues[],
  toast: ToastActions,
  notificationScope: ShareNotificationScope,
  meetingId?: string,
  customDeckDependencies?: CustomDeckDependencies,
): Promise<ShareableLinkResult[]> {
  const splitSeparator = isWindows ? ';' : ',';

  const shares: ContentShareProps[] = shareFileOptions.map((shareFileOption: ShareFileOption): ContentShareProps => shareFileOption.contentProps);

  const processor = (links: ShareableLinkResult[]) => links.reduce((acc, val, idx) => {
    return `${acc}${encodeURIComponent(val.title)}${val.title ? JUMP_LINE : ''}` +
      `${encodeURIComponent(val.link)}%20(link expires ${val.expiresAt})` +
      `${idx !== links.length - 1 ? JUMP_LINE + JUMP_LINE : ''}`
  }, '')

  const emailBodyAndFilesResults = await getEmailBodyAndFilesResultsWithTemplate(
    shares,
    attachmentsInfo,
    emailTemplate,
    processor,
    customValues,
    notificationScope,
    true,
    meetingId,
    customDeckDependencies,
  )
  const bodyTextToCopy = emailBodyAndFilesResults.bodyText.replace(/\n/g, JUMP_LINE);
  const cc = emailTemplate.cc && emailTemplate.cc.length > 0 ? encodeURIComponent(emailTemplate.cc.join(splitSeparator)) : '';
  const bcc = emailTemplate.bcc && emailTemplate.bcc.length > 0 ? encodeURIComponent(emailTemplate.bcc.join(splitSeparator)) : '';

  await sendEmail(cc, bcc, bodyTextToCopy, toast, emailTemplate.subject || '')
  return emailBodyAndFilesResults.filesResults
}

/**
 * This function opens email client with existing links
 * @param toast toast actions
 * @param attachmentsInfo attached files to email template (and attached files to the document??)
 * @param customValues tenant custom values
 * @param existingShareLinks list of existing shareable links
 * @param emailTemplate the email template to copy
 * @param notificationScope enum for determining if the user should be notified
 * @param meetingId optional if the links are attached to a meeting id
 * @returns list of existing sharable links
 */
async function shareEmailTemplateCopyExistingLinks(
  toast: ToastActions,
  attachmentsInfo: ContentShareProps[],
  customValues: CustomValues[],
  existingShareLinks: ExistingShareLink[],
  emailTemplate: EmailTemplate,
  notificationScope: ShareNotificationScope,
  meetingId?: string,
) {
  const splitSeparator = isWindows ? ';' : ',';
  const processor = (links: ExistingShareLink[]) => links.reduce((acc, val, idx) => {
    return `${acc}${val.title ? encodeURIComponent(val.title) : ''}${val.title ? JUMP_LINE : ''}` +
      `${encodeURIComponent(val.link)}%20(link expires ${val.expiresAt})` +
      `${idx !== links.length - 1 ? JUMP_LINE + JUMP_LINE : ''}`
  }, '')

  const bodyTextAndFileResults = await copyExistingEmailBodyAndFileWithTemplate(
    attachmentsInfo,
    customValues,
    processor,
    existingShareLinks,
    emailTemplate,
    notificationScope,
    true,
    meetingId,
  )
  const bodyTextToCopy = bodyTextAndFileResults.bodyText.replace(/\n/g, JUMP_LINE);
  const cc = emailTemplate.cc && emailTemplate.cc.length > 0 ? encodeURIComponent(emailTemplate.cc.join(splitSeparator)) : '';
  const bcc = emailTemplate.bcc && emailTemplate.bcc.length > 0 ? encodeURIComponent(emailTemplate.bcc.join(splitSeparator)) : '';

  await sendEmail(cc, bcc, bodyTextToCopy, toast, emailTemplate.subject || '')
  return bodyTextAndFileResults.filesResults
}

/**
 * this function copies email template and file with existing share links
 * @param attachments list attached documents
 * @param customValues tenant custom values
 * @param processor function that returns the shareable links as string
 * @param existingShareLinks existing shareable links
 * @param emailTemplate the email template to copy
 * @param notificationScope enum for determining if the user should be notified
 * @param encodeReservedChar boolean indicating if there are reserved character in the subject or body
 * @param meetingId optional if the links are attached to a meeting id
 * @returns email body text and list of existing share links
 */
async function copyExistingEmailBodyAndFileWithTemplate(
  attachments: ContentShareProps[],
  customValues: CustomValues[],
  processor: (links: ExistingShareLink[]) => string,
  existingShareLinks: ExistingShareLink[],
  emailTemplate: EmailTemplate,
  notificationScope: ShareNotificationScope,
  encodeReservedChar: boolean = false,
  meetingId?: string,
): Promise<{ bodyText: string, filesResults: ExistingShareLink[] }> {
  const attachmentsLinksList = attachments.map(share => {
    const { title, contentId, type } = share
    return getShareableLink(contentId, type, title, customValues, notificationScope, meetingId)
  })
  const attachmentsResults = await Promise.all(attachmentsLinksList)
  const fileLinks = processor(existingShareLinks)
  const attachmentLinks = processor(attachmentsResults)

  const bodyText = getBodyText(emailTemplate, fileLinks, attachmentLinks, encodeReservedChar)
  return { bodyText, filesResults: existingShareLinks };
}

/**
 * function that generates links and copies the email template to clipboard
 * @param shareFileOptions array of ShareFileOption
 * @param attachments list attached documents
 * @param emailTemplate the email template to copy
 * @param processor function that returns the shareable links as string
 * @param customValues tenant custom values
 * @param notificationScope enum for determining if the user should be notified
 * @param encodeReservedChar boolean indicating if there are reserved character in the subject or body
 * @param meetingId optional if the links are attached to a meeting id
 * @returns email body text and list of shareable links
 */
async function getEmailBodyAndFilesResultsWithTemplate(
  shareFileOptions: ContentShareProps[],
  attachments: ContentShareProps[],
  emailTemplate: EmailTemplate,
  processor: (links: ShareableLinkResult[]) => string,
  customValues: CustomValues[],
  notificationScope: ShareNotificationScope,
  encodeReservedChar: boolean = false,
  meetingId?: string,
  customDeckDependencies?: CustomDeckDependencies,
): Promise<{bodyText: string, filesResults: ShareableLinkResult[]}> {
  const shareableLinksList = shareFileOptions.map(share => {
    const { title, contentId, type } = share
    return getShareableLink(contentId, type, title, customValues, notificationScope, meetingId, customDeckDependencies)
  })
  const filesResults = await Promise.all(shareableLinksList)

  const attachmentsLinksList = attachments.map(share => {
    const { title, contentId, type } = share
    return getShareableLink(contentId, type, title, customValues, notificationScope, meetingId, customDeckDependencies)
  })
  const attachmentsResults = await Promise.all(attachmentsLinksList)
  const fileLinks = processor(filesResults)
  const attachmentLinks = processor(attachmentsResults)

  const bodyText = getBodyText(emailTemplate, fileLinks, attachmentLinks, encodeReservedChar)
  return { bodyText, filesResults };
}

const checkIfShouldAddNewlines = (body: string, placeholder: string) => {
  const bodyLines = body.split('\n');
  const lineWithPlaceholder = bodyLines.find(l => l.indexOf(placeholder) > -1);
  let shouldAddNewLines = false;
  if (lineWithPlaceholder && !lineWithPlaceholder.trim().startsWith(placeholder)) {
    shouldAddNewLines = true;
  }

  return shouldAddNewLines;
}

async function emailTemplatePreview(
  toast: ToastActions,
  subject?: string,
  body?: string,
  cc?: string[],
  bcc?: string[],
  attachments?: ContentShareProps[]): Promise<void> {
  const splitSeparator = isWindows ? ';' : ',';
  const formattedBCC = (bcc?.length && encodeURIComponent(bcc.join(splitSeparator))) || '';
  const formattedCC = (cc?.length && encodeURIComponent(cc.join(splitSeparator))) || '';
  let formattedBody = body

  if (formattedBody) {
    formattedBody = encodeReservedURICharacters(formattedBody)
  }

  if (formattedBody && attachments) {
    const expiresAt = format(addDays(new Date(), 30), 'yyyy-MM-dd')
    const links = attachments.reduce((acc, val) => {
      /* eslint-disable max-len */
      return `${acc}${encodeURIComponent(val.title ?? '')}${val.title ? JUMP_LINE : ''}[Document link]%20(link expires ${expiresAt})${JUMP_LINE + JUMP_LINE}`
    }, '')

    formattedBody = formattedBody.replace('[Attachments]', checkIfShouldAddNewlines(formattedBody, '[Attachments]') ? `\n${links}\n` : links);
  }
  formattedBody = formattedBody ? formattedBody.replace(/\n/g, JUMP_LINE) : '';

  await sendEmail(formattedCC, formattedBCC, formattedBody, toast, subject);
}

/**
 * this function opens the email client
 * @param cc email cc
 * @param bcc email bcc
 * @param body content of the email
 * @param toast toast actions
 * @param subject email subject
 */
const sendEmail = async (cc: string, bcc: string, body: string, toast: ToastActions, subject?: string) => {
  // [NOTE] - Only body has been partially encoded up until this point
  //        - We encode subject since it's likely to have more chance for special characters
  //        - We should probably encode cc and bcc as well, but leave this out for now
  //        - It's probably safe to decode everything then re-encode everything, but hold off on that for now
  const encodedSubject = encodeReservedURICharacters(subject ?? '')
  const mailtoURI = generateMailtoURI(
    cc,
    bcc,
    encodedSubject,
    body,
  )

  if (!isSafari) {
    const mail = document.createElement('a');
    mail.href = mailtoURI;
    mail.setAttribute('data-testid', 'emulated-email-client')
    mail.target = '_blank';

    const isOverWindowsCharLimit = overWindowsCharLimit(
      cc,
      bcc,
      encodedSubject,
      body,
    )
    if (isWindows && isOverWindowsCharLimit) {
      const fallbackURI = generateMailtoURI(
        cc,
        bcc,
        encodedSubject,
        MAX_ENCODED_URI_LENGTH_PLACEHOLDER,
      )

      mail.href = fallbackURI
      await navigator.clipboard.writeText(decodeURIComponent(body))
    }

    mail.click();
  } else {
    // On safari due to privacy restrictions it does not allow us to auto-click a generated
    // mailto link from an async method
    await (new Promise<void>((resolve) => {
      let emailGeneratedToastId = '';
      const handleOpenEmailClick = () => {
        const mail = document.createElement('a');
        mail.href = mailtoURI;
        mail.setAttribute('data-testid', 'emulated-email-client')
        mail.target = '_blank';
        mail.click();
        toast.remove(emailGeneratedToastId)
        resolve()
      }

      emailGeneratedToastId = toast.add(
        <GenericToast
          title="Email generated"
          width={275}
          status="information"
          actionConfig={{ actionTitle: 'Open email', callback: handleOpenEmailClick }}
        />,
        ToastOrientations.TOP_RIGHT, false, 0);
    }))
  }
}

/**
  * This function copies a text to clipboard
  * @param toast: toast actions
  * @param clipboardText: string to copy to clipboard
  * @param successTitle: title to show on success toast defaults to 'Link copied, expires in 30 days.'
 */
async function copyToClipboard(
  toast: ToastActions,
  clipboardText: string,
  successTitle: string = 'Link copied, expires in 30 days.',
) {
  const toastWidth = 275
  const generatingToastId = toast.add(<GenericToast
    title="Generating Link"
    status="loading"
    width={toastWidth}
  />, ToastOrientations.TOP_RIGHT)

  const linkCopiedToast = (<GenericToast
    title={successTitle}
    status="success"
    width={toastWidth}
  />)

  navigator.clipboard.writeText(clipboardText).then(
    () => {
      toast.remove(generatingToastId)
      toast.add(linkCopiedToast, ToastOrientations.TOP_RIGHT);
    },
    () => {
      toast.remove(generatingToastId);
      let copyToastId = '';
      const handleCopyLink = () => {
        navigator.clipboard.writeText(clipboardText).then(() => {
          toast.remove(copyToastId);
          toast.add(linkCopiedToast, ToastOrientations.TOP_RIGHT);
        }, () => {
          toast.remove(copyToastId);
          toast.add(
            <GenericToast title="Unable to access clipboard." width={toastWidth} status="error" />,
            ToastOrientations.TOP_RIGHT,
          )
        })
      }
      copyToastId = toast.add(
        <GenericToast
          title="Link generated."
          width={toastWidth}
          status="information"
          actionConfig={{ actionTitle: 'Copy link', callback: handleCopyLink }}
        />,
        ToastOrientations.TOP_RIGHT, false, 0);
    },
  )
}

/**
  * This function copies the document page url to clipboard
  * @param toast: toast actions
  * @param documentId: document id
  * @returns document page url
*/
const copyDocPageUrlHandler = async (
  toast: ToastActions,
  documentId: string) => {
  const path = ROUTES.BEACON_COPY_LINK
    .PATH.replace(':docId?', documentId)
    .replace(':page?', '1')

  await copyToClipboard(toast, `${window.location.origin}${path}`, 'Link copied')

  analytics?.track('SHARE_PUBLISHER_COPY_LINK', {
    action: 'COPY_LINK',
    category: 'DOCUMENT',
    documentId,
  })
}

/**
 * this function returns the body text given a template and list of links
 * @param emailTemplate the email template to copy
 * @param fileLinks document or hub share link
 * @param attachmentLinks attached document share link
 * @param encodeReservedChar boolean indicating if there are reserved character in the subject or body
 * @returns email body text
 */
function getBodyText(
  emailTemplate: EmailTemplate,
  fileLinks: string,
  attachmentLinks: string,
  encodeReservedChar: boolean = false,
): string {
  // [TODO-BEAC-6623]
  // [NOTE] - We do this character encoding/replacement as early as possible because other code will encode/manipulate in other places
  //        - This should not affect those modifiers
  //        - This should only be used for the mailto URI, if we encode for clipboard copy, it will not decode in the email client
  let bodyText = emailTemplate.body!

  if (encodeReservedChar) {
    bodyText = encodeReservedURICharacters(bodyText)
  }

  bodyText = bodyText
    .replace(
      '[Files]',
      checkIfShouldAddNewlines(emailTemplate.body!, '[Files]')
        ? `\n${fileLinks}\n`
        : fileLinks,
    )
    .replace(
      '[Attachments]',
      checkIfShouldAddNewlines(emailTemplate.body!, '[Attachments]')
        ? `\n${attachmentLinks}\n`
        : attachmentLinks,
    );

  return bodyText
}

const overWindowsCharLimit = (
  cc: string,
  bcc: string,
  subject: string,
  body: string,
): boolean => {
  // Since we're not sure if everything has been fully encoded correctly by this point ...
  // Decode everything, then re-encode everything to get the most accurate character count

  const encodedURI = generateMailtoURI(
    encodeURIComponent(decodeURIComponent(cc)),
    encodeURIComponent(decodeURIComponent(bcc)),
    encodeURIComponent(decodeURIComponent(subject)),
    encodeURIComponent(decodeURIComponent(body)),
  )

  logger.email.debug('Encoded Email Template', { encodedURI, length: encodedURI.length })

  // Technically we should be safe at WINDOWS_MAX_ENCODED_URI_LENGTH but maybe add some padding just to be safe
  // Encoded characters take a lot of space up anyways so we're not really losing many characters here
  return encodedURI.length > (WINDOWS_MAX_ENCODED_URI_LENGTH - WINDOWS_MAX_ENCODED_URI_LENGTH_PADDING)
}

/**
 * Calculates the number of days from the given date to today.
 *
 * @param {string} dateString - The date string in ISO format (YYYY-MM-DD) to compare with today.
 * @returns {number} The number of days from the given date to today.
 *                  A positive number indicates days in the past,
 *                  while a negative number indicates days in the future.
 */
const calculateRemainingDaysFromDate = (dateString: string): number => {
  const today = new Date();
  const expiresAt = new Date(dateString);
  const differenceInTime =  expiresAt.getTime() - today.getTime();
  return Math.round(differenceInTime / (1000 * 3600 * 24)) + 1;
}

export {
  generateAndCopyLinkToClipboard,
  copyExistingLinkToClipboard,
  copyDocPageUrlHandler,
  shareEmailGenerateLink,
  shareEmailExistingLinks,
  generateLinksAndcopyEmailTemplateToClipboard,
  copyExistingEmailTemplateToClipboard,
  shareEmailTemplateGenerateLinks,
  shareEmailTemplateCopyExistingLinks,
  emailTemplatePreview,
}
