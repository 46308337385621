import {
  CustomDeck,
  CustomDeckGroup,
  CustomDeckPage,
  User,
  Notation,
  UserNotationsType,
} from '@alucio/aws-beacon-amplify/src/models';

import { AnyAction, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { GetThunkAPI } from '@reduxjs/toolkit/dist/createAsyncThunk'
import addDays from 'date-fns/addDays'
import isPast from 'date-fns/isPast'
import { generateClient } from 'aws-amplify/api';
import {
  CustomDeckGroupORM,
  CustomDeckORM,
  DocumentVersionORM,
  FolderItemORM,
  FolderORM,
  PageMapping,
  VERSION_UPDATE_STATUS,
} from 'src/types/types';
import * as logger from 'src/utils/logger';

import { commonReducers, datastoreSave, initialState, SliceState, SliceStatus } from './common';
import {
  lockCustomDeck as lockCustomDeckMutation,
  updateCustomDeckLambda,
} from '@alucio/aws-beacon-amplify/src/graphql/mutations';
import sharedFolderSlice from './sharedFolder'
import { OmitDeep } from '@alucio/awshelpers/src/utils';
import { DNAModalActions } from './DNAModal/DNAModal';
import RemoveErrorMessage from 'src/components/DNA/Modal/DNAPresentationBuilder/RemoveErrorMessage';
import { getRootFolderORM } from '../selector/folder';
import { EDITOR_TYPE } from './PresentationBuilder/PresentationBuilder';
import type { RootState, AppDispatch } from 'src/state/redux';
import { pageMappingActions } from './pageMapping';
import { v4 as uuid } from 'uuid';
import {
  CreateUserNotationsPayload,
  userNotationsActions
} from './userNotations'
import { multiSliceActions } from './multiSlice'
import { MAPPING_NOTATION_AUTOUPGRADE_DEBUGGING_KEY } from 'src/state/redux/slice/pageMapping'
import { CustomDeckGroupLambdaInput, UpdateCustomDeckLambdaMutation } from '@alucio/aws-beacon-amplify/src/API';

type UpdateLambdaCustomDeckType = {
  customDeck: CustomDeck,
  groups: CustomDeckGroup[],
  title: string,
  folder: FolderORM,
  folderItem: FolderItemORM,
  currentUser: User,
}

type TargetCustomDeckUpdates = {
  ORM: CustomDeckORM,
  targetGroups: Record<string, CustomDeckGroupORM>
}

const processCustomDecksUpgrades = async (
  thunkAPI: GetThunkAPI<{ state: RootState, dispatch: AppDispatch }>,
  customDeckORMs: CustomDeckORM[],
  isAutoUpdate: boolean = false,
  gracePeriodDays?: number,
) => {
  const targetDocumentVersionORMs: Record<string, DocumentVersionORM> = {}
  const targetCustomDeckUpdates: TargetCustomDeckUpdates[] = []

  customDeckORMs.forEach((deck) => {
    if (deck.meta.version.updateStatus === VERSION_UPDATE_STATUS.CURRENT) return

    logger.customDeckSlice.debug(`Checking deck ${deck.model.id} for minor updates`)

    const targetUpdates: TargetCustomDeckUpdates = {
      ORM: deck,
      targetGroups: {},
    }

    // Identify Groups to update and DocumentVerions for fetching pages.json
    deck.meta.customDeckGroups.forEach((group) => {
      const isGroupMinorUpdate = group.meta.version.updateStatus === VERSION_UPDATE_STATUS.PENDING_MINOR
      if (!isGroupMinorUpdate) {
        return;
      }

      // Identify eligible items by inspecting the page
      group.pages.forEach((page) => {
        const isPageMinorUpdate = (
          page.documentVersionORM.meta.version.updateStatus === VERSION_UPDATE_STATUS.PENDING_MINOR
        )

        // Looks like we only update certain pages at a time based on the source document
        if (isPageMinorUpdate) {
          const docORM = page.documentVersionORM.relations.documentORM
          const latestVersion = docORM.relations.version.latestPublishedDocumentVersionORM!
          const exceedsGracePeriod = isPast(addDays(new Date(latestVersion.model.updatedAt), gracePeriodDays!))

          const shouldUpdatePage = (
            !isAutoUpdate ||
            (isAutoUpdate && exceedsGracePeriod)
          )

          if (shouldUpdatePage) {
            logger.customDeckSlice.debug(
              // eslint-disable-next-line max-len
              `Identified page that needs update: ${page.model.pageId} needs to be updated to doc ${latestVersion.model.id}`,
            )

            targetDocumentVersionORMs[latestVersion.model.id] = latestVersion
            targetUpdates.targetGroups[group.model.id] = group
          }
        }
      })
    })

    const hasEligibleGroups = Object.keys(targetUpdates.targetGroups).length
    if (hasEligibleGroups) {
      targetCustomDeckUpdates.push(targetUpdates)
    }
  })

  //  No eligible upgrades found
  if (!targetCustomDeckUpdates.length) {
    return;
  }

  await thunkAPI.dispatch(
    pageMappingActions
      .fetchPageMappings(Object.values(targetDocumentVersionORMs)),
  )
    .unwrap()
    .catch(err => logger.upgrade.folderCustomDecks.warn('Could not fetch mappings for CustomDeck upgrades', err))

  const pageMappingSlice = thunkAPI.getState().pageMapping
  const pageMappings = pageMappingSlice
    .records
    .reduce<Record<string, PageMapping>>(
      (acc, pageMapping) => {
        acc[pageMapping.documentVersionId] = pageMapping
        return acc
      },
      {},
    )
  const now = new Date().toISOString()

  const debugNotations: CreateUserNotationsPayload[] = []
  const debugCustomDeckPayload: Partial<CustomDeck>[] = []
  const ENABLE_MAPPING_NOTATION_AUTOUPGRADE_DEBUGGING = !!localStorage
    .getItem(MAPPING_NOTATION_AUTOUPGRADE_DEBUGGING_KEY)

  // Update identified CustomDeck (groups) and UserNotations
  targetCustomDeckUpdates.forEach((targetUpdate) => {
    const { ORM, targetGroups } = targetUpdate
    try {
      const upgradedGroupsPayload = ORM.meta.customDeckGroups.reduce<CustomDeckGroup[]>(
        (acc, groupORM) => {
          const isGroupUpgradeTarget = targetGroups[groupORM.model.id]
          const isPublisherGroup = groupORM.pages.length > 1

          // Keep existing group
          if (!isGroupUpgradeTarget) return [...acc, groupORM.model]

          // [NOTE] - Technically should only be groups of 1 page size at this point since we don't upgrade groups
          const upgradedPagesPayload = groupORM.pages
            .map<CustomDeckPage | undefined>((pageORM, pageIdx) => {
              const docORM = pageORM
                .documentVersionORM
                .relations
                .documentORM
              const latestDocVerId = docORM
                .relations
                .version
                .latestPublishedDocumentVersionORM
                ?.model
                .id!

              if (isPublisherGroup) {
                const targetGroupName = groupORM.model.name
                const prevGroup = pageORM
                  .documentVersionORM
                  .model
                  .pageGroups
                  ?.find(pageGroup => pageGroup.name === targetGroupName)

                // I don't think this should happen between any minor upgrades
                if (!prevGroup) {
                  return undefined
                }
                const newMappingPageId = prevGroup.pageIds?.at(pageIdx)
                if (!newMappingPageId) {
                  return undefined
                }

                const upgradedGroupPage: CustomDeckPage = {
                  pageId: newMappingPageId,
                  pageNumber: pageORM.model.pageNumber,
                  documentVersionId: latestDocVerId,
                }

                return upgradedGroupPage
              }

              if (!pageMappings || !pageMappings[latestDocVerId]) {
                // [TODO] - This should probably be a hard error
                throw new Error(
                  'May not have correctly fetched pages json correctly during customDeck upgrade - docVerId ' +
                  latestDocVerId,
                )
              }

              // const { unmatchedCount, prevPageIdMappings } = pageMappings[latestDocVerId]
              // const hasExplicitMapping = (unmatchedCount || Object.keys(prevPageIdMappings).length)
              const newMappingPageId = pageMappings[latestDocVerId].prevPageIdMappings[pageORM.model.pageId]
              const fallbackPageNumberId = `${latestDocVerId}_${pageORM.model.pageNumber}`
              const hasInvalidMapping = ([null, undefined, ''].includes(newMappingPageId))
              const isExplicictUnmatch = newMappingPageId === 'USER_UNMATCHED'

              if (isExplicictUnmatch) {
                return undefined;
              }

              const newPageId = !hasInvalidMapping
                ? newMappingPageId
                : fallbackPageNumberId

              const upgradedPage: CustomDeckPage = {
                pageId: newPageId,
                pageNumber: pageORM.model.pageNumber,
                documentVersionId: latestDocVerId,
              }

              return upgradedPage
            },
            )
            .filter<CustomDeckPage>((page): page is CustomDeckPage => !!page)

          // Filtered or mapped group
          if (upgradedPagesPayload.length) {
            const upgradedGroup: CustomDeckGroup = {
              ...groupORM.model,
              pages: upgradedPagesPayload,
            }

            return [...acc, upgradedGroup]
          }

          // Dropped group
          return acc
        },
        [],
      )

      const upgradedCustomDeckPayload: Partial<CustomDeck> = {
        ...ORM.model,
        groups: upgradedGroupsPayload,
        autoUpdateAcknowledgedAt: isAutoUpdate ? AUTO_UPDATE_DEFAULT_DATE : now,
        updatedAt: now,
      }

      // Save CustomDeck
      debugCustomDeckPayload.push(upgradedCustomDeckPayload)
      // [TODO] - You probably want to batch these with the notation upgrades so we avoid having potential partial updates if something goes wrong
      if (!ENABLE_MAPPING_NOTATION_AUTOUPGRADE_DEBUGGING) {
        datastoreSave<CustomDeck>(
          CustomDeck,
          ORM.model,
          upgradedCustomDeckPayload,
        )
      }

      logger.upgrade.folderCustomDecks.info(
        'Upgraded Custom Deck',
        ORM.model.id,
      )
    } catch (err) {
      logger.upgrade.folderCustomDecks.error(err)
      return;
    }

    // Upgrade Notations
    if (!ORM.relations.userNotations) return

    try {
      const userNotations = ORM
        .relations
        .userNotations

      const upgradedPageIds = debugCustomDeckPayload.reduce<Record<string, CustomDeckPage>>(
        (acc, customDeckPayload) => {
          customDeckPayload
            .groups
            ?.forEach(group => group.pages.forEach(page => { acc[page.pageId] = page }))

          return acc
        },
        {},
      )

      const newNotations = userNotations
        .notation
        .reduce<Notation[]>(
          (acc, notation) => {
            const [currentNotationDocId, , currentNotationPageNumber] = notation.pageId.split('_')
            const latestDocumentVersionId = Object
              .keys(targetDocumentVersionORMs)
              .find(id => id.includes(currentNotationDocId))

            // Not part of the upgrade group - keep the notation
            if (!latestDocumentVersionId) {
              return [...acc, notation]
            }

            // const { unmatchedCount, prevPageIdMappings } = pageMappings[latestDocumentVersionId]
            // const hasExplicitMapping = (unmatchedCount || Object.keys(prevPageIdMappings).length)

            const newPageMappingId = pageMappings[latestDocumentVersionId].prevPageIdMappings[notation.pageId]
            const hasInvalidMapping = ([null, undefined, ''].includes(newPageMappingId))
            const isActiveNotation = notation.status === 'ACTIVE'
            const pageNumberFallbackMappingId = `${latestDocumentVersionId}_${currentNotationPageNumber}`
            const isExplicictUnmatch = newPageMappingId === 'USER_UNMATCHED'

            if (!isActiveNotation) return acc

            // We drop the record for now anytime there is some explicit matching from the publisher
            if (isExplicictUnmatch) return acc

            // Otherwise we should have a valid mapping, but fallback if there's no explicit publisher mapping here
            const newPageId = !hasInvalidMapping
              ? newPageMappingId!
              : pageNumberFallbackMappingId

            // Only carry notations for pages that will be upgraded
            if (!upgradedPageIds[newPageId]) {
              return acc
            }

            const newNotation: Notation = {
              ...notation,
              id: uuid(),
              createdAt: now,
              updatedAt: now,
              pageId: newPageId,
            }

            return [...acc, newNotation]
          },
          [],
        )

      const newNotationPayload: CreateUserNotationsPayload = {
        customDeckId: ORM.model.id,
        notation: newNotations,
        type: UserNotationsType.CUSTOM_DECK,
      }

      // Dispatch (individual) Notations updates
      // [TODO] - We should probably try to batch customDeck upgrades and notation upgrades in serial
      //          So we don't end up with a partial udpate
      if (!ENABLE_MAPPING_NOTATION_AUTOUPGRADE_DEBUGGING) {
        thunkAPI.dispatch(userNotationsActions.createUserNotations(newNotationPayload))
      }
      debugNotations.push(newNotationPayload)

      logger.upgrade.folderCustomDecks.info(
        'Upgraded Notation for CustomDeck',
        ORM.model.id,
      )
    } catch (err) {
      logger.upgrade.folderCustomDecks.error(err)
      // [NOTE] - For QA testing, we want to see explicit errors so we can any potential bugs
      //          However, we can turn this off in prod if we would rather have silent no-ops
      throw new Error(err as any)
      // Uncomment return for silent no-op upgrade
      // return
    }
    // [TODO] - And any analytics (which the current implementation does not have)
  })

  // [NOTE] - This is only enabled for debugging purposes
  //        - Currently, this is disabled, this function will no-op!!
  if (
    (debugNotations.length || debugCustomDeckPayload.length) &&
    ENABLE_MAPPING_NOTATION_AUTOUPGRADE_DEBUGGING
  ) {
    await thunkAPI.dispatch(multiSliceActions.promptCustomDeckMigration({
      newNotations: debugNotations,
      newCustomDecks: debugCustomDeckPayload,
      customDeckORMs: targetCustomDeckUpdates.map(target => target.ORM),
      pageData: pageMappingSlice.debug,
    }))
  }
}

const lockCustomDeck = createAsyncThunk<
  { updatedObject: CustomDeck | undefined, currentUser: User },
  { customDeckId: string, timestarted: string, lock: boolean, currentUser: User }
>(
  'sharedFolder/lockCustomDeck',
  async (args) => {
    const { customDeckId, timestarted, lock, currentUser } = args

    const appsyncClient = generateClient();
    const { data } = await appsyncClient.graphql({
      query: lockCustomDeckMutation,
      variables: {
        customDeckId: customDeckId,
        timestarted: timestarted,
        lock: lock,
      },
    });
    return {
      updatedObject: data?.lockCustomDeck as CustomDeck,
      currentUser,
    }
  },
)

const updateCustomDeckByAPI = createAsyncThunk<
  { updatedObject: CustomDeck | undefined, currentUser: User },
  UpdateLambdaCustomDeckType
>(
  'sharedFolder/updateCustomDeckByAPI',
  async ({
    customDeck,
    groups,
    title,
    folder,
    folderItem,
    currentUser,
  }, thunkAPI) => {
    const updateObj = {
      ...customDeck,
      groups,
      title,
      autoUpdateAcknowledgedAt: new Date().toISOString(),
    };

    const { dispatch } = thunkAPI

    //  Update the title on the front end
    dispatch(
      sharedFolderSlice.actions.updateFolderItemTitle({
        id: folderItem.model.id,
        title: title,
      }),
    );
    let updatedObject: (UpdateCustomDeckLambdaMutation | undefined)
    try {
      // We need to send the parent folder to this call to make the validations, due to the element that is being shared
      const rootFolder = getRootFolderORM(folder) || folder;

      const appsyncClient = generateClient();
      const { data } = await appsyncClient.graphql({
        query: updateCustomDeckLambda,
        variables: {
          customDeck: {
            ...OmitDeep(updateObj, ['_version', '_deleted', '_lastChangedAt']),
            id: updateObj.id!!,
            groups: updateObj.groups.map((group) => {
              return { ...group } as CustomDeckGroupLambdaInput
            }),
          },
          folderId: folder.model.id,
          rootFolderId: rootFolder.model.id,
        },
      });

      updatedObject = data
    } catch (e) {
      const errors = [
        'Custom deck is REMOVED',
        'Custom deck is not shared with user',
        'User does not have permissions to edit this deck',
        'Custom deck is NOT_SHARED',
      ];
      // [TODO] - Handle the typing properly
      if (errors.includes((e as any).errors[0].message)) {
        dispatch(
          DNAModalActions.setModal({
            isVisible: true,
            allowBackdropCancel: true,
            // @ts-expect-error // Check how to pass the message to the component without change to tsx
            component: RemoveErrorMessage,
          }),
        );

        dispatch(customDeckSlice.actions.remove(customDeck));
      }
    }

    analytics?.track('CUSTOM_SAVE', {
      action: 'SAVE',
      category: 'CUSTOM',
      customDeckId: customDeck.id,
      editorType: EDITOR_TYPE.COLLABORATOR,
    });

    // [TODO]: Check why typescript doesnt allow to call this thunk directly
    dispatch(
      (customDeckActions.lockCustomDeck({
        customDeckId: customDeck.id,
        lock: false,
        timestarted: '',
        currentUser,
      }) as unknown) as AnyAction,
    );

    return {
      updatedObject: updatedObject?.updateCustomDeckLambda as CustomDeck,
      currentUser,
    };
  },
);

const upgradeCustomDecks = createAsyncThunk<
  void,
  { customDeckORMs: CustomDeckORM[] },
  { state: RootState, dispatch: AppDispatch }
>(
  'customDeck/upgradeCustomDecks',
  (payload, thunkAPI) => {
    const { customDeckORMs } = payload;
    const sharedCustomDeckORMs = customDeckORMs.filter((deck) => {
      return thunkAPI
        .getState()
        .customDeck
        .records
        .some((record) => record.id === deck.model.id)
    })

    processCustomDecksUpgrades(thunkAPI, sharedCustomDeckORMs)
  },
)

const autoUpgradeCustomDecks = createAsyncThunk<
  void,
  {
    customDeckORMs: CustomDeckORM[],
    gracePeriodDays: number,
  },
  { state: RootState, dispatch: AppDispatch }
>(
  'customDeck/autoUpgradeCustomDecks',
  (payload, thunkAPI) => {
    const { customDeckORMs, gracePeriodDays } = payload;
    const filteredCustomDeckORMs = customDeckORMs.filter((deck) => {
      return thunkAPI
        .getState()
        .customDeck
        .records
        .some((record) => record.id === deck.model.id)
    })

    processCustomDecksUpgrades(thunkAPI, filteredCustomDeckORMs, true, gracePeriodDays)
  },
)

const sliceName = 'customDeck';
const { reducers, extraReducersBuilder } = commonReducers<CustomDeck>(sliceName)

export const AUTO_UPDATE_DEFAULT_DATE = '1900-01-01T00:00:00.000Z'

export const customDeckSlice = createSlice({
  name: sliceName,
  initialState: initialState<CustomDeck>(),
  reducers: {
    ...reducers,
    acknowledgeAutoUpdate: {
      prepare: (customDeckORMs: CustomDeckORM[]) => {
        return {
          payload: {
            customDeckORMs,
          },
        }
      },
      reducer: (
        state: SliceState<CustomDeck>,
        action: PayloadAction<{
          customDeckORMs: CustomDeckORM[],
        }>,
      ) => {
        const now = new Date().toISOString();
        const { customDeckORMs } = action.payload;

        customDeckORMs.forEach(customDeckORM => {
          datastoreSave<CustomDeck>(CustomDeck, customDeckORM.model, {
            autoUpdateAcknowledgedAt: now,
            updatedAt: now,
          })

          // TODO if analytics is required, it should go here
        })
      },
    },
  },
  extraReducers: (builder) => {
    builder.addCase(lockCustomDeck.fulfilled, (state, { payload }) => {
      state.status = SliceStatus.OK;
      state.hydrated = true;

      if (payload.updatedObject?.createdBy !== payload.currentUser.id) {
        state.records = state.records.filter(customDeck => customDeck.id !== payload.updatedObject?.id)
        state.records.push(payload.updatedObject!)
      }
    })
    builder.addCase(lockCustomDeck.rejected, (state) => {
      state.status = SliceStatus.ERROR;
    })

    builder.addCase(updateCustomDeckByAPI.fulfilled, (state, { payload }) => {
      state.status = SliceStatus.OK;
      if (payload.updatedObject?.createdBy !== payload.currentUser.id) {
        state.records = state.records.filter(customDeck => customDeck.id !== payload.updatedObject!.id)
        state.records.push(payload.updatedObject!)
      }
    })
    builder.addCase(updateCustomDeckByAPI.rejected, (state) => {
      state.status = SliceStatus.ERROR;
    })

    builder.addCase(upgradeCustomDecks.fulfilled, (state) => {
      state.status = SliceStatus.OK
    })
    builder.addCase(upgradeCustomDecks.rejected, (state) => {
      state.status = SliceStatus.ERROR
      state.errorStatus = { error: 'Failed upgrade upgradeCustomDecks' }
    })

    builder.addCase(autoUpgradeCustomDecks.fulfilled, (state) => {
      state.status = SliceStatus.OK
    })
    builder.addCase(autoUpgradeCustomDecks.rejected, (state) => {
      state.status = SliceStatus.ERROR
      state.errorStatus = { error: 'Failed to autoUpgradeCustomDecks' }
    })

    extraReducersBuilder(builder)
  },
});

export default customDeckSlice;
export const customDeckActions = {
  lockCustomDeck,
  updateCustomDeckByAPI,
  upgradeCustomDecks,
  autoUpgradeCustomDecks,
  ...customDeckSlice.actions,
}
