import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { commonReducers, initialState, SliceStatus } from './common'
import { IntegrationSettings } from '@alucio/aws-beacon-amplify/src/models'
import { generateClient } from 'aws-amplify/api';
import {
  getAvailableIntegrationsByCognitoUser,
  listIntegrationSettings
} from '@alucio/aws-beacon-amplify/src/graphql/queries'
import {
  GetAvailableIntegrationsByCognitoUserQuery,
  ListIntegrationSettingsQuery
} from '@alucio/aws-beacon-amplify/src/API';
const sliceName = 'integrationSetting'
const { reducers, extraReducers, asyncActions } = commonReducers<IntegrationSettings>(sliceName)

const getIntegrationsAction = createAsyncThunk(
  'integrationSettings/getIntegrationSettings',
  async (tenantId: string) => {
    const appsyncClient = generateClient<ListIntegrationSettingsQuery>();
    const result = await appsyncClient.graphql({
      query: listIntegrationSettings,
      variables: {
        filter: { tenantId: { eq: tenantId } },
      },
    });
    return result.data.listIntegrationSettings.items || [];
  },
)

const getIntegrationsByCognitoAction = createAsyncThunk(
  'integrationSettings/getIntegrationsByCognitoAction',
  async () => {
    const appSyncClient = generateClient<GetAvailableIntegrationsByCognitoUserQuery>()
    const { data } = await appSyncClient.graphql(
      {
        query: getAvailableIntegrationsByCognitoUser,
      });
    return data.getAvailableIntegrationsByCognitoUser || [];
  },
)

const integrationSettingsSlice = createSlice({
  name: sliceName,
  initialState: initialState<IntegrationSettings>(),
  reducers: {
    ...reducers,
    disable: {
      prepare: (integrationSettings: IntegrationSettings) => {
        return {
          payload: {
            model: IntegrationSettings,
            entity: integrationSettings,
            updates: { enabled: false },
          },
        }
      },
      reducer: reducers.save,
    },
  },
  extraReducers: {
    [getIntegrationsAction.fulfilled.toString()]: (state, resp) => {
      state.status = SliceStatus.OK;
      state.hydrated = true;
      state.records = resp.payload;
    },
    [getIntegrationsAction.rejected.toString()]: (state) => {
      state.status = SliceStatus.ERROR;
    },
    [getIntegrationsByCognitoAction.fulfilled.toString()]: (state, resp) => {
      state.status = SliceStatus.OK;
      state.hydrated = true;
      state.records = resp.payload;
    },
    [getIntegrationsByCognitoAction.rejected.toString()]: (state) => {
      state.status = SliceStatus.ERROR;
    },
    ...extraReducers,
  },
})

export default integrationSettingsSlice
export const integrationSettingsActions = {
  ...integrationSettingsSlice.actions,
  ...asyncActions,
  getIntegrationsAction,
  getIntegrationsByCognitoAction,
}
