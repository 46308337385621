import {
  generateTokenForContentAccess,
  getRefreshedContentAccessToken,
} from '@alucio/aws-beacon-amplify/src/graphql/queries';
import { getDocPath } from 'src/components/ContentPreviewModal/ContentPreview/IFrame/IFrame.web';
import { DocumentVersionORM } from 'src/types/orms';
import { useAppSettings } from 'src/state/context/AppSettings';
import { useAuthTokens } from 'src/state/redux/selector/authToken';
import authTokenSlice, { AccessTokenDataExtended } from 'src/state/redux/slice/authToken';
import { store } from 'src/state/redux';
import graphqlClient from 'src/utils/graphqlClient';

const LOCAL_STORAGE_TOKEN_TIME = parseInt(localStorage.getItem('content-access-token-expiration-time') || '', 10) || 0;
// IF THE PROVIDED EXPIRATION TIME IS LONGER THAN AN HOUR AND LESS THAN 5 MINUTES, AN HOUR WILL BE USED
const DEFAULT_TOKEN_TIME =
  (LOCAL_STORAGE_TOKEN_TIME > 60 || LOCAL_STORAGE_TOKEN_TIME < 5 ? 60 : LOCAL_STORAGE_TOKEN_TIME) * 60;

interface GetTokenResponse {
  accessToken: string,
  expirationTimeInSeconds: number,
}

const useTokenHandler = () => {
  const { isOnline } = useAppSettings();
  const authTokens = useAuthTokens();

  async function getExistingToken(tokenRecord: AccessTokenDataExtended): Promise<GetTokenResponse> {
    const now = new Date();
    const tokenExpirationInSeconds = (new Date(tokenRecord.accessTokenExpire).getTime() - now.getTime()) / 1000;

    // WE'LL USE THE SAME TOKEN IF IT'S VALID FOR AT LEAST 10 MIN
    if (tokenExpirationInSeconds > 600) {
      return {
        accessToken: tokenRecord.accessToken,
        expirationTimeInSeconds: tokenExpirationInSeconds,
      };
    }

    // IF IT'S NOT VALID, CALLS THE LAMBDA TO REFRESH THE TOKEN
    // [TODO] - Use proper error handling here for `gracefulQuery`
    const response = await graphqlClient.gracefulQuery({
      query: getRefreshedContentAccessToken,
      variables: {
        expiredToken: tokenRecord.accessToken,
        durationSeconds: DEFAULT_TOKEN_TIME
      },
    });
    const accessToken = response.data?.getRefreshedContentAccessToken?.token ?? '';
    const expiresAt = response.data?.getRefreshedContentAccessToken?.expiresAt ?? '';

    // UPDATE THE REDUX SLICE WITH THE NEW TOKEN
    store.dispatch(authTokenSlice.actions.upsert(
      authTokens.map((authTokenRecord) => {
        if (authTokenRecord.documentVersionId === tokenRecord.documentVersionId) {
          authTokenRecord.accessToken = accessToken;
          authTokenRecord.accessTokenExpire = expiresAt
        }
        return authTokenRecord;
      }),
    ));

    return {
      accessToken,
      expirationTimeInSeconds: DEFAULT_TOKEN_TIME,
    }
  }

  async function getAccessToken(documentVersionORM: DocumentVersionORM): Promise<GetTokenResponse> {
    const docPath = getDocPath(documentVersionORM, isOnline);
    const tokenRecord = authTokens.find(({ documentVersionId }) => documentVersionId === documentVersionORM?.model.id);

    // IF THERE'S A TOKEN RECORD, IT MEANS THE DOCUMENT MUST BE OUTSIDE OF USER'S DOCUMENTS
    if (tokenRecord?.accessToken) {
      return getExistingToken(tokenRecord);
    }

    // OTHERWISE, REQUEST A TOKEN FOR THE GIVEN DOCUMENTVERSIONORM
    // [TODO] - Use proper error handling here for `gracefulQuery`
    const response = await graphqlClient.gracefulQuery({
      query: generateTokenForContentAccess,
      variables: {
        documentId: documentVersionORM.relations.documentORM.model.id,
        documentVersionId: documentVersionORM.model.id,
        authorizedPath: docPath,
        durationSeconds: DEFAULT_TOKEN_TIME,
      },
    });

    const accessToken = response.data?.generateTokenForContentAccess?.token ?? ''
    const expiresAt = response.data?.generateTokenForContentAccess?.expiresAt ?? ''

    // GIVEN THAT WE DON'T HAVE A TOKEN FOR THE CURRENT DOCUMENT VERSION WE'LL TAKE
    // ADVANTAGE OF THE REDUX STORE TO STORE IT. THIS WAY, WE USE IT IF NECESSARY INSTEAD OF
    // FETCHING A NEW ONE
    store.dispatch(authTokenSlice.actions.upsert([...authTokens, new AccessTokenDataExtended({
      documentVersionId: documentVersionORM.model.id,
      accessToken,
      accessTokenExpire: expiresAt,
    })]));

    return {
      accessToken,
      expirationTimeInSeconds: DEFAULT_TOKEN_TIME,
    }
  }

  return {
    getAccessToken,
  };
};

export default useTokenHandler;
