import { FolderItemType, CustomDeck, FolderItemStatus } from '@alucio/aws-beacon-amplify/src/models';
import { DataStore } from 'aws-amplify/datastore';
import { FolderItemORM, FolderORM } from 'src/types/orms';
import { isCustomDeckORM } from 'src/types/typeguards';

type HandleDuplicateItemsParams = {
  targetFolderORM: FolderORM
  itemToDupe: FolderItemORM
};

const uniqBy = (arr: FolderItemORM[], fn) =>
  [
    ...new Map(
      arr
        .reverse()
        .map((x) => [typeof fn === 'function' ? fn(x) : x[fn], x]),
    ).values(),
  ].reverse();

export async function handleDuplicateItems({ targetFolderORM, itemToDupe }: HandleDuplicateItemsParams) {
  const targetFolderItems = targetFolderORM.relations.items
  const itemToDupeCurrentTitle = itemToDupe.meta.title

  /** Check for 'Copy' & number at the end of the title */
  const titleSuffix = itemToDupeCurrentTitle?.split(' ').slice(-2)
  const titleSuffixStr = titleSuffix?.join(' ') || ''
  const titleIsCopy = titleSuffix.length > 1
    ? (titleSuffix?.[0] === 'Copy') && (titleSuffix?.[1].match(/^-?\d+$/))
    : false

  const titleRoot = titleIsCopy
    ? itemToDupeCurrentTitle?.substring(0, itemToDupeCurrentTitle.length - titleSuffixStr?.length - 1)
    : itemToDupeCurrentTitle

  /** Identify the existing folderitems with duplicate titles */
  const existingDupes = targetFolderItems
    .filter(folderItemORM => {
      return (
        itemToDupeCurrentTitle &&
        folderItemORM.meta.title.includes(titleRoot) &&
        folderItemORM.model.type === itemToDupe.model.type &&
        folderItemORM.meta.title !== titleRoot &&
        folderItemORM.model.status !== FolderItemStatus.REMOVED
      )
    })
    .sort((a, b) => {
      const aCopyNumber = parseInt(a.meta.title.split(' ').pop() || '0', 10)
      const bCopyNumber = parseInt(b.meta.title.split(' ').pop() || '0', 10)
      return aCopyNumber - bCopyNumber
    })

  const uniqueDupes: FolderItemORM[] = uniqBy(
    existingDupes,
    ({ meta }) => meta.title,
  )

  /** Prune out duplicates past a gap in the sequence number */
  const prunedDupes = uniqueDupes.filter((dupe, idx) => {
    const dupeNumber = parseInt(dupe.meta.title.split(' ').pop() || '0', 10)
    return dupeNumber === idx + 1
  })

  /** Next number in the sequence should be right after the gap we identified above */
  const sequenceNumber = prunedDupes.length + 1;
  let basedCustomDeck
  const itemId = itemToDupe.model.itemId
  if (itemToDupe.model.type === FolderItemType.CUSTOM_DECK) {
    basedCustomDeck = await DataStore.query(
      CustomDeck,
      itemToDupe.model.itemId,
    );
    // in case we are duplicating a custom deck that does
    // not belong to the current user
    if (!basedCustomDeck) {
      basedCustomDeck = isCustomDeckORM(itemToDupe.relations.itemORM) ? itemToDupe.relations.itemORM.model : undefined
      if (!basedCustomDeck) {
        throw new Error(
          `Could not find custom deck with id ${itemToDupe.model.itemId}`,
        );
      }
      basedCustomDeck = { ...basedCustomDeck }
    }
  }

  return {
    prunedDupes,
    sequenceNumber,
    itemId,
    basedCustomDeck,
  };
}
