/**
 * These type guards are useful for identifying the type of data we are working with
 * which, in turn, is used to help enforce type safety.
 *
 * When structuring a typeguard, the item passed in can be of unknown type and the return
 * value is a boolean representing the EVALUATION of the data as a particular type.
 *
 * Note the use of 'as' to assert the type of the unknown item being passed in ONLY for the evaluation
 * here so that the expected properties can be accessed without the compiler complaining.
 * */

import {
  CustomDeckORM,
  FolderItemORM,
  FolderORM,
  ORMTypes,
  DocumentORM,
  DocumentVersionORM,
  PageGroupORM,
  HubORM,
  AssociatedFileORM,
  MeetingORM,
} from './types';
import { AssociatedFile, Document } from '@alucio/aws-beacon-amplify/src/models';
import { SalesforceFirstSubmitPayload } from '../classes/CRM/CRMIndexedDBTypes';
import { ObjectWithId } from '../components/CustomFields/ComposableFormUtilities';

const HTML_WEB_VIDEO_FILE_TYPE =  ['ZIP', 'HTML', 'WEB', 'MP4']

export const isCustomDeckORM = (item: unknown): item is CustomDeckORM => item
  ? (item as CustomDeckORM).type === ORMTypes.CUSTOM_DECK
  : false

export const isDocumentORM = (item: unknown): item is DocumentORM => item

  ? (item as DocumentORM).type === ORMTypes.DOCUMENT
  : false

export const isDocumentVersionORM = (item: unknown): item is DocumentVersionORM => item
  ? (item as DocumentVersionORM).type === ORMTypes.DOCUMENT_VERSION
  : false

export const isWebHtmlOrVideoDocumentVersionORM = (item: unknown): item is DocumentVersionORM => item
  ? (item as DocumentVersionORM).type === ORMTypes.DOCUMENT_VERSION &&
    HTML_WEB_VIDEO_FILE_TYPE.includes((item as DocumentVersionORM).model.type)
  : false

export const isMeetingORM = (item: unknown): item is MeetingORM => item
  ? (item as MeetingORM).type === ORMTypes.MEETING
  : false

export const isAssociatedFileORM = (item: unknown): item is AssociatedFileORM => item
  ? (item as AssociatedFileORM).type === ORMTypes.ASSOCIATED_FILE
  : false

export const isFolderORM = (item: unknown): item is FolderORM => item
  ? (item as FolderORM).type === ORMTypes.FOLDER
  : false

export const isAssociatedFileAttachment = (item: unknown): item is AssociatedFile => item
  ? (item as AssociatedFile).type === 'ATTACHED_FILE'
  : false

export const isPublishedDocumentAttachment = (item: unknown): item is Document => item
  ? (item as Document).status === 'PUBLISHED'
  : false

export const isFolderItemORM = (item: unknown): item is FolderItemORM => item
  ? (item as FolderItemORM).type === ORMTypes.FOLDER_ITEM
  : false

export const isPageGroupORM = (item: unknown): item is PageGroupORM => item
  ? (item as PageGroupORM).type === ORMTypes.PAGE_GROUP
  : false

export const isSalesforceFirstSubmitPayload = (item: unknown): item is SalesforceFirstSubmitPayload =>
  Array.isArray((item as SalesforceFirstSubmitPayload)?.records) &&
  (item as SalesforceFirstSubmitPayload)?.records.every((record) => !!record.attributes)

export const isArrayOfObjectsWithIds = (items: unknown): items is ObjectWithId[] =>
  Array.isArray(items) && items.every((item) => !!(item as ObjectWithId).id)

export const isElement = (item: unknown): item is Element => {
  return item ? !!(item as Element).tagName : false
}

export const isHubORM = (item: unknown): item is HubORM => item
  ? (item as HubORM).type === ORMTypes.HUB
  : false
