import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DataStore } from 'aws-amplify/datastore';
import {
  CrmSyncStatus,
  CustomFormRecord,
  CustomFormRecordStatus,
} from '@alucio/aws-beacon-amplify/src/models';
import { commonReducers, initialState, SliceState } from './common';
import ActiveUser from '../../global/ActiveUser';
const sliceName = 'customFormRecord';
const { reducers, extraReducers } = commonReducers<CustomFormRecord>(sliceName);

export interface CreateCustomFormRecordPayload {
  draft: Pick<CustomFormRecord, 'values' | 'crmFields' | 'customFormId'
    | 'entity' | 'name' | 'parentId' | 'parentModel'>
  onSave?: (recordId: string) => void;
}

interface UpdateCustomFormRecordPayload extends CreateCustomFormRecordPayload {
  customFormRecord: CustomFormRecord;
}

interface UpdateCustomFormRecordFromCRMSubmit {
  status?: CustomFormRecordStatus;
  externalId?: string;
  syncStatus: CrmSyncStatus;
  customFormRecord: CustomFormRecord;
}

const customFormRecordSlice = createSlice({
  name: sliceName,
  initialState: initialState<CustomFormRecord>(),
  reducers: {
    ...reducers,
    createCustomFormRecord: (
      _state: SliceState<CustomFormRecord>,
      action: PayloadAction<CreateCustomFormRecordPayload>,
    ): void => {
      const { parentId, parentModel } = action.payload.draft;

      if (!ActiveUser.user) {
        throw new Error('Could not get current user')
      } else if (parentId && !parentModel) {
        throw new Error('Parent model is missing')
      } else if (parentModel && !parentId) {
        throw new Error('Parent id is missing')
      }

      const now = new Date().toISOString();
      DataStore.save(
        new CustomFormRecord({
          ...action.payload.draft,
          tenantId: ActiveUser.user.tenantId,
          status: CustomFormRecordStatus.ACTIVE,
          updatedAt: now,
          updatedBy: ActiveUser.user.id,
          createdAt: now,
          createdBy: ActiveUser.user.id,
        }),
      ).then((record) => action.payload.onSave?.(record.id));
    },
    updateCustomFormRecord: {
      prepare: (payload: UpdateCustomFormRecordPayload) => {
        const { customFormRecord, draft } = payload;
        return {
          payload: {
            model: CustomFormRecord,
            entity: customFormRecord,
            updates: {
              ...draft,
              updatedAt: (new Date()).toISOString(),
              updatedBy: ActiveUser?.user?.id,
            },
          },
        }
      },
      reducer: reducers.save,
    },
    updateFromCRMResponse: {
      prepare: (payload: UpdateCustomFormRecordFromCRMSubmit) => {
        const { customFormRecord, externalId, status, syncStatus } = payload;
        const now = (new Date()).toISOString();

        return {
          payload: {
            model: CustomFormRecord,
            entity: customFormRecord,
            updates: {
              status: status || customFormRecord.status,
              crmFields: {
                ...customFormRecord.crmFields!,
                externalId,
                syncStatus,
                lastSyncedAt: now,
              },
              updatedAt: now,
              updatedBy: ActiveUser?.user?.id,
            },
          },
        }
      },
      reducer: reducers.save,
    },
    deleteCustomFormRecord: {
      prepare: (customFormRecord: CustomFormRecord) => {
        return {
          payload: {
            model: CustomFormRecord,
            entity: customFormRecord,
            updates: {
              status: CustomFormRecordStatus.DELETED,
              updatedAt: (new Date()).toISOString(),
              updatedBy: ActiveUser?.user?.id,
            },
          },
        }
      },
      reducer: reducers.save,
    },
  },
  extraReducers,
});

export default customFormRecordSlice;
export const customFormRecordActions = customFormRecordSlice.actions;
