import React, { useState, createContext, useEffect, useContext, PropsWithChildren } from 'react'
import { useSafePromise } from '@alucio/lux-ui';
import { getEmbeddedUrl, getReportList } from '@alucio/aws-beacon-amplify/src/graphql/queries'
import { generateClient } from 'aws-amplify/api';
import { useUserTenant } from 'src/state/redux/selector/user';
import {
  CustomFieldUsage,
  Dashboard,
  FieldStatus, PermissionEffect, AvailableDashboards, ReportItem,
} from '@alucio/aws-beacon-amplify/src/models';
import useFeatureFlags from 'src/hooks/useFeatureFlags/useFeatureFlags';
import { useTenantCustomFields } from 'src/state/redux/selector/tenant';
import useIsPublisherView from 'src/hooks/useIsPublisherView/useIsPublisherView'
import { DashboardExperience } from 'amazon-quicksight-embedding-sdk';

const CUSTOM_HIDE_DOCUMENT_REPORTS = [
  {
    name: 'Share Activity',
    hiddenByBlockSharing: true,
    hiddedByBlockDownloading: false,
    hiddenPublisherByFutureFlag: false,
    hiddenSlidesReportsFeatureFlag: false,
    hiddenByShareCustomDeckFeatureFlag: false,
    hiddenByHubsFeatureFlag: false,
  },
  {
    name: 'Top 10 Shared Documents',
    hiddenByBlockSharing: true,
    hiddedByBlockDownloading: false,
    hiddenPublisherByFutureFlag: false,
    hiddenSlidesReportsFeatureFlag: false,
    hiddenByDisableCustomDeckUsage: false,
    hiddenByHubsFeatureFlag: false,
  },
  {
    name: 'Document Links Sent And Downloaded',
    hiddenByBlockSharing: true,
    hiddedByBlockDownloading: false,
    hiddenPublisherByFutureFlag: true,
    hiddenSlidesReportsFeatureFlag: false,
    hiddenByDisableCustomDeckUsage: false,
    hiddenByHubsFeatureFlag: false,
  },
  {
    name: 'All Content Downloads by Viewer',
    hiddenByBlockSharing: false,
    hiddedByBlockDownloading: true,
    hiddenPublisherByFutureFlag: false,
    hiddenSlidesReportsFeatureFlag: false,
    hiddenByDisableCustomDeckUsage: false,
    hiddenByHubsFeatureFlag: false,
  },
  {
    name:'Slide Views by Presentation',
    hiddenByBlockSharing: false,
    hiddedByBlockDownloading: false,
    hiddenPublisherByFutureFlag: false,
    hiddenSlidesReportsFeatureFlag: true,
    hiddenByDisableCustomDeckUsage: false,
    hiddenByHubsFeatureFlag: false,
  },
  {
    name:'Custom Deck Presented',
    hiddenByBlockSharing: false,
    hiddedByBlockDownloading: false,
    hiddenPublisherByFutureFlag: false,
    hiddenSlidesReportsFeatureFlag: false,
    hiddenByDisableCustomDeckUsage: true,
    hiddenByHubsFeatureFlag: false,
  },
  {
    name: 'Hub',
    hiddenByBlockSharing: false,
    hiddedByBlockDownloading: false,
    hiddenPublisherByFutureFlag: false,
    hiddenSlidesReportsFeatureFlag: false,
    hiddenByDisableCustomDeckUsage: false,
    hiddenByHubsFeatureFlag: true,
  },
]

type ReportProvider = {
  selectedDashboard: Dashboard | undefined,
  reportList: ReportItem[],
  onReportSelected: (report: ReportItem) => void,
  isLoadedReportsList: boolean,
  isLoadingReport: boolean,
  setIsLoadingReport: (isLoading: boolean) => void,
  selectedReport: ReportItem | undefined,
  setEmbeddableObject: (embeddableObject: DashboardExperience) => void,
  onSelectedSheetChange: (sheet) => void,
  folderName: string,
  isSharingBlocked: boolean,
  isDownloadingBlocked: boolean,
  onDashboardChange: (isTeamLeader: Boolean) => void,
  isTeamLeader: boolean,
  isTeamViewer: boolean,
  setIsTeamViewer: (isTeamViewer: boolean) => void,
}

const getEmbeddedUrlAsync = async (dashboardId: string, folderName: string, isPublisherMode: boolean) => {
  const appsyncClient = generateClient();
  const { data } = await appsyncClient.graphql({
    query: getEmbeddedUrl,
    variables: {
      dashboardId: dashboardId,
      folderName,
      isPublisherMode,
    },
  });
  if (!data.getEmbeddedUrl) {
    throw new Error('An error occurred getting the embedded URL report')
  }

  return data;
}

const getReportListAsync = async (folderName: string, isPublisherMode: boolean) => {
  const appsyncClient = generateClient();
  const { data } = await appsyncClient.graphql({
    query: getReportList,
    variables: {
      folderName,
      isPublisherMode,
    },
  });

  if (!data.getReportList) {
    throw new Error('No report list get from the service')
  }

  return {
    ...data.getReportList,
    dashboards: data.getReportList.dashboards
      .sort((a, b) => a.name.localeCompare(b.name)),
  };
}

const ReportContext = createContext<ReportProvider>(null!)

interface InitialValues {
  folderName: string
}

export const ReportProvider: React.FC<PropsWithChildren<InitialValues>> = (props) => {
  const { folderName } = props
  const [dashboards, setDashboards] = useState<AvailableDashboards>()
  const [selectedDashboard, setSelectedDashboard] = useState<Dashboard>()
  const safePromise = useSafePromise()
  const [isLoadingReport, setIsLoadingReport] = useState(false);
  const [embeddableObject, setEmbeddableObject] = useState<DashboardExperience>();
  const tenant = useUserTenant();
  const isSharingBlocked = tenant?.config?.defaultDocumentPermissions.share === PermissionEffect.BLOCK;
  const featureFlags = useFeatureFlags('hideSlideTrackingReport', 'publisherShareSentDownloadReport',
    'BEAC_6655_custom_deck_usage', 'enableHubs');
  const [selectedReport, setSelectedReport] = useState<ReportItem>()
  const documentCustomFields = useTenantCustomFields({ usages: { internalUsages: [CustomFieldUsage.DOCUMENT] } })
  const [reportList, setReportList] = useState<ReportItem[]>([])
  const [isTeamViewer, setIsTeamViewer] = useState(false)
  const isPublisherMode = useIsPublisherView()

  useEffect(() => {
    const listReports = async () => {
      try {
        const data = await safePromise.makeSafe(getReportListAsync(folderName, isPublisherMode))
        setReportsConfig(data)
      }
      catch (e) {
        console.warn(e)
      }
    }

    listReports()
  }, [])

  const setReportsConfig = (data: AvailableDashboards) => {
    const reports = data.dashboards.flatMap(dashboard => dashboard.reports)
    const filteredReportList = reports.filter(report => {
      const reportName = report?.name.trim().toLocaleLowerCase();
      const hiddenReport = CUSTOM_HIDE_DOCUMENT_REPORTS.find(r =>
        reportName.toLocaleLowerCase().includes(r.name.trim().toLocaleLowerCase()));
      if (isSharingBlocked && hiddenReport && hiddenReport.hiddenByBlockSharing) {
        return false;
      }
      if (hiddenReport && hiddenReport.hiddenPublisherByFutureFlag &&
        folderName.toLowerCase().includes('publisher') && !featureFlags.publisherShareSentDownloadReport) {
        return false;
      }
      if (isDownloadingBlocked && hiddenReport && hiddenReport.hiddedByBlockDownloading) {
        return false;
      }
      if (hiddenReport && hiddenReport.hiddenSlidesReportsFeatureFlag && featureFlags.hideSlideTrackingReport) {
        return false;
      }
      if (hiddenReport && hiddenReport.hiddenByDisableCustomDeckUsage &&
        !featureFlags.BEAC_6655_custom_deck_usage) {
        return false;
      }
      if (hiddenReport && hiddenReport.hiddenByHubsFeatureFlag && !featureFlags.enableHubs) {
        return false;
      }
      return true;
    }).sort((a, b) => a.name.localeCompare(b.name))

    setReportList(filteredReportList)
    const dashboard = data.dashboards[0]
    if (dashboard.dashboardUrl) {
      setSelectedReport(dashboard.reports[0])
      setSelectedDashboard(dashboard)
    }
    setDashboards(data)
  }

  const onDashboardChange = async (isTeamLeader: Boolean) => {
    setIsLoadingReport(true)
    setSelectedDashboard(undefined)
    const folder = !isTeamLeader ? folderName : 'Beacon_Embedded_Publisher'
    const data = await safePromise.makeSafe(getReportListAsync(folder, isPublisherMode))
    setReportsConfig(data)
  }

  const checkIfDownloadDocumentsIsBlocked = () => {
    const IsAnyDownloadValueActive = documentCustomFields
      .filter(q => q.status === FieldStatus.ENABLED).map(customField => customField.fieldValueDefinitions
        .filter(valueDefinition => valueDefinition.documentSettings?.permission?.download === PermissionEffect.ALLOW &&
          valueDefinition.disabled !== true)
        .map(valueDefinition => valueDefinition.id))
      .flat().length > 0;
    const isDownloadBlocked = tenant?.config?.defaultDocumentPermissions.download === PermissionEffect.BLOCK;
    return isDownloadBlocked || !IsAnyDownloadValueActive;
  }
  const isDownloadingBlocked = checkIfDownloadDocumentsIsBlocked();

  const setDefaultParameters = () => {
    const createISODate = (month, date) => {
      const d = new Date();
      d.setMonth(month);
      d.setDate(date);
      return d.toISOString();
    };
    const parameters = [
      { Name: 'startdate', Values: [createISODate(0, 1)] },
      { Name: 'enddate', Values: [createISODate(11, 31)] },
      { Name: 'userLabelKey', Values: [] },
      { Name: 'documentLabel', Values: [] },
      { Name: 'userRole', Values: ['Viewer'] },
      { Name: 'meetingType', Values: ['ALL_VALUES'] },
      { Name: 'lastUsableVersion', Values: ['ALL_VALUES'] },
      { Name: 'semVersion', Values: ['ALL_VALUES'] },
      { Name: 'documentVersionTitle', Values: ['ALL_VALUES'] },
      { Name: 'userEmail', Values: ['ALL_VALUES'] },
      { Name: 'documentStatus', Values: ['ALL_VALUES'] },
      { Name: 'deviceType', Values: ['ALL_VALUES'] },
      { Name: 'documentLabelKeyNotAll', Values: ['-- Select --'] },
      { Name: 'documentLabelValue', Values: ['ALL_VALUES'] },
      { Name: 'documentStatus', Values: ['ALL_VALUES'] },
      { Name: 'documentStatusSingle', Values: ['ALL_VALUES'] },
      { Name: 'documentVersionTitle', Values: ['ALL_VALUES'] },
      { Name: 'meetingType', Values: ['ALL_VALUES'] },
      { Name: 'recipient', Values: ['ALL_VALUES'] },
      { Name: 'semVersion', Values: ['ALL_VALUES'] },
      { Name: 'userEmail', Values: ['ALL_VALUES'] },
      { Name: 'userLabelValue', Values: ['ALL_VALUES'] },
      { Name: 'startDate', Values: [createISODate(0, 1)] },
    ];
    embeddableObject?.setParameters(parameters);
  };

  const onSelectedSheetChange = async (selectedSheet) => {
    if (selectedSheet) {
      const sheetName = (selectedSheet.Name || selectedSheet.name)?.split('-')[1]
      const report = selectedDashboard?.reports.find(report => report.name === sheetName)
      setSelectedReport(report)
    }
  }

  const onReportSelected = async (report: ReportItem) => {
    // check if the report is one of the tabs in the dashboard
    const folder = !isTeamViewer ? folderName : 'Beacon_Embedded_Publisher'
    const sheet = selectedDashboard?.dashboardSheets.find(sheet => sheet.name.split('-')[1].toLowerCase() ===
      report.name.toLocaleLowerCase())
    if (sheet) {
      // if the report is one of the tabs in the dashboard, set the selected sheet
      onSelectedSheetChange(sheet)
      embeddableObject?.setSelectedSheetId(sheet.sheetId)
      setDefaultParameters()
    }
    else {
      setIsLoadingReport(true)
      setSelectedDashboard(undefined)
      const newDashboard = dashboards?.dashboards.find(dashboard => dashboard.reports.find(r => r.name === report.name))
      if (!newDashboard) {
        setIsLoadingReport(false)
        setSelectedDashboard(dashboards?.dashboards[0])
        return
      }
      setSelectedReport(report)
      const embeddedUrl = await safePromise.makeSafe(getEmbeddedUrlAsync(newDashboard?.dashboardId, folder,
        isPublisherMode))
      setSelectedDashboard({ ...newDashboard, dashboardUrl: embeddedUrl.getEmbeddedUrl || '' })
    }
  }

  const contextValues = {
    selectedDashboard,
    reportList,
    onReportSelected,
    isLoadedReportsList: !!selectedDashboard,
    isLoadingReport,
    setIsLoadingReport,
    selectedReport,
    setEmbeddableObject,
    onSelectedSheetChange,
    folderName,
    isSharingBlocked,
    isDownloadingBlocked,
    onDashboardChange,
    isTeamLeader: dashboards?.isTeamLeader ?? false,
    isTeamViewer,
    setIsTeamViewer,
  }

  return (
    <ReportContext.Provider value={contextValues}>
      {props.children}
    </ReportContext.Provider>
  )
}

export const useReport = () => useContext(ReportContext)
