import React, { useMemo, useRef, useState } from 'react';
import { StyleSheet } from 'react-native';
import { DataStore } from 'aws-amplify/datastore';
import { DNABox, DNAButton, DNADivider, DNAText } from '@alucio/lux-ui';
import colors from '@alucio/lux-ui/lib/theming/themes/alucio/colors';
import { Tenant, CustomFieldUsage, QuickFilter } from '@alucio/aws-beacon-amplify/src/models';
import ActiveUser from 'src/state/global/ActiveUser';
import { TenantORM, useTenant, useTenantCustomFields } from 'src/state/redux/selector/tenant';
import { formatDataStorePayload } from 'src/state/redux/slice/common';
import UploadImageTenant from './UploadImageTenant';
import { ManagedFile } from 'src/components/DNA/FileUpload/FileUpload';

import { generateClient } from 'aws-amplify/api';
import { updateTenantLogo, updateZendeskOrg } from '@alucio/aws-beacon-amplify/src/graphql/mutations';
import isEqual from 'lodash/isEqual';
import omit from 'lodash/omit';

const styles = StyleSheet.create({
  actionsContainer: {
    paddingVertical: 20,
    paddingHorizontal: 16,
  },
  mainWrapper: {
    backgroundColor: colors['color-text-white'],
    overflowX: 'auto',
  },
});

interface ConfigurationProps {
  tenantId: string;
}

function removeInternalFields(tenantORM: TenantORM) {
  const tenant = { ...tenantORM.tenant };
  const internalFields = [
    'id', '_deleted', '_version', '_lastChangedAt', 'createdAt', 'createdBy',
    'updatedAt', 'updatedBy', 'statusChangedAt', 'statusChangedBy',
  ];
  return omit(tenant, internalFields);
}

const Configuration = (props: ConfigurationProps) => {
  const tenantORM = useTenant(props.tenantId);
  const jsonTenant = useMemo(() =>
    JSON.stringify(removeInternalFields(tenantORM!), undefined, 2),
  [tenantORM]);
  const [jsonConfig, setJsonConfig] = useState<string>(jsonTenant);
  const [error, setError] = useState<undefined | string>(undefined);
  const [isDirty, setIsDirty] = useState<boolean>(false)
  const logo = useRef<null | ManagedFile>(null);
  const initialTenantName = tenantORM?.tenant.name;
  const initialSsoDomains = tenantORM?.tenant.ssoDomains;
  const tenantFields = useTenantCustomFields({
    tenantId: props.tenantId, usages: { internalUsages: [CustomFieldUsage.DOCUMENT] },
  });

  const validateQuickFilters = (quickFilters: QuickFilter[]) => {
    for (let i = 0; i < quickFilters.length; i++) {
      const filter = quickFilters[i];
      if (!filter.id || !filter.label || !filter.path) {
        setError('QuickFilter Error: A QuickFilter must contain an id, label, and path')
        return false;
      }
      for (let j = 0; j < filter.path.length; j++) {
        if (filter.path.some((id) => {
          const field = tenantFields.find((field) => field.id === id);
          if (!field) {
            setError(`QuickFilter Error: custom field id: ${id} is not a valid id for path: ${filter.label}`)
            return true
          }
          else if (!(field.fieldType === 'MULTICATEGORICAL' || field.fieldType === 'CATEGORICAL')) {
            // eslint-disable-next-line max-len
            setError(`QuickFilter Error: custom field id: ${id}. ${field.fieldLabel} is not categorical nor multicategorical`)
            return true
          }
        })) {
          return false
        }
      }
    }
    return true
  }

  async function save(): Promise<void> {
    try {
      setError(undefined);
      const jsonParsed = JSON.parse(jsonConfig);
      const now = new Date().toISOString();

      try {
        if (jsonTenant !== jsonConfig) {
          let continueUpdate = true;
          // validate quickFilters
          if (jsonParsed.config.quickFilters && jsonParsed.config.quickFilters.length) {
            const isValidFilters = validateQuickFilters(jsonParsed.config.quickFilters);
            if (!isValidFilters) {
              continueUpdate = false;
            }
          }

          const nameUpdated = jsonParsed.name && jsonParsed.name !== initialTenantName;
          const domainsUpdated = jsonParsed.ssoDomains && !isEqual(jsonParsed.ssoDomains, initialSsoDomains);
          const shouldUpdateZendeskOrg = (nameUpdated || domainsUpdated) && continueUpdate;
          if (shouldUpdateZendeskOrg && tenantORM?.tenant) {
            const appsyncClient = generateClient();
            const res = await appsyncClient.graphql({
              query: updateZendeskOrg,
              variables: {
                input: {
                  tenantId: tenantORM.tenant.id,
                  tenantName: jsonParsed.name,
                  ssoDomains: jsonParsed.ssoDomains,
                },
              },
            });

            if (!res.data?.updateZendeskOrg) {
              setError('Error updating Zendesk Organization. Please try again');
              continueUpdate = false;
            }
          }
          // If the zendesk update was not successful, we will abort the rest of the tenant update
          if (continueUpdate) {
            const newStatus = jsonParsed.status !== tenantORM?.tenant.status;
            const payload = {
              ...jsonParsed,
              id: tenantORM?.tenant.id,
              updatedAt: now,
              // @ts-expect-error - This is an internal appsync only field that isn't typed
              _version: tenantORM?.tenant._version,
              statusChangedAt: newStatus ? now : tenantORM?.tenant.statusChangedAt,
              statusChangedBy: newStatus ? ActiveUser.user!.id : tenantORM?.tenant.statusChangedBy,
            };
            await DataStore.save(formatDataStorePayload(Tenant, tenantORM?.tenant!, payload));
          }
        }

        if (logo.current) {
          const appsyncClient = generateClient();
          await appsyncClient.graphql({
            query: updateTenantLogo,
            variables: {
              input: {
                tenantId: tenantORM?.tenant.id!,
                key: logo.current.key,
              },
            },
          });
          setIsDirty(false);
        }
      } catch (e) {
        setError('Invalid Tenant (definition error)');
      }
    } catch (e) {
      console.error(e);
      setError('Invalid JSON (syntax error)');
    }
  }

  function isFormDirty(initialInput: string, currentInput: string) {
    return initialInput !== currentInput
  }

  function onJSONChange(value): void {
    setIsDirty(isFormDirty(jsonTenant, value.target.value))
    setJsonConfig(value.target.value);
  }

  function onFinishHandler(file): void {
    logo.current = file;
    setIsDirty(true);
  }

  function removeImageHandler(): void {
    logo.current = null;
    if (jsonTenant === jsonConfig) {
      setIsDirty(false);
    }
  }

  const UploadImageTenantMemo = useMemo(() => (<UploadImageTenant
    tenantId={tenantORM?.tenant.id!}
    onFinishHandler={onFinishHandler}
    removeImage={removeImageHandler}
  />), [tenantORM?.tenant.id]);

  return (
    <DNABox style={styles.mainWrapper} fill appearance="col">
      <DNABox spacing="sm" alignX="end" alignY="center" style={styles.actionsContainer}>
        <DNAButton
          size="sm"
          status="primary"
          appearance="filled"
          onPress={save}
          disabled={!isDirty}
        >
          Save
        </DNAButton>
      </DNABox>
      <DNADivider />

      <DNABox style={{ marginHorizontal: 16, marginVertical: 32 }}>
        {UploadImageTenantMemo}
      </DNABox>

      <DNABox appearance="col" style={styles.actionsContainer} fill>
        {
          error &&
            <DNAText style={{ marginBottom: 10 }} status="danger">{error}</DNAText>
        }
        <textarea
          onChange={onJSONChange}
          placeholder="Add JSON code..."
          value={jsonConfig}
          style={{
            minHeight: 200,
            height: '100%',
            borderRadius: 4,
            borderColor: colors[error ? 'color-danger-500' : 'color-gray-100'],
          }}
        />
      </DNABox>
    </DNABox>
  );
};

Configuration.displayName = 'Configuration';

export default Configuration;
