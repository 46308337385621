import React, { useCallback, useRef, useEffect } from 'react';
import { useDispatch } from 'src/state/redux';
import { fetchAuthSession } from 'aws-amplify/auth';
import config from 'src/config/app.json';
import { useAppSettings, DeviceMode } from 'src/state/context/AppSettings';
import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal'
import TabletPreviewModal from 'src/screens/Hubs/EditHub/TabletPreviewModal';
import * as logger from 'src/utils/logger';

export default function usePreviewHub() {
  const openedWindows = useRef<Window[]>([])
  const dispatch = useDispatch()
  const { deviceMode } = useAppSettings()
  const isTabletMode = deviceMode === DeviceMode.tablet

  const getAuthToken = async () => {
    const currentSession = await fetchAuthSession()
    const idToken = currentSession.tokens?.idToken?.toString() || ''
    const expiredAt = currentSession.tokens?.idToken?.payload.exp || 0

    return { idToken, expiredAt }
  }

  const openPreviewWindow = useCallback(
    async (hubId: string, docVerId?: string) => {
      const base = config.contentURL
      const url = new URL(`/hubs/${hubId}`, base)

      url.searchParams.append('preview', 'true');
      if (docVerId) {
        url.searchParams.append('docVerId', docVerId);
      }

      if (isTabletMode) {
        logger.hub.hubManagement.debug(`Opening preview modal for hub: ${hubId}`)
        dispatch(DNAModalActions.setModal({
          isVisible: true,
          allowBackdropCancel: false,
          component: (props) =>
            (<TabletPreviewModal
              { ...props }
              url={url.toString()}
            />),
        }));
      } else {
        logger.hub.hubManagement.debug(`Opening preview window for hub: ${hubId}`)
        const newWindow = window.open(url.toString(), '_blank')
        if (newWindow) {
          openedWindows.current.push(newWindow)
        }
      }
    },
    [],
  )

  useEffect(
    () => {
      const listener = async (evt: MessageEvent) => {
        if (evt.origin === config.contentURL && evt.data === 'requestJWT') {
          const { idToken, expiredAt } = await getAuthToken()
          const source = evt.source as WindowProxy
          // [NOTE] - This seems like a bug on amplify's end where the unix date is off by a factor of 1000, what?
          // ECFLAG
          const payload = { expiredAt: expiredAt * 1000, idToken }
          source.postMessage(JSON.stringify(payload), config.contentURL)
        }
      }

      window.addEventListener('message', listener)

      // Clean up the event listeners when the component unmounts
      return () => {
        // close all the preview tabs
        window.removeEventListener('message', listener);
        openedWindows.current.forEach(windowObj => { windowObj.close() })
      }
    },
    [],
  )

  return { openPreviewWindow }
}
