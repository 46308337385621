import { batch } from 'react-redux';
import { DataStore, Predicates } from 'aws-amplify/datastore';
import { fetchUserAttributes, FetchUserAttributesOutput } from 'aws-amplify/auth';
import {
  AttachedFile,
  CustomDeck,
  CustomFormRecord,
  Document,
  DocumentVersion,
  EmailTemplate,
  EmailTemplateStatus,
  Folder,
  Hub,
  Meeting,
  Tenant,
  User,
  UserNotations,
  UserRole,
} from '@alucio/aws-beacon-amplify/src/models';

import { store } from 'src/state/redux';
import { attachedFileActions } from 'src/state/redux/slice/attachedFile';
import { customDeckActions } from 'src/state/redux/slice/customDeck';
import { customFormRecordActions } from 'src/state/redux/slice/customFormRecord';
import { documentActions } from 'src/state/redux/slice/document';
import { documentVersionActions } from 'src/state/redux/slice/documentVersion';
import { emailTemplateActions } from 'src/state/redux/slice/emailTemplate';
import { folderActions } from 'src/state/redux/slice/folder';
import { hubActions } from 'src/state/redux/slice/hub';
import { meetingActions } from 'src/state/redux/slice/meeting';
import { tenantActions } from 'src/state/redux/slice/tenant';
import { userActions } from 'src/state/redux/slice/user';
import { userNotationsActions } from 'src/state/redux/slice/userNotations';
import { allCustomDecks } from 'src/state/redux/selector/folder';
import {
  filterDocumentsForDeletedAndLockedFilters,
  getIndexedValidCustomFields,
  matchesLockedFilters,
} from 'src/state/datastore/query';
import activeUser from 'src/state/global/ActiveUser';
import PWALoaded from 'src/state/global/PWALoaded';
import { syncCacheManifest } from 'src/state/cache/syncManifests';
import { LDClient } from 'launchdarkly-react-client-sdk';
import * as logger from 'src/utils/logger';
import {
  upgradeFolderDocuments,
  upgradeLibraryDocumentsNotations,
} from 'src/state/redux/saga';

const hydrate = async (isOfflineEnabled: boolean, ldClient: LDClient, upsert: boolean = false) => {
  logger.clientState.hydrate.info('Starting hydration process');

  // 1. Fetch all DataStore records
  const modelRecords = await Promise.all([
    DataStore.query(AttachedFile, Predicates.ALL),
    DataStore.query(CustomDeck, Predicates.ALL),
    DataStore.query(CustomFormRecord, Predicates.ALL),
    DataStore.query(Document, Predicates.ALL),
    DataStore.query(DocumentVersion, Predicates.ALL),
    DataStore.query(EmailTemplate, Predicates.ALL),
    DataStore.query(Folder, Predicates.ALL),
    DataStore.query(Hub, Predicates.ALL),
    DataStore.query(Meeting, Predicates.ALL),
    DataStore.query(Tenant, Predicates.ALL),
    DataStore.query(User, Predicates.ALL),
    DataStore.query(UserNotations, Predicates.ALL),
  ]);

  logger.clientState.hydrate.info('DataStore queries completed');

  const [
    attachedFiles,
    customDeck,
    customFormRecords,
    documents,
    documentVersions,
    emailTemplates,
    folders,
    hubs,
    meetings,
    tenants,
    users,
    allUserNotations,
  ] = modelRecords;

  // 2. Attempt to identify CognitoUser
  // [TODO] - Type this if possible, but not officially provided definition
  let cognitoUser: FetchUserAttributesOutput;
  try {
    cognitoUser = await fetchUserAttributes();
    logger.clientState.hydrate.info('Current authenticated user retrieved');
  } catch (e) {
    cognitoUser = JSON.parse(localStorage.getItem('amplify-latest-user-attributes') ?? '');
    logger.clientState.hydrate.warn(
      'Failed to retrieve authenticated user, using latest user attributes from localStorage',
    );
  }

  /**
   * first cognitoUser.signInUserSession.idToken.payload.email is the one that we are overriding on the cognitoTriggerHandler that one is in lowercase
   * second cognitoUser.attributes.email is the one that we are getting from the cognitoUserPool that one can be in uppercase or lowercase
   */
  const cognitoEmail = cognitoUser.email;
  const currentUser = users.find(({ email }) => email === cognitoEmail);

  if (!currentUser) {
    logger.clientState.hydrate.error('User not found in DynamoDB');
    throw new Error('User not found in DynamoDB');
  }

  // 3. Set Singleton values
  activeUser.set(currentUser)
  const isPWALoaded = window.matchMedia('(display-mode: standalone)').matches
  PWALoaded.set(isPWALoaded)

  // 4. Ensure there's only one Tenant Record loaded from DataStore
  if (tenants.length > 1 && currentUser?.role !== UserRole.ALUCIO_ADMIN) {
    logger.clientState.hydrate.error('More than one tenant returned by Datastore');
    throw new Error('More than one tenant returned by Datastore');
  }
  const usersTenant = tenants.find(({ id }) => id === currentUser?.tenantId);

  // 5. Apply filters for DocVer records going into Redux
  //    - Locked Filters
  //    - Deleted Docs
  const validIndexedCustomFields = getIndexedValidCustomFields(usersTenant?.config?.customFields || []);
  const { documents: modifiedDocuments, versions: modifiedDocumentVersions } =
    filterDocumentsForDeletedAndLockedFilters(
      currentUser,
      documents,
      documentVersions,
      validIndexedCustomFields,
    )

  // 6. Apply filters for EmailTemplate records going into Redux
  //    - Locked filters
  //    - Deleted Templates
  const modifiedEmailTemplates = emailTemplates
    .filter(({ customFilterValues, status }) => (
      matchesLockedFilters(
        currentUser?.lockedFiltersCustomValues || [],
        validIndexedCustomFields,
      )(status === EmailTemplateStatus.ACTIVE, customFilterValues) &&
      status !== 'REMOVED'
    ));

  // 7. Apply filters for UserNotation records going into Redux
  // [NOTE] - We currently do not update this status
  const filteredUserNotations = allUserNotations.filter(userNotations => userNotations.status !== 'DELETED');
  // 8. Apply filters for Folder records going into Redux
  const filteredFolder = folders.filter(folder => folder.status !== 'REMOVED');
  // 9. Apply filters for CustomDeck records going into Redux
  // [TODO] - We should filter these out at the DataStore query level
  const filteredCustomDeck: CustomDeck[] = [];
  filteredFolder.forEach(folder => {
    folder.items.forEach(folderItem => {
      if (folderItem.status === 'ACTIVE' && folderItem.type === 'CUSTOM_DECK') {
        const targetCustomDeck = customDeck.find(cd => cd.id === folderItem.itemId);
        if (targetCustomDeck) filteredCustomDeck.push(targetCustomDeck);
      }
    })
  });
  // 9. Apply filters for Hub records going into Redux
  const filteredHubs = hubs.filter(hub => hub.status !== 'DELETED');

  // 10. Setup Analytics (based on current user)
  if (currentUser) {
    const userInfo = {
      userId: currentUser.id,
      email: currentUser.email,
      givenName: currentUser.givenName,
      lastName: currentUser.familyName,
      role: [currentUser.role],
      tenant: usersTenant?.name,
      tenantId: currentUser.tenantId,
    }
    logger.clientState.hydrate.info('Initializing analytics', userInfo);
    analytics?.identify(currentUser.email, userInfo);

    logger.clientState.hydrate.info('Setting group properties on analytics', userInfo);
    analytics?.group(currentUser.tenantId, {
      name: usersTenant?.name,
    });

    const newContext = {
      kind: 'user',
      // there is also the option to have a multi context with user and tenant, but if we decided to create a tenant context and use that we would have to refactor all of our existing flags in the LD dashboard
      key: currentUser.id,
      tenantId: currentUser.tenantId,
      tenantName: usersTenant?.name,
    }

    // 11. Setup LaunchDarkly (based on current user)
    // [NOTE] - We skip LD Client errors for now (this should normally error in offline mode)
    logger.clientState.hydrate.info('Configuring LaunchDarkly');
    await ldClient
      ?.identify(newContext)
      .catch((error) => {
        logger.clientState.hydrate.warn('LD Client identify failed', error);
      })
  }

  // 12. Insert (filtered) records into Redux
  batch(() => {
    // DataStore
    logger.clientState.hydrate.info('Dispatching data to Redux store')
    // [NOTE]: Not typed so be careful here
    const action = upsert ? 'batchUpsert' : 'add'

    store.dispatch(attachedFileActions[action](attachedFiles))
    store.dispatch(customDeckActions[action](filteredCustomDeck))
    store.dispatch(customFormRecordActions[action](customFormRecords))
    store.dispatch(documentActions[action](modifiedDocuments))
    store.dispatch(documentVersionActions[action](modifiedDocumentVersions))
    store.dispatch(emailTemplateActions[action](modifiedEmailTemplates))
    store.dispatch(folderActions[action](filteredFolder))
    store.dispatch(hubActions[action](filteredHubs))
    store.dispatch(meetingActions[action](meetings))
    store.dispatch(tenantActions[action](tenants))
    store.dispatch(userActions[action](users))
    store.dispatch(userNotationsActions[action](filteredUserNotations))

    // Mark as hydrated
    // [TODO] - Alternatively, manage an overall app hydrated state in a separate slice
    store.dispatch(attachedFileActions.setHydrated())
    store.dispatch(customDeckActions.setHydrated())
    store.dispatch(customFormRecordActions.setHydrated())
    store.dispatch(documentActions.setHydrated())
    store.dispatch(documentVersionActions.setHydrated())
    store.dispatch(emailTemplateActions.setHydrated())
    store.dispatch(folderActions.setHydrated())
    store.dispatch(hubActions.setHydrated())
    store.dispatch(meetingActions.setHydrated())
    store.dispatch(tenantActions.setHydrated())
    store.dispatch(userActions.setHydrated())
    store.dispatch(userNotationsActions.setHydrated())

    // Now that everything's hydrated we need to check if we need to auto-update anything
    // NOTE: Including validation for null because that whats we get instead of undefined if the
    // field does not exist in DynamoDB Tenant record
    if (
      usersTenant?.folderUpdateGracePeriodDays !== undefined &&
      usersTenant?.folderUpdateGracePeriodDays !== null
    ) {
      // 13. Auto-upgrade Custom Deck Minor Versions
      try {
        const customDecks = allCustomDecks(store.getState())
        store.dispatch(customDeckActions.autoUpgradeCustomDecks({
          customDeckORMs: customDecks,
          gracePeriodDays: usersTenant?.folderUpdateGracePeriodDays ?? 0,
        }))
      } catch (error) {
        logger.clientState.hydrate.error('Failed to auto-upgrade custom decks:', error);
      }
    }
  })

  try {
    // 14. Apply Auto upgrades - Library Document Version Notations
    await upgradeLibraryDocumentsNotations()
  } catch (error) {
    logger.clientState.hydrate.error('Failed to auto-upgrade library document version notations:', error);
  }
  try {
    // 15. Apply Auto upgrades - Bump DocVers in Folders
    await upgradeFolderDocuments()
  } catch (error) {
    logger.clientState.hydrate.error('Failed to auto-upgrade folder document versions:', error);
  }
  // 16. Prepare Offline Cache syncing
  if (isOfflineEnabled) {
    logger.offline.contentCache.info('Syncing cache manifest for offline mode');
    await syncCacheManifest();
  }

  logger.clientState.hydrate.info('Hydration process completed');
}

export default hydrate;
