import React, { useCallback, useEffect, useState } from 'react';
import { StyleSheet, Image } from 'react-native';
import { DNABox, DNAButton, DNADivider, DNAText, Iffy, luxColors } from '@alucio/lux-ui';
import { TenantORM } from 'src/state/redux/selector/tenant';
import {
  useIntegrationSettingsList,
  useIsIntegrationSettingsHydrated,
} from 'src/state/redux/selector/integrationSettings';
import DNAEmpty, { EmptyVariant } from 'src/components/DNA/Empty/DNAEmpty';
import colors from '@alucio/lux-ui/lib/theming/themes/alucio/colors';
import { useDispatch } from 'src/state/redux';
import { integrationSettingsActions } from 'src/state/redux/slice/integrationSettings';
import Integration from 'src/screens/Integrations';
import { IntegrationSettings } from '@alucio/aws-beacon-amplify/src/models';
import { AdminIntegrationRow } from 'src/screens/Integrations/AdminIntegrationRow';
import { PublisherIntegrationRow } from 'src/screens/Integrations/PublisherIntegrationRow';

type variant = 'admin' | 'publisher'

type IntegrationListProps = {
  selectedTenant: TenantORM,
  variant: variant,
}

export type IntegrationRowProps = {
  integration: IntegrationSettings,
  handleEdit: (integration: IntegrationSettings) => void,
}

export const styles = StyleSheet.create({
  crumbsColor: {
    color: luxColors.flat.primary,
  },
  header: {
    marginBottom: 16,
  },
  itemTextColor: {
    color: luxColors.contentText.tertiary,
  },
  mainWrapper: {
    backgroundColor: colors['color-text-white'],
    overflowX: 'auto',
  },
  tab: {
    paddingHorizontal: 12,
    paddingVertical: 20,
  },
  tenantRowWrapper: {
    borderBottomColor: luxColors.divider.primary,
    borderBottomWidth: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 16,
    paddingVertical: 20,
  },
  tenantsWrapper: {
    borderRadius: 4,
    overflow: 'hidden',
  },
  integrationRow: {
    borderRadius: 4,
    height: 64,
    justifyContent: 'space-between',
    borderColor: '#EBEBEB',
    paddingHorizontal: 16,
    paddingVertical: 10,
    borderWidth: 1,
    borderStyle: 'solid',
    marginTop: 16,
    border: '1px solid #EBEBEB',
    alignItems: 'center',
  },
  integrationPublisherRow: {
    borderRadius: 4,
    height: 84,
    justifyContent: 'space-between',
    borderColor: '#EBEBEB',
    paddingHorizontal: 16,
    paddingVertical: 24,
    borderBottomWidth: 1,
    borderStyle: 'solid',
    marginTop: 16,
    borderBottom: '1px solid #EBEBEB',
    alignItems: 'center',
  },
  integrationTitle: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 15,
    lineHeight: 24,
    display: 'flex',
    alignItems: 'center',
    color: colors['color-gray-800'],
  },
  integrationStatus: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 13,
    lineHeight: 20,
    display: 'flex',
    alignItems: 'center',
  },
  integrationSync: {
    fontFamily: 'Lato',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 13,
    lineHeight: 20,
    display: 'flex',
    alignItems: 'center',
    paddingVertical: 24,
  },
  actionsContainer: {
    padding: 16,
  },
  listContainer: {
    padding: 24,
  },
});

const EmptyIntegrationSettings = ({ handleCreate, variant }) => (
  <DNABox appearance="col" alignX="center">
    {variant === 'admin' && (<DNAEmpty emptyVariant={EmptyVariant.IntegrationSettingsEmpty} />)}
    {variant === 'publisher' && (<DNAEmpty emptyVariant={EmptyVariant.IntegrationPublisherSettingsEmpty} />)}
    {variant === 'admin' && (
      <DNAButton
        size="md"
        status="secondary"
        appearance="outline"
        onPress={handleCreate}
      >
        Add integration
      </DNAButton>)}
  </DNABox>
);

const AddIntegrationButton = ({ handleCreate }) => (
  <DNABox spacing="sm" alignX="end" style={styles.actionsContainer}>
    <DNAButton
      size="sm"
      status="secondary"
      appearance="outline"
      onPress={handleCreate}
    >
      Add integration
    </DNAButton>
  </DNABox>
);

const ListVariant = {
  admin: AdminIntegrationRow,
  publisher: PublisherIntegrationRow,
}

const IntegrationsList = (props: IntegrationListProps) => {
  const { selectedTenant, variant } = props
  const dispatch = useDispatch()
  const integrationSettings = useIntegrationSettingsList()
  const isIntegrationSettingsHydrated = useIsIntegrationSettingsHydrated();
  const [showForm, setShowForm] = useState(false)
  const [integrationSetting, setIntegrationSetting] = useState<IntegrationSettings | undefined>(undefined)

  const ListComponent = ListVariant[props.variant]
  const title = props.variant === 'admin' ? 'Configured Integrations' : 'Syncing'

  useEffect(() => {
    if (variant === 'admin') {
      dispatch(integrationSettingsActions.getIntegrationsAction(selectedTenant.tenant.id))
    }
    else {
      dispatch(integrationSettingsActions.getIntegrationsByCognitoAction())
    }
  }, [])

  const handleCreate = useCallback(
    () => {
      setShowForm(true)
      setIntegrationSetting(undefined)
    },
    [],
  )

  const handleEdit = useCallback(
    (integration) => {
      setShowForm(true)
      setIntegrationSetting(integration)
    },
    [],
  )

  const handleCloseForm = useCallback(() => {
    setShowForm(false)
    setIntegrationSetting(undefined)
  }, [])

  return (
    <DNABox style={styles.mainWrapper} fill appearance="col">
      <Iffy is={!showForm}>
        <Iffy is={props.variant === 'admin'}>
          <AddIntegrationButton handleCreate={handleCreate} />
        </Iffy>
        <DNADivider />
        <DNABox appearance="col" style={styles.listContainer} >
          <Iffy is={isIntegrationSettingsHydrated && (!integrationSettings || integrationSettings.length === 0)}>
            <EmptyIntegrationSettings handleCreate={handleCreate} variant={props.variant}/>
          </Iffy>
          <Iffy is={!isIntegrationSettingsHydrated}>
            <DNABox fill alignX="center" alignY="center">
              <Image
                testID="dna-loader-report"
                style={{ width: 200, height: 200 }}
                source={require('../../../../../assets/images/LoaderDNA.gif')}
              />
            </DNABox>
          </Iffy>
          <Iffy is={integrationSettings && integrationSettings.length > 0}>
            <>
              <DNAText bold>{title}</DNAText>
              {integrationSettings
                ?.filter(i => i.integrationType === 'VEEVA' || i.integrationType === 'BEACON')
                .map(i => <ListComponent key={i.id} integration={i} handleEdit={handleEdit} />)
              }
            </>
          </Iffy>
        </DNABox>
      </Iffy>
      <Iffy is={showForm}>
        <Integration
          onClose={handleCloseForm}
          selectedTenant={selectedTenant}
          selectedIntegration={integrationSetting}
        />
      </Iffy>
    </DNABox>
  );
};

IntegrationsList.displayName = 'IntegrationsList';

export default IntegrationsList;
