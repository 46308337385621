import React, { useCallback, useState } from 'react';
import {
  DNABox,
  DNAButton,
  DNACard,
  DNASpinner,
  DNAText,
  Iffy,
  Toggle,
} from '@alucio/lux-ui';
import { StyleSheet } from 'react-native';
import { Divider } from '@ui-kitten/components';
import { generateClient } from 'aws-amplify/api';
import {
  FieldDataType,
  IntegrationSettings,
  Tenant,
  CustomFieldDefinition,
  CustomFieldUsage,
} from '@alucio/aws-beacon-amplify/src/models';
import {
  submitIntegrationRun,
  createIntegrationSettings,
  updateIntegrationSettings,
  submitUserIntegrationRun,
} from '@alucio/aws-beacon-amplify/src/graphql/mutations';
import { TenantORM } from 'src/state/redux/selector/tenant';
import IntegrationProvider, { useIntegrationContext } from '../Integrations/Provider';
import IntegrationForm, { integrationTypesMapping } from './IntegrationForm';
import { store, useDispatch } from 'src/state/redux';
import { tenantActions } from 'src/state/redux/slice/tenant'

import { Controller, useForm, FormProvider } from 'react-hook-form'
import { v4 as uuid } from 'uuid';
import { CurrentUser, useCurrentUser } from 'src/state/redux/selector/user'
import { integrationSettingsActions } from 'src/state/redux/slice/integrationSettings';
import { SUBMITTING_STATUS } from 'src/components/EmailTemplateDrawer/EmailTemplateDrawer';

import { DNAModalActions } from 'src/state/redux/slice/DNAModal/DNAModal'

import DNAIntegrationSyncModal from 'src/components/DNA/Modal/DNAIntegrationSyncModal'
import DNAIntegrationLogsModal from 'src/components/DNA/Modal/DNAIntegrationLogsModal';
import {
  IntegrationFieldMappingInput,
  SubmitIntegrationResult,
  SubmitIntegrationRunMutation,
  SubmitUserIntegrationRunMutation
} from '@alucio/aws-beacon-amplify/src/API';

export enum FieldType {
  SYSTEM = 'SYSTEM',
  CUSTOM = 'CUSTOM',
}

export interface valueMap {
  srcValue: string,
  targetValue: string,
}

const styles = StyleSheet.create({
  backButton: {
    marginRight: 32,
  },
  buttonContainer: {
    marginTop: 16,
    paddingRight: 16,
  },
  sectionContainer: {
    marginHorizontal: 32,
    paddingBottom: 40,
  },
  sectionMarging: {
    marginTop: 80,
  },
  settingMarging: {
    marginTop: 40,
  },
});

export type BeaconAttribute = {
  name: string,
  propName: string,
  isRequired: boolean,
  dataType: FieldDataType,
  fieldType: FieldType,
  fieldValues: any, // TODO: change from any to the correct type
  label: string,
}

export interface BeaconMappingValue {
  [key: string]: BeaconAttribute
}

const SyncButton: React.FC<{
  disableSync: boolean,
  onSyncHandler: () => void,
}> = (props) => {
  const { disableSync, onSyncHandler } = props
  const { integrationType } = useIntegrationContext()

  return (<Iffy is={integrationType !== 'EXPORT_TO_S3'}>
    <DNAButton
      size="sm"
      disabled={disableSync}
      style={{ marginHorizontal: 8 }}
      onPress={onSyncHandler}
      appearance="outline"
    >
      Sync
    </DNAButton>
  </Iffy>);
}

export async function submitIntegrationRunAPI(integrationSettingId: string): Promise<SubmitIntegrationResult> {
  const appsyncClient = generateClient<SubmitIntegrationRunMutation>();
  const { data } = await appsyncClient.graphql({
    query: submitIntegrationRun,
    variables: {
      input: {
        integrationSettingId: integrationSettingId,
      },
    },
  });
  return data.submitIntegrationRun;
}

export async function submitPublisherIntegrationRunAPI(integrationSettingId: string): Promise<SubmitIntegrationResult> {
  const appsyncClient = generateClient<SubmitUserIntegrationRunMutation>();
  const { data } = await appsyncClient.graphql({
    query: submitUserIntegrationRun,
    variables: {
      input: {
        integrationSettingId: integrationSettingId,
      },
    },
  });
  return data.submitUserIntegrationRun;
}

const getFormData = (
  data, // TODO need to properly type this BEAC-4152
  beaconMappingValues: BeaconMappingValue,
  tenant: TenantORM,
  currentUser: CurrentUser,
  selectedIntegration?: IntegrationSettings,
) => {
  if (data) {
    // format the mapping field
    const mapping = data?.fieldMapping
      ? Object.keys(data.fieldMapping).reduce((mappedArray, fieldMappingKey) => {
        if (data.fieldMapping[fieldMappingKey].srcFieldname) {
          const mapItem: IntegrationFieldMappingInput = {
            dataType: beaconMappingValues[fieldMappingKey].dataType,
            fieldType: beaconMappingValues[fieldMappingKey].fieldType,
            targetFieldName: beaconMappingValues[fieldMappingKey].propName || beaconMappingValues[fieldMappingKey].name,
            srcFieldname: data.fieldMapping[fieldMappingKey].srcFieldname,
            valueMappings: data.fieldMapping[fieldMappingKey].valueMappings &&
              data.fieldMapping[fieldMappingKey].valueMappings,
            key: uuid(), // not sure why this key is required but backend needs a uniqueID
          }
          return [...mappedArray, mapItem]
        } else {
          return mappedArray
        }
      }, [] as IntegrationFieldMappingInput[])
      : [] as IntegrationFieldMappingInput[]

    // format the clientConfigurationFields
    const clientConfigFormatted = Object.keys(data.clientConfigurationFields).map(configKey => {
      return {
        key: configKey,
        value: data.clientConfigurationFields[configKey],
      }
    }).filter(config => config.value)

    // format integrationType
    const formattedIntegrationType =
      (Array.isArray(data.integrationType) ? data.integrationType[0] : data.integrationType)?.replace(/\s/g, '_')
    const now = new Date().toISOString();

    return {
      clientConfigurationFields: clientConfigFormatted,
      integrationType: integrationTypesMapping[formattedIntegrationType]?.replace(/\s/g, '_'),
      name: data.name,
      notificationEmail: data.notificationEmail || '',
      errorSyncEmail: data.errorSyncEmail || '',
      mapping,
      syncContentEvery: 24, // this needs to be changed once we add the settings to pick the sync interval
      enabled: data.shouldSync,
      tenantId: tenant.tenant.id,
      id: selectedIntegration && selectedIntegration.id,
      updatedAt: now,
      updatedBy: currentUser.userProfile?.email!,
      _version: selectedIntegration ? (selectedIntegration as any)._version : undefined,
    }
  }
}

const Integration: React.FC<{
  onClose: () => void,
  selectedTenant: TenantORM
  selectedIntegration?: IntegrationSettings,
}> = (props) => {
  const { onClose, selectedTenant, selectedIntegration } = props
  const [submittingStatus, setSubmittingStatus] = useState<SUBMITTING_STATUS>(SUBMITTING_STATUS.NONE);
  const form = useForm({ defaultValues: { shouldSync: true } })
  const { formState: { isDirty }, control, watch } = form
  const shouldSyncVal = watch('shouldSync')
  const currentUser = useCurrentUser()
  const dispatch = useDispatch()

  // TODO: MOVE THIS TO THE FORM
  const [disableSync, setDisableSync] = React.useState(false)
  const [syncError, setSyncError] = React.useState<string | undefined>(undefined)
  const genericFields = {
    'title': {
      name: 'Title',
      propName: 'title',
      isRequired: true,
      dataType: FieldDataType.STRING,
      fieldType: 'SYSTEM',
      fieldValues: null,
      label: 'Title',
    },
  }

  function formatTenantConfig(cstmValues: CustomFieldDefinition[]) {
    return cstmValues.map(customValue => {
      const { fieldName, fieldLabel, required, fieldType, fieldValueDefinitions, id } = customValue
      return {
        [fieldName]: {
          name: fieldName,
          isRequired: required,
          dataType: fieldType,
          fieldType: 'CUSTOM',
          fieldValues: fieldValueDefinitions.map(fieldValue => fieldValue.value),
          key: id,
          label: fieldLabel,
        },
      }
    })
  }

  const customFields = selectedTenant?.tenant.config?.customFields
    ? selectedTenant.tenant.config.customFields
      .filter(customField => customField.usage.includes(CustomFieldUsage.DOCUMENT))
    : []

  const formattedTeenantConfigList = formatTenantConfig(customFields)
  const beaconMappingValuesNotSorted: BeaconMappingValue = Object.assign(
    genericFields,
    ...formattedTeenantConfigList,
  )

  // sorting the list alphabetically for the form
  const beaconMappingValues: BeaconMappingValue = Object.keys(beaconMappingValuesNotSorted)
    .sort((a, b) => beaconMappingValuesNotSorted[a].name.localeCompare(beaconMappingValuesNotSorted[b].name))
    .reduce((acc, value) => ({
      ...acc, [value]: beaconMappingValuesNotSorted[value],
    }), {})

  const onSyncHandler = useCallback(async () => {
    if (!props.selectedIntegration) {
      return
    }

    setDisableSync(true)
    // At this moment is a sync operation on the future should be handled as an async operation
    const syncResult = await submitIntegrationRunAPI(props.selectedIntegration?.id);
    setSyncError(syncResult.message || '')
    setDisableSync(false)
  }, [])

  const onSubmit = async (data) => {
    const formattedFormData =
      getFormData(data, beaconMappingValues, selectedTenant, currentUser, selectedIntegration)
    if (formattedFormData) {
      const formattedIntegrationType = formattedFormData?.integrationType
      const obj = { ...selectedIntegration, ...formattedFormData }
      delete (obj as any)._deleted
      delete (obj as any)._lastChangedAt
      if (selectedIntegration) {
        setSubmittingStatus(SUBMITTING_STATUS.SUBMITTING);
        const appsyncClient = generateClient();
        const { data } = await appsyncClient.graphql({
          query: updateIntegrationSettings,
          variables: {
            input: {
              ...obj,
              id: obj.id || '',
            }
          },
        });

        dispatch(integrationSettingsActions.update(data.updateIntegrationSettings as IntegrationSettings))
        setSubmittingStatus(SUBMITTING_STATUS.SUCCESS);

        setTimeout(() => {
          setSubmittingStatus(SUBMITTING_STATUS.NONE);
        }, 3000);
      } else {
        setSubmittingStatus(SUBMITTING_STATUS.SUBMITTING);
        try {
          const appsyncClient = generateClient();
          const newIntegration = await appsyncClient.graphql({
            query: createIntegrationSettings,
            variables: {
              input: {
                ...formattedFormData,
                // id: uuid(),
                createdAt: formattedFormData.updatedAt,
                createdBy: formattedFormData.updatedBy,
              },
            },
          });

          dispatch(integrationSettingsActions.add(newIntegration.data.createIntegrationSettings as IntegrationSettings))
          onClose()
          setSubmittingStatus(SUBMITTING_STATUS.SUCCESS);
        } catch (e) {
          console.error('Error creating integration', e)
        }
        setTimeout(() => {
          setSubmittingStatus(SUBMITTING_STATUS.NONE);
        }, 3000);
      }
      // Need to update the tenant's list of integrations (if the integration wasn't already added)
      if (!selectedTenant.tenant.integrations ||
        !selectedTenant.tenant.integrations.includes(formattedIntegrationType)) {
        store.dispatch(tenantActions.save({
          model: Tenant,
          entity: selectedTenant.tenant,
          updates: {
            integrations: selectedTenant.tenant.integrations && formattedIntegrationType
              ? Array.from(new Set(
                [...selectedTenant.tenant.integrations, formattedIntegrationType],
              ))
              : [formattedIntegrationType],
          },
        }))
      }
    }
  }

  const handleSyncConfirmation = (onChange, syncVal) => {
    if (shouldSyncVal) {
      dispatch(DNAModalActions.setModal(
        {
          isVisible: true,
          allowBackdropCancel: false,
          component: (props) => (
            <DNAIntegrationSyncModal
              {...props}
              onChange={onChange}
              syncVal={syncVal}
            />
          ),
        },
      ))
    } else {
      onChange(syncVal)
    }
  }

  const handleViewLogs = () => {
    dispatch(DNAModalActions.setModal({
      isVisible: true,
      allowBackdropCancel: true,
      component: (props) => (
        <DNAIntegrationLogsModal
          integrationSettings={selectedIntegration}
          {...props}
        />
      ),
    }))
  }

  const isSuccess = submittingStatus === SUBMITTING_STATUS.SUCCESS;
  const isSubmitting = submittingStatus === SUBMITTING_STATUS.SUBMITTING;

  return (
    <IntegrationProvider>
      <FormProvider {...form}>
        <DNACard
          appearance="flat"
        >
          <DNABox appearance="col">
            <DNABox style={styles.buttonContainer} appearance="row" fill spacing="between">
              <DNAButton
                appearance="ghost"
                style={styles.backButton}
                iconLeft="chevron-left"
                onPress={onClose}
                status="secondary"
              >
                Back
              </DNAButton>
              {/* Save buttons */}
              <DNABox appearance="row" spacing="md" alignY="center">
                <DNABox spacing="sm" alignY="center" fill>
                  <Controller
                    name="shouldSync"
                    control={control}
                    defaultValue={true}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <Toggle.Kitten
                          checked={value}
                          onChange={e => handleSyncConfirmation(onChange, e)}
                          status="success"
                        />
                      )
                    }}
                  />
                  <DNAText>{shouldSyncVal ? 'Syncing enabled (syncs every 24 hours)' : 'Syncing disabled'}</DNAText>
                </DNABox>
                <Iffy is={selectedIntegration}>
                  <DNAButton
                    size="sm"
                    disabled={disableSync}
                    style={{ marginHorizontal: 8 }}
                    onPress={handleViewLogs}
                    appearance="outline"
                  >
                    View Logs
                  </DNAButton>
                </Iffy>
                <SyncButton disableSync={disableSync} onSyncHandler={onSyncHandler} />
                <DNAButton
                  style={{ maxHeight: 43 }}
                  onPress={isSubmitting
                    ? undefined
                    : form.handleSubmit(onSubmit)
                  }
                  size="sm"
                  disabled={!isDirty}
                  status={isSuccess ? 'success' : undefined}
                  iconLeft={isSuccess ? 'check' : undefined}
                >
                  {
                    isSuccess
                      ? selectedIntegration ? 'Update' : 'Save'
                      : isSubmitting ? <DNASpinner size="xs" status="info" /> : selectedIntegration ? 'Update' : 'Save'
                  }
                </DNAButton>
              </DNABox>
            </DNABox>
            <DNABox appearance="row" fill alignX="end">
              <DNAText style={{ marginHorizontal: 12, marginVertical: 4 }} status="danger">{syncError}</DNAText>
            </DNABox>
            <Divider style={{ marginTop: 16 }} />
            {/* Section Container */}
            <DNABox appearance="col" style={styles.sectionContainer}>
              <IntegrationForm beaconMappingValues={beaconMappingValues} selectedIntegration={selectedIntegration} />
            </DNABox>
          </DNABox>
        </DNACard>
      </FormProvider>
    </IntegrationProvider>)
}

export default Integration;
