/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getUser = /* GraphQL */ `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    cognitoSubId
    tenantId
    email
    givenName
    phoneNumber
    familyName
    meetingId
    defaultFiltersCustomValues {
      fieldId
      objectRecords {
        id
        externalId
        syncStatus
        status
        values {
          fieldId
          values
          __typename
        }
        __typename
      }
      values
      __typename
    }
    lockedFiltersCustomValues {
      fieldId
      objectRecords {
        id
        externalId
        syncStatus
        status
        values {
          fieldId
          values
          __typename
        }
        __typename
      }
      values
      __typename
    }
    defaultFilterValues {
      key
      values
      __typename
    }
    lockedFilters {
      key
      values
      __typename
    }
    shareBCC
    shareCC
    createdAt
    createdBy
    updatedAt
    status
    role
    bookmarkedDocs {
      docID
      createdAt
      __typename
    }
    isExcludedFromReporting
    additionalRoles
    altUsername
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUserQueryVariables, APITypes.GetUserQuery>;
export const listUsers = /* GraphQL */ `query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      cognitoSubId
      tenantId
      email
      givenName
      phoneNumber
      familyName
      meetingId
      defaultFiltersCustomValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
            __typename
          }
          __typename
        }
        values
        __typename
      }
      lockedFiltersCustomValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
            __typename
          }
          __typename
        }
        values
        __typename
      }
      defaultFilterValues {
        key
        values
        __typename
      }
      lockedFilters {
        key
        values
        __typename
      }
      shareBCC
      shareCC
      createdAt
      createdBy
      updatedAt
      status
      role
      bookmarkedDocs {
        docID
        createdAt
        __typename
      }
      isExcludedFromReporting
      additionalRoles
      altUsername
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListUsersQueryVariables, APITypes.ListUsersQuery>;
export const syncUsers = /* GraphQL */ `query SyncUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncUsers(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      cognitoSubId
      tenantId
      email
      givenName
      phoneNumber
      familyName
      meetingId
      defaultFiltersCustomValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
            __typename
          }
          __typename
        }
        values
        __typename
      }
      lockedFiltersCustomValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
            __typename
          }
          __typename
        }
        values
        __typename
      }
      defaultFilterValues {
        key
        values
        __typename
      }
      lockedFilters {
        key
        values
        __typename
      }
      shareBCC
      shareCC
      createdAt
      createdBy
      updatedAt
      status
      role
      bookmarkedDocs {
        docID
        createdAt
        __typename
      }
      isExcludedFromReporting
      additionalRoles
      altUsername
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncUsersQueryVariables, APITypes.SyncUsersQuery>;
export const getTenant = /* GraphQL */ `query GetTenant($id: ID!) {
  getTenant(id: $id) {
    id
    name
    licensedUsers
    status
    fields {
      fieldName
      description
      required
      isEmailTemplateFilter
      dataType
      userFilter
      defaultSearchFilter
      values
      __typename
    }
    folderUpdateGracePeriodDays
    medInfoURL
    publisherListConfig {
      fields {
        fieldName
        header
        width
        __typename
      }
      sort {
        field
        isAsc
        __typename
      }
      __typename
    }
    mslListConfig {
      fields {
        fieldName
        header
        width
        __typename
      }
      sort {
        field
        isAsc
        __typename
      }
      __typename
    }
    featureFlags {
      flagId
      value
      __typename
    }
    createdAt
    createdBy
    updatedAt
    statusChangedAt
    statusChangedBy
    ssoDomains
    integrations
    config {
      requiredSlidesHiddenMessage
      forms {
        id
        name
        label
        fields {
          id
          fieldName
          fieldLabel
          reportingName
          status
          description
          required
          maxLength
          formatValidation
          fieldType
          showByDefault
          dependentFieldId
          controlType
          order
          settings {
            isBadge
            fieldPosition
            __typename
          }
          usage
          documentSettings {
            fieldPosition
            __typename
          }
          fieldValueDefinitions {
            id
            value
            label
            disabled
            isDefault
            documentSettings {
              presentationWatermarkText
              permission {
                present
                modify
                share
                download
                externalNotation
                __typename
              }
              __typename
            }
            badgeColor
            badgeLabel
            createdAt
            dependentValueIds
            __typename
          }
          displayOnParentSelection
          displayOnValueSelection
          objectSetting {
            childrenFieldIds
            restriction {
              syncUpdate
              syncDelete
              update
              delete
              __typename
            }
            __typename
          }
          isChildField
          dateRestriction
          createdAt
          scaleNumber
          precisionNumber
          __typename
        }
        targetRequestTemplate
        targetType
        targetAddress
        targetParameters {
          key
          value
          __typename
        }
        status
        __typename
      }
      meetingFields {
        id
        fieldName
        status
        description
        required
        maxLength
        type
        controlType
        fieldValueDefinitions {
          id
          value
          disabled
          createdAt
          __typename
        }
        createdAt
        __typename
      }
      customFields {
        id
        fieldName
        fieldLabel
        reportingName
        status
        description
        required
        maxLength
        formatValidation
        fieldType
        showByDefault
        dependentFieldId
        controlType
        order
        settings {
          isBadge
          fieldPosition
          __typename
        }
        usage
        documentSettings {
          fieldPosition
          __typename
        }
        fieldValueDefinitions {
          id
          value
          label
          disabled
          isDefault
          documentSettings {
            presentationWatermarkText
            permission {
              present
              modify
              share
              download
              externalNotation
              __typename
            }
            __typename
          }
          badgeColor
          badgeLabel
          createdAt
          dependentValueIds
          __typename
        }
        displayOnParentSelection
        displayOnValueSelection
        objectSetting {
          childrenFieldIds
          restriction {
            syncUpdate
            syncDelete
            update
            delete
            __typename
          }
          __typename
        }
        isChildField
        dateRestriction
        createdAt
        scaleNumber
        precisionNumber
        __typename
      }
      defaultDocumentPermissions {
        present
        modify
        share
        download
        externalNotation
        __typename
      }
      mslListConfig {
        sort {
          field
          isAsc
          __typename
        }
        fields {
          field
          width
          __typename
        }
        __typename
      }
      publisherListConfig {
        sort {
          field
          isAsc
          __typename
        }
        fields {
          field
          width
          __typename
        }
        __typename
      }
      crmIntegration {
        id
        crmIntegrationType
        instanceUrl
        clientId
        redirectUri
        name
        accountsSettings {
          query
          displaySettings {
            shortened
            extended
            __typename
          }
          addressSettings {
            alias
            displaySettings {
              shortened
              extended
              __typename
            }
            __typename
          }
          __typename
        }
        meetingSetting {
          apiName
          presentationsFieldName
          children {
            name
            apiName
            relationshipName
            type
            veevaSetting {
              markerFieldName
              __typename
            }
            __typename
          }
          type
          __typename
        }
        additionalSettings {
          allowCommentsOnTasks
          detailContentTableSettings {
            name
            apiName
            relationshipName
            prefix
            __typename
          }
          enableSurveys
          crmAccountToObjectSettings {
            accountFieldNames {
              accountFieldName
              objectFieldName
              __typename
            }
            apiName
            __typename
          }
          __typename
        }
        loginUrl
        crmStandaloneForms {
          id
          apiName
          children {
            name
            apiName
            relationshipName
            type
            veevaSetting {
              markerFieldName
              __typename
            }
            __typename
          }
          type
          parents {
            apiName
            relationshipName
            __typename
          }
          usage
          __typename
        }
        __typename
      }
      sessionTimeout
      quickFilters {
        id
        label
        path
        __typename
      }
      hubsConfig {
        shareExpirationDays
        disabledSections
        disableNotationDescription
        __typename
      }
      reports {
        key
        value
        __typename
      }
      emailDocumentUpdateFrequency
      sharePPTXasPDF
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetTenantQueryVariables, APITypes.GetTenantQuery>;
export const listTenants = /* GraphQL */ `query ListTenants(
  $filter: ModelTenantFilterInput
  $limit: Int
  $nextToken: String
) {
  listTenants(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      licensedUsers
      status
      fields {
        fieldName
        description
        required
        isEmailTemplateFilter
        dataType
        userFilter
        defaultSearchFilter
        values
        __typename
      }
      folderUpdateGracePeriodDays
      medInfoURL
      publisherListConfig {
        fields {
          fieldName
          header
          width
          __typename
        }
        sort {
          field
          isAsc
          __typename
        }
        __typename
      }
      mslListConfig {
        fields {
          fieldName
          header
          width
          __typename
        }
        sort {
          field
          isAsc
          __typename
        }
        __typename
      }
      featureFlags {
        flagId
        value
        __typename
      }
      createdAt
      createdBy
      updatedAt
      statusChangedAt
      statusChangedBy
      ssoDomains
      integrations
      config {
        requiredSlidesHiddenMessage
        forms {
          id
          name
          label
          fields {
            id
            fieldName
            fieldLabel
            reportingName
            status
            description
            required
            maxLength
            formatValidation
            fieldType
            showByDefault
            dependentFieldId
            controlType
            order
            settings {
              isBadge
              fieldPosition
              __typename
            }
            usage
            documentSettings {
              fieldPosition
              __typename
            }
            fieldValueDefinitions {
              id
              value
              label
              disabled
              isDefault
              documentSettings {
                presentationWatermarkText
                permission {
                  present
                  modify
                  share
                  download
                  externalNotation
                  __typename
                }
                __typename
              }
              badgeColor
              badgeLabel
              createdAt
              dependentValueIds
              __typename
            }
            displayOnParentSelection
            displayOnValueSelection
            objectSetting {
              childrenFieldIds
              restriction {
                syncUpdate
                syncDelete
                update
                delete
                __typename
              }
              __typename
            }
            isChildField
            dateRestriction
            createdAt
            scaleNumber
            precisionNumber
            __typename
          }
          targetRequestTemplate
          targetType
          targetAddress
          targetParameters {
            key
            value
            __typename
          }
          status
          __typename
        }
        meetingFields {
          id
          fieldName
          status
          description
          required
          maxLength
          type
          controlType
          fieldValueDefinitions {
            id
            value
            disabled
            createdAt
            __typename
          }
          createdAt
          __typename
        }
        customFields {
          id
          fieldName
          fieldLabel
          reportingName
          status
          description
          required
          maxLength
          formatValidation
          fieldType
          showByDefault
          dependentFieldId
          controlType
          order
          settings {
            isBadge
            fieldPosition
            __typename
          }
          usage
          documentSettings {
            fieldPosition
            __typename
          }
          fieldValueDefinitions {
            id
            value
            label
            disabled
            isDefault
            documentSettings {
              presentationWatermarkText
              permission {
                present
                modify
                share
                download
                externalNotation
                __typename
              }
              __typename
            }
            badgeColor
            badgeLabel
            createdAt
            dependentValueIds
            __typename
          }
          displayOnParentSelection
          displayOnValueSelection
          objectSetting {
            childrenFieldIds
            restriction {
              syncUpdate
              syncDelete
              update
              delete
              __typename
            }
            __typename
          }
          isChildField
          dateRestriction
          createdAt
          scaleNumber
          precisionNumber
          __typename
        }
        defaultDocumentPermissions {
          present
          modify
          share
          download
          externalNotation
          __typename
        }
        mslListConfig {
          sort {
            field
            isAsc
            __typename
          }
          fields {
            field
            width
            __typename
          }
          __typename
        }
        publisherListConfig {
          sort {
            field
            isAsc
            __typename
          }
          fields {
            field
            width
            __typename
          }
          __typename
        }
        crmIntegration {
          id
          crmIntegrationType
          instanceUrl
          clientId
          redirectUri
          name
          accountsSettings {
            query
            displaySettings {
              shortened
              extended
              __typename
            }
            addressSettings {
              alias
              displaySettings {
                shortened
                extended
                __typename
              }
              __typename
            }
            __typename
          }
          meetingSetting {
            apiName
            presentationsFieldName
            children {
              name
              apiName
              relationshipName
              type
              veevaSetting {
                markerFieldName
                __typename
              }
              __typename
            }
            type
            __typename
          }
          additionalSettings {
            allowCommentsOnTasks
            detailContentTableSettings {
              name
              apiName
              relationshipName
              prefix
              __typename
            }
            enableSurveys
            crmAccountToObjectSettings {
              accountFieldNames {
                accountFieldName
                objectFieldName
                __typename
              }
              apiName
              __typename
            }
            __typename
          }
          loginUrl
          crmStandaloneForms {
            id
            apiName
            children {
              name
              apiName
              relationshipName
              type
              veevaSetting {
                markerFieldName
                __typename
              }
              __typename
            }
            type
            parents {
              apiName
              relationshipName
              __typename
            }
            usage
            __typename
          }
          __typename
        }
        sessionTimeout
        quickFilters {
          id
          label
          path
          __typename
        }
        hubsConfig {
          shareExpirationDays
          disabledSections
          disableNotationDescription
          __typename
        }
        reports {
          key
          value
          __typename
        }
        emailDocumentUpdateFrequency
        sharePPTXasPDF
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListTenantsQueryVariables,
  APITypes.ListTenantsQuery
>;
export const syncTenants = /* GraphQL */ `query SyncTenants(
  $filter: ModelTenantFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncTenants(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      name
      licensedUsers
      status
      fields {
        fieldName
        description
        required
        isEmailTemplateFilter
        dataType
        userFilter
        defaultSearchFilter
        values
        __typename
      }
      folderUpdateGracePeriodDays
      medInfoURL
      publisherListConfig {
        fields {
          fieldName
          header
          width
          __typename
        }
        sort {
          field
          isAsc
          __typename
        }
        __typename
      }
      mslListConfig {
        fields {
          fieldName
          header
          width
          __typename
        }
        sort {
          field
          isAsc
          __typename
        }
        __typename
      }
      featureFlags {
        flagId
        value
        __typename
      }
      createdAt
      createdBy
      updatedAt
      statusChangedAt
      statusChangedBy
      ssoDomains
      integrations
      config {
        requiredSlidesHiddenMessage
        forms {
          id
          name
          label
          fields {
            id
            fieldName
            fieldLabel
            reportingName
            status
            description
            required
            maxLength
            formatValidation
            fieldType
            showByDefault
            dependentFieldId
            controlType
            order
            settings {
              isBadge
              fieldPosition
              __typename
            }
            usage
            documentSettings {
              fieldPosition
              __typename
            }
            fieldValueDefinitions {
              id
              value
              label
              disabled
              isDefault
              documentSettings {
                presentationWatermarkText
                permission {
                  present
                  modify
                  share
                  download
                  externalNotation
                  __typename
                }
                __typename
              }
              badgeColor
              badgeLabel
              createdAt
              dependentValueIds
              __typename
            }
            displayOnParentSelection
            displayOnValueSelection
            objectSetting {
              childrenFieldIds
              restriction {
                syncUpdate
                syncDelete
                update
                delete
                __typename
              }
              __typename
            }
            isChildField
            dateRestriction
            createdAt
            scaleNumber
            precisionNumber
            __typename
          }
          targetRequestTemplate
          targetType
          targetAddress
          targetParameters {
            key
            value
            __typename
          }
          status
          __typename
        }
        meetingFields {
          id
          fieldName
          status
          description
          required
          maxLength
          type
          controlType
          fieldValueDefinitions {
            id
            value
            disabled
            createdAt
            __typename
          }
          createdAt
          __typename
        }
        customFields {
          id
          fieldName
          fieldLabel
          reportingName
          status
          description
          required
          maxLength
          formatValidation
          fieldType
          showByDefault
          dependentFieldId
          controlType
          order
          settings {
            isBadge
            fieldPosition
            __typename
          }
          usage
          documentSettings {
            fieldPosition
            __typename
          }
          fieldValueDefinitions {
            id
            value
            label
            disabled
            isDefault
            documentSettings {
              presentationWatermarkText
              permission {
                present
                modify
                share
                download
                externalNotation
                __typename
              }
              __typename
            }
            badgeColor
            badgeLabel
            createdAt
            dependentValueIds
            __typename
          }
          displayOnParentSelection
          displayOnValueSelection
          objectSetting {
            childrenFieldIds
            restriction {
              syncUpdate
              syncDelete
              update
              delete
              __typename
            }
            __typename
          }
          isChildField
          dateRestriction
          createdAt
          scaleNumber
          precisionNumber
          __typename
        }
        defaultDocumentPermissions {
          present
          modify
          share
          download
          externalNotation
          __typename
        }
        mslListConfig {
          sort {
            field
            isAsc
            __typename
          }
          fields {
            field
            width
            __typename
          }
          __typename
        }
        publisherListConfig {
          sort {
            field
            isAsc
            __typename
          }
          fields {
            field
            width
            __typename
          }
          __typename
        }
        crmIntegration {
          id
          crmIntegrationType
          instanceUrl
          clientId
          redirectUri
          name
          accountsSettings {
            query
            displaySettings {
              shortened
              extended
              __typename
            }
            addressSettings {
              alias
              displaySettings {
                shortened
                extended
                __typename
              }
              __typename
            }
            __typename
          }
          meetingSetting {
            apiName
            presentationsFieldName
            children {
              name
              apiName
              relationshipName
              type
              veevaSetting {
                markerFieldName
                __typename
              }
              __typename
            }
            type
            __typename
          }
          additionalSettings {
            allowCommentsOnTasks
            detailContentTableSettings {
              name
              apiName
              relationshipName
              prefix
              __typename
            }
            enableSurveys
            crmAccountToObjectSettings {
              accountFieldNames {
                accountFieldName
                objectFieldName
                __typename
              }
              apiName
              __typename
            }
            __typename
          }
          loginUrl
          crmStandaloneForms {
            id
            apiName
            children {
              name
              apiName
              relationshipName
              type
              veevaSetting {
                markerFieldName
                __typename
              }
              __typename
            }
            type
            parents {
              apiName
              relationshipName
              __typename
            }
            usage
            __typename
          }
          __typename
        }
        sessionTimeout
        quickFilters {
          id
          label
          path
          __typename
        }
        hubsConfig {
          shareExpirationDays
          disabledSections
          disableNotationDescription
          __typename
        }
        reports {
          key
          value
          __typename
        }
        emailDocumentUpdateFrequency
        sharePPTXasPDF
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncTenantsQueryVariables,
  APITypes.SyncTenantsQuery
>;
export const getDocumentVersion = /* GraphQL */ `query GetDocumentVersion($id: ID!) {
  getDocumentVersion(id: $id) {
    id
    tenantId
    documentId
    versionNumber
    srcFilename
    contentURL
    conversionStatus
    status
    srcFile {
      bucket
      region
      key
      url
      __typename
    }
    srcHash
    srcSize
    numPages
    pageSettings {
      pageId
      number
      isRequired
      linkedSlides
      __typename
    }
    pageGroups {
      id
      pageIds
      name
      locked
      sourceID
      source
      __typename
    }
    type
    releaseNotes
    changeType
    labelValues {
      key
      value
      __typename
    }
    customValues {
      fieldId
      objectRecords {
        id
        externalId
        syncStatus
        status
        values {
          fieldId
          values
          __typename
        }
        __typename
      }
      values
      __typename
    }
    title
    shortDescription
    longDescription
    owner
    expiresAt
    hasCopyright
    hasCustomThumbnail
    purpose
    canHideSlides
    distributable
    downloadable
    isInternalGenerated
    semVer {
      minor
      major
      __typename
    }
    notificationScope
    selectedThumbnail
    publishedAt
    uploadedAt
    uploadedBy
    convertedArchiveKey
    convertedArchiveSize
    convertedFolderKey
    associatedFiles {
      id
      isDistributable
      isDefault
      type
      attachmentId
      status
      createdAt
      createdBy
      __typename
    }
    editPermissions
    converterVersion
    createdAt
    createdBy
    updatedAt
    updatedBy
    integration {
      externalVersionId
      version
      timestamp
      srcFileHash
      srcDocumentHash
      __typename
    }
    integrationType
    conversionWarningCode
    conversionErrorCode
    scheduledPublish
    mappingStatus
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDocumentVersionQueryVariables,
  APITypes.GetDocumentVersionQuery
>;
export const listDocumentVersions = /* GraphQL */ `query ListDocumentVersions(
  $filter: ModelDocumentVersionFilterInput
  $limit: Int
  $nextToken: String
) {
  listDocumentVersions(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      tenantId
      documentId
      versionNumber
      srcFilename
      contentURL
      conversionStatus
      status
      srcFile {
        bucket
        region
        key
        url
        __typename
      }
      srcHash
      srcSize
      numPages
      pageSettings {
        pageId
        number
        isRequired
        linkedSlides
        __typename
      }
      pageGroups {
        id
        pageIds
        name
        locked
        sourceID
        source
        __typename
      }
      type
      releaseNotes
      changeType
      labelValues {
        key
        value
        __typename
      }
      customValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
            __typename
          }
          __typename
        }
        values
        __typename
      }
      title
      shortDescription
      longDescription
      owner
      expiresAt
      hasCopyright
      hasCustomThumbnail
      purpose
      canHideSlides
      distributable
      downloadable
      isInternalGenerated
      semVer {
        minor
        major
        __typename
      }
      notificationScope
      selectedThumbnail
      publishedAt
      uploadedAt
      uploadedBy
      convertedArchiveKey
      convertedArchiveSize
      convertedFolderKey
      associatedFiles {
        id
        isDistributable
        isDefault
        type
        attachmentId
        status
        createdAt
        createdBy
        __typename
      }
      editPermissions
      converterVersion
      createdAt
      createdBy
      updatedAt
      updatedBy
      integration {
        externalVersionId
        version
        timestamp
        srcFileHash
        srcDocumentHash
        __typename
      }
      integrationType
      conversionWarningCode
      conversionErrorCode
      scheduledPublish
      mappingStatus
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDocumentVersionsQueryVariables,
  APITypes.ListDocumentVersionsQuery
>;
export const syncDocumentVersions = /* GraphQL */ `query SyncDocumentVersions(
  $filter: ModelDocumentVersionFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncDocumentVersions(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      tenantId
      documentId
      versionNumber
      srcFilename
      contentURL
      conversionStatus
      status
      srcFile {
        bucket
        region
        key
        url
        __typename
      }
      srcHash
      srcSize
      numPages
      pageSettings {
        pageId
        number
        isRequired
        linkedSlides
        __typename
      }
      pageGroups {
        id
        pageIds
        name
        locked
        sourceID
        source
        __typename
      }
      type
      releaseNotes
      changeType
      labelValues {
        key
        value
        __typename
      }
      customValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
            __typename
          }
          __typename
        }
        values
        __typename
      }
      title
      shortDescription
      longDescription
      owner
      expiresAt
      hasCopyright
      hasCustomThumbnail
      purpose
      canHideSlides
      distributable
      downloadable
      isInternalGenerated
      semVer {
        minor
        major
        __typename
      }
      notificationScope
      selectedThumbnail
      publishedAt
      uploadedAt
      uploadedBy
      convertedArchiveKey
      convertedArchiveSize
      convertedFolderKey
      associatedFiles {
        id
        isDistributable
        isDefault
        type
        attachmentId
        status
        createdAt
        createdBy
        __typename
      }
      editPermissions
      converterVersion
      createdAt
      createdBy
      updatedAt
      updatedBy
      integration {
        externalVersionId
        version
        timestamp
        srcFileHash
        srcDocumentHash
        __typename
      }
      integrationType
      conversionWarningCode
      conversionErrorCode
      scheduledPublish
      mappingStatus
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncDocumentVersionsQueryVariables,
  APITypes.SyncDocumentVersionsQuery
>;
export const documentVersionsByTenantIdStatus = /* GraphQL */ `query DocumentVersionsByTenantIdStatus(
  $tenantId: ID!
  $status: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelDocumentVersionFilterInput
  $limit: Int
  $nextToken: String
) {
  documentVersionsByTenantIdStatus(
    tenantId: $tenantId
    status: $status
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tenantId
      documentId
      versionNumber
      srcFilename
      contentURL
      conversionStatus
      status
      srcFile {
        bucket
        region
        key
        url
        __typename
      }
      srcHash
      srcSize
      numPages
      pageSettings {
        pageId
        number
        isRequired
        linkedSlides
        __typename
      }
      pageGroups {
        id
        pageIds
        name
        locked
        sourceID
        source
        __typename
      }
      type
      releaseNotes
      changeType
      labelValues {
        key
        value
        __typename
      }
      customValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
            __typename
          }
          __typename
        }
        values
        __typename
      }
      title
      shortDescription
      longDescription
      owner
      expiresAt
      hasCopyright
      hasCustomThumbnail
      purpose
      canHideSlides
      distributable
      downloadable
      isInternalGenerated
      semVer {
        minor
        major
        __typename
      }
      notificationScope
      selectedThumbnail
      publishedAt
      uploadedAt
      uploadedBy
      convertedArchiveKey
      convertedArchiveSize
      convertedFolderKey
      associatedFiles {
        id
        isDistributable
        isDefault
        type
        attachmentId
        status
        createdAt
        createdBy
        __typename
      }
      editPermissions
      converterVersion
      createdAt
      createdBy
      updatedAt
      updatedBy
      integration {
        externalVersionId
        version
        timestamp
        srcFileHash
        srcDocumentHash
        __typename
      }
      integrationType
      conversionWarningCode
      conversionErrorCode
      scheduledPublish
      mappingStatus
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.DocumentVersionsByTenantIdStatusQueryVariables,
  APITypes.DocumentVersionsByTenantIdStatusQuery
>;
export const documentVersionsByDocumentIdVersionNumber = /* GraphQL */ `query DocumentVersionsByDocumentIdVersionNumber(
  $documentId: ID!
  $versionNumber: ModelIntKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelDocumentVersionFilterInput
  $limit: Int
  $nextToken: String
) {
  documentVersionsByDocumentIdVersionNumber(
    documentId: $documentId
    versionNumber: $versionNumber
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tenantId
      documentId
      versionNumber
      srcFilename
      contentURL
      conversionStatus
      status
      srcFile {
        bucket
        region
        key
        url
        __typename
      }
      srcHash
      srcSize
      numPages
      pageSettings {
        pageId
        number
        isRequired
        linkedSlides
        __typename
      }
      pageGroups {
        id
        pageIds
        name
        locked
        sourceID
        source
        __typename
      }
      type
      releaseNotes
      changeType
      labelValues {
        key
        value
        __typename
      }
      customValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
            __typename
          }
          __typename
        }
        values
        __typename
      }
      title
      shortDescription
      longDescription
      owner
      expiresAt
      hasCopyright
      hasCustomThumbnail
      purpose
      canHideSlides
      distributable
      downloadable
      isInternalGenerated
      semVer {
        minor
        major
        __typename
      }
      notificationScope
      selectedThumbnail
      publishedAt
      uploadedAt
      uploadedBy
      convertedArchiveKey
      convertedArchiveSize
      convertedFolderKey
      associatedFiles {
        id
        isDistributable
        isDefault
        type
        attachmentId
        status
        createdAt
        createdBy
        __typename
      }
      editPermissions
      converterVersion
      createdAt
      createdBy
      updatedAt
      updatedBy
      integration {
        externalVersionId
        version
        timestamp
        srcFileHash
        srcDocumentHash
        __typename
      }
      integrationType
      conversionWarningCode
      conversionErrorCode
      scheduledPublish
      mappingStatus
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.DocumentVersionsByDocumentIdVersionNumberQueryVariables,
  APITypes.DocumentVersionsByDocumentIdVersionNumberQuery
>;
export const getAttachedFile = /* GraphQL */ `query GetAttachedFile($id: ID!) {
  getAttachedFile(id: $id) {
    id
    title
    tenantId
    documentId
    srcFile {
      bucket
      region
      key
      url
      __typename
    }
    srcFileName
    srcHash
    srcSize
    type
    editPermissions
    createdAt
    createdBy
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAttachedFileQueryVariables,
  APITypes.GetAttachedFileQuery
>;
export const listAttachedFiles = /* GraphQL */ `query ListAttachedFiles(
  $filter: ModelAttachedFileFilterInput
  $limit: Int
  $nextToken: String
) {
  listAttachedFiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      tenantId
      documentId
      srcFile {
        bucket
        region
        key
        url
        __typename
      }
      srcFileName
      srcHash
      srcSize
      type
      editPermissions
      createdAt
      createdBy
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAttachedFilesQueryVariables,
  APITypes.ListAttachedFilesQuery
>;
export const syncAttachedFiles = /* GraphQL */ `query SyncAttachedFiles(
  $filter: ModelAttachedFileFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncAttachedFiles(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      title
      tenantId
      documentId
      srcFile {
        bucket
        region
        key
        url
        __typename
      }
      srcFileName
      srcHash
      srcSize
      type
      editPermissions
      createdAt
      createdBy
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncAttachedFilesQueryVariables,
  APITypes.SyncAttachedFilesQuery
>;
export const getDocument = /* GraphQL */ `query GetDocument($id: ID!) {
  getDocument(id: $id) {
    id
    tenantId
    accessLevel
    status
    owner
    expiresAt
    integration {
      integrationId
      externalId
      firstSync
      lastSync
      __typename
    }
    integrationType
    type
    editPermissions
    createdAt
    createdBy
    updatedAt
    updatedBy
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDocumentQueryVariables,
  APITypes.GetDocumentQuery
>;
export const listDocuments = /* GraphQL */ `query ListDocuments(
  $filter: ModelDocumentFilterInput
  $limit: Int
  $nextToken: String
) {
  listDocuments(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      tenantId
      accessLevel
      status
      owner
      expiresAt
      integration {
        integrationId
        externalId
        firstSync
        lastSync
        __typename
      }
      integrationType
      type
      editPermissions
      createdAt
      createdBy
      updatedAt
      updatedBy
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDocumentsQueryVariables,
  APITypes.ListDocumentsQuery
>;
export const syncDocuments = /* GraphQL */ `query SyncDocuments(
  $filter: ModelDocumentFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncDocuments(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      tenantId
      accessLevel
      status
      owner
      expiresAt
      integration {
        integrationId
        externalId
        firstSync
        lastSync
        __typename
      }
      integrationType
      type
      editPermissions
      createdAt
      createdBy
      updatedAt
      updatedBy
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncDocumentsQueryVariables,
  APITypes.SyncDocumentsQuery
>;
export const getEmailTemplate = /* GraphQL */ `query GetEmailTemplate($id: ID!) {
  getEmailTemplate(id: $id) {
    id
    tenantId
    labelValues {
      key
      value
      __typename
    }
    customFilterValues {
      fieldId
      objectRecords {
        id
        externalId
        syncStatus
        status
        values {
          fieldId
          values
          __typename
        }
        __typename
      }
      values
      __typename
    }
    subject
    title
    cc
    bcc
    body
    associatedFiles {
      id
      isDistributable
      isDefault
      type
      attachmentId
      status
      createdAt
      createdBy
      __typename
    }
    status
    editPermissions
    createdBy
    createdAt
    updatedBy
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetEmailTemplateQueryVariables,
  APITypes.GetEmailTemplateQuery
>;
export const listEmailTemplates = /* GraphQL */ `query ListEmailTemplates(
  $filter: ModelEmailTemplateFilterInput
  $limit: Int
  $nextToken: String
) {
  listEmailTemplates(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      tenantId
      labelValues {
        key
        value
        __typename
      }
      customFilterValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
            __typename
          }
          __typename
        }
        values
        __typename
      }
      subject
      title
      cc
      bcc
      body
      associatedFiles {
        id
        isDistributable
        isDefault
        type
        attachmentId
        status
        createdAt
        createdBy
        __typename
      }
      status
      editPermissions
      createdBy
      createdAt
      updatedBy
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListEmailTemplatesQueryVariables,
  APITypes.ListEmailTemplatesQuery
>;
export const syncEmailTemplates = /* GraphQL */ `query SyncEmailTemplates(
  $filter: ModelEmailTemplateFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncEmailTemplates(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      tenantId
      labelValues {
        key
        value
        __typename
      }
      customFilterValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
            __typename
          }
          __typename
        }
        values
        __typename
      }
      subject
      title
      cc
      bcc
      body
      associatedFiles {
        id
        isDistributable
        isDefault
        type
        attachmentId
        status
        createdAt
        createdBy
        __typename
      }
      status
      editPermissions
      createdBy
      createdAt
      updatedBy
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncEmailTemplatesQueryVariables,
  APITypes.SyncEmailTemplatesQuery
>;
export const getDocumentSetting = /* GraphQL */ `query GetDocumentSetting($id: ID!) {
  getDocumentSetting(id: $id) {
    id
    tenantId
    createdAt
    createdBy
    updatedAt
    updatedBy
    documentId
    documentVersionId
    notation {
      id
      type
      description
      status
      pageId
      coordinate {
        x
        y
        __typename
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      __typename
    }
    status
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDocumentSettingQueryVariables,
  APITypes.GetDocumentSettingQuery
>;
export const listDocumentSettings = /* GraphQL */ `query ListDocumentSettings(
  $filter: ModelDocumentSettingFilterInput
  $limit: Int
  $nextToken: String
) {
  listDocumentSettings(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      tenantId
      createdAt
      createdBy
      updatedAt
      updatedBy
      documentId
      documentVersionId
      notation {
        id
        type
        description
        status
        pageId
        coordinate {
          x
          y
          __typename
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        __typename
      }
      status
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDocumentSettingsQueryVariables,
  APITypes.ListDocumentSettingsQuery
>;
export const syncDocumentSettings = /* GraphQL */ `query SyncDocumentSettings(
  $filter: ModelDocumentSettingFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncDocumentSettings(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      tenantId
      createdAt
      createdBy
      updatedAt
      updatedBy
      documentId
      documentVersionId
      notation {
        id
        type
        description
        status
        pageId
        coordinate {
          x
          y
          __typename
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        __typename
      }
      status
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncDocumentSettingsQueryVariables,
  APITypes.SyncDocumentSettingsQuery
>;
export const getUserNotations = /* GraphQL */ `query GetUserNotations($id: ID!) {
  getUserNotations(id: $id) {
    id
    tenantId
    createdAt
    createdBy
    updatedAt
    updatedBy
    documentId
    documentVersionId
    customDeckId
    notation {
      id
      type
      description
      status
      pageId
      coordinate {
        x
        y
        __typename
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      __typename
    }
    status
    type
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserNotationsQueryVariables,
  APITypes.GetUserNotationsQuery
>;
export const listUserNotations = /* GraphQL */ `query ListUserNotations(
  $filter: ModelUserNotationsFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserNotations(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      tenantId
      createdAt
      createdBy
      updatedAt
      updatedBy
      documentId
      documentVersionId
      customDeckId
      notation {
        id
        type
        description
        status
        pageId
        coordinate {
          x
          y
          __typename
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        __typename
      }
      status
      type
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListUserNotationsQueryVariables,
  APITypes.ListUserNotationsQuery
>;
export const syncUserNotations = /* GraphQL */ `query SyncUserNotations(
  $filter: ModelUserNotationsFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncUserNotations(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      tenantId
      createdAt
      createdBy
      updatedAt
      updatedBy
      documentId
      documentVersionId
      customDeckId
      notation {
        id
        type
        description
        status
        pageId
        coordinate {
          x
          y
          __typename
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        __typename
      }
      status
      type
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncUserNotationsQueryVariables,
  APITypes.SyncUserNotationsQuery
>;
export const getCustomDeck = /* GraphQL */ `query GetCustomDeck($id: ID!) {
  getCustomDeck(id: $id) {
    id
    createdAt
    createdBy
    autoUpdateAcknowledgedAt
    updatedAt
    updatedBy
    tenantId
    groups {
      id
      pages {
        pageId
        pageNumber
        documentVersionId
        __typename
      }
      visible
      srcId
      docAccessLevel
      name
      locked
      __typename
    }
    title
    editMutex {
      userId
      timeStarted
      lastSeenAt
      __typename
    }
    sourceCustomDeckId
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCustomDeckQueryVariables,
  APITypes.GetCustomDeckQuery
>;
export const listCustomDecks = /* GraphQL */ `query ListCustomDecks(
  $filter: ModelCustomDeckFilterInput
  $limit: Int
  $nextToken: String
) {
  listCustomDecks(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      createdAt
      createdBy
      autoUpdateAcknowledgedAt
      updatedAt
      updatedBy
      tenantId
      groups {
        id
        pages {
          pageId
          pageNumber
          documentVersionId
          __typename
        }
        visible
        srcId
        docAccessLevel
        name
        locked
        __typename
      }
      title
      editMutex {
        userId
        timeStarted
        lastSeenAt
        __typename
      }
      sourceCustomDeckId
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCustomDecksQueryVariables,
  APITypes.ListCustomDecksQuery
>;
export const syncCustomDecks = /* GraphQL */ `query SyncCustomDecks(
  $filter: ModelCustomDeckFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncCustomDecks(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      createdAt
      createdBy
      autoUpdateAcknowledgedAt
      updatedAt
      updatedBy
      tenantId
      groups {
        id
        pages {
          pageId
          pageNumber
          documentVersionId
          __typename
        }
        visible
        srcId
        docAccessLevel
        name
        locked
        __typename
      }
      title
      editMutex {
        userId
        timeStarted
        lastSeenAt
        __typename
      }
      sourceCustomDeckId
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncCustomDecksQueryVariables,
  APITypes.SyncCustomDecksQuery
>;
export const getFolder = /* GraphQL */ `query GetFolder($id: ID!) {
  getFolder(id: $id) {
    id
    tenantId
    name
    isPinned
    status
    items {
      id
      type
      itemId
      status
      addedAt
      updateAcknowledgedAt
      itemLastUpdatedAt
      visiblePages
      customTitle
      __typename
    }
    createdAt
    createdBy
    updatedAt
    updatedBy
    sharePermissions {
      id
      isDeleted
      type
      targetType
      targetUsername
      targetCustomValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
            __typename
          }
          __typename
        }
        values
        __typename
      }
      targetLabels {
        key
        values
        __typename
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      deletedBy
      deletedAt
      __typename
    }
    shareStatus
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetFolderQueryVariables, APITypes.GetFolderQuery>;
export const listFolders = /* GraphQL */ `query ListFolders(
  $filter: ModelFolderFilterInput
  $limit: Int
  $nextToken: String
) {
  listFolders(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      tenantId
      name
      isPinned
      status
      items {
        id
        type
        itemId
        status
        addedAt
        updateAcknowledgedAt
        itemLastUpdatedAt
        visiblePages
        customTitle
        __typename
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      sharePermissions {
        id
        isDeleted
        type
        targetType
        targetUsername
        targetCustomValues {
          fieldId
          objectRecords {
            id
            externalId
            syncStatus
            status
            values {
              fieldId
              values
              __typename
            }
            __typename
          }
          values
          __typename
        }
        targetLabels {
          key
          values
          __typename
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        deletedBy
        deletedAt
        __typename
      }
      shareStatus
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListFoldersQueryVariables,
  APITypes.ListFoldersQuery
>;
export const syncFolders = /* GraphQL */ `query SyncFolders(
  $filter: ModelFolderFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncFolders(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      tenantId
      name
      isPinned
      status
      items {
        id
        type
        itemId
        status
        addedAt
        updateAcknowledgedAt
        itemLastUpdatedAt
        visiblePages
        customTitle
        __typename
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      sharePermissions {
        id
        isDeleted
        type
        targetType
        targetUsername
        targetCustomValues {
          fieldId
          objectRecords {
            id
            externalId
            syncStatus
            status
            values {
              fieldId
              values
              __typename
            }
            __typename
          }
          values
          __typename
        }
        targetLabels {
          key
          values
          __typename
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        deletedBy
        deletedAt
        __typename
      }
      shareStatus
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncFoldersQueryVariables,
  APITypes.SyncFoldersQuery
>;
export const foldersByTenantStatusShared = /* GraphQL */ `query FoldersByTenantStatusShared(
  $tenantId: ID!
  $statusShareStatus: ModelFolderByTenantStatusSharedCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelFolderFilterInput
  $limit: Int
  $nextToken: String
) {
  foldersByTenantStatusShared(
    tenantId: $tenantId
    statusShareStatus: $statusShareStatus
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tenantId
      name
      isPinned
      status
      items {
        id
        type
        itemId
        status
        addedAt
        updateAcknowledgedAt
        itemLastUpdatedAt
        visiblePages
        customTitle
        __typename
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      sharePermissions {
        id
        isDeleted
        type
        targetType
        targetUsername
        targetCustomValues {
          fieldId
          objectRecords {
            id
            externalId
            syncStatus
            status
            values {
              fieldId
              values
              __typename
            }
            __typename
          }
          values
          __typename
        }
        targetLabels {
          key
          values
          __typename
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        deletedBy
        deletedAt
        __typename
      }
      shareStatus
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.FoldersByTenantStatusSharedQueryVariables,
  APITypes.FoldersByTenantStatusSharedQuery
>;
export const getContentShare = /* GraphQL */ `query GetContentShare($id: ID!) {
  getContentShare(id: $id) {
    id
    token
    tenantId
    type
    contentId
    meetingId
    expiresAt
    createdAt
    updatedAt
    createdBy
    customValues {
      fieldId
      objectRecords {
        id
        externalId
        syncStatus
        status
        values {
          fieldId
          values
          __typename
        }
        __typename
      }
      values
      __typename
    }
    revoked
    customDeckDependencies {
      versions
      folderId
      __typename
    }
    notificationScope
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetContentShareQueryVariables,
  APITypes.GetContentShareQuery
>;
export const listContentShares = /* GraphQL */ `query ListContentShares(
  $filter: ModelContentShareFilterInput
  $limit: Int
  $nextToken: String
) {
  listContentShares(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      token
      tenantId
      type
      contentId
      meetingId
      expiresAt
      createdAt
      updatedAt
      createdBy
      customValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
            __typename
          }
          __typename
        }
        values
        __typename
      }
      revoked
      customDeckDependencies {
        versions
        folderId
        __typename
      }
      notificationScope
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListContentSharesQueryVariables,
  APITypes.ListContentSharesQuery
>;
export const syncContentShares = /* GraphQL */ `query SyncContentShares(
  $filter: ModelContentShareFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncContentShares(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      token
      tenantId
      type
      contentId
      meetingId
      expiresAt
      createdAt
      updatedAt
      createdBy
      customValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
            __typename
          }
          __typename
        }
        values
        __typename
      }
      revoked
      customDeckDependencies {
        versions
        folderId
        __typename
      }
      notificationScope
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncContentSharesQueryVariables,
  APITypes.SyncContentSharesQuery
>;
export const contentShareByToken = /* GraphQL */ `query ContentShareByToken(
  $token: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelContentShareFilterInput
  $limit: Int
  $nextToken: String
) {
  contentShareByToken(
    token: $token
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      token
      tenantId
      type
      contentId
      meetingId
      expiresAt
      createdAt
      updatedAt
      createdBy
      customValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
            __typename
          }
          __typename
        }
        values
        __typename
      }
      revoked
      customDeckDependencies {
        versions
        folderId
        __typename
      }
      notificationScope
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ContentShareByTokenQueryVariables,
  APITypes.ContentShareByTokenQuery
>;
export const contentShareByContentID = /* GraphQL */ `query ContentShareByContentID(
  $contentId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelContentShareFilterInput
  $limit: Int
  $nextToken: String
) {
  contentShareByContentID(
    contentId: $contentId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      token
      tenantId
      type
      contentId
      meetingId
      expiresAt
      createdAt
      updatedAt
      createdBy
      customValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
            __typename
          }
          __typename
        }
        values
        __typename
      }
      revoked
      customDeckDependencies {
        versions
        folderId
        __typename
      }
      notificationScope
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ContentShareByContentIDQueryVariables,
  APITypes.ContentShareByContentIDQuery
>;
export const getIntegrationLog = /* GraphQL */ `query GetIntegrationLog($id: ID!) {
  getIntegrationLog(id: $id) {
    integrationId
    id
    log {
      level
      timestamp
      message
      srcDocId
      srcDocVersion
      __typename
    }
    status
    tenantId
    createdAt
    updatedAt
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetIntegrationLogQueryVariables,
  APITypes.GetIntegrationLogQuery
>;
export const listIntegrationLogs = /* GraphQL */ `query ListIntegrationLogs(
  $filter: ModelIntegrationLogFilterInput
  $limit: Int
  $nextToken: String
) {
  listIntegrationLogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      integrationId
      id
      log {
        level
        timestamp
        message
        srcDocId
        srcDocVersion
        __typename
      }
      status
      tenantId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListIntegrationLogsQueryVariables,
  APITypes.ListIntegrationLogsQuery
>;
export const syncIntegrationLogs = /* GraphQL */ `query SyncIntegrationLogs(
  $filter: ModelIntegrationLogFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncIntegrationLogs(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      integrationId
      id
      log {
        level
        timestamp
        message
        srcDocId
        srcDocVersion
        __typename
      }
      status
      tenantId
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncIntegrationLogsQueryVariables,
  APITypes.SyncIntegrationLogsQuery
>;
export const getIntegrationSettings = /* GraphQL */ `query GetIntegrationSettings($id: ID!) {
  getIntegrationSettings(id: $id) {
    id
    tenantId
    syncContentEvery
    name
    mapping {
      key
      fieldType
      dataType
      targetFieldName
      srcFieldname
      valueMappings {
        srcValue
        targetValue
        __typename
      }
      __typename
    }
    clientConfigurationFields {
      key
      value
      __typename
    }
    integrationType
    enabled
    notificationEmail
    errorSyncEmail
    createdAt
    createdBy
    updatedAt
    updatedBy
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetIntegrationSettingsQueryVariables,
  APITypes.GetIntegrationSettingsQuery
>;
export const listIntegrationSettings = /* GraphQL */ `query ListIntegrationSettings(
  $filter: ModelIntegrationSettingsFilterInput
  $limit: Int
  $nextToken: String
) {
  listIntegrationSettings(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tenantId
      syncContentEvery
      name
      mapping {
        key
        fieldType
        dataType
        targetFieldName
        srcFieldname
        valueMappings {
          srcValue
          targetValue
          __typename
        }
        __typename
      }
      clientConfigurationFields {
        key
        value
        __typename
      }
      integrationType
      enabled
      notificationEmail
      errorSyncEmail
      createdAt
      createdBy
      updatedAt
      updatedBy
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListIntegrationSettingsQueryVariables,
  APITypes.ListIntegrationSettingsQuery
>;
export const syncIntegrationSettings = /* GraphQL */ `query SyncIntegrationSettings(
  $filter: ModelIntegrationSettingsFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncIntegrationSettings(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      tenantId
      syncContentEvery
      name
      mapping {
        key
        fieldType
        dataType
        targetFieldName
        srcFieldname
        valueMappings {
          srcValue
          targetValue
          __typename
        }
        __typename
      }
      clientConfigurationFields {
        key
        value
        __typename
      }
      integrationType
      enabled
      notificationEmail
      errorSyncEmail
      createdAt
      createdBy
      updatedAt
      updatedBy
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncIntegrationSettingsQueryVariables,
  APITypes.SyncIntegrationSettingsQuery
>;
export const getMeeting = /* GraphQL */ `query GetMeeting($id: ID!) {
  getMeeting(id: $id) {
    id
    tenantId
    title
    startTime
    endTime
    contentPresented {
      contentId
      groupId
      folderItemId
      title
      groupTitle
      status
      contentType
      events {
        pageNumber
        pageId
        timestamp
        end
        __typename
      }
      openedAt
      closedAt
      presentedMeta {
        pageId
        presented
        followUp
        sentiment
        note
        title
        __typename
      }
      __typename
    }
    type
    fieldValues {
      fieldId
      valueOrId
      __typename
    }
    customValues {
      fieldId
      objectRecords {
        id
        externalId
        syncStatus
        status
        values {
          fieldId
          values
          __typename
        }
        __typename
      }
      values
      __typename
    }
    notes
    status
    createdAt
    createdBy
    updatedAt
    updatedBy
    attendees {
      id
      name
      status
      updatedAt
      email
      crmAccountId
      crmAddressId
      attendeeType
      crmRecord {
        crmCallId
        crmCustomValues {
          fieldId
          objectRecords {
            id
            externalId
            syncStatus
            status
            values {
              fieldId
              values
              __typename
            }
            __typename
          }
          values
          __typename
        }
        crmSyncStatus
        lastSyncedAt
        __typename
      }
      lastSyncedAt
      __typename
    }
    crmRecord {
      crmCallId
      crmCustomValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
            __typename
          }
          __typename
        }
        values
        __typename
      }
      crmSyncStatus
      lastSyncedAt
      __typename
    }
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMeetingQueryVariables,
  APITypes.GetMeetingQuery
>;
export const listMeetings = /* GraphQL */ `query ListMeetings(
  $filter: ModelMeetingFilterInput
  $limit: Int
  $nextToken: String
) {
  listMeetings(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      tenantId
      title
      startTime
      endTime
      contentPresented {
        contentId
        groupId
        folderItemId
        title
        groupTitle
        status
        contentType
        events {
          pageNumber
          pageId
          timestamp
          end
          __typename
        }
        openedAt
        closedAt
        presentedMeta {
          pageId
          presented
          followUp
          sentiment
          note
          title
          __typename
        }
        __typename
      }
      type
      fieldValues {
        fieldId
        valueOrId
        __typename
      }
      customValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
            __typename
          }
          __typename
        }
        values
        __typename
      }
      notes
      status
      createdAt
      createdBy
      updatedAt
      updatedBy
      attendees {
        id
        name
        status
        updatedAt
        email
        crmAccountId
        crmAddressId
        attendeeType
        crmRecord {
          crmCallId
          crmCustomValues {
            fieldId
            objectRecords {
              id
              externalId
              syncStatus
              status
              values {
                fieldId
                values
                __typename
              }
              __typename
            }
            values
            __typename
          }
          crmSyncStatus
          lastSyncedAt
          __typename
        }
        lastSyncedAt
        __typename
      }
      crmRecord {
        crmCallId
        crmCustomValues {
          fieldId
          objectRecords {
            id
            externalId
            syncStatus
            status
            values {
              fieldId
              values
              __typename
            }
            __typename
          }
          values
          __typename
        }
        crmSyncStatus
        lastSyncedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMeetingsQueryVariables,
  APITypes.ListMeetingsQuery
>;
export const syncMeetings = /* GraphQL */ `query SyncMeetings(
  $filter: ModelMeetingFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncMeetings(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      tenantId
      title
      startTime
      endTime
      contentPresented {
        contentId
        groupId
        folderItemId
        title
        groupTitle
        status
        contentType
        events {
          pageNumber
          pageId
          timestamp
          end
          __typename
        }
        openedAt
        closedAt
        presentedMeta {
          pageId
          presented
          followUp
          sentiment
          note
          title
          __typename
        }
        __typename
      }
      type
      fieldValues {
        fieldId
        valueOrId
        __typename
      }
      customValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
            __typename
          }
          __typename
        }
        values
        __typename
      }
      notes
      status
      createdAt
      createdBy
      updatedAt
      updatedBy
      attendees {
        id
        name
        status
        updatedAt
        email
        crmAccountId
        crmAddressId
        attendeeType
        crmRecord {
          crmCallId
          crmCustomValues {
            fieldId
            objectRecords {
              id
              externalId
              syncStatus
              status
              values {
                fieldId
                values
                __typename
              }
              __typename
            }
            values
            __typename
          }
          crmSyncStatus
          lastSyncedAt
          __typename
        }
        lastSyncedAt
        __typename
      }
      crmRecord {
        crmCallId
        crmCustomValues {
          fieldId
          objectRecords {
            id
            externalId
            syncStatus
            status
            values {
              fieldId
              values
              __typename
            }
            __typename
          }
          values
          __typename
        }
        crmSyncStatus
        lastSyncedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncMeetingsQueryVariables,
  APITypes.SyncMeetingsQuery
>;
export const attendemeetingsByCreatedByStartTimeesByHostIdStatus = /* GraphQL */ `query AttendemeetingsByCreatedByStartTimeesByHostIdStatus(
  $createdBy: ID!
  $startTime: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelMeetingFilterInput
  $limit: Int
  $nextToken: String
) {
  attendemeetingsByCreatedByStartTimeesByHostIdStatus(
    createdBy: $createdBy
    startTime: $startTime
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tenantId
      title
      startTime
      endTime
      contentPresented {
        contentId
        groupId
        folderItemId
        title
        groupTitle
        status
        contentType
        events {
          pageNumber
          pageId
          timestamp
          end
          __typename
        }
        openedAt
        closedAt
        presentedMeta {
          pageId
          presented
          followUp
          sentiment
          note
          title
          __typename
        }
        __typename
      }
      type
      fieldValues {
        fieldId
        valueOrId
        __typename
      }
      customValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
            __typename
          }
          __typename
        }
        values
        __typename
      }
      notes
      status
      createdAt
      createdBy
      updatedAt
      updatedBy
      attendees {
        id
        name
        status
        updatedAt
        email
        crmAccountId
        crmAddressId
        attendeeType
        crmRecord {
          crmCallId
          crmCustomValues {
            fieldId
            objectRecords {
              id
              externalId
              syncStatus
              status
              values {
                fieldId
                values
                __typename
              }
              __typename
            }
            values
            __typename
          }
          crmSyncStatus
          lastSyncedAt
          __typename
        }
        lastSyncedAt
        __typename
      }
      crmRecord {
        crmCallId
        crmCustomValues {
          fieldId
          objectRecords {
            id
            externalId
            syncStatus
            status
            values {
              fieldId
              values
              __typename
            }
            __typename
          }
          values
          __typename
        }
        crmSyncStatus
        lastSyncedAt
        __typename
      }
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AttendemeetingsByCreatedByStartTimeesByHostIdStatusQueryVariables,
  APITypes.AttendemeetingsByCreatedByStartTimeesByHostIdStatusQuery
>;
export const getHub = /* GraphQL */ `query GetHub($id: ID!) {
  getHub(id: $id) {
    id
    tenantId
    name
    status
    createdAt
    createdBy
    updatedAt
    updatedBy
    hubSections {
      id
      sectionHeader
      status
      type
      visible
      order
      textarea {
        content
        __typename
      }
      sharedFiles {
        id
        contentId
        title
        contentType
        documentVersionSettings {
          associatedFiles {
            associatedFileId
            versionId
            notation {
              id
              type
              description
              status
              pageId
              coordinate {
                x
                y
                __typename
              }
              createdAt
              createdBy
              updatedAt
              updatedBy
              __typename
            }
            __typename
          }
          __typename
        }
        status
        createdAt
        updatedAt
        notation {
          id
          type
          description
          status
          pageId
          coordinate {
            x
            y
            __typename
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
          __typename
        }
        __typename
      }
      toDos {
        id
        title
        status
        completedAt
        resolution
        order
        createdAt
        updatedAt
        __typename
      }
      links {
        id
        url
        title
        status
        order
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    customValues {
      fieldId
      objectRecords {
        id
        externalId
        syncStatus
        status
        values {
          fieldId
          values
          __typename
        }
        __typename
      }
      values
      __typename
    }
    meetingId
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetHubQueryVariables, APITypes.GetHubQuery>;
export const listHubs = /* GraphQL */ `query ListHubs($filter: ModelHubFilterInput, $limit: Int, $nextToken: String) {
  listHubs(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      tenantId
      name
      status
      createdAt
      createdBy
      updatedAt
      updatedBy
      hubSections {
        id
        sectionHeader
        status
        type
        visible
        order
        textarea {
          content
          __typename
        }
        sharedFiles {
          id
          contentId
          title
          contentType
          documentVersionSettings {
            associatedFiles {
              associatedFileId
              versionId
              notation {
                id
                type
                description
                status
                pageId
                coordinate {
                  x
                  y
                  __typename
                }
                createdAt
                createdBy
                updatedAt
                updatedBy
                __typename
              }
              __typename
            }
            __typename
          }
          status
          createdAt
          updatedAt
          notation {
            id
            type
            description
            status
            pageId
            coordinate {
              x
              y
              __typename
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
            __typename
          }
          __typename
        }
        toDos {
          id
          title
          status
          completedAt
          resolution
          order
          createdAt
          updatedAt
          __typename
        }
        links {
          id
          url
          title
          status
          order
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      customValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
            __typename
          }
          __typename
        }
        values
        __typename
      }
      meetingId
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.ListHubsQueryVariables, APITypes.ListHubsQuery>;
export const syncHubs = /* GraphQL */ `query SyncHubs(
  $filter: ModelHubFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncHubs(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      tenantId
      name
      status
      createdAt
      createdBy
      updatedAt
      updatedBy
      hubSections {
        id
        sectionHeader
        status
        type
        visible
        order
        textarea {
          content
          __typename
        }
        sharedFiles {
          id
          contentId
          title
          contentType
          documentVersionSettings {
            associatedFiles {
              associatedFileId
              versionId
              notation {
                id
                type
                description
                status
                pageId
                coordinate {
                  x
                  y
                  __typename
                }
                createdAt
                createdBy
                updatedAt
                updatedBy
                __typename
              }
              __typename
            }
            __typename
          }
          status
          createdAt
          updatedAt
          notation {
            id
            type
            description
            status
            pageId
            coordinate {
              x
              y
              __typename
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
            __typename
          }
          __typename
        }
        toDos {
          id
          title
          status
          completedAt
          resolution
          order
          createdAt
          updatedAt
          __typename
        }
        links {
          id
          url
          title
          status
          order
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      customValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
            __typename
          }
          __typename
        }
        values
        __typename
      }
      meetingId
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.SyncHubsQueryVariables, APITypes.SyncHubsQuery>;
export const hubsByOwnerByStatus = /* GraphQL */ `query HubsByOwnerByStatus(
  $createdBy: ID!
  $status: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelHubFilterInput
  $limit: Int
  $nextToken: String
) {
  hubsByOwnerByStatus(
    createdBy: $createdBy
    status: $status
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tenantId
      name
      status
      createdAt
      createdBy
      updatedAt
      updatedBy
      hubSections {
        id
        sectionHeader
        status
        type
        visible
        order
        textarea {
          content
          __typename
        }
        sharedFiles {
          id
          contentId
          title
          contentType
          documentVersionSettings {
            associatedFiles {
              associatedFileId
              versionId
              notation {
                id
                type
                description
                status
                pageId
                coordinate {
                  x
                  y
                  __typename
                }
                createdAt
                createdBy
                updatedAt
                updatedBy
                __typename
              }
              __typename
            }
            __typename
          }
          status
          createdAt
          updatedAt
          notation {
            id
            type
            description
            status
            pageId
            coordinate {
              x
              y
              __typename
            }
            createdAt
            createdBy
            updatedAt
            updatedBy
            __typename
          }
          __typename
        }
        toDos {
          id
          title
          status
          completedAt
          resolution
          order
          createdAt
          updatedAt
          __typename
        }
        links {
          id
          url
          title
          status
          order
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      customValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
            __typename
          }
          __typename
        }
        values
        __typename
      }
      meetingId
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.HubsByOwnerByStatusQueryVariables,
  APITypes.HubsByOwnerByStatusQuery
>;
export const getCustomFormRecord = /* GraphQL */ `query GetCustomFormRecord($id: ID!) {
  getCustomFormRecord(id: $id) {
    id
    tenantId
    customFormId
    entity
    status
    crmFields {
      accountId
      externalId
      apiName
      recordTypeId
      syncStatus
      lastSyncedAt
      __typename
    }
    name
    parentId
    parentModel
    values {
      fieldId
      objectRecords {
        id
        externalId
        syncStatus
        status
        values {
          fieldId
          values
          __typename
        }
        __typename
      }
      values
      __typename
    }
    createdAt
    createdBy
    updatedAt
    updatedBy
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCustomFormRecordQueryVariables,
  APITypes.GetCustomFormRecordQuery
>;
export const listCustomFormRecords = /* GraphQL */ `query ListCustomFormRecords(
  $filter: ModelCustomFormRecordFilterInput
  $limit: Int
  $nextToken: String
) {
  listCustomFormRecords(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      tenantId
      customFormId
      entity
      status
      crmFields {
        accountId
        externalId
        apiName
        recordTypeId
        syncStatus
        lastSyncedAt
        __typename
      }
      name
      parentId
      parentModel
      values {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
            __typename
          }
          __typename
        }
        values
        __typename
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListCustomFormRecordsQueryVariables,
  APITypes.ListCustomFormRecordsQuery
>;
export const syncCustomFormRecords = /* GraphQL */ `query SyncCustomFormRecords(
  $filter: ModelCustomFormRecordFilterInput
  $limit: Int
  $nextToken: String
  $lastSync: AWSTimestamp
) {
  syncCustomFormRecords(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    lastSync: $lastSync
  ) {
    items {
      id
      tenantId
      customFormId
      entity
      status
      crmFields {
        accountId
        externalId
        apiName
        recordTypeId
        syncStatus
        lastSyncedAt
        __typename
      }
      name
      parentId
      parentModel
      values {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
            __typename
          }
          __typename
        }
        values
        __typename
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.SyncCustomFormRecordsQueryVariables,
  APITypes.SyncCustomFormRecordsQuery
>;
export const customFormRecordByOwnerByStatus = /* GraphQL */ `query CustomFormRecordByOwnerByStatus(
  $createdBy: ID!
  $status: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelCustomFormRecordFilterInput
  $limit: Int
  $nextToken: String
) {
  customFormRecordByOwnerByStatus(
    createdBy: $createdBy
    status: $status
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      tenantId
      customFormId
      entity
      status
      crmFields {
        accountId
        externalId
        apiName
        recordTypeId
        syncStatus
        lastSyncedAt
        __typename
      }
      name
      parentId
      parentModel
      values {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
            __typename
          }
          __typename
        }
        values
        __typename
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    nextToken
    startedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.CustomFormRecordByOwnerByStatusQueryVariables,
  APITypes.CustomFormRecordByOwnerByStatusQuery
>;
export const generateTokenForContentAccess = /* GraphQL */ `query GenerateTokenForContentAccess(
  $documentId: String!
  $documentVersionId: String!
  $authorizedPath: String!
  $durationSeconds: Int!
) {
  generateTokenForContentAccess(
    documentId: $documentId
    documentVersionId: $documentVersionId
    authorizedPath: $authorizedPath
    durationSeconds: $durationSeconds
  ) {
    token
    expiresAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GenerateTokenForContentAccessQueryVariables,
  APITypes.GenerateTokenForContentAccessQuery
>;
export const exchangeContentShareTokenForAccessToken = /* GraphQL */ `query ExchangeContentShareTokenForAccessToken($token: ID!) {
  exchangeContentShareTokenForAccessToken(token: $token) {
    id
    contentId
    token
    key
    expiresAt
    fileName
    associatedFileId
    shareType
    fileExtension
    contentURL
    pageGroups {
      id
      pageIds
      name
      locked
      sourceID
      source
      __typename
    }
    title
    numPages
    notation {
      id
      type
      description
      status
      pageId
      coordinate {
        x
        y
        __typename
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      __typename
    }
    coverThumbnailPageNum
    sharedBy
    tenantId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ExchangeContentShareTokenForAccessTokenQueryVariables,
  APITypes.ExchangeContentShareTokenForAccessTokenQuery
>;
export const getHubAndContentDetailsByToken = /* GraphQL */ `query GetHubAndContentDetailsByToken($token: ID!) {
  getHubAndContentDetailsByToken(token: $token) {
    hubId
    tenantId
    title
    status
    hubSections {
      id
      sectionHeader
      status
      type
      visible
      order
      textarea {
        content
        __typename
      }
      sharedFiles {
        id
        contentId
        title
        contentType
        documentVersionSettings {
          associatedFiles {
            associatedFileId
            versionId
            notation {
              id
              type
              description
              status
              pageId
              coordinate {
                x
                y
                __typename
              }
              createdAt
              createdBy
              updatedAt
              updatedBy
              __typename
            }
            __typename
          }
          __typename
        }
        status
        createdAt
        updatedAt
        notation {
          id
          type
          description
          status
          pageId
          coordinate {
            x
            y
            __typename
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
          __typename
        }
        __typename
      }
      toDos {
        id
        title
        status
        completedAt
        resolution
        order
        createdAt
        updatedAt
        __typename
      }
      links {
        id
        url
        title
        status
        order
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    content {
      id
      contentId
      token
      key
      expiresAt
      fileName
      associatedFileId
      shareType
      fileExtension
      contentURL
      pageGroups {
        id
        pageIds
        name
        locked
        sourceID
        source
        __typename
      }
      title
      numPages
      notation {
        id
        type
        description
        status
        pageId
        coordinate {
          x
          y
          __typename
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        __typename
      }
      coverThumbnailPageNum
      sharedBy
      tenantId
      __typename
    }
    tenantLogoUrl
    sharedBy
    featureFlags {
      flagId
      value
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetHubAndContentDetailsByTokenQueryVariables,
  APITypes.GetHubAndContentDetailsByTokenQuery
>;
export const getHubAndContentDetailsById = /* GraphQL */ `query GetHubAndContentDetailsById($hubId: ID!) {
  getHubAndContentDetailsById(hubId: $hubId) {
    hubId
    tenantId
    title
    status
    hubSections {
      id
      sectionHeader
      status
      type
      visible
      order
      textarea {
        content
        __typename
      }
      sharedFiles {
        id
        contentId
        title
        contentType
        documentVersionSettings {
          associatedFiles {
            associatedFileId
            versionId
            notation {
              id
              type
              description
              status
              pageId
              coordinate {
                x
                y
                __typename
              }
              createdAt
              createdBy
              updatedAt
              updatedBy
              __typename
            }
            __typename
          }
          __typename
        }
        status
        createdAt
        updatedAt
        notation {
          id
          type
          description
          status
          pageId
          coordinate {
            x
            y
            __typename
          }
          createdAt
          createdBy
          updatedAt
          updatedBy
          __typename
        }
        __typename
      }
      toDos {
        id
        title
        status
        completedAt
        resolution
        order
        createdAt
        updatedAt
        __typename
      }
      links {
        id
        url
        title
        status
        order
        createdAt
        updatedAt
        __typename
      }
      createdAt
      updatedAt
      __typename
    }
    content {
      id
      contentId
      token
      key
      expiresAt
      fileName
      associatedFileId
      shareType
      fileExtension
      contentURL
      pageGroups {
        id
        pageIds
        name
        locked
        sourceID
        source
        __typename
      }
      title
      numPages
      notation {
        id
        type
        description
        status
        pageId
        coordinate {
          x
          y
          __typename
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        __typename
      }
      coverThumbnailPageNum
      sharedBy
      tenantId
      __typename
    }
    tenantLogoUrl
    sharedBy
    featureFlags {
      flagId
      value
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetHubAndContentDetailsByIdQueryVariables,
  APITypes.GetHubAndContentDetailsByIdQuery
>;
export const getProviderByUserEmail = /* GraphQL */ `query GetProviderByUserEmail($idpIdentifier: String!) {
  getProviderByUserEmail(idpIdentifier: $idpIdentifier)
}
` as GeneratedQuery<
  APITypes.GetProviderByUserEmailQueryVariables,
  APITypes.GetProviderByUserEmailQuery
>;
export const getReportList = /* GraphQL */ `query GetReportList($folderName: String!, $isPublisherMode: Boolean) {
  getReportList(folderName: $folderName, isPublisherMode: $isPublisherMode) {
    dashboards {
      reports {
        dashboardId
        name
        group {
          name
          order
          __typename
        }
        __typename
      }
      dashboardUrl
      dashboardId
      name
      dashboardSheets {
        sheetId
        name
        __typename
      }
      __typename
    }
    isTeamLeader
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetReportListQueryVariables,
  APITypes.GetReportListQuery
>;
export const getEmbeddedUrl = /* GraphQL */ `query GetEmbeddedUrl(
  $dashboardId: String!
  $folderName: String!
  $isPublisherMode: Boolean
) {
  getEmbeddedUrl(
    dashboardId: $dashboardId
    folderName: $folderName
    isPublisherMode: $isPublisherMode
  )
}
` as GeneratedQuery<
  APITypes.GetEmbeddedUrlQueryVariables,
  APITypes.GetEmbeddedUrlQuery
>;
export const getSharedFolders = /* GraphQL */ `query GetSharedFolders {
  getSharedFolders {
    folders {
      id
      tenantId
      name
      isPinned
      status
      items {
        id
        type
        itemId
        status
        addedAt
        updateAcknowledgedAt
        itemLastUpdatedAt
        visiblePages
        customTitle
        __typename
      }
      createdAt
      createdBy
      updatedAt
      updatedBy
      sharePermissions {
        id
        isDeleted
        type
        targetType
        targetUsername
        targetCustomValues {
          fieldId
          objectRecords {
            id
            externalId
            syncStatus
            status
            values {
              fieldId
              values
              __typename
            }
            __typename
          }
          values
          __typename
        }
        targetLabels {
          key
          values
          __typename
        }
        createdAt
        createdBy
        updatedAt
        updatedBy
        deletedBy
        deletedAt
        __typename
      }
      shareStatus
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    permissions {
      id
      type
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetSharedFoldersQueryVariables,
  APITypes.GetSharedFoldersQuery
>;
export const getDependenciesForSharedFolder = /* GraphQL */ `query GetDependenciesForSharedFolder($folderId: ID!, $parentFolderId: ID!) {
  getDependenciesForSharedFolder(
    folderId: $folderId
    parentFolderId: $parentFolderId
  ) {
    customDecks {
      id
      createdAt
      createdBy
      autoUpdateAcknowledgedAt
      updatedAt
      updatedBy
      tenantId
      groups {
        id
        pages {
          pageId
          pageNumber
          documentVersionId
          __typename
        }
        visible
        srcId
        docAccessLevel
        name
        locked
        __typename
      }
      title
      editMutex {
        userId
        timeStarted
        lastSeenAt
        __typename
      }
      sourceCustomDeckId
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    documents {
      id
      tenantId
      accessLevel
      status
      owner
      expiresAt
      integration {
        integrationId
        externalId
        firstSync
        lastSync
        __typename
      }
      integrationType
      type
      editPermissions
      createdAt
      createdBy
      updatedAt
      updatedBy
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    documentVersions {
      id
      tenantId
      documentId
      versionNumber
      srcFilename
      contentURL
      conversionStatus
      status
      srcFile {
        bucket
        region
        key
        url
        __typename
      }
      srcHash
      srcSize
      numPages
      pageSettings {
        pageId
        number
        isRequired
        linkedSlides
        __typename
      }
      pageGroups {
        id
        pageIds
        name
        locked
        sourceID
        source
        __typename
      }
      type
      releaseNotes
      changeType
      labelValues {
        key
        value
        __typename
      }
      customValues {
        fieldId
        objectRecords {
          id
          externalId
          syncStatus
          status
          values {
            fieldId
            values
            __typename
          }
          __typename
        }
        values
        __typename
      }
      title
      shortDescription
      longDescription
      owner
      expiresAt
      hasCopyright
      hasCustomThumbnail
      purpose
      canHideSlides
      distributable
      downloadable
      isInternalGenerated
      semVer {
        minor
        major
        __typename
      }
      notificationScope
      selectedThumbnail
      publishedAt
      uploadedAt
      uploadedBy
      convertedArchiveKey
      convertedArchiveSize
      convertedFolderKey
      associatedFiles {
        id
        isDistributable
        isDefault
        type
        attachmentId
        status
        createdAt
        createdBy
        __typename
      }
      editPermissions
      converterVersion
      createdAt
      createdBy
      updatedAt
      updatedBy
      integration {
        externalVersionId
        version
        timestamp
        srcFileHash
        srcDocumentHash
        __typename
      }
      integrationType
      conversionWarningCode
      conversionErrorCode
      scheduledPublish
      mappingStatus
      _version
      _deleted
      _lastChangedAt
      __typename
    }
    accessTokens {
      documentVersionId
      accessToken
      accessTokenExpire
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDependenciesForSharedFolderQueryVariables,
  APITypes.GetDependenciesForSharedFolderQuery
>;
export const getCustomDeckLambda = /* GraphQL */ `query GetCustomDeckLambda(
  $customDeckId: ID!
  $folderId: ID!
  $rootFolderId: ID!
) {
  getCustomDeckLambda(
    customDeckId: $customDeckId
    folderId: $folderId
    rootFolderId: $rootFolderId
  ) {
    id
    createdAt
    createdBy
    autoUpdateAcknowledgedAt
    updatedAt
    updatedBy
    tenantId
    groups {
      id
      pages {
        pageId
        pageNumber
        documentVersionId
        __typename
      }
      visible
      srcId
      docAccessLevel
      name
      locked
      __typename
    }
    title
    editMutex {
      userId
      timeStarted
      lastSeenAt
      __typename
    }
    sourceCustomDeckId
    _version
    _deleted
    _lastChangedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCustomDeckLambdaQueryVariables,
  APITypes.GetCustomDeckLambdaQuery
>;
export const getAvailableIntegrationsByCognitoUser = /* GraphQL */ `query GetAvailableIntegrationsByCognitoUser {
  getAvailableIntegrationsByCognitoUser {
    id
    tenantId
    name
    integrationType
    enabled
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAvailableIntegrationsByCognitoUserQueryVariables,
  APITypes.GetAvailableIntegrationsByCognitoUserQuery
>;
export const getZendeskJWT = /* GraphQL */ `query GetZendeskJWT($feature: ZENDESK_FEATURE!) {
  getZendeskJWT(feature: $feature) {
    token
    expiresAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetZendeskJWTQueryVariables,
  APITypes.GetZendeskJWTQuery
>;
export const getRefreshedContentAccessToken = /* GraphQL */ `query GetRefreshedContentAccessToken(
  $expiredToken: String!
  $durationSeconds: Int!
) {
  getRefreshedContentAccessToken(
    expiredToken: $expiredToken
    durationSeconds: $durationSeconds
  ) {
    token
    expiresAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetRefreshedContentAccessTokenQueryVariables,
  APITypes.GetRefreshedContentAccessTokenQuery
>;
export const getCRMAuthUrl = /* GraphQL */ `query GetCRMAuthUrl {
  getCRMAuthUrl
}
` as GeneratedQuery<
  APITypes.GetCRMAuthUrlQueryVariables,
  APITypes.GetCRMAuthUrlQuery
>;
export const getCRMAuthInformation = /* GraphQL */ `query GetCRMAuthInformation($code: String!) {
  getCRMAuthInformation(code: $code) {
    accessToken
    instanceUrl
    refreshToken
    userInfo {
      id
      organizationId
      displayName
      thumbnail
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetCRMAuthInformationQueryVariables,
  APITypes.GetCRMAuthInformationQuery
>;
export const logOutCRM = /* GraphQL */ `query LogOutCRM($accessToken: String) {
  logOutCRM(accessToken: $accessToken)
}
` as GeneratedQuery<APITypes.LogOutCRMQueryVariables, APITypes.LogOutCRMQuery>;
export const refreshTokenCRM = /* GraphQL */ `query RefreshTokenCRM($accessToken: String, $refreshToken: String) {
  refreshTokenCRM(accessToken: $accessToken, refreshToken: $refreshToken) {
    accessToken
    issuedAt
    signature
    id
    __typename
  }
}
` as GeneratedQuery<
  APITypes.RefreshTokenCRMQueryVariables,
  APITypes.RefreshTokenCRMQuery
>;
export const getPoolUserSubIdByEmail = /* GraphQL */ `query GetPoolUserSubIdByEmail($email: String!) {
  getPoolUserSubIdByEmail(email: $email)
}
` as GeneratedQuery<
  APITypes.GetPoolUserSubIdByEmailQueryVariables,
  APITypes.GetPoolUserSubIdByEmailQuery
>;
export const fullTextSearch = /* GraphQL */ `query FullTextSearch(
  $queryText: String!
  $filter: FullTextSearchFilterInput
  $configName: String
) {
  fullTextSearch(
    queryText: $queryText
    filter: $filter
    configName: $configName
  ) {
    items {
      documentId
      title {
        text
        highlights {
          beginOffset
          endOffset
          topAnswer
          type
          __typename
        }
        __typename
      }
      documentExcerpt {
        text
        highlights {
          beginOffset
          endOffset
          topAnswer
          type
          __typename
        }
        __typename
      }
      queryId
      resultId
      pageNumber
      __typename
    }
    searchServerTime
    searchClientTime
    __typename
  }
}
` as GeneratedQuery<
  APITypes.FullTextSearchQueryVariables,
  APITypes.FullTextSearchQuery
>;
export const sendAccessNotification = /* GraphQL */ `query SendAccessNotification($token: ID!) {
  sendAccessNotification(token: $token)
}
` as GeneratedQuery<
  APITypes.SendAccessNotificationQueryVariables,
  APITypes.SendAccessNotificationQuery
>;
