import React, { PropsWithChildren, useEffect, useRef, useState } from 'react';
import { Hub } from 'aws-amplify/utils';
import subDays from 'date-fns/subDays';
import LoadingScreen, { useIsExternalPlayer } from 'src/screens/Loading'
import { fetchUserAttributes, FetchUserAttributesOutput } from 'aws-amplify/auth'
import { DataStore, syncExpression } from 'aws-amplify/datastore';
import {
  useDataStoreSubscription,
  useSyncMachineSubscription,
  useOfflineManifestSubscription,
} from 'src/state/datastore/subscriptions';
import hydrate from '../../state/datastore/hydrate';
import { useAppSettings } from 'src/state/context/AppSettings';
import { useSelector } from 'react-redux';
import { RootState } from 'src/state/redux';
import { validate as uuidValidate } from 'uuid';
import {
  Folder,
  FolderStatus,
  Hub as BeaconHub,
  HubStatus,
  Meeting,
  UserNotations,
  UserNotationsStatus,
} from '@alucio/aws-beacon-amplify/src/models'
import { useLDClient } from 'launchdarkly-react-client-sdk';
import AuthUtil from '../Authenticator/services/authUtil';
import useLogOut from '../Authenticator/LogOut';
import { useValidateUser } from '../Authenticator/useValidateUser';
import { DNALoaderEvents } from '../DNA/Loader/DNALoader';
import * as logger from 'src/utils/logger'
import useHandleDSInitialSyncingStatus from 'src/hooks/useHandleDSInitialSyncingStatus/useHandleDSInitialSyncingStatus';

const UserInit: React.FC<PropsWithChildren> = (props) => {
  const { children } = props;
  const [, setError] = useState<any>()
  const isHydrated = useSelector((state: RootState) => state.document.hydrated && state.emailTemplate.hydrated);
  const [dataStoreReady, setDataStoreReady] = useState(isHydrated);
  const dataStoreSubscribed = useDataStoreSubscription(dataStoreReady);
  const isExternalPlayer = useIsExternalPlayer();
  const { isOfflineEnabled } = useAppSettings();
  const { isPerformingLogOut, hasFinishedLogOut } = useLogOut();
  const syncMachineSubscribed = useSyncMachineSubscription(dataStoreReady, isOfflineEnabled);
  const isDataStorePublishingEventsRef = useRef<boolean>(false);
  const [isDataStoreHung, setIsDataStoreHung] = useState<boolean>(false);
  const isInitialSyncingProcessCompleted = useHandleDSInitialSyncingStatus();

  useOfflineManifestSubscription();
  const LDClient = useLDClient();

  useEffect(() => {
    let removeListener: () => void | undefined
    let dataStoreTimeOut;
    const init = async () => {
      const initializer = isExternalPlayer ? 'External Player' : 'Beacon';
      logger.userInit.debug(`${initializer} initializing...`);
      if (isPerformingLogOut || hasFinishedLogOut) {
        return;
      }

      if (isOfflineEnabled !== undefined && LDClient && !isHydrated) {
        removeListener = Hub.listen('datastore', async (capsule) => {
          isDataStorePublishingEventsRef.current = true;
          const isDataStoreReady = isInitialSyncingProcessCompleted(capsule);

          if (isDataStoreReady) {
            // [NOTE] - Since this code executes async in a listener function, errors will not
            //          be caught within the React lifecycle
            //        - In order to bring it back within the lifecycle, we must use a hook
            //          per https://github.com/facebook/react/issues/14981#issuecomment-468460187
            //        - For all other errors within the parent `init` function, the try/catch
            //          should be able to pick those errors up accordingly
            await hydrate(isOfflineEnabled, LDClient)
              .catch(err => { setError(() => { throw err }) });
            setDataStoreReady(true);
            logger.userInit.debug('Done hydrating redux')
            localStorage.setItem('hasDatastoreHydrated', 'true');
            removeListener();
          }
        });

        // IF NO EVENT IS PUBLISHED BY DATASTORE WITHIN 5 SECONDS
        // WE'LL DISPLAY THE "INSTRUCTIONS TO REFRESH" COMPONENT
        dataStoreTimeOut = setTimeout(() => {
          if (!isDataStorePublishingEventsRef.current) {
            logger.userInit.warn('No event has been published by DataStore');
            setIsDataStoreHung(true);
          }
        }, 5000);

        // Configure datastore to use the tenantId GSI when querying dynamoDB rather than a scan
        let cognitoUserAttributes:FetchUserAttributesOutput = {};
        const ninetyDaysAgo = subDays(new Date(), 90).toISOString();
        try {
          cognitoUserAttributes = await fetchUserAttributes();
        } catch (e) {
          if (localStorage.getItem('amplify-latest-user-attributes') !== null) {
            cognitoUserAttributes = JSON.parse(localStorage.getItem('amplify-latest-user-attributes') ?? '');
          } else {
            logger.userInit.error('Error getting current authenticated user', e);
            return;
          }
        }
        const tenantId = cognitoUserAttributes['custom:org_id'] || ''
        const oneHourAgo = new Date(Date.now() - 60 * 60 * 1000);
        const syncExpressions = uuidValidate(tenantId) ? [
          syncExpression(UserNotations, () => {
            return obj => obj.and(p => [p.status.ne(UserNotationsStatus.DELETED)])
          }),
          syncExpression(Folder, () => {
            return obj => obj.or(p => [
              p.and(p => [p.status.ne(FolderStatus.REMOVED)]),
              p.and(p => [p.updatedAt.ge(oneHourAgo.toISOString())]),
            ])
          }),
          syncExpression(Meeting, () => {
            return obj => obj.and(p => [p.startTime.gt(ninetyDaysAgo)]);
          }),
          syncExpression(BeaconHub, () => {
            return obj => obj.and(p => [p.status.ne(HubStatus.DELETED)]);
          }),
        ] : [];

        const LOCALSTORAGE_FULL_SYNC_INTERVAL_MIN = parseInt(localStorage.getItem('FULL_SYNC_INTERVAL_MIN') ?? '', 10)
        const fullSyncInterval = isNaN(LOCALSTORAGE_FULL_SYNC_INTERVAL_MIN)
          ? undefined
          : LOCALSTORAGE_FULL_SYNC_INTERVAL_MIN

        if (fullSyncInterval) {
          logger.userInit.debug('Setting custom FULL_SYNC_INTERVAL (min)', fullSyncInterval)
        }

        DataStore.configure({
          syncPageSize: 250,
          errorHandler: (error) => logger.userInit.warn('Datastore Sync Error', error),
          syncExpressions: syncExpressions,
          fullSyncInterval,
        })

        // Start the DataStore, this kicks-off the sync process.
        logger.userInit.debug('Calling dataStoreInit')
        AuthUtil.dataStoreInit()
      }
    }
    try {
      init()
    }
    catch (err) {
      // [NOTE] - This triggers the error-boundary correctly for everything outside of the
      //          Hub listener
      setError(() => { throw err })
    }
    return () => {
      logger.userInit.debug('Cleanup function called, remove listener')
      clearTimeout(dataStoreTimeOut);
      removeListener?.();
    }
  }, [isOfflineEnabled, LDClient, isHydrated, isPerformingLogOut, isExternalPlayer, setError]);

  const isDataStoreReady = (
    dataStoreReady &&
    dataStoreSubscribed &&
    (!isOfflineEnabled || syncMachineSubscribed) &&
    isHydrated
  )

  if (!isDataStoreReady) {
    logger.userInit.debug('Missing DataStore Syncing Flags:');
    !dataStoreReady && logger.userInit.debug('DataStore Initial Syncing');
    !dataStoreSubscribed && logger.userInit.debug('DataStore Subscriptions');
    !isHydrated && logger.userInit.debug('ReduxStore Hydration');
    isOfflineEnabled && !syncMachineSubscribed && logger.userInit.debug('SyncMachine Subscription');
  }

  return isDataStoreReady
    ? <>{children}</>
    : <LoadingScreen
        showRefreshComponent={isDataStoreHung}
        analyticsEventType={DNALoaderEvents.LOGIN}
        context="User Init - App Init"
    />
};

/*
  * This component is used to prevent the compenet will be rendered when the user is logging out or logging in
*/
const UserAuthWrapper: React.FC<PropsWithChildren> = (props) => {
  const { isPerformingLogOut, hasFinishedLogOut } = useLogOut();
  const { isValidatingUser } = useValidateUser()
  const { isOnline } = useAppSettings();

  const { children } = props;

  if (isValidatingUser && isOnline) return <LoadingScreen context="User Init - Validating User" />
  // NOTE: in theory, after logging out, Amplify should prevent this component from being rendered. However, there
  // seems to be a brief delay during which the component may still render, causing the UserInit function to re-render.
  // The "hasFinishedLogOut" condition serves as an additional security layer to handle this brief gap.
  if (isPerformingLogOut || hasFinishedLogOut) return <LoadingScreen context="User Init - Logging Out"/>

  return (
    <UserInit>
      {children}
    </UserInit>
  )
}

export default UserAuthWrapper;
