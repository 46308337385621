import { createMachine, InterpreterFrom } from 'xstate';
import { Hub, HubCapsule } from 'aws-amplify/utils'
import { DataStore } from 'aws-amplify/datastore';
import AuthUtil from '../../../components/Authenticator/services/authUtil';
import Observable from 'zen-observable';
import { assign } from '@xstate/immer';
import * as logger from 'src/utils/logger'

export type ValidateUserMachine = typeof validateUserMachine
export type ValidateUserService = InterpreterFrom<ValidateUserMachine>
export type PrevUserType = { email: string, date: Date } | null

const observable = new Observable<{
  type: 'SIGN_IN',
  payload: {
    data: HubCapsule<'auth', any>
  }
}>(subscriber => {
  const listener = async (data: HubCapsule<'auth', any>) => {
    logger.auth.signIn.authenticator.info('Auth event received', data);
    subscriber.next({ type: 'SIGN_IN', payload: { data } });
    logger.auth.signIn.authenticator.info('Started listening for auth events');
  }
  const unregister = Hub.listen('auth', listener);
  return () => {
    unregister()
    logger.auth.signIn.authenticator.info('Stopped listening for auth events');
  };
});

type Context = {
  error?: string;
}

type SIGN_IN = { type: 'SIGN_IN', payload: {data : HubCapsule<'auth', any>} }

type Event = SIGN_IN

export const validateUserMachine = createMachine<Context, Event, any>({
  id: 'validateUserMachine',
  initial: 'idle',
  context: {
    error: undefined,
  },
  states: {
    idle: {
      invoke : {
        id: 'authListener',
        src: () => observable,
        onDone: {
          target: 'validateUser',
        },
      },
    },
    validateUser: {
      tags: ['validatingUser'],
      invoke: {
        id: 'validateUser',
        src: 'validateUser',
        onDone: {
          target: '#validateUserMachine.idle',
        },
        onError: {
          target: '#validateUserMachine.error',
        },
      },
    },
    done: {
      type: 'final',
    },
    error: {
      entry: ['onError'],
    },
  },
  on: {
    SIGN_IN: {
      target: 'validateUser',
    },
  },
}, {
  actions: {
    onError: assign((context, event) => {
      context.error = JSON.stringify(event.payload);
      logger.auth.signIn.authenticator.error('Error assigned to context', context.error);
    }),
  },
  services: {
    validateUser: async (context, event) => {
      const { data } = event.payload;
      const authEvent = data.payload.event;

      if (authEvent === 'signedIn') {
        logger.auth.signIn.authenticator.info('signIn event detected');
        // we cannot use the payload here, because for sso the email is not present
        const currUserEmail = await AuthUtil.getEmail();

        await DataStore.stop();

        const prevUser = AuthUtil.getPrevAuthUser();
        const isDifferentUser = (
          prevUser?.email &&
          (prevUser.email !== currUserEmail)
        )
        const shouldPurgeExisting = (
          !currUserEmail ||
          isDifferentUser
        )

        if (shouldPurgeExisting) {
          logger.auth.signIn.authenticator.info('Purging all previous user data', { currUserEmail, prevUser });

          try {
            await AuthUtil.clearDataStore();
            await AuthUtil.purgeCacheDB();
            await AuthUtil.cleanCRMData();
            await AuthUtil.clearOfflineAnalyticsDB();
            await AuthUtil.deleteDBs();
            logger.auth.signIn.authenticator.info('Data cleaned successfully');
          } catch (e) {
            logger.auth.signIn.authenticator.error('Error cleaning previous user data', e);
          }

          AuthUtil.clearUserDetailLocalStorage();
          AuthUtil.clearPrevAuthUser();
          logger.auth.signIn.authenticator.info('Finished cleaning previous user data');
        }
      }
    },
  },
});
