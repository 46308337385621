import React from 'react'
import { v4 as uniqueId } from 'uuid'
import { DNABox, DNAButton, DNAText } from '@alucio/lux-ui'
import DNAPopover from 'src/components/DNA/Popover/DNAPopover'
import { DNAButtonProps } from '@alucio/lux-ui/src/components/controls/DNAButton/DNAButton'
import colors from '@alucio/lux-ui/src/theming/themes/alucio/colors'

import { usePresentationBuilderState } from '../state/PresentationBuilderStateProvider'
import Header from './Header'
import SlidesGroupTarget from 'src/components/DnD/Clone/PresentationBuilder/SlidesGroupTarget'
import FindReplacement from './FindReplacement/FindReplacement'

export interface Action extends DNAButtonProps {
  label?: string
  fill?: boolean
  hidden?: boolean
  tooltip?: string
}

export const usePresentationBuilderSharedResources = () => {
  const containerMargin = 15
  const getActionEls: (actions: Action[]) => JSX.Element[] = (actions) => {
    return actions.filter(action => !action.hidden).map(action => {
      const {
        disabled = false,
        iconLeft,
        onPress,
        label,
        appearance = 'ghostLink',
        status = 'tertiary',
        fill,
        style,
        size = 'md',
        tooltip,
        padding,
        testID,
      } = action

      const actionButton =
        (<DNAButton
          disabled={disabled}
          iconLeft={iconLeft}
          onPress={onPress}
          appearance={appearance}
          status={status}
          style={style}
          size={size}
          padding={padding}
          testID={testID}
        >
          {label}
        </DNAButton>)

      return (
        <DNABox
          key={uniqueId()}
          fill={fill}
        >
          {tooltip
            ? <DNAPopover placement="top" disablePopover={['tabletPWA']}>
              <DNAPopover.Anchor>
                {actionButton}
              </DNAPopover.Anchor>
              <DNAPopover.Content>
                <DNAText
                  style={{ color: colors['color-text-white'] }}
                >{tooltip}</DNAText>
              </DNAPopover.Content>

            </DNAPopover>
            : actionButton}
        </DNABox>
      )
    })
  }

  return {
    containerMargin,
    getActionEls,
  }
}

const PresentationEditor: React.FC = () => {
  const { builderMode } = usePresentationBuilderState()
  const isSelectionMode = builderMode === 'selection'

  return (
    <>
      <FindReplacement />
      <DNABox
        fill
        appearance="col"
        style={{
          maxWidth: isSelectionMode ? 600 : undefined,
          borderLeftColor: colors['color-gray-80'],
          borderLeftWidth: isSelectionMode ? 2 : undefined,
        }}
      >
        <Header />
        <SlidesGroupTarget />
      </DNABox>
    </>
  )
}
export default PresentationEditor
